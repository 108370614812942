import { useEffect, useState } from 'react';

export const useStepper = () => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [isFirstStep, setFirstStep] = useState<boolean>(false);
  const [isLastStep, setLastStep] = useState<boolean>(false);

  useEffect(() => {
    if (activeStep === 0) {
      setFirstStep(true);
    } else {
      setFirstStep(false);
    }
    if (activeStep >= 4) {
      setLastStep(true);
    } else {
      setLastStep(false);
    }
  }, [activeStep]);

  const prevStep = () => {
    if (!isFirstStep) {
      setActiveStep(prev => prev - 1);
    }
  };

  const nextStep = () => {
    if (!isLastStep) {
      setActiveStep(prev => prev + 1);
    }
  };

  const setStep = (stepIndex: number) => {
    if (stepIndex < activeStep) {
      setActiveStep(stepIndex);
    }
  };

  return {
    activeStep,
    setStep,
    prevStep,
    nextStep,
    isFirstStep,
    isLastStep
  };
};
