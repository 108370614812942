import { Dispatch, FC, SetStateAction, useMemo, useRef } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { useChildHeightExceeded } from 'hooks';
import { ValidatedWord, ValidatedWordData, ValidatedWordsData } from 'models';
import styles from './UploadWords.module.css';
import { filterWordsForAddingToLesson, getStatusStatisticsFromWords } from './utils';

import Button from 'components/atoms/Button';
import { notifySuc } from 'helpers/notification';
import { StatusBar, WordCard } from './components';

export interface FieldValues {
  words: ValidatedWord[];
}

type Params = {
  lessonId: string;
};

type UploadWordsProps = {
  validatedWordsData: ValidatedWordsData;
  setValidatedWordsData: Dispatch<SetStateAction<ValidatedWordsData | null>>;
};

const UploadWords: FC<UploadWordsProps> = ({
  validatedWordsData: { words },
  setValidatedWordsData
}) => {
  const { lessonId } = useParams<keyof Params>() as Params;
  const queryClient = useQueryClient();

  const { mutate: createAndAddWordsMutate, isLoading: createAndAddWordsLoading } = useMutation(
    ({ lessonId, words }: { lessonId: string; words: ValidatedWordData[] }) => {
      return apiCourses.createAndAddWords(lessonId, words);
    },
    {
      onSuccess() {
        notifySuc('Слова добавлены в урок');

        queryClient.invalidateQueries([`lessons/one/${lessonId}`]);
        setValidatedWordsData(null);
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const handleReset = () => {
    setValidatedWordsData(null);
  };

  const handleSubmit = async () => {
    const filteredWords = filterWordsForAddingToLesson(words);
    const filteredWordsData = filteredWords.map(word => word.data);

    createAndAddWordsMutate({ lessonId, words: filteredWordsData });
  };

  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLUListElement>(null);
  const [isChildHeightExceeded] = useChildHeightExceeded(parentRef, childRef);

  const statusStatistics = useMemo(() => {
    const statusStatistics = getStatusStatisticsFromWords(words);
    return statusStatistics;
  }, [words]);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.header__title}>Загрузить слова в урок</h2>
      </header>

      <StatusBar value={statusStatistics} />

      <div
        className={`${styles.words} ${isChildHeightExceeded ? 'pr-[21px]' : ''}`}
        ref={parentRef}
      >
        {words ? (
          <ul className={styles.words__list} ref={childRef}>
            {words.map((word, index) => (
              <WordCard
                key={JSON.stringify(word) + index}
                lessonId={lessonId}
                word={word}
                index={index}
                setValidatedWordsData={setValidatedWordsData}
              />
            ))}
          </ul>
        ) : (
          <span>Загрузка...</span>
        )}
      </div>

      <div className={styles.buttons}>
        <Button
          type='reset'
          variant='secondary'
          title='Отменить'
          className='w-[198px]'
          isDisabled={createAndAddWordsLoading}
          onClick={handleReset}
        />

        <Button
          type='submit'
          variant='primary'
          title='Загрузить'
          className='w-[198px]'
          isDisabled={
            !statusStatistics.new && !statusStatistics.inVocabulary && !statusStatistics.newExamples
          }
          isLoading={createAndAddWordsLoading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default UploadWords;
