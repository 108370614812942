import { useMutation, useQueryClient } from 'react-query';

import { apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export interface UploadCourseMiniImageDto {
  courseId: string;
  courseMiniImage: File;
}

export const useUploadCourseMiniImageMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ courseId, courseMiniImage }: UploadCourseMiniImageDto) => {
      return apiCourses.setCourseMiniImage(courseId, courseMiniImage);
    },
    {
      onSuccess(_, variables) {
        notifySuc('Миниатюра курса загружена');

        // TODO add response with new image url on the backend for cache updating
        queryClient.invalidateQueries([`courses/one/${variables.courseId}`], {
          refetchActive: false
        });
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
