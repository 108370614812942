import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { apiCourses } from 'api/api-courses';
import { FORM_ERROR_MESSAGES } from 'consts';
import { handleError } from 'helpers/handleError';
import styles from './DeleteModuleForm.module.css';

import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';
import Input from 'components/atoms/Input';
import { notifySuc } from 'helpers/notification';

interface DeleteModuleFormProps {
  courseId: string;
  moduleId: string;
  currentTitle: string;
  refetchCourse: () => void;
  onCancel: () => void;
}

const DeleteModuleForm: React.FC<DeleteModuleFormProps> = ({
  courseId,
  moduleId,
  currentTitle,
  refetchCourse,
  onCancel
}) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FieldValues>({ mode: 'onSubmit' });

  const { mutate: deleteModuleMutate, isLoading: deleteModuleLoading } = useMutation(
    () => {
      return apiCourses.deleteModules(courseId, [moduleId]);
    },
    {
      onSuccess() {
        notifySuc('Модуль удален');

        refetchCourse();
        onCancel();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const onSubmit = async (data: FieldValues) => {
    const { value } = data;

    if (value.trim() === currentTitle) {
      deleteModuleMutate();
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <span className={styles.title}>
          Удалить модуль <br />
          {currentTitle}?
        </span>
        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <p className={styles.text}>
        Модуль будет удален навсегда.
        <br />
        Вместе с модулем <b>будут удалены уроки и их шаги</b>.
        <br />
        <br />
        Чтобы подтвердить, пожалуйста, <b>введите имя модуля</b>
      </p>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          <Input
            type='text'
            name='value'
            variant='dark'
            width='big'
            placeholder='Название модуля'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                },
                equalToCurrentValue: (value: string) =>
                  value.trim() === currentTitle ? true : FORM_ERROR_MESSAGES.EQUAL_TO_CURRENT_TITLE
              }
            }}
            loading={deleteModuleLoading}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            onClick={onCancel}
            isDisabled={deleteModuleLoading}
          />

          <Button
            type='submit'
            variant='warning'
            title='Удалить модуль'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={deleteModuleLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default DeleteModuleForm;
