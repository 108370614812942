/* eslint-disable react-hooks/exhaustive-deps */

import {
  ActiveElement,
  BubbleDataPoint,
  CategoryScale,
  Chart,
  ChartEvent,
  Chart as ChartJS,
  ChartTypeRegistry,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  Point,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  TooltipModel
} from 'chart.js';
import { FC, useCallback, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { AllStudentsDateData } from 'api/general-analytics';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from 'consts';
import { useGetAllStudentsQuery } from 'hooks/general-analytics';
import { TooltipData } from 'models';
import { convertToLocalDate, findMaximum } from 'utils';
import { chartOptions } from './const';
import { fillMissingDays } from './utils';

import { DateRange, DateRangePicker } from 'components/atoms';
import Button from 'components/atoms/Button';
import Modal from 'components/atoms/Modal';
import { IconImport } from 'components/atoms/icons';
import { CustomTooltip } from '../CustomTooltip';
import { NewUsersDay } from '../NewUsersDay';
import AllUsersSkeleton from './AllUsersSkeleton';
import { Periods } from './Periods';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

interface Params {
  courseId: string;
}

const AllUsers: FC = () => {
  const [date, setDate] = useState<DateRange>({
    from: new Date(DEFAULT_START_DATE),
    to: new Date(DEFAULT_END_DATE)
  });

  const { data: allStudentsData, isLoading: allStudentsLoading } = useGetAllStudentsQuery({
    startDate: date.from ? date.from.toLocaleDateString('en') : DEFAULT_START_DATE,
    endDate: date.to
      ? date.to.toLocaleDateString('en')
      : date.from
      ? date.from.toLocaleDateString('en')
      : DEFAULT_END_DATE
  });

  const [tooltipData, setTooltipData] = useState<TooltipData>({
    showTooltip: false,
    x: undefined,
    y: undefined,
    height: undefined,
    width: undefined,
    xAlign: undefined,
    yAlign: undefined,
    date: undefined,
    label: undefined
  });
  const [currentData, setCurrentData] = useState<AllStudentsDateData[]>([]);
  const [currentDate, setCurrentDate] = useState<number>(0);
  const [dayModalActive, setDayModalActive] = useState<boolean>(false);

  const gradient = useMemo(() => {
    const ctx = document.createElement('canvas').getContext('2d');
    if (!ctx) return;
    const gradient: CanvasGradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, 'rgba(68, 48, 255, 0.08)'); // Start color at the top
    gradient.addColorStop(1, 'rgba(68, 48, 255, 0.00)'); // End color at the bottom
    return gradient;
  }, []);

  const chartData = useMemo(() => {
    if (!allStudentsData) return;

    const filledData = allStudentsData
      ? fillMissingDays(
          allStudentsData,
          date.from || new Date(DEFAULT_START_DATE),
          date.to || date.from || new Date(DEFAULT_START_DATE)
        )
      : [];
    setCurrentData(filledData);

    const data = filledData.map(item => item.cumulativeCount);
    const labels = filledData.map(item => convertToLocalDate(item.date));

    return {
      labels,
      datasets: [
        {
          label: 'Ученика(ов)',
          data,
          fill: {
            target: 'origin',
            above: gradient || 'rgba(68, 48, 255, 0.08)'
          },
          tension: 0.3
        }
      ]
    };
  }, [allStudentsData]);

  const handleClick = (
    event: ChartEvent,
    elements: ActiveElement[],
    chart: ChartJS<
      keyof ChartTypeRegistry,
      (number | Point | [number, number] | BubbleDataPoint | null)[],
      unknown
    >
  ) => {
    if (!elements.length || !currentData.length) return;
    const index = elements[0].index;
    const date = currentData[index]?.date;
    if (!date) return;
    setCurrentDate(date);
    setDayModalActive(true);
  };

  const externalTooltipHandler = useCallback<
    (
      this: TooltipModel<'line'>,
      args: {
        chart: Chart<
          keyof ChartTypeRegistry,
          (number | Point | [number, number] | BubbleDataPoint | null)[],
          unknown
        >;
        tooltip: TooltipModel<'line'>;
      }
    ) => void
  >(
    ({ chart, tooltip }) => {
      // hide if no tooltip
      if (tooltip.opacity === 0 && tooltipData.showTooltip) {
        setTooltipData(tooltipData => ({ ...tooltipData, showTooltip: false }));
        return;
      }

      if (tooltipData.y !== tooltip.y || tooltipData.x !== tooltip.x) {
        setTooltipData({
          showTooltip: true,
          height: tooltip.height,
          width: tooltip.width,
          x: tooltip.x,
          y: tooltip.y,
          xAlign: tooltip.xAlign,
          yAlign: tooltip.yAlign,
          date: tooltip.title[0],
          label: tooltip.body[0].lines[0]
        });
      }
    },
    [tooltipData.showTooltip, tooltipData.x, tooltipData.y]
  );

  if (allStudentsLoading) return <AllUsersSkeleton />;

  return (
    <div className='flex w-full flex-1 flex-col'>
      <Periods />

      <header className='flex items-center justify-between py-[16px] px-[15px]'>
        <h3 className='text-[21px] font-[700] leading-[24px] text-[#20233A]'>Всего учащихся</h3>

        <DateRangePicker date={date} setDate={setDate} />

        <Button
          type='button'
          variant='light'
          title='Скачать .xlsx (90Кб)'
          icon={<IconImport color='#5770F3' />}
          iconPosition='left'
          className='h-[48px] rounded-[62px]'
          isDisabled
        />
      </header>

      <div className='flex flex-1 px-[15px] pb-[30px]'>
        <div className='relative flex flex-1 cursor-pointer'>
          {allStudentsLoading ? (
            <span>Загрузка...</span>
          ) : (
            <>
              {chartData ? (
                <Line
                  data={chartData}
                  options={{
                    ...chartOptions,
                    plugins: {
                      ...chartOptions.plugins,
                      tooltip: {
                        enabled: false,
                        external: externalTooltipHandler
                      }
                    },
                    scales: {
                      ...chartOptions.scales,
                      y: {
                        suggestedMax: findMaximum(chartData.datasets[0].data) * 1.1,
                        ticks: {
                          stepSize: 100,
                          color: '#A6B0C9',
                          font: {
                            size: 14
                          }
                        }
                      }
                    },
                    onClick: handleClick
                  }}
                />
              ) : (
                <span>Для данного курса статистика не записывается</span>
              )}
            </>
          )}

          <CustomTooltip tooltipData={tooltipData} />
        </div>
      </div>

      {dayModalActive && (
        <Modal onClose={() => setDayModalActive(false)}>
          <NewUsersDay date={currentDate} onCancel={() => setDayModalActive(false)} />
        </Modal>
      )}
    </div>
  );
};

export default AllUsers;
