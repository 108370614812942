/* eslint-disable react-hooks/exhaustive-deps */

import { ChangeEvent, FC, useEffect, useRef, useState, MouseEvent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { IWord } from 'api/api-words';
import { createLinkToThematicDictionary, WORDS_TABLE_HEADERS } from 'consts';
import { useGetDictionaryQuery } from 'hooks/dictionary';
import useDebounce from 'hooks/useDebounce';
import styles from './Content.module.css';
import { handleFileDownload } from './lib';
import Modal from 'components/atoms/Modal';
import SearchField from 'components/atoms/SearchField/SearchField';
import {
  IconCopyLink,
  IconImport,
  IconPen,
  IconTrash,
  IconUpload,
  IconCopy,
  IconPlus
} from 'components/atoms/icons';
import { AddWordForm } from '..';
import ContentSkeleton from './ContentSkeleton';
import { DeleteDictionaryForm, UpdateDictionaryForm, WordCard } from './components';
import { notifySuc } from 'helpers/notification';
import { useDeleteWordsMutation } from 'hooks/mutations';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import { MenuButton } from 'components/atoms';
import { MenuItems } from 'components/atoms/Menu/MenuButton';

type Params = {
  dictionaryId: string;
};

interface ContentProps {
  handleUploadFile: (event: ChangeEvent<HTMLInputElement>) => void;
  fileUploadLoading?: boolean;
  notionTextUploadLoading: boolean;
  toggleIsNotionTextParseOpen: () => void;
}

const Content: FC<ContentProps> = ({
  handleUploadFile,
  fileUploadLoading,
  notionTextUploadLoading,
  toggleIsNotionTextParseOpen
}) => {
  const { dictionaryId } = useParams<keyof Params>() as Params;
  const { data: dictionary, isLoading: dictionaryLoading } = useGetDictionaryQuery(dictionaryId);
  const [isMenuMustBeOpened, setIsMenuMustBeOpened] = useState<boolean>(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [addWordModalActive, setAddWordModalActive] = useState<boolean>(false);
  const [updateDictionaryModalActive, setUpdateDictionaryModalActive] = useState<boolean>(false);
  const [deleteDictionaryModalActive, setDeleteDictionaryModalActive] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [deleteAllWordsModalActive, setDeleteAllWordsModalActive] = useState<boolean>(false);
  const { mutate: deleteWordsMutate, isLoading: deleteWordsLoading } = useDeleteWordsMutation();

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    setSearchValue(value);
  }, [debouncedSearchValue]);

  const handleCopyLinkToClipboard = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(createLinkToThematicDictionary({ vocabularyId: dictionaryId }))
      .then(() => notifySuc('Ссылка скопирована'));
  };

  const handleDeleteAllWords = () => {
    setDeleteAllWordsModalActive(true);
  };

  const deleteAllWords = () => {
    const wordIds: string[] = dictionary?.words?.map(({ id }) => id) || [];

    deleteWordsMutate(
      { dictionaryId, body: { wordIds } },
      {
        onSuccess: () => {
          setDeleteAllWordsModalActive(false);
        }
      }
    );
  };
  const menuItemsList = useMemo(() => {
    return [
      {
        items: [
          {
            title: 'Добавить одно слово',
            onClick: () => setAddWordModalActive(true),
            icon: <IconPlus color='#5770F3' />
          }
        ]
      },
      {
        title: 'Таблица',
        items: [
          {
            title: 'Скачать шаблон Csv',
            onClick: handleFileDownload,
            icon: <IconImport color='#5770F3' />
          },
          {
            title: 'Загрузить слова из Csv',
            onClick: () => {
              setIsMenuMustBeOpened(true);
              inputFileRef.current?.click();
            },
            icon: <IconUpload color='#5770F3' />,
            wrapper: children => {
              return (
                <label htmlFor='file'>
                  {children}

                  <input
                    type='file'
                    id='file'
                    className='hidden'
                    accept='text/csv'
                    onChange={handleUploadFile}
                    ref={inputFileRef}
                  />
                </label>
              );
            }
          }
        ]
      },
      {
        title: 'Notion',
        items: [
          {
            title: 'Добавить слова из Notion',
            icon: <IconCopy color='#5770F3' />,
            onClick: toggleIsNotionTextParseOpen
          }
        ]
      }
    ] as MenuItems;
  }, [handleUploadFile]);

  const [currentWords, setCurrentWords] = useState<IWord[]>([]);
  useEffect(() => {
    if (dictionary && dictionary.words) {
      const words =
        searchValue.length > 0
          ? dictionary.words.filter(({ id, original, translation }) => {
              const normalizedId = id.toLowerCase();
              const normalizedOriginal = original.toLowerCase();
              const normalizedTranslation = translation.toLowerCase();
              const normalizedSearchValue = searchValue.toLowerCase();

              return (
                normalizedId.includes(normalizedSearchValue) ||
                normalizedOriginal.includes(normalizedSearchValue) ||
                normalizedTranslation.includes(normalizedSearchValue)
              );
            })
          : dictionary.words;

      setCurrentWords(words);
    }
  }, [dictionary, searchValue]);

  if (dictionaryLoading || fileUploadLoading) return <ContentSkeleton />;
  if (!dictionary) return <h2>Не удалось загрузить курс</h2>;
  const wordsCount = currentWords?.length;
  return (
    <>
      <div className={styles.container}>
        <header className='mb-[24px] flex h-[36px] justify-between gap-[8px]'>
          <h2 className='text-[30px] font-[700] leading-[36px] text-[#20233A]'>
            {dictionary.title}
          </h2>
          <div className='flex items-center gap-[32px]'>
            <button title='Скопировать ссылку на словарь' onClick={handleCopyLinkToClipboard}>
              <IconCopyLink />
            </button>
            <button
              className={styles.buttonEdit}
              onClick={() => setUpdateDictionaryModalActive(true)}
            >
              <IconPen color='#71798F' />
            </button>
            <button
              className={styles.buttonDelete}
              onClick={() => setDeleteDictionaryModalActive(true)}
            >
              <IconTrash color='#A1AABC' />
            </button>
          </div>
        </header>

        <div className='mb-[24px] flex h-[50px] items-center justify-between gap-[8px]'>
          <div className='gap-[4px]'>
            <h2 className='h-[24px] text-[21px] font-[700] leading-[24px] text-[#20233A]'>Слова</h2>
            <div className='h-[19px] text-[16px] font-[500]'>{wordsCount} слов</div>
          </div>
          <div className='flex gap-[8px]'>
            <MenuButton
              items={menuItemsList}
              className='mr-2'
              isMustBeOpened={isMenuMustBeOpened}
              setIsMustBeOpened={setIsMenuMustBeOpened}
              title='Добавить слова'
              dropdownMenuContainerClassName='w-[262px]'
            />
            <button
              className='w-[192px] rounded-[62px] bg-red-100 font-bold text-red-500'
              onClick={handleDeleteAllWords}
            >
              Удалить все слова
            </button>
          </div>
        </div>

        <div className={styles.table}>
          <SearchField
            id={'search'}
            type={'text'}
            className='mb-[26px] flex-auto'
            placeholder={'Поиск по ID, на корейском, на русском'}
            required={false}
            value={value}
            setState={setValue}
          />

          <div className={styles.table__headers}>
            <span className={`${styles.table__header} ${styles.table__header_image}`}>
              {WORDS_TABLE_HEADERS[1]}
            </span>

            <span className={`${styles.table__header} ${styles.table__header_kr}`}>
              {WORDS_TABLE_HEADERS[2]}
            </span>

            <span className={`${styles.table__header} ${styles.table__header_ru}`}>
              {WORDS_TABLE_HEADERS[3]}
            </span>

            <span className={`${styles.table__header} ${styles.table__header_actions}`}>
              {WORDS_TABLE_HEADERS[4]}
            </span>
          </div>

          <div className={styles.table__rows}>
            {currentWords.length > 0 ? (
              <>
                {currentWords.map((word, index) => {
                  return (
                    <WordCard
                      key={word.id}
                      word={word}
                      index={index}
                      dictionaryId={dictionary.id}
                    />
                  );
                })}
              </>
            ) : (
              <span>Слово не найдено</span>
            )}
          </div>
        </div>
      </div>

      {addWordModalActive && (
        <Modal onClose={() => setAddWordModalActive(false)}>
          <AddWordForm
            dictionaryId={dictionary.id}
            wordIds={currentWords.map(word => word.id)}
            onCancel={() => setAddWordModalActive(false)}
          />
        </Modal>
      )}

      {updateDictionaryModalActive && (
        <Modal onClose={() => setUpdateDictionaryModalActive(false)}>
          <UpdateDictionaryForm
            dictionary={dictionary}
            onCancel={() => setUpdateDictionaryModalActive(false)}
          />
        </Modal>
      )}

      {deleteDictionaryModalActive && (
        <Modal onClose={() => setDeleteDictionaryModalActive(false)}>
          <DeleteDictionaryForm
            dictionary={dictionary}
            onCancel={() => setDeleteDictionaryModalActive(false)}
          />
        </Modal>
      )}
      {deleteAllWordsModalActive && (
        <ConfirmationModal
          title={<>Удалить все слова в уроке?</>}
          text={
            <>
              Слова будут{' '}
              <b>
                удалены навсегда из Общего словаря и
                <br />
                из всех курсов и уроков
              </b>
              , к которым они относятся.
            </>
          }
          isDelete
          confirmButtonText='Удалить слова'
          onConfirm={deleteAllWords}
          onClose={() => setDeleteAllWordsModalActive(false)}
          isLoading={deleteWordsLoading}
        />
      )}
    </>
  );
};

export default Content;
