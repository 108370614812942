import { Skeleton } from 'components/atoms';
import { FC } from 'react';

const SidebarSkeleton: FC = () => {
  return (
    <div className='flex w-[210px] grow flex-col'>
      <Skeleton height={28} width={170} className='mb-[35px]' />
      <Skeleton height={24} width={160} className='mb-[20px]' />
      <Skeleton height={54} width={54} className='mb-[20px]' />
      <Skeleton height={16} width={43} className='mb-[4px]' />
      <Skeleton height={21} width={206} />
    </div>
  );
};

export default SidebarSkeleton;
