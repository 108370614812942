import React, { FC, useEffect, useMemo, useState } from 'react';
import SearchField from 'components/atoms/SearchField/SearchField';
import { useDebounce } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import {
  DEFAULT_PAGE,
  SEARCH_PARAMS,
  DEFAULT_DEALS_PER_PAGE,
  dealsPerPageOptions,
  filterSelectStyles
} from './const';
import RSelect, { SingleValue } from 'react-select';
import { DealNotificationsFilterByEnum, IOption } from 'models';
import { useQuery } from 'react-query';
import { handleError } from 'helpers/handleError';
import { apiDealNotifications } from 'api/api-deal-notifications';
import styles from './DealNotificationsPage.module.css';
import SortBottomIcon from 'components/atoms/icons/SortBottomIcon';
import SortTopIcon from 'components/atoms/icons/SortTopIcon';
import SortDisabledIcon from 'components/atoms/icons/SortDisabledIcon';
import BePagintaion from 'components/molecules/BePagination/BePagination';
import { DealNotificationCard } from './components';

const DealNotificationsPage: FC = () => {
  const [value, setValue] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();

  const searchValue = useMemo(() => {
    return searchParams.get(SEARCH_PARAMS.SEARCH) || '';
  }, [searchParams]);

  const dealsPerPage = useMemo(() => {
    return Number(searchParams.get(SEARCH_PARAMS.USERS_PER_PAGE)) || DEFAULT_DEALS_PER_PAGE;
  }, [searchParams]);

  const activeFilter = useMemo(() => {
    return (searchParams.get(SEARCH_PARAMS.FILTER) as DealNotificationsFilterByEnum) || null;
  }, [searchParams]);

  const currentPage = useMemo(() => {
    return Number(searchParams.get(SEARCH_PARAMS.PAGE)) || DEFAULT_PAGE;
  }, [searchParams]);

  const sortBy = useMemo(() => {
    return searchParams.get(SEARCH_PARAMS.SORT_BY) || undefined;
  }, [searchParams]);

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    if (debouncedSearchValue) {
      searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));
      searchParams.set(SEARCH_PARAMS.SEARCH, debouncedSearchValue);
      setSearchParams(searchParams);
    } else {
      if (searchParams.has(SEARCH_PARAMS.SEARCH)) {
        searchParams.delete(SEARCH_PARAMS.SEARCH);
        setSearchParams(searchParams);
      }
    }
  }, [debouncedSearchValue]);

  const { data: dealsData, isLoading: dealsDataLoading } = useQuery(
    ['deal-notifications/all', dealsPerPage, currentPage, searchValue, activeFilter, sortBy],
    async () =>
      await apiDealNotifications.getDealNotifications({
        page: currentPage,
        itemsPerPage: dealsPerPage,
        search: searchValue,
        filter: activeFilter,
        sortBy
      }),
    {
      onError(error) {
        handleError(error);
      }
    }
  );

  const handleFilter = (filter: DealNotificationsFilterByEnum) => {
    if (activeFilter !== filter) {
      searchParams.set(SEARCH_PARAMS.FILTER, filter);
      setSearchParams(searchParams);
    }
  };

  const handleSetPage = (page: number) => {
    if (currentPage !== page) {
      searchParams.set(SEARCH_PARAMS.PAGE, String(page));
      setSearchParams(searchParams);
    }
  };

  const handleSortByCreatedAt = () => {
    searchParams.set(SEARCH_PARAMS.PAGE, '1');

    if (sortBy === 'createdAt-asc') {
      searchParams.set(SEARCH_PARAMS.SORT_BY, 'createdAt-desc');
    } else if (sortBy === 'createdAt-desc') {
      searchParams.delete(SEARCH_PARAMS.SORT_BY);
    } else {
      searchParams.set(SEARCH_PARAMS.SORT_BY, 'createdAt-asc');
    }

    setSearchParams(searchParams);
  };

  return (
    <>
      <div className='flex flex-col'>
        <header className='mb-[20px] flex h-[74px] items-center justify-between'>
          <h2 className='text-[36px] font-[700] leading-[42px] text-[#20233A]'>Уведомления</h2>

          <SearchField
            type='text'
            id='search'
            placeholder='Поиск по номеру сделки'
            value={value}
            setState={setValue}
            className='max-w-[720px]'
          />
        </header>

        <div className='mb-[28px] flex w-full gap-[8px]'>
          <button
            type='button'
            className={
              'rounded-[12px] px-[24px] py-[16px] text-[16px] leading-[19px] font-[500] ' +
              'transition-all duration-300 select-none ' +
              `${
                !activeFilter || activeFilter === DealNotificationsFilterByEnum.ALL
                  ? 'bg-[#162030] text-[#FFFFFF]'
                  : 'bg-[#EEF0FF] text-[#20233A]'
              }`
            }
            onClick={() => handleFilter(DealNotificationsFilterByEnum.ALL)}
          >
            Все
          </button>

          <button
            type='button'
            className={
              'rounded-[12px] px-[24px] py-[16px] text-[16px] leading-[19px] font-[500] ' +
              'transition-all duration-300 select-none ' +
              `${
                activeFilter === DealNotificationsFilterByEnum.WITH_ERROR
                  ? 'bg-[#162030] text-[#FFFFFF]'
                  : 'bg-[#EEF0FF] text-[#20233A]'
              }`
            }
            onClick={() => handleFilter(DealNotificationsFilterByEnum.WITH_ERROR)}
          >
            Только с ошибками
          </button>

          <button
            type='button'
            className={
              'rounded-[12px] px-[24px] py-[16px] text-[16px] leading-[19px] font-[500] ' +
              'transition-all duration-300 select-none ' +
              `${
                activeFilter === DealNotificationsFilterByEnum.WITHOUT_ERROR
                  ? 'bg-[#162030] text-[#FFFFFF]'
                  : 'bg-[#EEF0FF] text-[#20233A]'
              }`
            }
            onClick={() => handleFilter(DealNotificationsFilterByEnum.WITHOUT_ERROR)}
          >
            Только без ошибок
          </button>

          <RSelect
            value={dealsPerPageOptions.find(({ value }) => value === dealsPerPage)}
            classNamePrefix='custom-select'
            styles={filterSelectStyles}
            placeholder='По 10'
            options={dealsPerPageOptions}
            onChange={(newValue: SingleValue<unknown>) => {
              searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));
              const usersPerPage = (newValue as IOption).value;
              searchParams.set(SEARCH_PARAMS.USERS_PER_PAGE, usersPerPage);
              setSearchParams(searchParams);
            }}
            className='ml-auto'
            isSearchable={false}
          />
        </div>

        <div className={styles.table__headers}>
          <span className={`${styles.table__header} ${styles.table__header_id}`}>
            Номер сделки (ID транзакции)
          </span>

          <span className={`${styles.table__header} ${styles.table__header_type}`}>Тип сделки</span>

          <button
            className={`${styles.table__header} ${styles.table__header_createdAt}`}
            onClick={handleSortByCreatedAt}
          >
            Дата и время создания
            {sortBy === 'createdAt-desc' ? (
              <SortBottomIcon />
            ) : sortBy === 'createdAt-asc' ? (
              <SortTopIcon />
            ) : (
              <SortDisabledIcon />
            )}
          </button>

          <span className={`${styles.table__header} ${styles.table__header_actions}`}>
            Результат
          </span>
        </div>

        <div className={styles.table__rows}>
          {!dealsDataLoading ? (
            <>
              {!!dealsData?.data.length ? (
                <>
                  {dealsData.data.map((dealNotification, index) => (
                    <DealNotificationCard
                      key={dealNotification.id}
                      dealNotification={dealNotification}
                      index={index}
                    />
                  ))}
                </>
              ) : (
                <h2>Уведомлений не найдено</h2>
              )}
            </>
          ) : (
            <h2>Загрузка...</h2>
          )}
        </div>

        <div className='w-full flex-1'>
          {dealsData && dealsData.pagination.totalItems > dealsPerPage && (
            <BePagintaion pagination={dealsData.pagination} setCurrentPage={handleSetPage} />
          )}
        </div>
      </div>
    </>
  );
};

export default DealNotificationsPage;
