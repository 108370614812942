import { IconCheck, IconClose, IconMinus, IconPlus } from 'components/atoms/icons';
import { FC } from 'react';

import styles from './StatusBar.module.css';

interface StatusBarProps {
  value: {
    new: number;
    exists: number;
    inVocabulary: number;
    error: number;
  };
}

const StatusBar: FC<StatusBarProps> = ({ value }) => {
  return (
    <div className={styles.container}>
      <ul className={styles.statuses}>
        <li className={styles.status}>
          <IconPlus color='#66C84D' />

          <div className={styles.status__content}>
            <div className={styles.status__header}>{`В словарь и в урок (${value.new})`}</div>
            <div className={styles.status__text}>Будет добавлено и в словарь, и в урок</div>
          </div>
        </li>

        <li className={styles.status}>
          <IconCheck color='#5770F3' />

          <div className={styles.status__content}>
            <div className={styles.status__header}>{`В урок (${value.inVocabulary})`}</div>
            <div className={styles.status__text}>Есть в словаре, будет добавлено в урок</div>
          </div>
        </li>

        <li className={styles.status}>
          <IconClose color='#71798F' />

          <div className={styles.status__content}>
            <div className={styles.status__header}>{`Уже есть (${value.exists})`}</div>
            <div className={styles.status__text}>Уже есть в словаре и добавлено в урок</div>
          </div>
        </li>

        <li className={styles.status}>
          <IconMinus color='#FF4444' />

          <div className={styles.status__content}>
            <div className={styles.status__header}>{`Ошибка (${value.error})`}</div>
            <div className={styles.status__text}>В слове, переводе или в примерах есть ошибка.</div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default StatusBar;
