/* eslint-disable react-hooks/exhaustive-deps */

import { OutputData } from '@editorjs/editorjs';
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
  apiCourses,
  CreateTextStepData,
  ISection,
  ITextStep,
  STEP_TYPES,
  UpdateTextStepData
} from 'api/api-courses';
import { DEFAULT_EDITOR_DATA } from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useGetCurrentUserQuery } from 'hooks/queries';
import { StepChange } from 'models';
import { normalizeData } from 'utils';
import { useDeleteStep } from '../hooks';
import { saveToChangeHistory } from '../utils';
import styles from './TextStep.module.css';

import Button from 'components/atoms/Button';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import { IconTrash } from 'components/atoms/icons';
import Modal from 'components/atoms/Modal';
import Editor from 'components/organisms/Editor';
import ChangeHistory from '../../ChangeHistory/ChangeHistory';

interface TextStepProps {
  step: ITextStep;
  stepIndex: number | 'potential';
  section: ISection;
  isPotential?: boolean;
  deletePotentialStep?: () => void;
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  setOnSubmitCallback: Dispatch<SetStateAction<(() => void) | null>>;
  refetchSection: () => void;
}

const TextStep: FC<TextStepProps> = ({
  step,
  section,
  deletePotentialStep,
  isPotential,
  stepIndex,
  isDirty,
  setIsDirty,
  setIsValid,
  setOnSubmitCallback,
  refetchSection
}) => {
  const queryClient = useQueryClient();
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [data, setData] = useState<OutputData>(() =>
    isPotential ? DEFAULT_EDITOR_DATA : step.content
  );
  const { data: viewer } = useGetCurrentUserQuery();
  const [changeHistoryModalActive, setChangeHistoryModalActive] = useState<boolean>(false);

  // TODO separate mutation hook
  const { mutateAsync: addNewTextStepMutate, isLoading: isLoadingCreate } = useMutation(
    (data: CreateTextStepData) => {
      return apiCourses.addTextStep(section.id, data);
    },
    {
      onSuccess(data) {
        const oldSectionData = queryClient.getQueryData<ISection>(`sections/one/${section.id}`);
        if (oldSectionData) {
          queryClient.setQueryData<ISection>(`sections/one/${section.id}`, {
            ...oldSectionData,
            steps: [...oldSectionData.steps, { ...data, type: STEP_TYPES.TEXT }]
          });
        }

        notifySuc('Шаг создан');
        deletePotentialStep && deletePotentialStep();

        setIsTouched(false);
        setIsDirty(false);

        if (!viewer) return;
        const time = new Date(Date.now()).toISOString();
        saveToChangeHistory({
          stepId: data.id,
          change: { type: 'text', step: data, user: viewer, time }
        });
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  // TODO separate mutation hook
  const { mutateAsync: updateTextStepMutate, isLoading: isLoadingUpdate } = useMutation(
    (data: UpdateTextStepData) => {
      return apiCourses.updateTextStep(step.id, data);
    },
    {
      onSuccess(data) {
        refetchSection();
        notifySuc('Шаг обновлен');

        setIsTouched(false);
        setIsDirty(false);

        if (!viewer) return;
        const time = new Date(Date.now()).toISOString();
        saveToChangeHistory({
          stepId: data.id,
          change: { type: 'text', step: data, user: viewer, time }
        });
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const handleSave = async (data: OutputData) => {
    if (!data) return;
    const normalizedData = normalizeData(data);

    if (isPotential) {
      await addNewTextStepMutate({
        order: section.steps?.length + 1 || 1,
        content: normalizedData
      });
      return;
    } else {
      await updateTextStepMutate({ order: step.order, content: normalizedData });
    }
  };

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSave(data);
  };

  const [key, setKey] = useState<number>(0);
  const handleReset = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    setData(isPotential ? DEFAULT_EDITOR_DATA : step.content);
    setKey(prev => prev + 1);

    setIsTouched(false);
    setIsDirty(false);
  };

  const { deleteStepMutate: deleteTextStepMutate, isLoading: isLoadingDelete } = useDeleteStep({
    section,
    stepId: step.id,
    stepType: step.type,
    setIsDirty,
    setIsValid,
    setOnSubmitCallback
  });
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

  const deleteTextStep = () => {
    if (isPotential) {
      if (deletePotentialStep) {
        deletePotentialStep();
      }
      return;
    }
    deleteTextStepMutate();
  };

  const submitCallback = (callback: any) => () => callback();

  useEffect(() => {
    setIsValid(true);
  }, []);

  useEffect(() => {
    if (isTouched) {
      setIsDirty(true);
    }

    setOnSubmitCallback(() => submitCallback(async () => await handleSave(data)));
  }, [data]);

  const handleApplyVersion = async (data: StepChange) => {
    if (data.type !== 'text') return;
    await handleSave(data.step.content);
    setChangeHistoryModalActive(false);
    setData(data.step.content);
    setKey(prev => prev + 1);
  };

  return (
    <>
      <div className='mb-[16px] flex items-center justify-between'>
        <div className='flex h-[56px] items-center justify-between text-[18px] font-[500] leading-[21px] text-[#20233A]'>
          {`Шаг ${isPotential ? section.steps?.length + 1 : stepIndex} | Текст`}
        </div>

        <button
          className='flex h-[24px] w-[24px] items-center justify-center'
          onClick={() => setDeleteModalIsOpen(true)}
        >
          <IconTrash color='#71798F' />
        </button>
      </div>

      <form className={styles.form} onSubmit={handleSubmit} onReset={handleReset}>
        <div className={styles.text} onClick={() => setIsTouched(true)}>
          <Editor data={data} onChange={setData} holder='editorjs-container' key={key} />
        </div>

        <div className={styles.buttons}>
          {!isPotential && (
            <Button
              type='button'
              variant='secondary'
              title='История правок'
              className='mr-auto w-[198px]'
              onClick={() => setChangeHistoryModalActive(true)}
            />
          )}

          <Button
            type='reset'
            variant='secondary'
            title='Сбросить'
            className='w-[198px]'
            isDisabled={!isTouched || !isDirty || isLoadingCreate || isLoadingUpdate}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isTouched || !isDirty}
            isLoading={isLoadingCreate || isLoadingUpdate}
          />
        </div>
      </form>

      {deleteModalIsOpen && (
        <ConfirmationModal
          title={<>Удалить {isPotential ? 'Шаг | Текст' : `Шаг ${stepIndex} | Текст`}?</>}
          text={
            <>
              Шаг будет удален <strong>навсегда</strong>.
            </>
          }
          isDelete={true}
          confirmButtonText='Удалить шаг'
          onConfirm={deleteTextStep}
          onClose={() => setDeleteModalIsOpen(false)}
          isLoading={isLoadingDelete}
        />
      )}

      {changeHistoryModalActive && step.id && (
        <Modal onClose={() => setChangeHistoryModalActive(false)} className='z-20'>
          <ChangeHistory
            stepId={step.id}
            applyVersion={handleApplyVersion}
            onClose={() => setChangeHistoryModalActive(false)}
            isLoading={isLoadingCreate || isLoadingUpdate}
          />
        </Modal>
      )}
    </>
  );
};

export default TextStep;
