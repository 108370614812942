import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ICourse } from 'api/api-courses';
import miniAvatarSVG from 'assets/images/svg/mini-avatar.svg';
import { COURSES_COLORS } from 'consts';
import { useCourseStatusIcon } from 'hooks/common';
import styles from './DictionaryCard.module.css';

import { IconPen } from 'components/atoms/icons';

type DictionaryCardProps = {
  course: ICourse;
};

const DictionaryCard: FC<DictionaryCardProps> = ({
  course: { id: courseId, title: courseTitle, status, color, miniImageUrl, level }
}) => {
  const navigate = useNavigate();
  const StatusIcon = useCourseStatusIcon(status);
  const goToDictionaryPage = () => {
    navigate(`/dictionaries/${courseId}`);
  };

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: color || COURSES_COLORS[0].hex }}
      onClick={goToDictionaryPage}
    >
      <header className={styles.header}>
        <span title={`Уровень ${level}`} className={styles.courseLevel}>
          {`Уровень ${level}`}
        </span>
      </header>

      <div className={styles.content}>
        <div className={styles.titleAndEdit}>
          <p title={courseTitle} className={styles.title}>
            {courseTitle}
          </p>

          <button className={styles.buttonEdit}>
            <IconPen className='h-[20px] w-[20px] text-[#5770F3]' />
            Редактировать
          </button>
        </div>

        <div className={styles.image}>
          <img src={miniImageUrl || miniAvatarSVG} alt={courseTitle} />
        </div>

        {StatusIcon}
      </div>
    </div>
  );
};

export default DictionaryCard;
