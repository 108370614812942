import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { apiCourses, CreateModuleData } from 'api/api-courses';
import { FORM_ERROR_MESSAGES } from 'consts';
import { handleError } from 'helpers/handleError';
import styles from './CreateModuleForm.module.css';

import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';
import Input from 'components/atoms/Input';
import { notifySuc } from 'helpers/notification';

interface CreateModuleFormProps {
  courseId: string;
  order: number;
  refetchCourse: () => void;
  onCancel: () => void;
}

const CreateModuleForm: FC<CreateModuleFormProps> = ({
  courseId,
  order,
  refetchCourse,
  onCancel
}) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FieldValues>({ mode: 'onSubmit' });

  const { mutate: addModuleMutate, isLoading: addModuleLoading } = useMutation(
    (createModuleData: CreateModuleData) => {
      return apiCourses.addModules(courseId, [createModuleData]);
    },
    {
      onSuccess() {
        notifySuc('Модуль добавлен');

        refetchCourse();
        onCancel();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const onSubmit = async (data: FieldValues) => {
    const { title } = data;

    addModuleMutate({ title: title.trim(), order });
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <span className={styles.title}>Добавить модуль</span>
        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          <Input
            type='text'
            name='title'
            variant='dark'
            width='big'
            label='Название модуля'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                }
              }
            }}
            loading={addModuleLoading}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            onClick={onCancel}
            isDisabled={addModuleLoading}
          />

          <Button
            type='submit'
            variant='primary'
            title='Добавить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={addModuleLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default CreateModuleForm;
