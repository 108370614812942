import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ICourse } from 'api/api-courses';
import { useDeleteCourseUsersMutation } from 'hooks/mutations';
import { UserWithPurchase } from 'models';
import { convertToLocalDate } from 'utils';

import ConfirmationModal from 'components/atoms/ConfirmationModal';
import { IconPen, IconTrash } from 'components/atoms/icons';

interface CourseUserCardProps {
  userWithPurchase: UserWithPurchase;
  course: ICourse;
  isEven: boolean;
}

const CourseUserCard: FC<CourseUserCardProps> = props => {
  const {
    userWithPurchase: { user, purchase },
    course,
    isEven
  } = props;
  const navigate = useNavigate();
  const [deleteUserModalActive, setDeleteUserModalActive] = useState<boolean>(false);
  const { mutate: deleteCourseUsersMutate, isLoading: deleteCourseUsersLoading } =
    useDeleteCourseUsersMutation();

  const deleteUser = () => {
    deleteCourseUsersMutate(
      { courseId: course.id, emails: [user.email] },
      { onSuccess: () => setDeleteUserModalActive(false) }
    );
  };

  return (
    <div
      className={
        'flex h-[60px] transition-all duration-300 ' +
        `hover:bg-[#eef0ff] ${isEven ? 'bg-[#fafafa]' : ''}`
      }
    >
      <button
        type='button'
        className={
          'flex h-[60px] w-[30%] items-center px-[15px] py-[20px] ' +
          'border-r-[2px] border-solid border-[#e9ecf0]'
        }
        onClick={() => navigate(`/users/${user.id}`)}
      >
        <span
          title={user.name}
          className={
            'text-[16px] leading-[19px] text-[#5770F3] ' +
            'overflow-hidden text-ellipsis whitespace-nowrap'
          }
        >
          {user.name}
        </span>
      </button>

      <div
        className={
          'flex h-[60px] w-[30%] items-center px-[15px] py-[20px] ' +
          'border-r-[2px] border-solid border-[#e9ecf0]'
        }
      >
        <span
          title={user.email}
          className={
            'text-[16px] leading-[19px] text-[#20233a] ' +
            'overflow-hidden text-ellipsis whitespace-nowrap'
          }
        >
          {user.email}
        </span>
      </div>

      <div
        className={
          'flex flex-col h-[60px] w-[25%] justify-center items-end px-[15px] py-[20px] ' +
          'border-r-[2px] border-solid border-[#e9ecf0]'
        }
      >
        {purchase ? (
          <>
            {purchase?.isPermanent ? (
              <span
                className={
                  'text-[14px] leading-[16px] text-[#5A667B] ' +
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                }
              >
                Бессрочно
              </span>
            ) : (
              <div className='flex flex-col items-end justify-center'>
                <span
                  className={
                    'text-[14px] leading-[16px] text-[#5A667B] ' +
                    'overflow-hidden text-ellipsis whitespace-nowrap'
                  }
                >
                  с {purchase.paymentDate ? convertToLocalDate(purchase.paymentDate) : '-'}
                </span>
                <span
                  className={
                    'text-[14px] leading-[16px] text-[#5A667B] ' +
                    'overflow-hidden text-ellipsis whitespace-nowrap'
                  }
                >
                  по {purchase.expirationDate ? convertToLocalDate(purchase.expirationDate) : '-'}
                </span>
              </div>
            )}
          </>
        ) : (
          <span
            className={
              'text-[14px] leading-[16px] text-[#5A667B] ' +
              'overflow-hidden text-ellipsis whitespace-nowrap'
            }
          >
            Нет подписки
          </span>
        )}
      </div>

      <div className={'flex h-[60px] w-[15%] items-center gap-[32px] px-[15px] py-[20px]'}>
        <button onClick={() => navigate(`/users/${user.id}/subscriptions`)}>
          <IconPen color='#71798F' />
        </button>

        <button onClick={() => setDeleteUserModalActive(true)}>
          <IconTrash color='#71798F' />
        </button>
      </div>

      {deleteUserModalActive && (
        <ConfirmationModal
          title={
            <>
              Удалить ученика из курса <br />
              {course.title}?
            </>
          }
          text={
            <>
              Ученик потеряет <b>доступ</b> к курсу. Его можно будет вернуть <b>позже</b>.
            </>
          }
          isDelete
          confirmButtonText='Удалить ученика'
          onConfirm={deleteUser}
          onClose={() => setDeleteUserModalActive(false)}
          isLoading={deleteCourseUsersLoading}
        />
      )}
    </div>
  );
};

export default CourseUserCard;
