import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { OutputData } from '@editorjs/editorjs';
import { FC } from 'react';
import { FieldArrayWithId, UseFieldArrayRemove, UseFormRegister } from 'react-hook-form';

import { DialogPart as IDialogPart } from 'api/api-courses';
import { FORM_ERROR_MESSAGES } from 'consts';
import { AudioPart, StepFormat } from 'models';
import styles from './DialogPart.module.css';

import { IconClose, IconDragHandle } from 'components/atoms/icons';
import Input from 'components/atoms/Input';

type FieldValues = {
  condition?: string;
  editableCondition: OutputData;
  purpose?: string;
  editablePurpose: OutputData;
  contentParts: Omit<IDialogPart, 'order'>[];
  hint?: string;
  format: StepFormat;
  textForAudio?: string;
  audioParts?: AudioPart[];
  url?: string;
  picture?: FileList;
  audio?: FileList;
  videoUrl?: string;
};

interface DialogPartProps {
  contentPart: FieldArrayWithId<FieldValues, 'contentParts', 'id'>;
  index: number;
  control: any;
  register: UseFormRegister<any>;
  remove: UseFieldArrayRemove;
  allowRemove: boolean;
}

const DialogPart: FC<DialogPartProps> = ({
  contentPart: { id },
  index,
  control,
  remove,
  allowRemove
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <li
      className='flex flex-col text-[16px] font-[500] leading-[135%] text-[#20233A]'
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <label className={styles.label}>реплика</label>

      <div className='flex flex-auto items-center'>
        <button
          type='button'
          className={styles.dragHandle}
          ref={setActivatorNodeRef}
          {...listeners}
        >
          <IconDragHandle color='#A6B0C9' />
        </button>

        <Input
          type='text'
          variant={'bordered'}
          width='big'
          name={`contentParts.${index}.text` as const}
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
            validate: {
              doesntConsistOfSpaces: (value: any) => {
                return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
              }
            }
          }}
          containerClassName='mr-[8px]'
        />

        {allowRemove && (
          <button
            type='button'
            className='flex h-[48px] w-[56px] items-center justify-center rounded-[12px] bg-[#EEF0FF]'
            onClick={() => remove(index)}
          >
            <IconClose color='#71798F' />
          </button>
        )}
      </div>
    </li>
  );
};

export default DialogPart;
