import React from 'react';
import { ImonykiIcon } from '../../atoms/icons';

interface HeaderProps {
  isOnLogin: boolean;
  type: 'dark' | 'light';
}

const Header: React.FC<HeaderProps> = ({ type }) => {
  return (
    <div className={'flex w-full max-w-[calc(100%-160px)] items-center justify-start'}>
      <div className={'flex items-center'}>
        <ImonykiIcon />

        <div className={'ml-[10px] flex flex-col items-start'}>
          <span
            className={
              'mb-[2px] text-[13px] font-[500] uppercase leading-[15px] text-[#20233A] opacity-50'
            }
          >
            admin panel
          </span>
          <h1 className={'text-[20px] font-[500] leading-[23px] text-[#20233A]'}>Korean simple</h1>
        </div>
      </div>
    </div>
  );
};

export default Header;
