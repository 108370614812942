import { FC, useMemo } from 'react';

import { ILesson, STEP_TYPES } from 'api/api-courses';
import stepExampleChooseAnswer from 'assets/images/png/stepExampleChooseAnswer.png';
import stepExampleChooseSentence from 'assets/images/png/stepExampleChooseSentence.png';
import stepExampleDialog from 'assets/images/png/stepExampleDialog.png';
import stepExampleFillingWords from 'assets/images/png/stepExampleFillingWords.png';
import stepExampleInsertingWords from 'assets/images/png/stepExampleInsertingWords.png';
import stepExampleMakingSentence from 'assets/images/png/stepExampleMakingSentence.png';
import stepExampleMatchingPairs from 'assets/images/png/stepExampleMatchingPairs.png';

interface SectionSideInfoProps {
  sectionOrder: number;
  lesson: ILesson;
  moduleTitle?: string;
  stepType?: STEP_TYPES;
}

const SectionSideInfo: FC<SectionSideInfoProps> = ({ moduleTitle, lesson, stepType }) => {
  const exampleStepInfo = useMemo(() => {
    switch (stepType) {
      case STEP_TYPES.MATCHING_PAIRS:
        return (
          <>
            <div className='mb-[14px] text-[16px] font-[600] text-[#20233A]'>Пример теста:</div>
            <img
              width={234}
              src={stepExampleMatchingPairs}
              alt='stepExampleMatchingPairs.png'
              className='rounded-[20px] border-[2px] border-solid border-[#E9ECF0]'
            />
          </>
        );
      case STEP_TYPES.CHOOSE_ANSWER:
        return (
          <>
            <div className='mb-[14px] text-[16px] font-[600] text-[#20233A]'>Пример теста:</div>
            <img
              width={234}
              src={stepExampleChooseAnswer}
              alt='stepExampleChooseAnswer.png'
              className='rounded-[20px] border-[2px] border-solid border-[#E9ECF0]'
            />
          </>
        );
      case STEP_TYPES.CHOOSE_SENTENCE:
        return (
          <>
            <div className='mb-[14px] text-[16px] font-[600] text-[#20233A]'>Пример теста:</div>
            <img
              width={234}
              src={stepExampleChooseSentence}
              alt='stepExampleChooseSentence.png'
              className='rounded-[20px] border-[2px] border-solid border-[#E9ECF0]'
            />
          </>
        );
      case STEP_TYPES.MAKING_SENTENCE:
        return (
          <>
            <div className='mb-[14px] text-[16px] font-[600] text-[#20233A]'>Пример теста:</div>
            <img
              width={234}
              src={stepExampleMakingSentence}
              alt='stepExampleMakingSentence.png'
              className='rounded-[20px] border-[2px] border-solid border-[#E9ECF0]'
            />
          </>
        );
      case STEP_TYPES.FILLING_MISSING_WORDS:
        return (
          <>
            <div className='mb-[14px] text-[16px] font-[600] text-[#20233A]'>Пример теста:</div>
            <img
              width={234}
              src={stepExampleFillingWords}
              alt='stepExampleFillingWords.png'
              className='rounded-[20px] border-[2px] border-solid border-[#E9ECF0]'
            />
          </>
        );
      case STEP_TYPES.INSERTING_MISSING_WORDS:
        return (
          <>
            <div className='mb-[14px] text-[16px] font-[600] text-[#20233A]'>Пример теста:</div>
            <img
              width={234}
              src={stepExampleInsertingWords}
              alt='stepExampleInsertingWords.png'
              className='rounded-[20px] border-[2px] border-solid border-[#E9ECF0]'
            />
          </>
        );
      case STEP_TYPES.DIALOG:
        return (
          <>
            <div className='mb-[14px] text-[16px] font-[600] text-[#20233A]'>Пример теста:</div>
            <img
              width={234}
              src={stepExampleDialog}
              alt='stepExampleDialog.png'
              className='rounded-[20px] border-[2px] border-solid border-[#E9ECF0]'
            />
          </>
        );
    }
  }, [stepType]);

  return (
    <>
      <div className='mb-[63px] w-[210px]'>
        {moduleTitle && (
          <h3
            title={moduleTitle}
            className={
              'mb-[20px] text-[21px] font-[700] leading-[24px] text-[#20233A] ' +
              'overflow-hidden text-ellipsis whitespace-nowrap'
            }
          >
            {moduleTitle}
          </h3>
        )}

        <div className='mb-[20px]'>
          <img className='h-[54px] w-[54px]' src={lesson.imageUrl} alt='' />
        </div>

        <div
          title={lesson.subtitle ? lesson.subtitle : `Урок ${lesson.order}`}
          className={
            'mb-[4px] text-[14px] leading-[16px] text-[#71798F] ' +
            'overflow-hidden text-ellipsis whitespace-nowrap'
          }
        >
          {lesson.subtitle ? lesson.subtitle : `Урок ${lesson.order}`}
        </div>

        <div
          title={lesson.title}
          className={
            'text-[18px] font-[500] leading-[21px] text-[#20233A] ' +
            'overflow-hidden text-ellipsis whitespace-nowrap'
          }
        >
          {lesson.title}
        </div>
      </div>

      {exampleStepInfo}
    </>
  );
};

export default SectionSideInfo;
