import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { useValidateWordMutation } from 'hooks/dictionary';
import { ValidatedWord, ValidatedWordsData, ValidatedWordStatus } from 'models';
import styles from './WordCard.module.css';

import { IconCheck, IconClose, IconMinus, IconPlus } from 'components/atoms/icons';
import Input from 'components/atoms/Input';

interface WordCardProps {
  dictionaryId: string;
  word: ValidatedWord;
  index: number;
  setValidatedWordsData: Dispatch<SetStateAction<ValidatedWordsData | null>>;
}

const WordCard: FC<WordCardProps> = ({
  dictionaryId,
  word: { status, data, error },
  index,
  setValidatedWordsData
}) => {
  const { mutate: validateWordMutate, isLoading: validateWordLoading } = useValidateWordMutation();
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FieldValues>({
    mode: 'onSubmit',
    defaultValues: { data }
  });

  const Status = useMemo(() => {
    switch (status) {
      case ValidatedWordStatus.NEW:
        return (
          <div className={styles.status}>
            <IconPlus color='#66C84D' />
            <span>В общий каталог и в словарь</span>
          </div>
        );
      case ValidatedWordStatus.IN_VOCABULARY:
        return (
          <div className={styles.status}>
            <IconCheck color='#5770F3' />
            <span>В словарь</span>
          </div>
        );
      case ValidatedWordStatus.EXISTS:
        return (
          <div className={styles.status}>
            <IconClose color='#71798F' />
            <span>Уже есть</span>
          </div>
        );
      case ValidatedWordStatus.ERROR:
        return (
          <div className={styles.status}>
            <IconMinus color='#FF4444' />
            <span>Ошибка</span>
          </div>
        );
    }
  }, [status]);

  // TODO add type FormValues
  const onSubmit = ({ data }: FieldValues) => {
    validateWordMutate(
      { dictionaryId, body: data },
      {
        onSuccess: data => {
          setValidatedWordsData(prev => {
            if (prev) {
              const newWords = [...prev.words];
              newWords[index] = data;

              return {
                ...prev,
                words: newWords
              };
            }

            return null;
          });
        }
      }
    );
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <header className={styles.header}>
          <div className={styles.header__fields}>
            <Input
              type='text'
              variant='bordered'
              width='big'
              name='data.original'
              control={control}
              rules={{
                required: {
                  value: status !== ValidatedWordStatus.ERROR,
                  message: FORM_ERROR_MESSAGES.REQUIRED_FIELD
                },
                maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
                validate: {
                  doesntConsistOfSpaces: (value: any) => {
                    return !value || !value.length || !!value.trim()
                      ? true
                      : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                  }
                }
              }}
              className='h-[48px] w-[200px]'
            />

            <span className='mx-[8px]'>—</span>

            <Input
              type='text'
              variant='bordered'
              width='big'
              name='data.translation'
              control={control}
              rules={{
                required: {
                  value: status !== ValidatedWordStatus.ERROR,
                  message: FORM_ERROR_MESSAGES.REQUIRED_FIELD
                },
                maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
                validate: {
                  doesntConsistOfSpaces: (value: any) => {
                    return !value || !value.length || !!value.trim()
                      ? true
                      : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                  }
                }
              }}
              className='h-[48px] w-[200px]'
            />
          </div>

          {validateWordLoading ? (
            <div className={styles.status}>
              <span>Загрузка...</span>
            </div>
          ) : (
            <>
              {isDirty && (
                <button type='submit' title='Перепроверить слово' className={styles.header__button}>
                  <IconCheck color='#FFFFFF' />
                </button>
              )}

              {Status}
            </>
          )}
        </header>

        <div className={styles.examples}>
          <ul className={styles.examples__list}>
            {data.examples.length > 0 ? (
              <>
                {data.examples.map((example, i) => (
                  <li key={i} className={styles.examples__item}>
                    <Input
                      type='text'
                      variant='bordered'
                      width='big'
                      name={`data.examples.${i}.original`}
                      control={control}
                      rules={{
                        required: {
                          value: status !== ValidatedWordStatus.ERROR,
                          message: FORM_ERROR_MESSAGES.REQUIRED_FIELD
                        },
                        maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
                        validate: {
                          doesntConsistOfSpaces: (value: any) => {
                            return !value || !value.length || !!value.trim()
                              ? true
                              : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                          }
                        }
                      }}
                      className='h-auto min-h-[49px] p-[15px]'
                    />

                    <Input
                      type='text'
                      variant='bordered'
                      width='big'
                      name={`data.examples.${i}.translation`}
                      control={control}
                      rules={{
                        required: {
                          value: status !== ValidatedWordStatus.ERROR,
                          message: FORM_ERROR_MESSAGES.REQUIRED_FIELD
                        },
                        maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
                        validate: {
                          doesntConsistOfSpaces: (value: any) => {
                            return !value || !value.length || !!value.trim()
                              ? true
                              : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                          }
                        }
                      }}
                      className='h-auto min-h-[49px] p-[15px]'
                    />
                  </li>
                ))}
              </>
            ) : (
              <li className={styles.examples__item}>
                <span className={styles.examples__empty}>Примеров не обнаружено</span>
              </li>
            )}
          </ul>
        </div>
      </form>
    </div>
  );
};

export default WordCard;
