/* eslint-disable react-hooks/exhaustive-deps */

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { OutputData } from '@editorjs/editorjs';
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  ContentPartType,
  CreateInsertingWordsStepData,
  ContentPart as IContentPart,
  InsertingWordsStep as IInsertingWordsStep,
  ISection,
  STEP_TYPES,
  UpdateInsertingWordsStepData,
  apiCourses
} from 'api/api-courses';
import { DEFAULT_EDITABLE_CONDITION_INSERTING_WORDS, DEFAULT_EDITABLE_PURPOSE_DATA } from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useUploadStepImageMutation } from 'hooks/mutations';
import { AudioPart, StepFormat } from 'models';
import { normalizeData } from 'utils';
import { useDeleteStep } from '../hooks';
import styles from './InsertingWordsStep.module.css';

import Button from 'components/atoms/Button';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import {
  IconInsertingWords,
  IconSpace,
  IconSwap,
  IconText,
  IconTrash
} from 'components/atoms/icons';
import { Condition, ContentPart, ExtraWords, Format, Hint, Purpose } from '../components';

export interface FieldValues {
  condition?: string;
  editableCondition: OutputData;
  purpose?: string;
  editablePurpose: OutputData;
  contentParts: Omit<IContentPart, 'order'>[];
  extraWords: string;
  hint?: string;
  format: StepFormat;
  textForAudio?: string;
  audioParts?: AudioPart[];
  url?: string;
  picture?: FileList;
  audio?: FileList;
  videoUrl?: string;
}

interface InsertingWordsStepProps {
  step: IInsertingWordsStep;
  section: ISection;
  stepIndex: number | 'potential';
  isPotential?: boolean;
  deletePotentialStep?: () => void;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  setOnSubmitCallback: Dispatch<SetStateAction<(() => void) | null>>;
  refetchSection: () => void;
}

const InsertingWordsStep: FC<InsertingWordsStepProps> = ({
  step,
  section,
  stepIndex,
  isPotential,
  deletePotentialStep,
  setIsDirty,
  setIsValid,
  setOnSubmitCallback,
  refetchSection
}) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    formState: { isDirty, isValid, errors }
  } = useForm<FieldValues>({
    mode: 'onSubmit',
    defaultValues: {
      condition: step.condition,
      editableCondition: step.editableCondition || DEFAULT_EDITABLE_CONDITION_INSERTING_WORDS,
      purpose: step.purpose,
      editablePurpose: step.editablePurpose || DEFAULT_EDITABLE_PURPOSE_DATA,
      contentParts: step.contentParts?.sort((a, b) => a.order - b.order) || [
        { text: '', isWord: false },
        { text: 'Переход строки', isWord: false, type: ContentPartType.WRAP },
        { text: '', isWord: true }
      ],
      extraWords: step.incorrectWords?.join(' / ') || '',
      hint: step.hint || '',
      format: step.format || 'text',
      textForAudio: step.textForAudio || '',
      audioParts: !!step.audioList?.length ? step.audioList : [],
      url: step.url,
      videoUrl: step.format === 'video' ? step.url : undefined
    }
  });

  const format = watch('format');

  const {
    fields: contentPartFields,
    append: appendContentPart,
    move: moveContentPart,
    remove: removeContentPart
  } = useFieldArray({ control, name: 'contentParts' });

  const allowCorrectWordsRemove = useMemo(() => {
    const correctWordsLength = contentPartFields.reduce(
      (counter, cp) => (cp.isWord ? counter + 1 : counter),
      0
    );
    return correctWordsLength > 1;
  }, [contentPartFields]);

  const { mutate: setStepImageMutate, isLoading: setStepImageLoading } =
    useUploadStepImageMutation();

  const { mutateAsync: addInsertingWordsStepMutate, isLoading: isLoadingCreate } = useMutation(
    ({ data }: { data: CreateInsertingWordsStepData; image?: File; audio?: File }) => {
      return apiCourses.addInsertingWordsStep(section.id, data);
    },
    {
      async onSuccess(data, variables) {
        const onSuccess = () => {
          deletePotentialStep && deletePotentialStep();

          notifySuc('Шаг добавлен');
          refetchSection();
        };

        if (variables.image) {
          setStepImageMutate(
            {
              stepId: data.id,
              image: variables.image,
              type: STEP_TYPES.INSERTING_MISSING_WORDS
            },
            {
              onSuccess
            }
          );
        } else {
          onSuccess();
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const { mutateAsync: updateInsertingWordsStepMutate, isLoading: isLoadingUpdate } = useMutation(
    ({ data }: { data: UpdateInsertingWordsStepData; image?: File; audio?: File }) => {
      return apiCourses.updateInsertingWordsStep(step.id, data);
    },
    {
      async onSuccess(data, variables) {
        const onSuccess = () => {
          notifySuc('Шаг обновлен');
          refetchSection();
        };

        if (variables.image) {
          setStepImageMutate(
            {
              stepId: data.id,
              image: variables.image,
              type: STEP_TYPES.INSERTING_MISSING_WORDS
            },
            {
              onSuccess
            }
          );
        } else {
          onSuccess();
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const { deleteStepMutate: deleteInsertingWordsStepMutate, isLoading: isLoadingDelete } =
    useDeleteStep({
      section,
      stepId: step.id,
      stepType: step.type,
      setIsDirty,
      setIsValid,
      setOnSubmitCallback
    });
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

  const deleteInsertingWordsStep = () => {
    if (isPotential) {
      if (deletePotentialStep) {
        deletePotentialStep();
      }
      return;
    }
    deleteInsertingWordsStepMutate();
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = contentPartFields.findIndex(({ id }) => id === active.id);
      const newIndex = contentPartFields.findIndex(({ id }) => id === over.id);

      moveContentPart(oldIndex, newIndex);
    }
  };

  const handleReset = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    reset();
  };

  const onSubmit = async (data: FieldValues) => {
    const {
      condition,
      editableCondition,
      purpose,
      editablePurpose,
      contentParts: rawContentParts,
      extraWords,
      hint,
      format,
      picture,
      audio,
      textForAudio,
      audioParts,
      url,
      videoUrl
    } = data;

    const contentParts: IContentPart[] = rawContentParts.map(({ text, isWord, type }, i) => ({
      order: i + 1,
      text: text.trim(),
      isWord,
      type
    }));

    const stepData: UpdateInsertingWordsStepData = {
      order: step.order || section.steps?.length + 1 || 1,
      condition,
      editableCondition: normalizeData(editableCondition),
      purpose,
      editablePurpose: normalizeData(editablePurpose),
      contentParts,
      incorrectWords: extraWords.trim()
        ? extraWords
            .trim()
            .split('/')
            .map(word => word.trim())
        : [],
      hint,
      format,
      textForAudio: format === 'audio' && !audio?.length ? textForAudio : '',
      audioList: format === 'audio' ? audioParts : undefined,
      url: format === 'video' ? videoUrl : url
    };

    if (isPotential) {
      if (format === 'image' && picture && picture.length) {
        await addInsertingWordsStepMutate({ data: stepData, image: picture[0] });
      } else {
        await addInsertingWordsStepMutate({ data: stepData });
      }
    } else {
      if (format === 'image' && picture && picture.length) {
        await updateInsertingWordsStepMutate({ data: stepData, image: picture[0] });
      } else {
        await updateInsertingWordsStepMutate({ data: stepData });
      }
    }
  };

  useEffect(() => {
    setIsDirty(isDirty);
    setIsValid(isValid);

    setOnSubmitCallback(() => handleSubmit(async data => await onSubmit(data)));
  }, [isDirty, isValid]);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h3 className={styles.title}>
          {`Шаг ${isPotential ? section.steps?.length + 1 : stepIndex} | Тест — `}
          <IconInsertingWords color='#5770F3' className='mx-[8px]' /> Перетащить пропущенные слова
        </h3>

        <button
          className='flex h-[24px] w-[24px] items-center justify-center'
          onClick={() => setDeleteModalIsOpen(true)}
        >
          <IconTrash color='#71798F' />
        </button>
      </header>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
        <Condition stepId={step.id || 'potential'} control={control} />

        <Purpose
          stepId={step.id || 'potential'}
          control={control}
          text='Цель (перевод текста задания) (Выберите размер шрифта Заголовок 3)'
        />

        <div className={styles.contentParts}>
          <label className={`${styles.label} ${styles.contentParts__label}`}>
            Текст задания (на корейском), разбитый по частям
          </label>

          {contentPartFields.length > 0 && (
            <DndContext onDragEnd={handleDragEnd}>
              <SortableContext items={contentPartFields} strategy={verticalListSortingStrategy}>
                <ul className={styles.contentParts__list}>
                  {contentPartFields.map((contentPart, i) => (
                    <ContentPart
                      key={contentPart.id}
                      contentPart={contentPart}
                      index={i}
                      control={control}
                      register={register}
                      remove={removeContentPart}
                      allowCorrectWordsRemove={allowCorrectWordsRemove}
                    />
                  ))}
                </ul>
              </SortableContext>
            </DndContext>
          )}

          <div className={styles.contentParts__buttons}>
            <Button
              variant='light'
              type='button'
              title='Добавить текст'
              icon={<IconText color='#5770F3' />}
              iconPosition='left'
              className='h-[48px] rounded-[62px] font-[500]'
              onClick={() => appendContentPart({ isWord: false, text: '' })}
            />

            <Button
              variant='light'
              type='button'
              title='Добавить пропущенное слово'
              icon={<IconSpace color='#5770F3' />}
              iconPosition='left'
              className='h-[48px] rounded-[62px] font-[500]'
              onClick={() => appendContentPart({ isWord: true, text: '' })}
            />

            <Button
              variant='light'
              type='button'
              title='Добавить переход строки'
              icon={<IconSwap color='#5770F3' />}
              iconPosition='left'
              className='h-[48px] rounded-[62px] font-[500]'
              onClick={() =>
                appendContentPart({
                  isWord: false,
                  text: 'Переход строки',
                  type: ContentPartType.WRAP
                })
              }
            />
          </div>
        </div>

        <ExtraWords control={control} />

        <Hint control={control} />

        <Format
          savedFormat={step.format}
          format={format}
          register={register}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          imageError={!!errors.picture}
        />

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Сбросить'
            className='w-[198px]'
            isDisabled={!isDirty || isLoadingCreate || isLoadingUpdate || setStepImageLoading}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={isLoadingCreate || isLoadingUpdate || setStepImageLoading}
          />
        </div>
      </form>

      {deleteModalIsOpen && (
        <ConfirmationModal
          title={
            <>
              Удалить{' '}
              {isPotential
                ? 'Шаг | Тест - Перетащить пропущенные слова'
                : `Шаг ${stepIndex} | Тест - Перетащить пропущенные слова`}
              ?
            </>
          }
          text={
            <>
              Шаг будет удален <strong>навсегда</strong>.
            </>
          }
          isDelete={true}
          confirmButtonText='Удалить шаг'
          onConfirm={deleteInsertingWordsStep}
          onClose={() => setDeleteModalIsOpen(false)}
          isLoading={isLoadingDelete}
        />
      )}
    </div>
  );
};

export default InsertingWordsStep;
