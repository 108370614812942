import { useMutation } from 'react-query';

import { GetWordsFromFileDto, apiDictionary } from 'api/dictionary';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useGetWordsFromFileMutation = () => {
  return useMutation(
    (dto: GetWordsFromFileDto) => {
      return apiDictionary.getWordsFromFile(dto);
    },
    {
      onSuccess() {
        notifySuc('Файл обработан');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
