import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import spinner from 'assets/images/svg/spinner.svg';
import { DEFAULT_AUDIO_VOLUME, FORM_ERROR_MESSAGES } from 'consts';
import { notifyErr } from 'helpers/notification';
import { useGenerateTransliterationMutation, useUpdateWordMutation } from 'hooks/mutations';
import styles from './UpdateWordForm.module.css';

import Button from 'components/atoms/Button';
import { IconClose, IconRefresh, IconSound } from 'components/atoms/icons';
import Input from 'components/atoms/Input/Input';

type FormValues = {
  original: string;
  transliteration: string;
  translation: string;
};

interface UpdateWordFormProps {
  wordId: string;
  original: string;
  transliteration: string;
  translation: string;
  audio: HTMLAudioElement | null;
  refetchWord: () => void;
  onCancel: () => void;
}

const UpdateWordForm: FC<UpdateWordFormProps> = ({
  wordId,
  original,
  transliteration,
  translation,
  audio: currentAudio,
  refetchWord,
  onCancel
}) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(currentAudio);
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { isDirty }
  } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      original,
      transliteration,
      translation
    }
  });

  const { mutate: generateTransliterationMutate, isLoading: generateTransliterationLoading } =
    useGenerateTransliterationMutation();

  const { mutate: updateWordMutate, isLoading: updateWordLoading } = useUpdateWordMutation();

  const onSubmit = async (data: FormValues) => {
    const { original, transliteration, translation } = data;

    updateWordMutate(
      {
        wordId,
        original: original.trim(),
        transliteration: transliteration.trim(),
        translation: translation.trim()
      },
      {
        onSuccess: () => {
          onCancel();
          refetchWord();
        }
      }
    );
  };

  const generateTransliteration = () => {
    generateTransliterationMutate(
      { wordId, original: getValues('original') },
      {
        onSuccess(data) {
          // update transliteration
          setValue('transliteration', data.transliteration, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
          });

          // update audio
          const audio = new Audio(data.audioUrl);
          audio.volume = DEFAULT_AUDIO_VOLUME;
          setAudio(audio);
        }
      }
    );
  };

  const playAudio = () => {
    if (audio) {
      audio.play();
    } else {
      notifyErr('Нет аудиофайла');
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <span className={styles.title}>Редактировать слово</span>
        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          <Input
            type='text'
            name='original'
            placeholder='Слово на корейском'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                }
              }
            }}
          />

          <div className='flex items-center gap-[16px]'>
            <Input
              type='text'
              name='transliteration'
              placeholder='Транскрипция на русском'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
                validate: {
                  doesntConsistOfSpaces: (value: any) => {
                    return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                  }
                }
              }}
            />

            <button
              type='button'
              className={
                'flex h-[48px] w-[48px] shrink-0 items-center justify-center rounded-[50%] ' +
                'bg-[#162030] disabled:opacity-50'
              }
              onClick={playAudio}
              disabled={generateTransliterationLoading}
            >
              <IconSound color='#FFFFFF' className='h-[24px] w-[24px]' />
            </button>

            <button
              type='button'
              className={
                'flex h-[48px] w-[48px] shrink-0 items-center justify-center rounded-[13px] ' +
                'bg-[#5770F3] disabled:opacity-50'
              }
              onClick={generateTransliteration}
              disabled={generateTransliterationLoading}
            >
              {generateTransliterationLoading ? (
                <img src={spinner} alt='spinner' className='block h-[24px] w-[24px] animate-spin' />
              ) : (
                <IconRefresh color='#FFFFFF' className='h-[24px] w-[24px]' />
              )}
            </button>
          </div>

          <Input
            type='text'
            name='translation'
            placeholder='Перевод на русский'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                }
              }
            }}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            onClick={onCancel}
            isDisabled={updateWordLoading || generateTransliterationLoading}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty || generateTransliterationLoading}
            isLoading={updateWordLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default UpdateWordForm;
