import { FC, useMemo } from 'react';
import { Control, Controller, UseFieldArrayRemove, useController } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import {
  AddSubscriptionsFormValues,
  SubscriptionTerm,
  subscriptionTermOptions
} from 'models/subscription';

import { InputDate, Select } from 'components/atoms';
import { IconClose } from 'components/atoms/icons';
import { SelectCourse } from 'components/organisms';

type CourseCardProps = {
  control: Control<AddSubscriptionsFormValues>;
  removeCourse: UseFieldArrayRemove;
  isAllowToRemove: boolean;
  index: number;
};

const CourseCard: FC<CourseCardProps> = ({ control, removeCourse, isAllowToRemove, index }) => {
  const {
    field: { value: subscriptionTerm }
  } = useController({
    name: `courses.${index}.subscriptionTerm`,
    control
  });

  const SubscriptionTermComponent = useMemo(() => {
    switch (subscriptionTerm) {
      case SubscriptionTerm.ARBITRARY:
        return (
          <div className='flex flex-[2] items-center gap-[8px]'>
            <InputDate
              name={`courses.${index}.from`}
              label='с'
              width='big'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
              }}
            />
            <InputDate
              name={`courses.${index}.to`}
              label='по'
              width='big'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
              }}
            />
          </div>
        );
      case SubscriptionTerm.THREE_MONTHS:
      case SubscriptionTerm.SIX_MONTHS:
      case SubscriptionTerm.NINE_MONTHS:
      case SubscriptionTerm.TWELVE_MONTHS:
      case SubscriptionTerm.TWENTY_FOUR_MONTHS:
        return (
          <div className='flex flex-[2] items-center gap-[8px]'>
            <InputDate
              name={`courses.${index}.from`}
              label='с'
              width='big'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
              }}
            />
          </div>
        );
      case SubscriptionTerm.PERMANENT:
        return null;
    }
  }, [control, index, subscriptionTerm]);

  return (
    <div className='flex items-center gap-[8px]'>
      <Controller
        control={control}
        name={`courses.${index}.course`}
        rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <div className='flex flex-[2] flex-col'>
            <SelectCourse selectedCourse={value} selectCourse={onChange} />

            {error && (
              <span className='ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
                {error.message}
              </span>
            )}
          </div>
        )}
      />

      <div className='flex flex-[3] items-center gap-[8px]'>
        <Select
          name={`courses.${index}.subscriptionTerm`}
          placeholder='Выбрать срок'
          control={control}
          options={subscriptionTermOptions}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
          }}
          containerClassName='flex-[1]'
        />
        {SubscriptionTermComponent}
      </div>

      {isAllowToRemove && (
        <button
          type='button'
          className='flex h-[48px] w-[56px] shrink-0 items-center justify-center rounded-[12px] bg-[#EEF0FF]'
          onClick={() => removeCourse(index)}
        >
          <IconClose color='#71798F' />
        </button>
      )}
    </div>
  );
};

export default CourseCard;
