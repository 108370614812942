import { OutputData } from '@editorjs/editorjs';
import { FC } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetValues
} from 'react-hook-form';

import { FORM_ERROR_MESSAGES, TEST_STEPS_ERROR_MESSAGES } from 'consts';
import { StepFormat } from 'models';
import styles from './IncorrectWords.module.css';

import ButtonAdd from 'components/atoms/ButtonAdd';
import { IconClose } from 'components/atoms/icons';
import Input from 'components/atoms/Input';

interface FieldValues {
  condition?: string;
  editableCondition: OutputData;
  purpose?: string;
  editablePurpose: OutputData;
  correctAnswers: string;
  incorrectAnswers: Array<{ value: string }>;
  hint?: string;
  format: StepFormat;
  textForAudio?: string;
  url?: string;
  picture?: FileList;
  audio?: FileList;
  videoUrl?: string;
}

interface IncorrectWordsProps {
  control: any;
  name: string;
  fields: FieldArrayWithId<FieldValues, 'incorrectAnswers', 'id'>[];
  append: UseFieldArrayAppend<any, any>;
  remove: UseFieldArrayRemove;
  getValues: UseFormGetValues<FieldValues>;
  isEmptyAllowed?: boolean;
}

const IncorrectWords: FC<IncorrectWordsProps> = ({
  control,
  name,
  fields,
  append,
  remove,
  getValues,
  isEmptyAllowed = true
}) => {
  return (
    <div className={styles.container}>
      <label className={styles.label}>Неправильные ответы</label>

      {fields.length > 0 && (
        <ul className='mb-[16px] flex w-full flex-auto flex-col gap-[8px]'>
          <>
            {fields.map((field, i) => (
              <div className='flex flex-auto items-center gap-[8px]' key={field.id}>
                <Input
                  type='text'
                  variant='bordered'
                  width='big'
                  name={`${name}.${i}.value` as const}
                  control={control}
                  rules={{
                    required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                    maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
                    validate: {
                      doesntConsistOfSpaces: (value: any) => {
                        return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                      },
                      isNotDuplicate: (value: any) => {
                        const variants = [
                          ...getValues('incorrectAnswers').map(answer => answer.value),
                          getValues('correctAnswers')
                        ];
                        const copies = variants.reduce(
                          (acc, variant) => (variant.trim() === value.trim() ? acc + 1 : acc),
                          0
                        );
                        return copies < 2 ? true : TEST_STEPS_ERROR_MESSAGES.CHOOSE_ANSWER;
                      }
                    }
                  }}
                />

                {(isEmptyAllowed || fields.length > 1) && (
                  <button
                    type='button'
                    className='flex h-[48px] w-[56px] items-center justify-center rounded-[12px] bg-[#EEF0FF]'
                    onClick={() => remove(i)}
                  >
                    <IconClose color='#71798F' />
                  </button>
                )}
              </div>
            ))}
          </>
        </ul>
      )}

      <ButtonAdd
        variant='light'
        type='button'
        title='Добавить неправильный ответ'
        onClick={() => append({ value: '' })}
      />
    </div>
  );
};

export default IncorrectWords;
