import { OutputData } from '@editorjs/editorjs';

const normalizeText = (text: string): string => {
  return text
    .replace(/(?:&nbsp;)/g, ' ')
    .replace(/<br>/g, '')
    .trim();
};

export const normalizeData = (data: OutputData): OutputData => {
  const newBlocks = [...data.blocks].map(block => {
    if (block.data) {
      if (block.data.text) {
        const oldData = block.data;
        const oldText = oldData.text;
        const newText = normalizeText(oldText);
        return { ...block, data: { ...oldData, text: newText } };
      }

      if (block.data.items) {
        const oldData = block.data;
        const oldItems = oldData.items;
        const newItems = oldItems.map((item: string) => normalizeText(item));
        return { ...block, data: { ...oldData, items: newItems } };
      }
    }

    return block;
  });

  const newData = { ...data, blocks: newBlocks };
  return newData;
};
