export enum COURSE_STATUS {
  PUBLISHED_FOR_ALL = 'Опубликован для всех',
  PUBLISHED_BY_SUBSCRIPTION = 'Опубликован только по подписке',
  UNPUBLISHED = 'Не опубликован'
}

export const COURSE_MIN_ATTEMPTS = 1;
export const COURSE_MAX_ATTEMPTS = 10;

export const COURSE_MIN_LEVEL = 0;
export const COURSE_MAX_LEVEL = 1000;
