import { Dispatch, FC, SetStateAction, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useChildHeightExceeded } from 'hooks';
import { useCreateAndAddWordsMutation } from 'hooks/dictionary';
import { ValidatedWord, ValidatedWordsData } from 'models';
import styles from './UploadWords.module.css';
import { filterWordsForAddingToLesson, getStatusStatisticsFromWords } from './utils';

import Button from 'components/atoms/Button';
import { StatusBar, WordCard } from './components';

export interface FieldValues {
  words: ValidatedWord[];
}

interface UploadWordsProps {
  validatedWordsData: ValidatedWordsData;
  setValidatedWordsData: Dispatch<SetStateAction<ValidatedWordsData | null>>;
}

type Params = { dictionaryId: string };

const UploadWords: FC<UploadWordsProps> = ({
  validatedWordsData: { words },
  setValidatedWordsData
}) => {
  const { dictionaryId } = useParams<keyof Params>() as Params;
  const { mutate: createAndAddWordsMutate, isLoading: createAndAddWordsLoading } =
    useCreateAndAddWordsMutation();

  const handleReset = () => {
    setValidatedWordsData(null);
  };

  const handleSubmit = async () => {
    const filteredWords = filterWordsForAddingToLesson(words);
    const filteredWordsData = filteredWords.map(word => word.data);

    createAndAddWordsMutate(
      { dictionaryId, body: { words: filteredWordsData } },
      {
        onSuccess: () => {
          setValidatedWordsData(null);
        }
      }
    );
  };

  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLUListElement>(null);
  const [isChildHeightExceeded] = useChildHeightExceeded(parentRef, childRef);

  const statusStatistics = useMemo(() => {
    const statusStatistics = getStatusStatisticsFromWords(words);
    return statusStatistics;
  }, [words]);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.header__title}>Загрузить слова в урок</h2>
      </header>

      <StatusBar value={statusStatistics} />

      <div
        className={`${styles.words} ${isChildHeightExceeded ? 'pr-[21px]' : ''}`}
        ref={parentRef}
      >
        {words ? (
          <ul className={styles.words__list} ref={childRef}>
            {words.map((word, index) => (
              <WordCard
                key={JSON.stringify(word) + index}
                dictionaryId={dictionaryId}
                word={word}
                index={index}
                setValidatedWordsData={setValidatedWordsData}
              />
            ))}
          </ul>
        ) : (
          <span>Загрузка...</span>
        )}
      </div>

      <div className={styles.buttons}>
        <Button
          type='reset'
          variant='secondary'
          title='Отменить'
          className='w-[198px]'
          isDisabled={createAndAddWordsLoading}
          onClick={handleReset}
        />

        <Button
          type='submit'
          variant='primary'
          title='Загрузить'
          className='w-[198px]'
          isDisabled={!statusStatistics.new && !statusStatistics.inVocabulary}
          isLoading={createAndAddWordsLoading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default UploadWords;
