import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';
import { courseLearningObjectiveIcons } from 'consts';
import { FC, useState } from 'react';

interface SelectLearningObjectivesIconProps {
  onSelectIcon: (iconUrl: string) => void;
  currentIconUrl: string | null;
  onCancel: () => void;
}

const SelectLearningObjectivesIcon: FC<SelectLearningObjectivesIconProps> = ({
  onSelectIcon,
  currentIconUrl,
  onCancel
}) => {
  const [selectedIconUrl, setSelectedIconUrl] = useState<string | null>(currentIconUrl);

  const handleSubmit = () => {
    if (!selectedIconUrl) return;
    onSelectIcon(selectedIconUrl);
  };

  return (
    <div className='flex max-w-[925px] flex-col'>
      <header className='mb-[24px] flex h-[32px] items-center justify-between'>
        <h2 className='text-[22px] font-[700] leading-[26px] text-[#20233a]'>
          Выбрать иконку для описания
        </h2>

        <button className='h-[24px] w-[24px]' onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <div className='flex flex-col'>
        <ul className='mb-[24px] flex max-h-[489px] flex-wrap gap-[12px] overflow-auto'>
          {courseLearningObjectiveIcons.map(icon => (
            <li key={icon}>
              <button
                type='button'
                className={
                  'h-[80px] w-[80px] overflow-hidden rounded-[40px] flex items-center justify-center border-[2px] bg-[#FFC530] border-solid ' +
                  `${
                    selectedIconUrl === icon
                      ? 'bg-[#EEF0FF] border-[#5770F3]'
                      : 'bg-[#EFF1F4] border-[transparent]'
                  }`
                }
                onClick={() => setSelectedIconUrl(icon)}
              >
                <img src={icon} alt={`course-avatar-${icon}`} />
              </button>
            </li>
          ))}
        </ul>

        <div className='flex justify-end gap-[8px]'>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            onClick={onCancel}
            className='w-[180px]'
          />

          <Button
            type='button'
            variant='primary'
            title='Выбрать'
            onClick={handleSubmit}
            isDisabled={!selectedIconUrl}
            className='w-[180px]'
          />
        </div>
      </div>
    </div>
  );
};

export default SelectLearningObjectivesIcon;
