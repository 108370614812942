import { authActionConstTypes } from '../types/authTypes';
import { rootActions } from '../types/rootTypes';

const initialState = {
  isLoggedIn: !!localStorage.getItem('token') || false
};

export type initialStateType = typeof initialState;

export const authReducer = (state = initialState, action: rootActions): initialStateType => {
  switch (action.type) {
    case authActionConstTypes.SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload
      };
    default:
      return state;
  }
};
