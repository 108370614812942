import { FC, useMemo } from 'react';

import { TooltipData } from 'models';
import { TOOLTIP_DEFAULT_HEIGHT, TOOLTIP_DEFAULT_WIDTH } from './const';

interface CustomTooltipProps {
  tooltipData: TooltipData;
}

const CustomTooltip: FC<CustomTooltipProps> = ({
  tooltipData: { showTooltip, y, x, height, width, xAlign, yAlign, date, label }
}) => {
  const { amount, text } = useMemo<{ amount: string; text: string }>(() => {
    if (!label) return { amount: '', text: '' };
    const [text, amount] = label.split(':');
    return { amount: amount.trim(), text: text.trim().toLowerCase() };
  }, [label]);

  const top = useMemo<number | undefined>(() => {
    if (!y || !height || !yAlign) return undefined;

    switch (yAlign) {
      case 'center':
        return y + height / 2 - TOOLTIP_DEFAULT_HEIGHT / 2;
      case 'top':
        return y;
      case 'bottom':
        return y;
    }
  }, [y, height, yAlign]);

  const left = useMemo<number | undefined>(() => {
    if (!x || !width || !xAlign) return undefined;

    switch (xAlign) {
      case 'center':
        return x + width / 2 - TOOLTIP_DEFAULT_WIDTH / 2;
      case 'left':
        return x;
      case 'right':
        return x - (TOOLTIP_DEFAULT_WIDTH - width);
    }
  }, [x, width, xAlign]);

  return (
    <div
      style={{
        opacity: showTooltip ? 1 : 0,
        top,
        left
      }}
      className={
        `absolute flex flex-col p-[16px] rounded-[12px] gap-[16px] w-[${TOOLTIP_DEFAULT_WIDTH}px] h-[${TOOLTIP_DEFAULT_HEIGHT}px] ` +
        'bg-[#FFFFFF] transition-all duration-500 select-none pointer-events-none shadow-[0_4px_22px_0_rgba(0,0,0,0.08)]'
      }
    >
      <h5 className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>{date}</h5>
      <div className='flex h-[36px] max-w-[191px] items-center gap-[8px]'>
        <span className='text-[30px] font-[700] leading-[36px] text-[#20233A]'>{amount}</span>
        <span className='text-[18px] font-[500] leading-[22px] text-[#20233A] line-clamp-2'>
          {text}
        </span>
      </div>
    </div>
  );
};

export default CustomTooltip;
