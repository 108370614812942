import { FC } from 'react';
import { useForm } from 'react-hook-form';
import TextArea from 'components/atoms/TextArea';
import Button from 'components/atoms/Button';
import { useParams } from 'react-router-dom';
import cls from './NotionTextParse.module.css';

interface NotionTextParseProps {
  onClose: () => void;
  validateNotionText: (dictionaryId: string, text: string) => void;
}

interface FormValues {
  text: string;
}

const NotionTextParse: FC<NotionTextParseProps> = ({ onClose, validateNotionText }) => {
  const { dictionaryId } = useParams();

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormValues>({ defaultValues: { text: '' } });

  const onSubmit = (data: FormValues) => {
    if (dictionaryId && data.text) {
      validateNotionText(dictionaryId, data.text);
    }
  };

  return (
    <div className={cls.root}>
      <h3 className={cls.header}>Добавить слова из Notion</h3>
      <form onSubmit={handleSubmit(onSubmit)} className={cls.form}>
        <TextArea
          name='text'
          containerClassName={cls.textareaContainer}
          className={cls.textarea}
          control={control}
          variant='dark'
          rules={{ required: true }}
          resize={false}
        />
        <div className={cls.buttons}>
          <Button
            className={cls.button}
            type='button'
            title='Отмена'
            variant='secondary'
            onClick={onClose}
          />
          <Button
            className={cls.button}
            type='submit'
            title='Загрузить'
            variant='primary'
            isDisabled={!isValid}
          />
        </div>
      </form>
    </div>
  );
};

export default NotionTextParse;
