import { User } from 'models';
import { instance } from './api';
import { ILesson, IModule } from './api-courses';

export type GetAllStudentsDto = {
  courseId: string;
  startDate: string;
  endDate: string;
};

export type GetAllStudentsResponse = {
  data: AllStudentsDateData[];
  subscribed: number;
  totalStudents: number;
};

export type AllStudentsDateData = {
  date: number;
  cumulativeStudents: number;
};

export interface GetNewStudentsDto {
  courseId: string;
  startDate: string;
  endDate: string;
}

export interface GetNewStudentsResponse {
  _id: string;
  courseId?: string;
  subscribed: number;
  totalStudents: number;
  dateData: NewStudentsDateData[];
}

export interface NewStudentsDateData {
  date: number;
  newStudentsCount: number;
  studentsIds: string[];
}

export interface GetNewStudentsDayDto {
  courseId: string;
  date: string;
}

export enum Period {
  LAST_YEAR = 'lastYear',
  LAST_MONTH = 'lastMonth',
  LAST_WEEK = 'lastWeek',
  yesterday = 'yesterday'
}

export type GetNewStudentsByPeriodDto = {
  courseId: string;
};

export type GetNewStudentsByPeriodResponse = {
  period: Period;
  count: number;
}[];

export type GetNotStartedStudentsDto = {
  courseId: string;
  startDate: string;
  endDate: string;
};

export type GetNotStartedStudentsResponse = NotStartedStudentsDateData[];

export type NotStartedStudentsDateData = {
  date: number;
  studentsCount: number;
};

export type GetNotStartedStudentsByPeriodDto = {
  courseId: string;
};

export type GetNotStartedStudentsByPeriodResponse = {
  lastYear: number;
  lastMonth: number;
  lastWeek: number;
  yesterday: number;
  allTime: number;
};

export type GetDropoutStudentsDto = {
  courseId: string;
  startDate: string;
  endDate: string;
};

export type GetDropoutStudentsResponse = DropoutStudentsDateData[];

export type DropoutStudentsDateData = {
  date: number;
  studentsCount: number;
};

export type GetDropoutStudentsByPeriodDto = {
  courseId: string;
};

export type GetDropoutStudentsByPeriodResponse = {
  thirtyDaysCount: number;
  fourteenDaysCount: number;
  sevenDaysCount: number;
  students: any[];
};

export type GetCompletedStudentsDto = {
  courseId: string;
  startDate: string;
  endDate: string;
};

export type GetCompletedStudentsResponse = {
  courseId: string;
  dateData: CompletersDateData[];
};

export interface CompletersDateData {
  date: number;
  completed: number;
  studentsIds: string[];
}

export interface GetCompletedStudentsDayDto {
  courseId: string;
  date: string;
}

export type GetCompletedStudentsByPeriodDto = {
  courseId: string;
};

export type GetCompletedStudentsByPeriodResponse = {
  totalStudentsCount: number;
  allTime: CompletedStudentsByPeriod;
  lastYear: CompletedStudentsByPeriod;
  lastMonth: CompletedStudentsByPeriod;
  lastWeek: CompletedStudentsByPeriod;
};

export type CompletedStudentsByPeriod = {
  totalCompleted: number;
  uniqueStudents: number;
};

export type GetCourseInfoResponse = {
  usersCount?: number;
  modulesCount: number;
  lessonsCount: number;
  sectionsCount: number;
  stepsCount: number;
};

export type GetCourseMistakesResponse = {
  data: {
    module: MistakesModule;
    lessons: MistakesLesson[];
  }[];
};

export type GetCourseMistakesByPeriodDto = {
  courseId: string;
};

export type GetCourseMistakesByPeriodResponse = {
  lastYear: number;
  lastMonth: number;
  lastWeek: number;
  yesterday: number;
  lessonWithMaxMistakes: Omit<ILesson, 'sections' | 'words'>;
  maxMistakesLessonCount: number;
};

export type MistakesModule = Omit<IModule, 'lessons'>;

export type MistakesLesson = {
  lesson: Omit<ILesson, 'sections' | 'words'>;
  mistakesCount: number;
};

export type GetLessonMistakeUsersDto = { courseId: string; lessonId: string };

export type GetLessonMistakeUsersResponse = {
  id: string;
  name: string;
  email: string;
  avatarUrl?: string;
  mistakesCount: number;
}[];

export type GetStoppedStudyingResponse = {
  thirtyDaysCount: number;
  sevenDaysCount: number;
  students: User[];
};

export type ReviewsModule = Omit<IModule, 'lessons'>;

export type ReviewsLesson = {
  lesson: Omit<ILesson, 'sections' | 'words'>;
  goodReviews: number;
  neutralReviews: number;
  badReviews: number;
};

export type GetCourseReviewsResponse = {
  module: ReviewsModule;
  lessons: ReviewsLesson[];
}[];

export enum LessonReview {
  GOOD = 'good',
  NEUTRAL = 'neutral',
  BAD = 'bad'
}

export type GetLessonReviewUsersResponse = {
  id: string;
  name: string;
  email: string;
  avatarUrl?: string;
  review: LessonReview;
}[];

export const apiAnalytics = {
  getAllStudents: async ({ courseId, startDate, endDate }: GetAllStudentsDto) => {
    const response = await instance.get<GetAllStudentsResponse>(
      `/analytics/cumulative-new-students/${courseId}?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  },
  getNewStudents: async ({ courseId, startDate, endDate }: GetNewStudentsDto) => {
    const response = await instance.get<GetNewStudentsResponse>(
      `/analytics/new-students/${courseId}?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  },
  getNewStudentsDay: async ({ courseId, date }: GetNewStudentsDayDto) => {
    const response = await instance.get<User[]>(
      `/analytics/new-students-per-day/${courseId}?date=${date}`
    );
    return response.data;
  },
  getNewStudentByPeriod: async ({ courseId }: GetNewStudentsByPeriodDto) => {
    const response = await instance.get<GetNewStudentsByPeriodResponse>(
      `/analytics/new-students-by-periods/${courseId}`
    );
    return response.data;
  },
  getNotStartedStudents: async ({ courseId, startDate, endDate }: GetNotStartedStudentsDto) => {
    const response = await instance.get<GetNotStartedStudentsResponse>(
      `/analytics/not-started-studying/${courseId}?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  },
  getNotStartedStudentsByPeriod: async ({ courseId }: GetNotStartedStudentsByPeriodDto) => {
    const response = await instance.get<GetNotStartedStudentsByPeriodResponse>(
      `/analytics/not-started-by-periods/${courseId}`
    );
    return response.data;
  },
  getDropoutStudents: async ({ courseId, startDate, endDate }: GetDropoutStudentsDto) => {
    const response = await instance.get<GetDropoutStudentsResponse>(
      `/analytics/inactive-students-by-periods/${courseId}?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  },
  getDropoutStudentsByPeriod: async ({ courseId }: GetDropoutStudentsByPeriodDto) => {
    const response = await instance.get<GetDropoutStudentsByPeriodResponse>(
      `/analytics/stopped-studying/${courseId}`
    );
    return response.data;
  },
  getCompletedStudents: async ({ courseId, startDate, endDate }: GetCompletedStudentsDto) => {
    const response = await instance.get<GetCompletedStudentsResponse>(
      `/analytics/course-completion/${courseId}?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  },
  getCompletedStudentsDay: async ({ courseId, date }: GetCompletedStudentsDayDto) => {
    const response = await instance.get<User[]>(
      `/analytics/completed-course-users/${courseId}?date=${date}`
    );
    return response.data;
  },
  getCompletedStudentsByPeriod: async ({ courseId }: GetCompletedStudentsByPeriodDto) => {
    const response = await instance.get<GetCompletedStudentsByPeriodResponse>(
      `/analytics/completed-course-by-periods/${courseId}`
    );
    return response.data;
  },
  getCourseNumbers: async (courseId: string) => {
    const response = await instance.get<GetCourseInfoResponse>(
      `/analytics/course-information/${courseId}`
    );
    return response.data;
  },
  getCourseMistakes: async (courseId: string) => {
    const response = await instance.get<GetCourseMistakesResponse>(
      `/analytics/mistakes/${courseId}`
    );
    return response.data;
  },
  getCourseMistakesByPeriod: async ({ courseId }: GetCourseMistakesByPeriodDto) => {
    const response = await instance.get<GetCourseMistakesByPeriodResponse>(
      `/analytics/mistake-in-lessons-by-periods/${courseId}`
    );
    return response.data;
  },
  getLessonMistakeUsers: async ({ courseId, lessonId }: GetLessonMistakeUsersDto) => {
    const response = await instance.get<GetLessonMistakeUsersResponse>(
      `/analytics/mistakes/users/${courseId}/${lessonId}`
    );
    return response.data;
  },
  getStoppedStudying: async (courseId: string) => {
    const response = await instance.get<GetStoppedStudyingResponse>(
      `/analytics/stopped-studying/${courseId}`
    );
    return response.data;
  },
  getCourseReviews: async (courseId: string) => {
    const response = await instance.get<GetCourseReviewsResponse>(
      `/analytics/lesson-reviews/${courseId}`
    );
    return response.data;
  },
  getLessonReviewUsers: async (lessonId: string) => {
    const response = await instance.get<GetLessonReviewUsersResponse>(
      `/analytics/lesson-reviews/rated-users/${lessonId}`
    );
    return response.data;
  }
};
