import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CHECKLIST_OPTIONS } from 'consts';
import { ChecklistItem } from 'models';

import Button from 'components/atoms/Button';
import { IconBack, IconCheck, IconClose } from 'components/atoms/icons';
import { SelectCourseStatus } from '../../../SelectCourseStatus';
import { CourseReady } from '../CourseReady';

interface ReviewProps {
  steps: ChecklistItem[];
}

const Review: FC<ReviewProps> = ({ steps }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isReady = useMemo(() => {
    const stepsDone = steps.reduce((acc, step) => (step.condition ? ++acc : acc), 0);
    return stepsDone === steps.length;
  }, [steps]);

  return (
    <div className='flex h-full flex-col'>
      <header className='mb-[16px] flex h-[49px] w-full items-center justify-between'>
        <h2 className='text-[29px] font-[700] leading-[35px] text-[#20233A]'>Чек-лист</h2>

        <SelectCourseStatus />
      </header>

      <div className='mb-[24px] flex h-[49px] items-center'>
        <CourseReady steps={steps} variant='big' />
      </div>

      <div className='mb-auto flex flex-col gap-[20px]'>
        {steps.map(step => (
          <div key={step.id} className='flex gap-[12px]'>
            <div className='h-[24px] w-[24px]'>
              {step.condition ? <IconCheck color='#66C84D' /> : <IconClose color='#FF4444' />}
            </div>

            <div className='flex flex-col gap-[8px]'>
              <h3 className='text-[18px] font-[500] leading-[21px] text-[#20233A]'>{step.name}</h3>
              <p className='text-[16px] leading-[24px] text-[#71798F]'>{step.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className='mt-[24px] flex justify-between'>
        <Button
          type='button'
          variant='secondary'
          title='Назад'
          iconPosition='left'
          icon={<IconBack className='text-[#71798F]' />}
          onClick={() => {
            searchParams.set('checklistOption', CHECKLIST_OPTIONS.STEPS);
            setSearchParams(searchParams);
          }}
        />

        <Button
          type='button'
          variant='primary'
          title='Все верно'
          iconPosition='left'
          icon={<IconCheck />}
          onClick={() => {
            searchParams.set('checklistOption', CHECKLIST_OPTIONS.UPDATE_STATUS);
            setSearchParams(searchParams);
          }}
          isDisabled={!isReady}
        />
      </div>
    </div>
  );
};

export default Review;
