import { IconClose, IconPlus } from 'components/atoms/icons';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import { FC, useState } from 'react';
import { Control, Controller, UseFieldArrayRemove } from 'react-hook-form';
import { FORM_ERROR_MESSAGES } from '../../../../../../consts';
import { SelectLearningObjectivesIcon } from '../SelectLearningObjectivesIcon';

interface ItemProps {
  name: string;
  control: Control<any, any>;
  i: number;
  remove: UseFieldArrayRemove;
}

export const Item: FC<ItemProps> = ({ name, control, remove, i }) => {
  const [selectIconModalActive, setSelectIconModalActive] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const icon = control._getFieldArray(name)[i]?.iconLink;

  return (
    <>
      <div className='flex items-center gap-2'>
        <div
          className='flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#FFC530]'
          onClick={() => setSelectIconModalActive(true)}
        >
          {icon ? (
            <img src={icon} className='h-[24px] w-[24px]' alt='' />
          ) : (
            <IconPlus color='#fff' />
          )}
        </div>
        <div className='flex flex-1 gap-2'>
          <Input
            type='text'
            variant='bordered'
            width='big'
            placeholder='Описание'
            className='h-[54px] rounded-[14px] p-[16px] text-[14px] leading-[19px]'
            name={`${name}.${i}.text` as const}
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                }
              }
            }}
          />

          <button
            type='button'
            className='flex h-[48px] w-[56px] items-center justify-center rounded-xl bg-[#EEF0FF]'
            onClick={() => remove(i)}
          >
            <IconClose color='#71798F' />
          </button>
        </div>
        {selectIconModalActive && (
          <Modal onClose={() => setSelectIconModalActive(false)}>
            <Controller
              control={control}
              name={`${name}.${i}.iconLink` as const}
              render={({ field: { value, onChange } }) => (
                <SelectLearningObjectivesIcon
                  currentIconUrl={value}
                  onSelectIcon={iconUrl => {
                    onChange(iconUrl);
                    setSelectIconModalActive(false);
                  }}
                  onCancel={() => setSelectIconModalActive(false)}
                />
              )}
            />
          </Modal>
        )}
      </div>
    </>
  );
};
