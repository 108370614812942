import { FC } from 'react';

import { useGetNotStartedStudentsByPeriodQuery } from 'hooks/general-analytics';
import { classNames } from 'utils';
import { PeriodsSkeleton } from '../PeriodsSkeleton';

type Props = {
  className?: string;
};

export const Periods: FC<Props> = props => {
  const { className } = props;
  const { data: notStartedStudentsByPeriod, isLoading: notStartedStudentsByPeriodLoading } =
    useGetNotStartedStudentsByPeriodQuery();

  if (notStartedStudentsByPeriodLoading) {
    return <PeriodsSkeleton />;
  }

  if (!notStartedStudentsByPeriod) {
    return null;
  }

  return (
    <header
      className={classNames(
        'flex flex-1 w-full justify-between p-[15px] ' +
          'bg-[#F5F5F5] border-b-[1px] border-solid border-[#E9ECF0]',
        {},
        [className]
      )}
    >
      <ul className='flex items-end overflow-hidden'>
        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] line-clamp-2'>
            Всего купили курсы
            <br />
            но не приступили к обучению
          </p>
          <h3 className='h3 text-[#20233A] truncate'>{notStartedStudentsByPeriod.allTime}</h3>
        </li>
        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] truncate'>В прошлом году</p>
          <h3 className='h3 text-[#20233A] truncate'>{notStartedStudentsByPeriod.lastYear}</h3>
        </li>
        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] truncate'>В прошлом месяце</p>
          <h3 className='h3 text-[#20233A] truncate'>{notStartedStudentsByPeriod.lastMonth}</h3>
        </li>
        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] truncate'>На прошлой неделе</p>
          <h3 className='h3 text-[#20233A] truncate'>{notStartedStudentsByPeriod.lastWeek}</h3>
        </li>
      </ul>
    </header>
  );
};
