import { FC } from 'react';

import { WORDS_TABLE_HEADERS } from 'consts';
import styles from './CatalogPage.module.css';
import { DEFAULT_WORDS_AMOUNT } from './const';

import { Skeleton } from 'components/atoms';

const ContentSkeleton: FC = () => {
  return (
    <div className='flex w-[calc(100%-264px)] grow flex-col'>
      <Skeleton height={42} width={460} className='mb-[4px]' />
      <Skeleton height={21} width={180} className='mb-[32px]' />
      <Skeleton height={54} border={12} className='mb-[24px]' />

      <div className={styles.table__headers}>
        <span className={`${styles.table__header} ${styles.table__header_index}`}>
          {WORDS_TABLE_HEADERS[0]}
        </span>

        <span className={`${styles.table__header} ${styles.table__header_image}`}>
          {WORDS_TABLE_HEADERS[1]}
        </span>

        <span className={`${styles.table__header} ${styles.table__header_kr}`}>
          {WORDS_TABLE_HEADERS[2]}
        </span>

        <span className={`${styles.table__header} ${styles.table__header_ru}`}>
          {WORDS_TABLE_HEADERS[3]}
        </span>

        <span className={`${styles.table__header} ${styles.table__header_actions}`}>
          {WORDS_TABLE_HEADERS[4]}
        </span>
      </div>

      <div className={styles.table__rows}>
        {Array.from(Array(DEFAULT_WORDS_AMOUNT)).map((_, i) => {
          return (
            <div
              className={`${styles.table__row} ${i % 2 === 1 ? styles.table__row_even : ''}`}
              key={i}
            >
              <div className={`${styles.table__cell} ${styles.table__cell_index}`}>
                <Skeleton />
              </div>

              <div className={`${styles.table__cell} ${styles.table__cell_image}`}>
                <button className={styles.image}>
                  <Skeleton />
                </button>
              </div>

              <div className={`${styles.table__cell} ${styles.table__cell_original}`}>
                <Skeleton />
              </div>

              <div className={`${styles.table__cell} ${styles.table__cell_translation}`}>
                <Skeleton />
              </div>

              <div className={`${styles.table__cell} ${styles.table__cell_actions}`}>
                <button className={styles.buttonAction}>
                  <Skeleton />
                </button>

                <button className={styles.buttonAction}>
                  <Skeleton />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContentSkeleton;
