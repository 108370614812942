import { FC } from 'react';
import { Control } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { FormValues } from '../../model';

import { InputImage } from 'components/atoms';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import { IconNext } from 'components/atoms/icons';

interface Step1Props {
  control: Control<FormValues>;
  isDirty: boolean;
  cover?: string;
}

const Step1: FC<Step1Props> = ({ control, isDirty, cover }) => {
  return (
    <div className='flex flex-1 flex-col'>
      <h4 className='mb-[30px] text-[18px] font-[500] leading-[21px] text-[#20233A]'>
        Уведомление
      </h4>

      <div className='mb-auto flex gap-[32px]'>
        <div className='flex flex-1 flex-col'>
          <h6 className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            заголовок
          </h6>
          <Input
            type='text'
            variant='bordered'
            width='big'
            name='title'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: {
                value: 75,
                message: FORM_ERROR_MESSAGES.MAX_LIMIT
              }
            }}
            containerClassName='mb-[12px]'
          />

          <h6 className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            сообщение
          </h6>
          <Input
            type='text'
            variant='bordered'
            width='big'
            name='message'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
            }}
          />
        </div>

        <InputImage
          type='file'
          name='image'
          cover={cover}
          control={control}
          accept='.png, .jpg, .jpeg'
          title='Загрузить изображение'
          subtitle='Файл в формате PNG или JPG, в пропорции 2:1, рекомендуемый размер 640x320px'
          className='w-[320px] h-[160px]'
        />
      </div>

      <div className='mt-[30px] flex justify-end'>
        <Button
          type='submit'
          variant='primary'
          title='Далее'
          iconPosition='right'
          icon={<IconNext />}
          className='min-w-[175px]'
          isDisabled={!isDirty}
        />
      </div>
    </div>
  );
};

export default Step1;
