export const translateWeekday = (weekday: string): string => {
  const englishToRussianMap: { [key: string]: string } = {
    Monday: 'Понедельник',
    Tuesday: 'Вторник',
    Wednesday: 'Среда',
    Thursday: 'Четверг',
    Friday: 'Пятница',
    Saturday: 'Суббота',
    Sunday: 'Воскресенье'
  };

  const russianWeekday = englishToRussianMap[weekday];

  return russianWeekday || '';
};
