import { FC } from 'react';

import { ILesson } from 'api/api-courses';
import avatarPlaceholder from 'assets/images/svg/avatar.svg';
import styles from './MoveLessonCard.module.css';

interface MoveLessonCardProps {
  lesson: ILesson;
  isSelected: boolean;
  select?: () => void;
}

const MoveLessonCard: FC<MoveLessonCardProps> = ({
  lesson: { imageUrl, title, order, subtitle },
  isSelected,
  select
}) => {
  return (
    <button
      type='button'
      className={`${styles.container} ${isSelected ? styles.container_selected : ''}`}
      onClick={select}
      disabled={!select}
    >
      <div className={styles.image}>
        <img src={imageUrl || avatarPlaceholder} alt={imageUrl} />
      </div>

      <div className={styles.text}>
        <span className={styles.order}>{subtitle || `Урок ${order}`}</span>
        <h3 className={styles.title}>{title}</h3>
      </div>
    </button>
  );
};

export default MoveLessonCard;
