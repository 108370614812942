import { FC, useEffect, useState } from 'react';

import { ICourse } from 'api/api-courses';
import { COURSES_STATUSES, COURSES_STATUSES_FILTER } from 'consts';
import { useGetCoursesQuery } from 'hooks/course';

import { DictionaryCard } from '..';
import CourseDictionariesSkeleton from './CourseDictionariesSkeleton';

type CourseDictionariesProps = {
  selectedStatus: string;
};

const CourseDictionaries: FC<CourseDictionariesProps> = ({ selectedStatus }) => {
  const { data: courses, isLoading: coursesLoading } = useGetCoursesQuery();
  const [currentCourses, setCurrentCourses] = useState<ICourse[]>([]);

  useEffect(() => {
    if (!courses) return;
    const processedCourses = [...courses].filter(course => {
      if (selectedStatus === COURSES_STATUSES_FILTER[0]) return true;
      return (
        course.status === COURSES_STATUSES[COURSES_STATUSES_FILTER.indexOf(selectedStatus) - 1]
      );
    });

    setCurrentCourses(processedCourses);
  }, [selectedStatus, courses]);

  if (coursesLoading) return <CourseDictionariesSkeleton />;
  if (!courses) return <h2>Не удалось загрузить курсы</h2>;

  return (
    <>
      {currentCourses.map(course => (
        <DictionaryCard key={course.id} course={course} />
      ))}
    </>
  );
};

export default CourseDictionaries;
