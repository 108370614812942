import { FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import RSelect, { SingleValue } from 'react-select';

import { ICourse } from 'api/api-courses';
import { COURSES_PAGE_OPTIONS, COURSES_STATUSES, COURSES_STATUSES_FILTER } from 'consts';
import { useGetCoursesQuery } from 'hooks/course';
import { IOption } from 'models';
import styles from './CoursesPage.module.css';
import { IconOption, IconValue, filterSelectStyles, filterStatusOptions } from './const';

import { IconPlusBig } from 'components/atoms/icons';
import CourseCard from 'components/molecules/CourseCard';
import { CreateCourse, SkeletonCards } from './components';

const CoursesPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: courses,
    isLoading: coursesLoading,
    refetch: refetchCourses
  } = useGetCoursesQuery();

  const getActiveOption = useCallback(
    () => searchParams.get('activeOption') || COURSES_PAGE_OPTIONS.COURSES,
    [searchParams]
  );

  const [selectedStatus, setSelectedStatus] = useState('Все курсы');
  const [currentCourses, setCurrentCourses] = useState<ICourse[]>([]);

  useEffect(() => {
    if (!courses) return;

    const processedCourses = [...courses].filter(course => {
      if (selectedStatus === COURSES_STATUSES_FILTER[0]) return true;
      return (
        course.status === COURSES_STATUSES[COURSES_STATUSES_FILTER.indexOf(selectedStatus) - 1]
      );
    });

    setCurrentCourses(processedCourses);
  }, [selectedStatus, courses]);

  return (
    <div className={styles.container}>
      {currentCourses ? (
        <>
          {getActiveOption() === COURSES_PAGE_OPTIONS.COURSES && (
            <>
              <header className={styles.header}>
                <h2 className={styles.title}>Курсы</h2>

                <RSelect
                  value={filterStatusOptions.find(({ value }) => value === selectedStatus)}
                  classNamePrefix='custom-select'
                  styles={filterSelectStyles}
                  placeholder={'Все статусы'}
                  options={filterStatusOptions}
                  components={{ Option: IconOption, SingleValue: IconValue }}
                  onChange={(newValue: SingleValue<unknown>) => {
                    setSelectedStatus((newValue as IOption).value);
                  }}
                  isSearchable={false}
                />
              </header>

              <div className={styles.cards}>
                <button
                  className={styles.createCard}
                  onClick={() => setSearchParams({ activeOption: 'createCourse' })}
                >
                  <div className='flex h-[68px] w-[68px] items-center justify-center rounded-[50%] bg-[rgba(87,112,243,0.18)]'>
                    <IconPlusBig className='text-primary' />
                  </div>

                  <p className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>
                    Добавить курс
                  </p>
                </button>

                {coursesLoading ? (
                  <SkeletonCards />
                ) : (
                  <>
                    {currentCourses.map(course => (
                      <CourseCard key={course.id} course={course} refetchCourse={refetchCourses} />
                    ))}
                  </>
                )}
              </div>
            </>
          )}

          {getActiveOption() === COURSES_PAGE_OPTIONS.CREATE_COURSE && (
            <CreateCourse backToCourses={() => setSearchParams({ activeOption: 'courses' })} />
          )}
        </>
      ) : (
        <h2>Загрузка...</h2>
      )}
    </div>
  );
};

export default CoursesPage;
