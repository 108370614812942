import { useMutation, useQueryClient } from 'react-query';

import { apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export interface DeleteSpeakersDto {
  courseId: string;
  userIds: string[];
}

export const useDeleteSpeakersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ courseId, userIds }: DeleteSpeakersDto) => {
      return apiCourses.deleteSpeakers(courseId, userIds);
    },
    {
      onSuccess(_, variables) {
        notifySuc('Спикеры курса удалены');

        // TODO add response with new image url on the backend for cache updating
        queryClient.invalidateQueries([`courses/one/${variables.courseId}`], {
          refetchActive: false
        });
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
