import { useQuery } from 'react-query';

import { apiCourses } from 'api/api-courses';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetCoursesQuery = () => {
  return useQuery(['courses/all'], async () => await apiCourses.getAllCourses(), {
    staleTime: DEFAULT_STALE_TIME,
    onError(error) {
      handleError(error);
    }
  });
};
