import { instance } from './api';
import {
  GetDealNotificationResponse,
  GetDealNotificationsDto,
  GetDealNotificationsResponse
} from 'models';

export const apiDealNotifications = {
  getDealNotifications: async ({
    page,
    itemsPerPage,
    search,
    filter,
    sortBy
  }: GetDealNotificationsDto) => {
    let query = `/deal-notifications/all?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (!!search) {
      query += `&search=${search}`;
    }
    if (!!filter) {
      query += `&filter=${filter}`;
    }

    if (!!sortBy) {
      const [sort, sortOrder] = sortBy.split('-');
      query += `&sort=${sort}&sortOrder=${sortOrder}`;
    }

    const response = await instance.get<GetDealNotificationsResponse>(query);
    return response.data;
  },
  getDealNotification: async (id: string) => {
    const response = await instance.get<GetDealNotificationResponse>(
      `/deal-notifications/one/${id}`
    );
    return response.data;
  }
};
