import { ChangeEvent, InputHTMLAttributes, useState } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';

import { IconCalendar } from 'components/atoms/icons';
import cls from './InputData.module.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  width?: 'small' | 'big';
  name: string;
  placeholder?: string;
  label?: string;
  rules?: RegisterOptions;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
}

export type InputDateProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const InputDate = <T extends FieldValues>(props: InputDateProps<T>) => {
  const {
    width = 'small',
    name,
    placeholder = '',
    label,
    control,
    rules = {},
    className,
    containerClassName,
    disabled = false,
    loading = false,
    ...rest
  } = props;

  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController<T>({
    name,
    control,
    rules
  });

  const [inputFocus, setInputFocus] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <label
      htmlFor={name}
      className={
        `${containerClassName} flex flex-col gap-[6px] ` +
        `${width === 'small' ? 'max-w-[404px]' : 'max-w-none grow'}`
      }
    >
      <div
        className={
          'relative flex h-[56px] items-center gap-[8px] px-[15px] py-[19px] ' +
          'rounded-[14px] border-[2px] border-solid border-[rgba(140,154,175,0.25)] ' +
          `${inputFocus ? '' : ''} ` +
          `${!!error ? 'bg-[rgba(255,68,68,0.1)]' : 'bg-[#FFFFFF]'}`
        }
      >
        {label && (
          <label htmlFor={name} className='text-[16px] leading-[19px] text-[#20233A]'>
            {label}
          </label>
        )}

        <input
          type='date'
          id={name}
          placeholder={label ? '' : placeholder}
          value={value || ''}
          onChange={handleChange}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          disabled={disabled || loading}
          readOnly={disabled || loading}
          className={`${cls.input} w-full bg-transparent text-[16px] leading-[19px] text-[#20233A] outline-none`}
          {...rest}
        />

        <IconCalendar className='absolute top-[16px] right-[15px] h-[24px] w-[24px] text-[#5770F3]' />
      </div>

      {error && (
        <span className='ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
          {error.message}
        </span>
      )}
    </label>
  );
};

export default InputDate;
