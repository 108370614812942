/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';
import { UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import ReactPlayer from 'react-player';

import { DEFAULT_VIDEO_VOLUME, FORM_ERROR_MESSAGES } from 'consts';
import useDebounce from 'hooks/useDebounce';
import { StepFormat } from 'models';
import styles from './FormatVideo.module.css';

import { IconClose } from 'components/atoms/icons';
import Input from 'components/atoms/Input';

interface FormatVideoProps {
  savedFormat?: StepFormat;
  control: any;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
}

const FormatVideo: FC<FormatVideoProps> = ({ savedFormat, control, register, watch, setValue }) => {
  const [currentVideoUrl, setCurrentVideoUrl] = useState<string | null>(null);
  const videoUrl = watch('videoUrl');

  // check if the step already has a video url.
  useEffect(() => {
    if (videoUrl && savedFormat === 'video') {
      setCurrentVideoUrl(videoUrl);
    }
  }, []);

  const debouncedSearchValue = useDebounce(videoUrl, 500);
  useEffect(() => {
    setCurrentVideoUrl(videoUrl);
  }, [debouncedSearchValue]);

  const clearUrl = () => {
    setValue('videoUrl', '');
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.url} ${currentVideoUrl ? 'mb-[8px]' : ''}`}>
        <div className={styles.label}>Ссылка на видео (Vimeo)</div>

        <Input
          type='text'
          variant='bordered'
          width='big'
          name='videoUrl'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
            validate: {
              doesntConsistOfSpaces: (value: any) => {
                return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
              }
            }
          }}
          placeholder='Введите URL...'
        />
      </div>

      {currentVideoUrl && (
        <div className={styles.video}>
          <ReactPlayer
            className='flex-1 overflow-hidden rounded-[12px]'
            url={currentVideoUrl}
            controls
            volume={DEFAULT_VIDEO_VOLUME}
            width='100%'
            height='100%'
          />

          <button className={styles.buttonRemove} onClick={clearUrl}>
            <IconClose color='#71798F' />
          </button>
        </div>
      )}
    </div>
  );
};

export default FormatVideo;
