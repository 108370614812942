import { FC, useMemo, useRef, useState } from 'react';

import { STEP_TYPES } from 'api/api-courses';
import {
  IconChooseAnswer,
  IconChooseSentence,
  IconDialog,
  IconFillingWords,
  IconInsertingWords,
  IconMakingSentence,
  IconMatchingPairs,
  IconPlay,
  IconPlus,
  IconQuestion,
  IconText
} from 'components/atoms/icons';
import useOnClickOutside from 'hooks/useOnClickOutside';
import styles from './StepSelect.module.css';

import { PotentialSection } from '../EditSection/EditSection';

interface StepSelectProps {
  stepType?: STEP_TYPES;
  stepIndex?: number | 'potential';
  onSelectValue?: (data: PotentialSection) => void;
  onSelectStep?: (step: number | 'potential') => void;
  isSelected?: boolean;
  isPotential?: boolean;
}

const StepSelect: FC<StepSelectProps> = ({
  stepType,
  stepIndex,
  isSelected,
  onSelectValue,
  onSelectStep,
  isPotential
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const toggleDropdownHandle = () => {
    setIsOpen(prev => !prev);
  };

  useOnClickOutside(dropdownRef, toggleDropdownHandle, { buttonRef });

  const iconElement = useMemo(() => {
    let elem;
    const fill = isSelected ? '#FFFFFF' : '#5770F3';
    switch (stepType) {
      case STEP_TYPES.TEXT:
        elem = <IconText color={fill} />;
        break;
      case STEP_TYPES.VIDEO:
        elem = <IconPlay color={fill} />;
        break;
      case STEP_TYPES.CHOOSE_ANSWER:
        elem = <IconChooseAnswer color={fill} />;
        break;
      case STEP_TYPES.CHOOSE_SENTENCE:
        elem = <IconChooseSentence color={fill} />;
        break;
      case STEP_TYPES.FILLING_MISSING_WORDS:
        elem = <IconFillingWords color={fill} />;
        break;
      case STEP_TYPES.INSERTING_MISSING_WORDS:
        elem = <IconInsertingWords color={fill} />;
        break;
      case STEP_TYPES.MAKING_SENTENCE:
        elem = <IconMakingSentence color={fill} />;
        break;
      case STEP_TYPES.MATCHING_PAIRS:
        elem = <IconMatchingPairs color={fill} />;
        break;
      case STEP_TYPES.DIALOG:
        elem = <IconDialog color={fill} />;
        break;
      default:
        elem = <IconPlus color={isOpen ? '#FFFFFF' : '#5770F3'} />;
    }
    return elem;
  }, [stepType, isSelected, isOpen]);

  const selectStepHandle = (data: PotentialSection) => {
    if (onSelectValue) {
      onSelectValue(data);
    }
    toggleDropdownHandle();
  };

  const clickByStepHandle = () => {
    if (isPotential && onSelectStep) {
      onSelectStep('potential');
      return;
    }
    if (stepType && onSelectStep) {
      onSelectStep(stepIndex || 1);
      return;
    }
    toggleDropdownHandle();
  };

  return (
    <div className='flex h-[66px] flex-col items-center'>
      <div
        className={`mb-[2px] flex h-[40px] w-[64px] cursor-pointer items-center rounded-[12px]  
      ${stepType ? 'pl-[10px]' : 'justify-center'} 
      ${isSelected && stepType ? styles['is-selected-element'] : ''} 
      ${!isSelected && stepType ? styles['not-is-selected-element'] : ''} 
      ${isOpen && !stepType ? styles['open-empty-element'] : ''} 
      ${!isOpen && !stepType ? styles['not-open-empty-element'] : ''}
      `}
        onClick={clickByStepHandle}
        ref={buttonRef}
      >
        {stepType && (
          <div
            className={`text-[14px] leading-5 ${
              isSelected ? 'text-[#EEF0FF]' : 'text-[#71798F]'
            } mr-[6px]`}
          >
            {isPotential ? '?' : stepIndex}.
          </div>
        )}
        {iconElement}
      </div>

      {isOpen && (
        <div className={styles['select-window']} ref={dropdownRef}>
          <div
            className={`${styles.step} ${styles.step_selectable}`}
            onClick={() => selectStepHandle({ type: STEP_TYPES.TEXT, isPotential: true })}
          >
            <IconText color='#5770F3' className='mr-[8px]' />
            <span>Текст</span>
          </div>

          <div
            className={`${styles.step} ${styles.step_selectable}`}
            onClick={() =>
              selectStepHandle({
                type: STEP_TYPES.VIDEO,
                isPotential: true,
                url: '',
                description: ''
              })
            }
          >
            <IconPlay color='#5770F3' className='mr-[8px]' />
            <span>Видео</span>
          </div>

          <div className={styles.step}>
            <IconQuestion color='#5770F3' className='mr-[8px]' />
            <span>Тесты:</span>
          </div>

          <div
            className={`${styles.step} ${styles.step_selectable} ${styles.step_test}`}
            onClick={() => selectStepHandle({ type: STEP_TYPES.MATCHING_PAIRS, isPotential: true })}
          >
            <IconMatchingPairs color='#5770F3' className='mr-[8px]' />
            <span>Пары слов</span>
          </div>

          <div
            className={`${styles.step} ${styles.step_selectable} ${styles.step_test}`}
            onClick={() =>
              selectStepHandle({
                type: STEP_TYPES.CHOOSE_ANSWER,
                isPotential: true,
                condition: '',
                purpose: '',
                answers: [
                  { word: '', correct: true },
                  { word: '', correct: false }
                ],
                format: 'text'
              })
            }
          >
            <IconChooseAnswer color='#5770F3' className='mr-[8px]' />
            <span>Выбрать правильное слово</span>
          </div>

          <div
            className={`${styles.step} ${styles.step_selectable} ${styles.step_test}`}
            onClick={() =>
              selectStepHandle({
                type: STEP_TYPES.CHOOSE_SENTENCE,
                isPotential: true,
                condition: '',
                purpose: '',
                answers: [
                  { sentence: '', correct: true },
                  { sentence: '', correct: false }
                ],
                format: 'text'
              })
            }
          >
            <IconChooseSentence color='#5770F3' className='mr-[8px]' />
            <span>Выбрать правильное предложение</span>
          </div>

          <div
            className={`${styles.step} ${styles.step_selectable} ${styles.step_test}`}
            onClick={() =>
              selectStepHandle({ type: STEP_TYPES.MAKING_SENTENCE, isPotential: true })
            }
          >
            <IconMakingSentence color='#5770F3' className='mr-[8px]' />
            <span>Составить предложения</span>
          </div>

          <div
            className={`${styles.step} ${styles.step_selectable} ${styles.step_test}`}
            onClick={() =>
              selectStepHandle({ type: STEP_TYPES.FILLING_MISSING_WORDS, isPotential: true })
            }
          >
            <IconFillingWords color='#5770F3' className='mr-[8px]' />
            <span>Вписать пропущенные слова</span>
          </div>

          <div
            className={`${styles.step} ${styles.step_selectable} ${styles.step_test}`}
            onClick={() =>
              selectStepHandle({ type: STEP_TYPES.INSERTING_MISSING_WORDS, isPotential: true })
            }
          >
            <IconInsertingWords color='#5770F3' className='mr-[8px]' />
            <span>Перетащить пропущенные слова</span>
          </div>

          <div
            className={`${styles.step} ${styles.step_selectable} ${styles.step_test}`}
            onClick={() => selectStepHandle({ type: STEP_TYPES.DIALOG, isPotential: true })}
          >
            <IconDialog color='#5770F3' className='mr-[8px]' />
            <span>Диалог</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepSelect;
