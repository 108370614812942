import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { apiCourses, UpdateSectionData } from 'api/api-courses';
import { FORM_ERROR_MESSAGES } from 'consts';
import { handleError } from 'helpers/handleError';
import styles from './EditSectionForm.module.css';

import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';
import Input from 'components/atoms/Input';
import { notifySuc } from 'helpers/notification';

interface EditSectionFormProps {
  sectionOrder: number;
  sectionId: string;
  sectionTitle: string;
  refetchSection: () => void;
  onClose: () => void;
}

const EditSectionForm: FC<EditSectionFormProps> = ({
  sectionId,
  sectionOrder,
  sectionTitle,
  refetchSection,
  onClose
}) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FieldValues>({
    mode: 'onSubmit',
    defaultValues: {
      title: sectionTitle
    }
  });

  const { mutate: updateSectionMutate, isLoading: updateSectionLoading } = useMutation(
    ({
      sectionId,
      updateSectionData
    }: {
      sectionId: string;
      updateSectionData: UpdateSectionData;
    }) => {
      return apiCourses.updateSection(sectionId, updateSectionData);
    },
    {
      onSuccess() {
        notifySuc('Раздел обновлен');

        refetchSection();
        onClose();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const onSubmit = async (data: FieldValues) => {
    const { title } = data;

    updateSectionMutate({ sectionId, updateSectionData: { title: title.trim() } });
  };

  return (
    <div className='w-[404px] flex-1'>
      <div className='mb-[24px] flex items-start justify-between gap-[20px]'>
        <div className='text-[22px] font-[700] text-[#20233A]'>Редактировать раздел</div>

        <button className='pb-[8px] pl-[8px] outline-0' onClick={onClose}>
          <IconClose />
        </button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-[24px]'>
          <Input
            type='text'
            name='title'
            variant='dark'
            width='big'
            label='Название раздела'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                }
              }
            }}
            loading={updateSectionLoading}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            onClick={onClose}
            isDisabled={updateSectionLoading}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={updateSectionLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default EditSectionForm;
