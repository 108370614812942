/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';
import { UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { StepFormat } from 'models';
import styles from './FormatImage.module.css';

import { IconClose, IconUpload } from 'components/atoms/icons';

interface FormatImageProps {
  savedFormat?: StepFormat;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  imageError: boolean;
}

const FormatImage: FC<FormatImageProps> = ({
  savedFormat,
  register,
  watch,
  getValues,
  setValue,
  imageError
}) => {
  const [currentAudioUrl, setCurrentImageUrl] = useState<string | null>(null);
  const picture = watch('picture');

  // check if the step already has a url.
  useEffect(() => {
    const url = getValues('url');

    if (url && savedFormat === 'image') {
      setCurrentImageUrl(url);
    }
  }, []);

  useEffect(() => {
    if (!picture || !picture.length) {
      return;
    }

    const image = picture[0];
    const objectUrl = URL.createObjectURL(image);
    setCurrentImageUrl(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [picture]);

  const removeImage = () => {
    setCurrentImageUrl(null);
    setValue('picture', undefined);
  };

  return (
    <div className={styles.container}>
      {currentAudioUrl ? (
        <div className={styles.image}>
          <img src={currentAudioUrl} alt={currentAudioUrl} />

          <button className={styles.buttonRemove} onClick={removeImage}>
            <IconClose color='#71798F' />
          </button>
        </div>
      ) : (
        <div className={styles.upload}>
          <label className={styles.label}>загрузите картинку</label>

          <label
            htmlFor='picture'
            className={`${styles.upload__label} ${imageError ? styles.upload__label_error : ''}`}
          >
            <div className='flex h-[68px] items-center justify-center gap-[10px]'>
              <IconUpload color='#5770F3' />
              <span>Выбрать изображение в формате .JPG или .PNG</span>
            </div>
          </label>

          <input
            type='file'
            accept='.png, .jpg, .jpeg'
            id='picture'
            className={styles.input}
            {...register('picture', {
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              validate: {
                hasFiles: (value: any) => {
                  return !!value.length ? true : FORM_ERROR_MESSAGES.REQUIRED_FIELD;
                }
              }
            })}
          />

          {imageError && (
            <span className={styles.upload__errorMessage}>
              {FORM_ERROR_MESSAGES.REQUIRED_FIELD}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default FormatImage;
