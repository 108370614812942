import React from 'react';

const IconNext: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.3828 19.5977L17.8164 12.8829C17.9922 12.6719 18.0625 12.461 18.0625 12.2501C18.0625 12.0743 17.9922 11.8633 17.8516 11.6876L11.418 4.97271C11.1016 4.62115 10.5391 4.62115 10.2227 4.93755C9.87109 5.25396 9.87109 5.7813 10.1875 6.13286L16.0586 12.2501L10.1523 18.4376C9.83594 18.754 9.83594 19.3165 10.1875 19.6329C10.5039 19.9493 11.0664 19.9493 11.3828 19.5977Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconNext;
