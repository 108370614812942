import { FC } from 'react';

import { DEFAULT_USERS_PER_PAGE } from 'consts';
import styles from '../../UsersPage.module.css';

import { Skeleton } from 'components/atoms';

const SkeletonRows: FC = () => {
  return (
    <>
      {Array.from({ length: DEFAULT_USERS_PER_PAGE }).map((_, i) => (
        <div
          className={`${styles.table__row} ${i % 2 === 1 ? styles.table__row_even : ''}`}
          key={i}
        >
          <button type='button' className={`${styles.table__cell} ${styles.table__cell_name}`}>
            <Skeleton height={32} />
          </button>

          <div className={`${styles.table__cell} ${styles.table__cell_email}`}>
            <Skeleton height={32} />
          </div>

          <div className={`${styles.table__cell} ${styles.table__cell_role}`}>
            <Skeleton height={32} />
          </div>

          <div className={`${styles.table__cell} ${styles.table__cell_status}`}>
            <Skeleton height={32} />
          </div>

          <div className={`${styles.table__cell} ${styles.table__cell_actions}`}>
            <Skeleton height={32} />
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonRows;
