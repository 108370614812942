import { authActionConstTypes, setLoginActionType } from '../types/authTypes';
import { Dispatch } from 'react';
import { rootActions } from '../types/rootTypes';

// ACTION
export const setLogin = (isLoggedIn: boolean): setLoginActionType => {
  return {
    type: authActionConstTypes.SET_LOGGED_IN,
    payload: isLoggedIn
  };
};

// THUNK
export const Login = (accessToken: string, refreshToken: string) => {
  return async (dispatch: Dispatch<rootActions>) => {
    localStorage.setItem('token', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    dispatch(setLogin(true));
  };
};
