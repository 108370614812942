import { FC, useState } from 'react';
import { useMutation } from 'react-query';

import { apiWords, Example as IExample } from 'api/api-words';
import { handleError } from 'helpers/handleError';
import styles from './Example.module.css';

import ConfirmationModal from 'components/atoms/ConfirmationModal';
import { IconPen, IconTrash } from 'components/atoms/icons';
import Modal from 'components/atoms/Modal';
import { notifySuc } from 'helpers/notification';
import UpdateExampleForm from '../UpdateExampleForm';

interface ExampleProps {
  wordId: string;
  example: IExample;
  refetchWordData: () => void;
}

const Example: FC<ExampleProps> = ({ wordId, example, refetchWordData }) => {
  const [updateExampleModalActive, setUpdateExampleModalActive] = useState<boolean>(false);
  const [deleteExampleModalActive, setDeleteExampleModalActive] = useState<boolean>(false);

  const { mutate: deleteExampleMutate, isLoading: deleteExampleLoading } = useMutation(
    () => {
      return apiWords.deleteExample(wordId, example.id);
    },
    {
      onSuccess() {
        notifySuc('Пример удален');

        setDeleteExampleModalActive(false);
        refetchWordData();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  return (
    <li className={styles.container}>
      <div className={styles.content}>
        <h4 className={styles.original}>{example.original}</h4>
        <h5 className={styles.translation}>{example.translation}</h5>
      </div>

      <div className={styles.controls}>
        <button
          type='button'
          className={styles.buttonEdit}
          onClick={() => setUpdateExampleModalActive(true)}
        >
          <IconPen color='#71798F' />
        </button>

        <button
          type='button'
          className={styles.buttonDelete}
          onClick={() => setDeleteExampleModalActive(true)}
        >
          <IconTrash color='#A1AABC' />
        </button>
      </div>

      {updateExampleModalActive && (
        <Modal onClose={() => setUpdateExampleModalActive(false)}>
          <UpdateExampleForm
            example={example}
            onUpdate={() => refetchWordData()}
            onCancel={() => setUpdateExampleModalActive(false)}
          />
        </Modal>
      )}

      {deleteExampleModalActive && (
        <ConfirmationModal
          title={<>Удалить пример</>}
          text={
            <div className='flex flex-col'>
              <span className='mb-[24px]'>
                Пример будет удален <b>навсегда</b>.
              </span>

              <div className='flex flex-col gap-[8px]'>
                <span
                  title={example.original}
                  className={
                    'px-[16px] py-[20px] text-[16px] font-[500] leading-[22px] text-[#20233A] ' +
                    'rounded-[16px] bg-[#EFF1F4] overflow-hidden text-ellipsis whitespace-nowrap'
                  }
                >
                  {example.original}
                </span>

                <span
                  title={example.translation}
                  className={
                    'px-[16px] py-[20px] text-[16px] font-[500] leading-[22px] text-[#20233A] ' +
                    'rounded-[16px] bg-[#EFF1F4] overflow-hidden text-ellipsis whitespace-nowrap'
                  }
                >
                  {example.translation}
                </span>
              </div>
            </div>
          }
          isDelete
          confirmButtonText='Удалить пример'
          onConfirm={() => deleteExampleMutate()}
          onClose={() => setDeleteExampleModalActive(false)}
          isLoading={deleteExampleLoading}
        />
      )}
    </li>
  );
};

export default Example;
