import { User } from 'models';
import { instance } from './api';

export type GetCoursesInformationResponse = {
  coursesCount: number;
  usersCount: number;
  modulesCount: number;
  lessonsCount: number;
  sectionsCount: number;
  stepsCount: number;
};

export type GetAllStudentsDto = {
  startDate: string;
  endDate: string;
};

export type GetAllStudentsResponse = AllStudentsDateData[];

export type AllStudentsDateData = {
  date: number;
  cumulativeCount: number;
};

export type GetAllStudentsByPeriodResponse = {
  totalUsers: number;
  usersWithSubscriptions: number;
};

export type GetNewStudentsDto = {
  startDate: string;
  endDate: string;
};

export type GetNewStudentsResponse = NewStudentsDateData[];

export type NewStudentsDateData = {
  date: number;
  count: number;
};

export type GetNewStudentsByPeriodResponse = {
  lastYear: number;
  lastMonth: number;
  lastWeek: number;
  yesterday: number;
};

export type GetNewStudentsDayDto = {
  date: string;
};

export type GetNewStudentsDayResponse = User[];

export type GetNotStartedStudentsDto = {
  startDate: string;
  endDate: string;
};

export type GetNotStartedStudentsResponse = NotStartedStudentsDateData[];

export type NotStartedStudentsDateData = {
  date: number;
  studentsCount: number;
};

export type GetNotStartedStudentsByPeriodResponse = {
  lastYear: number;
  lastMonth: number;
  lastWeek: number;
  yesterday: number;
  allTime: number;
};

export type GetDropoutStudentsDto = {
  startDate: string;
  endDate: string;
};

export type GetDropoutStudentsResponse = DropoutStudentsDateData[];

export type DropoutStudentsDateData = {
  date: number;
  studentsCount: number;
};

export type GetDropoutStudentsByPeriodResponse = {
  thirtyDaysCount: number;
  fourteenDaysCount: number;
  sevenDaysCount: number;
};

export type GetCompletedStudentsDto = {
  startDate: string;
  endDate: string;
};

export type GetCompletedStudentsResponse = CompletersDateData[];

export type CompletersDateData = {
  date: number;
  completed: number;
  students: string[];
};

export type GetCompletedStudentsByPeriodResponse = {
  totalStudentsCount: number;
  allTime: CompletedStudentsByPeriod;
  lastYear: CompletedStudentsByPeriod;
  lastMonth: CompletedStudentsByPeriod;
  lastWeek: CompletedStudentsByPeriod;
};

export type CompletedStudentsByPeriod = {
  totalCompleted: number;
  uniqueStudents: number;
};

export const apiGeneralAnalytics = {
  getCoursesInformation: async () => {
    const response = await instance.get<GetCoursesInformationResponse>(
      '/general-analytics/courses-information'
    );
    return response.data;
  },
  getAllStudents: async ({ startDate, endDate }: GetAllStudentsDto) => {
    const response = await instance.get<GetAllStudentsResponse>(
      `/general-analytics/cumulative-student-count?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  },
  getAllStudentsByPeriod: async () => {
    const response = await instance.get<GetAllStudentsByPeriodResponse>(
      '/general-analytics/student-count'
    );
    return response.data;
  },
  getNewStudents: async ({ startDate, endDate }: GetNewStudentsDto) => {
    const response = await instance.get<GetNewStudentsResponse>(
      `/general-analytics/students-by-date-range?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  },
  getNewStudentsByPeriod: async () => {
    const response = await instance.get<GetNewStudentsByPeriodResponse>(
      '/general-analytics/students-count-by-periods'
    );
    return response.data;
  },
  getNewStudentsDay: async ({ date }: GetNewStudentsDayDto) => {
    const response = await instance.get<GetNewStudentsDayResponse>(
      `/general-analytics/students-by-date?date=${date}`
    );
    return response.data;
  },
  getNotStartedStudents: async ({ startDate, endDate }: GetNotStartedStudentsDto) => {
    const response = await instance.get<GetNotStartedStudentsResponse>(
      `/general-analytics/not-started-studying?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  },
  getNotStartedStudentsByPeriod: async () => {
    const response = await instance.get<GetNotStartedStudentsByPeriodResponse>(
      '/general-analytics/not-started-by-periods'
    );
    return response.data;
  },
  getDropoutStudents: async ({ startDate, endDate }: GetDropoutStudentsDto) => {
    const response = await instance.get<GetDropoutStudentsResponse>(
      `/general-analytics/inactive-students-by-period?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  },
  getDropoutStudentsByPeriod: async () => {
    const response = await instance.get<GetDropoutStudentsByPeriodResponse>(
      '/general-analytics/inactive-students-by-periods'
    );
    return response.data;
  },
  getCompletedStudents: async ({ startDate, endDate }: GetCompletedStudentsDto) => {
    const response = await instance.get<GetCompletedStudentsResponse>(
      `/general-analytics/completed-course-by-period?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  },
  getCompletedStudentsByPeriod: async () => {
    const response = await instance.get<GetCompletedStudentsByPeriodResponse>(
      '/general-analytics/completed-course-by-periods'
    );
    return response.data;
  }
};
