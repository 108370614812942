import { CSSProperties, FC } from 'react';

import cls from './Skeleton.module.css';

type SkeletonProps = {
  height?: string | number;
  width?: string | number;
  border?: string | number;
  className?: string;
};

const Skeleton: FC<SkeletonProps> = ({ height, width, border = '8px', className }) => {
  const style: CSSProperties = {
    height,
    maxWidth: width,
    borderRadius: border
  };

  return <div style={style} className={`${cls.skeleton} ${className}`} />;
};

export default Skeleton;
