import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { IconSearch } from '../icons';

interface SearchFieldProps {
  type: string;
  id: string;
  value: string;
  placeholder: string;
  required?: boolean;
  setState: Dispatch<SetStateAction<string>>;
  onFocus?: () => void;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
}

const SearchField: FC<SearchFieldProps> = ({
  type,
  id,
  value,
  placeholder,
  required,
  setState,
  onFocus,
  disabled,
  className,
  inputClassName
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState(e.target.value);
  };

  return (
    <div
      className={
        'flex items-center justify-between gap-[4px] w-full pl-[16px] pr-[12px] h-[54px] rounded-xl border-solid border-2 border-[#E9ECF0] ' +
        className
      }
    >
      <input
        autoComplete={'off'}
        type={type}
        className={
          'rounded-[12px] w-full text-[18px] text-[#20233A] leading-[21px] h-full focus:outline-none ' +
          (inputClassName ? inputClassName : '')
        }
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={onFocus}
        required={required}
        disabled={disabled}
      />

      <div className='flex h-[24px] w-[24px] items-center justify-center'>
        <IconSearch className='text-[#5770F3]' />
      </div>
    </div>
  );
};

export default SearchField;
