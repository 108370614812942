import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AllUsers, Completers, LessonStatistics, NewUsers, NotStartedUsers } from './components';
import { DropoutUsers } from './components/DropoutUsers';
import { options } from './const';
import { Tabs } from './enums';

type StatisticsTableProps = {
  courseId: string;
};

const StatisticsTable: FC<StatisticsTableProps> = ({ courseId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = useMemo<string>(() => searchParams.get('tab') || Tabs.ALL, [searchParams]);

  return (
    <div
      className={
        'flex flex-col items-start rounded-[12px] overflow-hidden ' +
        'border-[1px] border-solid border-[#E9ECF0] shadow-[0_6px_12px_0_rgba(0,0,0,0.06)]'
      }
    >
      <header className='flex grow w-full px-[15px] border-b-[1px] border-solid border-[#E9ECF0]'>
        <ul className='flex justify-between'>
          {options.map(({ tab, title, Icon }, index) => {
            const isActive = tab === selectedTab;

            return (
              <li key={index} className='flex-1'>
                <button
                  className={
                    'flex flex-col items-start gap-[16px] p-[16px_16px_20px_16px] ' +
                    'border-b-[4px] border-[transparent] border-solid ' +
                    'select-none transition-all duration-300 ' +
                    `${isActive ? '!border-[#5770F3]' : ''}`
                  }
                  onClick={() => {
                    searchParams.set('tab', tab);
                    setSearchParams(searchParams);
                  }}
                >
                  <Icon color={isActive ? '#5770F3' : '#71798F'} className='h-[24px] w-[24px]' />
                  <h4
                    className={
                      'text-[16px] font-[500] leading-[20px] text-start ' +
                      `${isActive ? 'text-[#20233A]' : 'text-[#71798F]'}`
                    }
                  >
                    {title}
                  </h4>
                </button>
              </li>
            );
          })}
        </ul>
      </header>

      {selectedTab === Tabs.ALL && <AllUsers />}
      {selectedTab === Tabs.NEW && <NewUsers />}
      {selectedTab === Tabs.NOT_STARTED && <NotStartedUsers />}
      {selectedTab === Tabs.DROPOUT && <DropoutUsers />}
      {selectedTab === Tabs.COMPLETERS && <Completers courseId={courseId} />}
      {selectedTab === Tabs.LESSON_MISTAKES && <LessonStatistics courseId={courseId} />}
    </div>
  );
};

export default StatisticsTable;
