import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { MistakesLesson as IMistakesLesson, MistakesModule } from 'api/api-analytics';
import avatarPlaceholder from 'assets/images/svg/avatar.svg';
import { useGetLessonMistakeUsersQuery } from 'hooks/queries/useGetLessonMistakeUsersQuery';

import { IconClose, IconError } from 'components/atoms/icons';

interface MistakesLessonProps {
  courseId: string;
  currentModule: MistakesModule | undefined;
  currentLesson: IMistakesLesson | undefined;
  onCancel: () => void;
}

const MistakesLesson: FC<MistakesLessonProps> = ({
  courseId,
  currentModule,
  currentLesson,
  onCancel
}) => {
  const navigate = useNavigate();
  const { data: lessonMistakeUsers, isLoading: lessonMistakeUsersLoading } =
    useGetLessonMistakeUsersQuery({ courseId, lessonId: currentLesson?.lesson.id || '' });

  const goToUserPage = (userId: string): void => {
    navigate(`/users/${userId}`);
  };

  if (!currentModule || !currentLesson) return null;
  return (
    <div className='flex w-[584px] flex-col'>
      <header className='mb-[16px] flex h-[32px] items-center justify-between gap-[8px]'>
        <div className='flex gap-[8px] text-[22px] font-[700] leading-[26px]'>
          <span className=' text-[#20233a]'>Ошибки в уроках</span>
          <span className='text-[#71798F]'>({currentLesson.mistakesCount})</span>
        </div>
        <button className='h-[24px] w-[24px]' onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <div className='mb-[24px] flex h-[58px] gap-[16px]'>
        <div className='h-[54px] w-[54px]'>
          <img
            src={currentLesson.lesson.imageUrl}
            alt={currentLesson.lesson.title}
            className='h-auto w-full'
          />
        </div>
        <div className='flex max-w-[514px] flex-col gap-[4px]'>
          <p className='text-[14px] leading-[16px] text-[#71798F]'>
            Урок {currentLesson.lesson.order}.
          </p>
          <h5
            className={
              'text-[16px] font-[500] leading-[19px] text-[#20233A] ' +
              'overflow-hidden text-ellipsis whitespace-nowrap'
            }
          >
            {currentLesson.lesson.title}
          </h5>
          <h6
            className={
              'text-[13px] font-[500] uppercase leading-[15px] text-[#71798F] ' +
              'overflow-hidden text-ellipsis whitespace-nowrap'
            }
          >
            {/* Модуль №{currentModule.order}. {currentModule.title} */}
            {currentModule.title}
          </h6>
        </div>
      </div>

      {lessonMistakeUsersLoading ? (
        <h2>Список учеников, совершивших ошибки в уроке, загружается...</h2>
      ) : (
        <>
          {!!lessonMistakeUsers ? (
            <>
              {!!lessonMistakeUsers.length ? (
                <ul
                  className={
                    'flex max-h-[364px] flex-col gap-[8px] overflow-y-auto ' +
                    `${lessonMistakeUsers.length > 6 ? 'pr-[12px]' : ''}`
                  }
                >
                  {lessonMistakeUsers.map(user => (
                    <li key={user.id}>
                      <button
                        type='button'
                        className={
                          'flex h-[54px] w-full select-none items-center justify-between rounded-[27px] text-[#20233a] ' +
                          'hover:text-[#5770F3] hover:bg-[#EEF0FF] transition-all duration-300 pr-[8px]'
                        }
                        onClick={() => goToUserPage(user.id)}
                      >
                        <div className='flex items-center gap-[16px] overflow-hidden'>
                          <div className='h-[54px] w-[54px]'>
                            <img
                              src={user.avatarUrl || avatarPlaceholder}
                              alt={`${user.name}`}
                              className='h-full w-full object-cover rounded-full'
                            />
                          </div>
                          <p
                            title={user.name}
                            className={
                              'text-[16px] font-[500] leading-[19px] text-inherit ' +
                              'max-w-[235px] overflow-hidden whitespace-nowrap text-ellipsis'
                            }
                          >
                            {user.name}
                          </p>
                          <p
                            title={user.email}
                            className={
                              'text-[16px] leading-[19px] text-[#71798F] ' +
                              'max-w-[235px] overflow-hidden whitespace-nowrap text-ellipsis'
                            }
                          >
                            {user.email}
                          </p>
                        </div>

                        <div className='flex items-center gap-[12px]'>
                          <h3 className='text-[21px] font-[700] leading-[24px] text-[#20233A]'>
                            {user.mistakesCount}
                          </h3>
                          <IconError color='#FF4444' />
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <h2>Список учеников, совершивших ошибки в уроке, пуст.</h2>
              )}
            </>
          ) : (
            <h2>Не удалось загрузить список учеников, совершивших ошибки в уроке.</h2>
          )}
        </>
      )}
    </div>
  );
};

export default MistakesLesson;
