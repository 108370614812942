import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { ICourse } from 'api';
import { COURSE_STATUS } from 'consts';
import { useDebounce } from 'hooks';
import { useClickOutside } from 'hooks/common';
import { useGetCoursesQuery } from 'hooks/course';

import SearchField from 'components/atoms/SearchField/SearchField';
import { IconStroke } from 'components/atoms/icons';

type SelectCourseProps = {
  selectedCourse?: ICourse;
  selectCourse: (course: ICourse) => void;
  className?: string;
};

const SelectCourse: FC<SelectCourseProps> = props => {
  const { selectedCourse, selectCourse, className } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setMenuOpen(false));
  const { data: courses, isLoading: coursesLoading } = useGetCoursesQuery();
  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    setSearchValue(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const filteredCourses = useMemo(() => {
    if (!courses) return [];

    const availableCourses = courses.filter(
      ({ status }) => status === COURSE_STATUS.PUBLISHED_BY_SUBSCRIPTION
    );
    if (!searchValue) return availableCourses;

    const filteredCourses = availableCourses.filter(({ title }) =>
      title.toLowerCase().includes(searchValue.toLowerCase())
    );

    return filteredCourses;
  }, [searchValue, courses]);

  const handleSelectCourse = (course: ICourse) => {
    selectCourse(course);
    setMenuOpen(false);
  };

  return (
    <div ref={ref} className={`relative flex ${className}`}>
      <button
        type='button'
        tabIndex={1}
        onClick={() => setMenuOpen(prev => !prev)}
        className={
          'px-[16px] py-[15px] flex grow h-[56px] justify-between items-center ' +
          'rounded-[14px] border-[2px] border-solid border-[#E9ECF0] bg-[#FFFFFF]'
        }
      >
        {selectedCourse ? (
          <div className='flex items-center gap-[12px]'>
            <div
              className='h-[36px] w-[36px] rounded-[20px]'
              style={{ background: selectedCourse.color }}
            >
              <img
                src={selectedCourse.imageUrl}
                alt={selectedCourse.title}
                className='h-full w-full'
              />
            </div>

            <div className='flex grow flex-col items-start'>
              <span className='text-[13px] font-[500] leading-[15px] text-[#5A667B]'>
                {selectedCourse.level}
              </span>
              <h5 className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>
                {selectedCourse.title}
              </h5>
            </div>
          </div>
        ) : (
          <span className='text-[16px] font-[500] leading-[19px] text-[#A1AABC]'>Выбрать курс</span>
        )}

        <IconStroke
          className={
            '-rotate-90 h-[24px] w-[24px] duration-[150ms] ' +
            `${menuOpen ? 'text-[hsl(0,0%,40%)]' : 'text-[hsl(0,0%,80%)]'}`
          }
        />
      </button>

      {menuOpen && (
        <div
          className={
            'z-[1] absolute inset-x-0 top-[60px] flex flex-col gap-[24px] p-[16px] ' +
            'bg-[#FFFFFF] rounded-[12px] shadow-[0_10px_20px_0_rgba(0,0,0,0.11)]'
          }
        >
          <SearchField
            type='text'
            id='title'
            placeholder='Поиск курса'
            value={value}
            setState={setValue}
          />

          {coursesLoading ? (
            <span>Загрузка...</span>
          ) : (
            <>
              {filteredCourses.length ? (
                <ul
                  className={
                    'flex max-h-[280px] flex-col gap-[8px] overflow-y-auto ' +
                    `${filteredCourses.length > 4 ? 'pr-[12px]' : ''}`
                  }
                >
                  {filteredCourses.map(course => (
                    <button
                      key={course.id}
                      type='button'
                      onClick={() => handleSelectCourse(course)}
                      className={
                        'h-[64px] rounded-[20px] flex gap-[16px] pr-[8px] items-center ' +
                        `${selectedCourse?.id === course.id ? 'bg-[#EEF0FF]' : 'bg-[#F5F5F5]'}`
                      }
                    >
                      <div
                        className='h-[64px] w-[64px] rounded-[20px]'
                        style={{ background: course.color }}
                      >
                        <img src={course.imageUrl} alt={course.title} className='h-full w-full' />
                      </div>

                      <div className='flex grow flex-col items-start gap-[4px]'>
                        <span className='text-[13px] font-[500] leading-[15px] text-[#5A667B]'>
                          {course.level}
                        </span>
                        <h5 className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>
                          {course.title}
                        </h5>
                      </div>
                    </button>
                  ))}
                </ul>
              ) : (
                <span>Результатов не найдено</span>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectCourse;
