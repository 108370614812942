import React from 'react';

const UsersIcon: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.4648 14.5C14.5039 14.5 16.1211 12.8828 16.1211 10.8438C16.1211 8.83984 14.4688 7.1875 12.4648 7.1875C10.4609 7.1875 8.84375 8.83984 8.84375 10.8438C8.80859 12.8828 10.4609 14.5 12.4648 14.5ZM12.4648 8.875C13.5547 8.875 14.4336 9.78906 14.4336 10.8438C14.4336 11.9336 13.5547 12.8125 12.4648 12.8125C11.4102 12.8125 10.4961 11.9336 10.4961 10.8438C10.4961 9.78906 11.4102 8.875 12.4648 8.875ZM19.25 8.875C20.7969 8.875 22.0625 7.64453 22.0625 6.0625C22.0625 4.51562 20.7969 3.25 19.25 3.25C17.668 3.25 16.4375 4.51562 16.4375 6.0625C16.4375 7.64453 17.668 8.875 19.25 8.875ZM14.2227 15.625H10.7422C7.96484 15.625 5.75 17.7344 5.75 20.3359C5.75 20.8633 6.17188 21.25 6.73438 21.25H18.2305C18.793 21.25 19.25 20.8633 19.25 20.3359C19.25 17.7344 17 15.625 14.2227 15.625ZM7.50781 19.5625C7.85938 18.2969 9.16016 17.3125 10.707 17.3125H14.2227C15.7695 17.3125 17.0703 18.2969 17.457 19.5625H7.50781ZM20.6211 10H18.4766C18.0195 10 17.5977 10.1406 17.2109 10.3164C17.2109 10.4922 17.2461 10.668 17.2461 10.8438C17.2461 12.0391 16.8242 13.1289 16.0859 13.9375H23.1172C23.4688 13.9375 23.75 13.6562 23.75 13.3047C23.75 11.4766 22.3438 10 20.6211 10ZM7.68359 10.8438C7.68359 10.668 7.71875 10.4922 7.75391 10.3164C7.36719 10.1055 6.94531 10 6.48828 10H4.34375C2.62109 10 1.25 11.4766 1.25 13.3047C1.25 13.6562 1.49609 13.9375 1.84766 13.9375H8.87891C8.14062 13.1289 7.68359 12.0391 7.68359 10.8438ZM5.75 8.875C7.29688 8.875 8.5625 7.64453 8.5625 6.0625C8.5625 4.51562 7.29688 3.25 5.75 3.25C4.16797 3.25 2.9375 4.51562 2.9375 6.0625C2.9375 7.64453 4.16797 8.875 5.75 8.875Z'
        fill='currentColor'
      />
    </svg>
  );
};
export default UsersIcon;
