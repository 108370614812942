import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import RSelect, { SingleValue, StylesConfig, components } from 'react-select';

import { COURSE_STATUS } from 'consts';
import { notifyErr } from 'helpers/notification';
import { useUpdateCourseStatusMutation } from 'hooks/mutations';
import { useGetCourseQuery } from 'hooks/course';

import { IconEye, IconEyeBlocked, IconEyeCrossed } from 'components/atoms/icons';

interface IOption {
  value: COURSE_STATUS;
  label: COURSE_STATUS;
  color?: string;
  Icon?: JSX.Element;
}

const statusOptions: IOption[] = [
  {
    value: COURSE_STATUS.PUBLISHED_FOR_ALL,
    label: COURSE_STATUS.PUBLISHED_FOR_ALL,
    color: '#20233A',
    Icon: <IconEye className='text-[#66C84D]' />
  },
  {
    value: COURSE_STATUS.PUBLISHED_BY_SUBSCRIPTION,
    label: COURSE_STATUS.PUBLISHED_BY_SUBSCRIPTION,
    color: '#20233A',
    Icon: <IconEyeBlocked className='text-[#66C84D]' />
  },
  {
    value: COURSE_STATUS.UNPUBLISHED,
    label: COURSE_STATUS.UNPUBLISHED,
    color: '#20233A',
    Icon: <IconEyeCrossed className='text-[#8C9AAF]' />
  }
];

const { Option, SingleValue: Value } = components;

const IconOption = (props: any) => {
  const {
    data: { Icon, label }
  } = props;

  return (
    <Option {...props}>
      <div className='flex h-full items-center gap-[9px]'>
        {Icon && Icon}
        <span>{label}</span>
      </div>
    </Option>
  );
};

const IconValue = (props: any) => {
  const {
    data: { Icon, label }
  } = props;

  return (
    <Value {...props}>
      <div className='flex h-full items-center gap-[9px]'>
        {Icon && Icon}
        <span>{label}</span>
      </div>
    </Value>
  );
};

const singleSelectStyles: StylesConfig = {
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    height: '48px',
    backgroundColor: '#EFF1F4',
    border: 'none',
    boxShadow: isFocused ? 'none' : 'inherit',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  menu: styles => ({
    ...styles,
    zIndex: '3',
    width: '350px',
    right: '0'
  }),
  option: (styles, { data, isFocused, isDisabled }) => ({
    ...styles,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    color: (data as IOption).color,
    backgroundColor: isFocused ? '#EFF1F4' : 'none',
    '&:active': {
      backgroundColor: '#EFF1F4'
    },
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  singleValue: (styles, { data, isDisabled }) => ({
    ...styles,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    color: (data as IOption).color,
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  })
};

interface Params {
  courseId: string;
}

const SelectCourseStatus: React.FC = () => {
  const { courseId } = useParams<keyof Params>() as Params;
  const { data: course } = useGetCourseQuery({ courseId });
  const { mutate: updateCourseStatusMutate } = useUpdateCourseStatusMutation();
  const isReady = useMemo(() => {
    const steps = course?.checklist;
    if (!steps) return;
    const stepsDone = steps.reduce((acc, step) => (step.condition ? ++acc : acc), 0);
    return stepsDone === steps.length;
  }, [course?.checklist]);

  const handleChange = (newValue: SingleValue<unknown>) => {
    const newStatus = (newValue as IOption).value;

    if (!course || newStatus === course.status) {
      return;
    }

    if (!isReady && newStatus !== COURSE_STATUS.UNPUBLISHED) {
      notifyErr('Курс не готов к публикации, сначала заполните чек-лист');
      return;
    }

    updateCourseStatusMutate({ courseId: course.id, body: { status: newStatus } });
  };

  if (!course) return null;

  return (
    <RSelect
      value={statusOptions.find(({ value }) => value === course.status)}
      classNamePrefix='custom-select'
      styles={singleSelectStyles}
      placeholder={'Выберите статус'}
      options={statusOptions}
      components={{ Option: IconOption, SingleValue: IconValue }}
      onChange={handleChange}
      isSearchable={false}
    />
  );
};

export default SelectCourseStatus;
