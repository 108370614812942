import { useQuery } from 'react-query';

import { apiUsers } from 'api/api-users';
import { LONG_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetCurrentUserQuery = () => {
  return useQuery(['users/current'], async () => await apiUsers.getCurrentUser(), {
    staleTime: LONG_STALE_TIME,
    onError(error) {
      handleError(error);
    }
  });
};
