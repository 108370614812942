/* eslint-disable react-hooks/exhaustive-deps */

import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { apiCourses, ICourse, IModule } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import useDebounce from 'hooks/useDebounce';
import styles from './MoveModuleForm.module.css';

import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';
import SearchField from 'components/atoms/SearchField/SearchField';
import MoveCourseCard from 'components/molecules/MoveCourseCard';
import { notifySuc } from 'helpers/notification';
import { useGetCoursesQuery } from 'hooks/course';

interface UpdateModuleFormProps {
  module: IModule;
  refetchCourse: () => void;
  onCancel: () => void;
}

const MoveModuleForm: FC<UpdateModuleFormProps> = ({ module, refetchCourse, onCancel }) => {
  const { data: courses, isLoading: coursesLoading } = useGetCoursesQuery();
  const [selectedCourse, setSelectedCourse] = useState<ICourse | null>(null);

  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentCourses, setCurrentCourses] = useState<ICourse[]>([]);

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    setSearchValue(value);
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (!courses) return;

    const filteredCourses = courses.filter(({ title }) =>
      title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setCurrentCourses(filteredCourses);
  }, [courses, searchValue]);

  const { mutate: moveModuleMutate, isLoading: moveModuleLoading } = useMutation(
    ({ courseId, moduleId }: { courseId: string; moduleId: string }) => {
      return apiCourses.moveModule(courseId, moduleId);
    },
    {
      onSuccess() {
        notifySuc('Модуль перемещен');

        refetchCourse();
        onCancel();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedCourse) {
      moveModuleMutate({ courseId: selectedCourse.id, moduleId: module.id });
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.header__title}>Переместить модуль в другой курс</h2>
        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <div className={styles.preview}>
        <h3 className={styles.preview__title}>{module.title}</h3>
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <SearchField
          placeholder={'Поиск курса'}
          className='mb-[16px]'
          value={value}
          setState={setValue}
          type={'text'}
          id={'title'}
        />

        <span className='mb-[16px] text-[16px] font-[500] leading-[19px] text-[#71798F]'>
          Выбрать курс:
        </span>

        <div className={`${styles.courses} ${currentCourses.length > 3 ? 'pr-[20px]' : ''}`}>
          {coursesLoading ? (
            <span>Загрузка...</span>
          ) : (
            <>
              {currentCourses.length > 0 ? (
                currentCourses.map(course => (
                  <MoveCourseCard
                    key={course.id}
                    course={course}
                    isSelected={!!selectedCourse && selectedCourse.id === course.id}
                    select={() => {
                      if (!!selectedCourse && selectedCourse.id === course.id) {
                        setSelectedCourse(null);
                      } else {
                        setSelectedCourse(course);
                      }
                    }}
                  />
                ))
              ) : (
                <span>Результатов не найдено</span>
              )}
            </>
          )}
        </div>

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            size='big'
            title='Отменить'
            onClick={onCancel}
            isDisabled={moveModuleLoading}
          />

          <Button
            type='submit'
            variant='primary'
            size='big'
            title='Переместить'
            isDisabled={!selectedCourse}
            isLoading={moveModuleLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default MoveModuleForm;
