import EditorJS, { OutputData } from '@editorjs/editorjs';
import { FC, memo, useEffect, useState } from 'react';
import { EDITOR_JS_TOOLS } from './tools';

type EditorProps = {
  data?: OutputData;
  onChange?(value: OutputData): void;
  holder: string;
  defaultBlock?: string;
  readOnly?: boolean;
};

const Editor: FC<EditorProps> = ({ data, onChange, holder, defaultBlock, readOnly = false }) => {
  const [editor, setEditor] = useState<EditorJS | null>(null);

  useEffect(() => {
    setEditor(prevEditor => {
      if (!prevEditor) {
        return new EditorJS({
          holder,
          tools: EDITOR_JS_TOOLS,
          data,
          defaultBlock,
          sanitizer: {
            b: {},
            i: {},
            span: {},
            p: {},
            h1: {},
            h2: {},
            h3: {},
            h4: {},
            h5: {},
            h6: {},
            h7: {},
            h8: {},
            color: {},
            font: {},
            mark: {}
          },
          async onChange(api, event) {
            if (!onChange) return;

            const data = await api.saver.save();
            onChange(data);
          },
          readOnly,
          i18n: {
            /**
             * @type {I18nDictionary}
             */
            messages: {
              ui: {
                blockTunes: {
                  toggler: {
                    'Click to tune': 'Нажмите, чтобы настроить',
                    'or drag to move': 'или перетащите'
                  }
                },
                inlineToolbar: {
                  converter: {
                    'Convert to': 'Конвертировать в'
                  }
                },
                toolbar: {
                  toolbox: {
                    Add: 'Добавить'
                  }
                }
              },

              toolNames: {
                Text: 'Параграф',
                Heading: 'Заголовок',
                List: 'Список',
                Image: 'Изображение',
                Audio: 'Аудио',
                Warning: 'Примечание',
                Checklist: 'Чеклист',
                Quote: 'Цитата',
                Code: 'Код',
                Delimiter: 'Разделитель',
                'Raw HTML': 'HTML-фрагмент',
                Table: 'Таблица',
                Link: 'Ссылка',
                Color: 'Цвет',
                Marker: 'Маркер',
                Bold: 'Жирный',
                Italic: 'Курсив',
                InlineCode: 'Моноширинный'
              },

              tools: {
                header: {
                  'Heading 1': 'Заголовок 1',
                  'Heading 2': 'Заголовок 2',
                  'Heading 3': 'Заголовок 3',
                  'Heading 4': 'Заголовок 4'
                },
                image: {
                  'Upload an Image': 'Загрузить изображение',
                  'Select an Image': 'Выберите изображение',
                  Caption: 'Описание',
                  'With border': 'С рамкой',
                  'Stretch image': 'Растянуть изображение',
                  'With background': 'С задним фоном'
                },
                list: {
                  Ordered: 'Упорядоченный',
                  Unordered: 'Неупорядоченный'
                },
                audio: {
                  'Upload an Audio': 'Загрузить аудио',
                  'Select an Audio': 'Выберите аудио'
                }
              },

              blockTunes: {
                delete: {
                  Delete: 'Удалить'
                },
                moveUp: {
                  'Move up': 'Переместить вверх'
                },
                moveDown: {
                  'Move down': 'Переместить вниз'
                }
              }
            }
          }
        });
      }
      return null;
    });

    return () => {
      if (editor) {
        editor.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id={holder} />;
};

export default memo(Editor);
