import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { CourseNumbers, StatisticsTable } from './components';

type Params = {
  courseId: string;
};

const CourseAnalytics: FC = () => {
  const { courseId } = useParams<keyof Params>() as Params;

  return (
    <div className='flex w-[calc(100%-264px)] flex-col'>
      <header className='mb-[16px] flex h-[49px] w-full items-center justify-between'>
        <h2 className='text-[30px] font-[700] leading-[36px] text-[#20233A]'>Аналитика</h2>
      </header>
      <CourseNumbers courseId={courseId} className='mb-[40px]' />
      <StatisticsTable courseId={courseId} />
    </div>
  );
};

export default CourseAnalytics;
