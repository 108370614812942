export function formatDate(dateString: string) {
  const date = new Date(dateString);

  const day: string = (date.getDate() < 10 ? '0' : '') + date.getDate();
  const month: string = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1);
  const year: number = date.getFullYear();
  const hours: string = (date.getHours() < 10 ? '0' : '') + date.getHours();
  const minutes: string = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  const seconds: string = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}
