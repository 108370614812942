import { FC } from 'react';
import { Control, FieldValues, UseFieldArrayRemove } from 'react-hook-form';

import { FORM_ERROR_MESSAGES, TIMECODE_REGEXP } from 'consts';

import Input from 'components/atoms/Input';
import { IconClose } from 'components/atoms/icons';

interface TimecodeProps {
  control: Control<FieldValues>;
  index: number;
  remove: UseFieldArrayRemove;
}

const Timecode: FC<TimecodeProps> = ({ control, index, remove }) => {
  return (
    <li className='flex items-center gap-[8px]'>
      <Input
        type='text'
        variant='bordered'
        width='big'
        placeholder='hh:mm:ss'
        name={`timecodes.${index}.time` as const}
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 8, message: 'Максимальная длина 8 символов' },
          validate: {
            doesntContainSpaces: (value: string) => {
              return !value.includes(' ') ? true : FORM_ERROR_MESSAGES.DOESNT_CONTAIN_SPACES;
            },
            validTimecode: (value: string) => {
              return TIMECODE_REGEXP.test(value) ? true : FORM_ERROR_MESSAGES.INCORRECT_TIMECODE;
            }
          }
        }}
        containerClassName='max-w-[100px]'
      />

      <span className='text-[16px] font-[500] leading-[22px] text-[#20233A]'>—</span>

      <Input
        type='text'
        variant='bordered'
        width='big'
        placeholder='Описание блока'
        name={`timecodes.${index}.description` as const}
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
          validate: {
            doesntConsistOfSpaces: (value: any) => {
              return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
            }
          }
        }}
      />

      <button
        type='button'
        className='flex h-[48px] w-[56px] items-center justify-center rounded-[12px] bg-[#EEF0FF]'
        onClick={() => remove(index)}
      >
        <IconClose color='#71798F' />
      </button>
    </li>
  );
};

export default Timecode;
