export enum ValidatedWordStatus {
  NEW = 'new',
  IN_VOCABULARY = 'inVocabulary',
  EXISTS = 'exists',
  NEW_EXAMPLES = 'newExamples',
  ERROR = 'error'
}

export interface ValidatedWordData {
  original: string | null;
  translation: string | null;
  transliteration: string | null;
  imageUrl: string | null;
  audioUrl: string | null;
  examples: {
    original: string;
    translation: string;
  }[];
}

export interface ValidatedWord {
  status: ValidatedWordStatus;
  error: string | null;
  data: ValidatedWordData;
}

export interface StatusStatistics {
  new: number;
  exists: number;
  inVocabulary: number;
  newExamples: number;
  error: number;
}

export interface ValidatedWordsData {
  statusStatistics: StatusStatistics;
  words: ValidatedWord[];
}
