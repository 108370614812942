import React from 'react';

const IconPlayFilled: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.1875 20.125C7.20312 20.125 6.5 19.3516 6.5 18.4375V6.0625C6.5 5.18359 7.20312 4.41016 8.1875 4.41016C8.46875 4.41016 8.78516 4.48047 9.06641 4.65625L19.1914 10.8438C19.6836 11.125 20 11.6875 20 12.25C20 12.8477 19.6836 13.4102 19.1914 13.6914L9.06641 19.8789C8.78516 20.0547 8.46875 20.125 8.1875 20.125Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconPlayFilled;
