import { apiSubscriptions } from 'api';
import SortBottomIcon from 'components/atoms/icons/SortBottomIcon';
import SortDisabledIcon from 'components/atoms/icons/SortDisabledIcon';
import SortTopIcon from 'components/atoms/icons/SortTopIcon';
import SearchField from 'components/atoms/SearchField/SearchField';
import BePagintaion from 'components/molecules/BePagination/BePagination';
import { IOption } from 'models';
import { PaidFrom } from 'models/subscription';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import RSelect, { SingleValue } from 'react-select';
import { handleError } from '../../helpers/handleError';
import { useDebounce } from '../../hooks';
import {
  DEFAULT_PAGE,
  filterPaidFromOptions,
  filterSelectStyles,
  PaidFromOptions,
  SEARCH_PARAMS
} from './const';
import styles from './PurchasesPage.module.css';

const PurchasesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const search = useMemo(() => {
    return searchParams.get(SEARCH_PARAMS.SEARCH) || '';
  }, [searchParams]);
  const paidFrom = useMemo(() => {
    return (searchParams.get(SEARCH_PARAMS.PAID_FROM) as PaidFrom) || undefined;
  }, [searchParams]);
  const sortOrder = useMemo(() => {
    return searchParams.get(SEARCH_PARAMS.SORT_ORDER) || '';
  }, [searchParams]);
  const currentPage = useMemo(() => {
    return Number(searchParams.get(SEARCH_PARAMS.PAGE)) || DEFAULT_PAGE;
  }, [searchParams]);

  const [value, setValue] = useState(search || '');
  const debouncedSearchValue = useDebounce(value, 500);

  useEffect(() => {
    if (debouncedSearchValue) {
      searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));
      searchParams.set(SEARCH_PARAMS.SEARCH, debouncedSearchValue);
      setSearchParams(searchParams);
    } else {
      if (searchParams.has(SEARCH_PARAMS.SEARCH)) {
        searchParams.delete(SEARCH_PARAMS.SEARCH);
        setSearchParams(searchParams);
      }
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    setValue(search);
  }, [search]);

  const { data: purchasesData, isLoading: isPurchasesLoading } = useQuery(
    ['purchases/all', currentPage, sortOrder, paidFrom, search],
    async () =>
      await apiSubscriptions.getPurchases({
        page: currentPage,
        itemsPerPage: 10,
        sortOrder,
        paidFrom,
        search
      }),
    {
      onError(error) {
        handleError(error);
      }
    }
  );

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <p className='text-[36px] font-[700] leading-[44px] text-[#2F324F]'>Покупки</p>
      </header>

      <div className={styles.table}>
        <div className={styles.table__filters}>
          <SearchField
            className='max-w-[360px]'
            placeholder={'Поиск по email пользователя'}
            value={value}
            setState={setValue}
            type={'text'}
            id={'title'}
          />

          <RSelect
            value={filterPaidFromOptions.find(({ value }) => value === paidFrom)}
            classNamePrefix='custom-select'
            styles={filterSelectStyles}
            placeholder={'Куплен с помощью'}
            options={filterPaidFromOptions}
            onChange={(newValue: SingleValue<unknown>) => {
              searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));
              const paidFrom = (newValue as IOption).value;
              searchParams.set(SEARCH_PARAMS.PAID_FROM, paidFrom);
              setSearchParams(searchParams);
            }}
            isSearchable={false}
          />
        </div>

        <div className={styles.table__headers}>
          <button
            className={`${styles.table__header} ${styles.table__header_createdAt}`}
            onClick={() => {
              searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));

              if (sortOrder === 'asc') {
                searchParams.set(SEARCH_PARAMS.SORT_ORDER, 'desc');
              } else if (sortOrder === 'desc') {
                searchParams.delete(SEARCH_PARAMS.SORT_ORDER);
              } else {
                searchParams.set(SEARCH_PARAMS.SORT_ORDER, 'asc');
              }
              setSearchParams(searchParams);
            }}
          >
            <span>Дата создания</span>

            {sortOrder === 'desc' ? (
              <SortBottomIcon />
            ) : sortOrder === 'asc' ? (
              <SortTopIcon />
            ) : (
              <SortDisabledIcon />
            )}
          </button>

          <span className={`${styles.table__header} ${styles.table__header_course}`}>Курс</span>

          <span className={`${styles.table__header} ${styles.table__header_userEmail}`}>
            Email пользователя
          </span>

          <span className={`${styles.table__header} ${styles.table__header_userName}`}>
            Имя пользователя
          </span>

          <span className={`${styles.table__header} ${styles.table__header_paidFrom}`}>
            Куплен через
          </span>

          <span className={`${styles.table__header} ${styles.table__header_isActive}`}>
            Подписка активна
          </span>
        </div>

        <div className={styles.table__rows}>
          {purchasesData && !isPurchasesLoading ? (
            <>
              {purchasesData.data.length > 0 ? (
                <>
                  {purchasesData.data.map((purchase, i) => {
                    return (
                      <div
                        className={`${styles.table__row} ${
                          i % 2 === 1 ? styles.table__row_even : ''
                        }`}
                        key={purchase.id}
                      >
                        <div className={`${styles.table__cell} ${styles.table__cell_createdAt}`}>
                          <span>{new Date(purchase.createdAt).toLocaleString()}</span>
                        </div>

                        <div
                          className={`${styles.table__cell} ${styles.table__cell_course}`}
                          title={purchase.course?.title}
                        >
                          <span>{purchase.course?.title}</span>
                        </div>

                        <div
                          className={`${styles.table__cell} ${styles.table__cell_userEmail}`}
                          title={purchase.user?.email}
                        >
                          <span>{purchase.user?.email}</span>
                        </div>

                        <div
                          className={`${styles.table__cell} ${styles.table__cell_userName}`}
                          title={purchase.user?.name}
                        >
                          <span>{purchase.user?.name}</span>
                        </div>

                        <div className={`${styles.table__cell} ${styles.table__cell_paidFrom}`}>
                          <span>
                            {
                              PaidFromOptions.find(paidFrom => paidFrom.value === purchase.paidFrom)
                                ?.label
                            }
                          </span>
                        </div>

                        <div className={`${styles.table__cell} ${styles.table__cell_isActive}`}>
                          <span>{purchase.isActive ? 'Да' : 'Нет'}</span>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <span>Результатов не найдено</span>
              )}
            </>
          ) : (
            <div>Загрузка...</div>
          )}
        </div>

        <div className={styles.table__pagination}>
          {purchasesData && purchasesData.pagination.totalItems > 10 && (
            <BePagintaion
              pagination={purchasesData.pagination}
              setCurrentPage={page => {
                searchParams.set(SEARCH_PARAMS.PAGE, String(page));
                setSearchParams(searchParams);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchasesPage;
