import { FC } from 'react';
import { Control } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { FormValues } from '../../../../model';

import Input from 'components/atoms/Input';

interface RepeatDayProps {
  control: Control<FormValues>;
}

const RepeatDay: FC<RepeatDayProps> = ({ control }) => {
  return (
    <Input
      type='text'
      variant='dark'
      placeholder='Время'
      name='repeatTime'
      control={control}
      rules={{
        required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
        validate: {
          validTime: (value: any) => {
            const errorMessage = 'Введите корректное время в формате hh:mm';
            const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
            if (!timeRegex.test(value)) {
              return errorMessage;
            }
            const [hours, minutes] = value.split(':').map(Number);
            if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
              return errorMessage;
            }
            return true;
          }
        }
      }}
      containerClassName=''
      className='h-[56px] w-[100px] text-[16px] font-[500] leading-[22px] text-[#71798F]'
    />
  );
};

export default RepeatDay;
