import { StatusStatistics, ValidatedWord, ValidatedWordStatus } from 'models';

export const getStatusStatisticsFromWords = (words: ValidatedWord[]): StatusStatistics => {
  const statusStatistics: StatusStatistics = {
    new: 0,
    inVocabulary: 0,
    exists: 0,
    newExamples: 0,
    error: 0
  };

  words.forEach(word => {
    switch (word.status) {
      case ValidatedWordStatus.NEW:
        statusStatistics.new++;
        break;
      case ValidatedWordStatus.IN_VOCABULARY:
        statusStatistics.inVocabulary++;
        break;
      case ValidatedWordStatus.EXISTS:
        statusStatistics.exists++;
        break;
      case ValidatedWordStatus.NEW_EXAMPLES:
        statusStatistics.newExamples++;
        break;
      case ValidatedWordStatus.ERROR:
        statusStatistics.error++;
        break;
    }
  });

  return statusStatistics;
};
