export const translateMonth = (englishMonth: string): string => {
  const englishToRussianMap: { [key: string]: string } = {
    January: 'Январь',
    February: 'Февраль',
    March: 'Март',
    April: 'Апрель',
    May: 'Май',
    June: 'Июнь',
    July: 'Июль',
    August: 'Август',
    September: 'Сентябрь',
    October: 'Октябрь',
    November: 'Ноябрь',
    December: 'Декабрь'
  };

  const russianMonth = englishToRussianMap[englishMonth];
  return russianMonth || '';
};
