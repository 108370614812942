import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import avatarPlaceholder from 'assets/images/svg/avatar.svg';
import { useGetCompletedStudentsDayQuery } from 'hooks/queries';
import { convertToLocalDate, timestampToISO } from 'utils';

import { IconClose } from 'components/atoms/icons';

interface CompletersDayProps {
  courseId: string;
  date: number;
  onCancel: () => void;
}

const CompletersDay: FC<CompletersDayProps> = ({ courseId, date, onCancel }) => {
  const navigate = useNavigate();
  const { data: completers, isLoading: completersLoading } = useGetCompletedStudentsDayQuery({
    courseId,
    date: timestampToISO(date)
  });

  const goToUserPage = (userId: string): void => {
    navigate(`/users/${userId}`);
  };

  return (
    <div className='flex w-[584px] flex-col'>
      <header className='mb-[16px] flex h-[32px] items-center justify-between gap-[8px]'>
        <h2 className='text-[22px] font-[700] leading-[26px] text-[#20233a]'>Завершившие курс</h2>
        <button className='h-[24px] w-[24px]' onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <p className='mb-[32px] text-[18px] leading-[21px] text-[#20233a]'>
        {convertToLocalDate(date)}
      </p>

      {completersLoading ? (
        <h2>Список учеников, завершивших курс, загружается...</h2>
      ) : (
        <>
          {!!completers ? (
            <>
              {!!completers.length ? (
                <ul
                  className={
                    'flex max-h-[364px] flex-col gap-[8px] overflow-y-auto ' +
                    `${completers.length > 6 ? 'pr-[12px]' : ''}`
                  }
                >
                  {completers.map(user => (
                    <li key={user.id}>
                      <button
                        type='button'
                        className={
                          'flex h-[54px] w-full select-none items-center gap-[16px] rounded-[27px] text-[#20233a] ' +
                          'hover:text-[#5770F3] hover:bg-[#EEF0FF] transition-all duration-300 pr-[8px] ' +
                          'overflow-hidden'
                        }
                        onClick={() => goToUserPage(user.id)}
                      >
                        <div className='h-[54px] w-[54px]'>
                          <img src={user.avatarUrl || avatarPlaceholder} alt={`${user.name}`} />
                        </div>
                        <p
                          title={user.name}
                          className={
                            'text-[16px] font-[500] leading-[19px] text-inherit ' +
                            'max-w-[235px] overflow-hidden whitespace-nowrap text-ellipsis'
                          }
                        >
                          {user.name}
                        </p>
                        <p
                          title={user.email}
                          className={
                            'text-[16px] leading-[19px] text-[#71798F] ' +
                            'max-w-[235px] overflow-hidden whitespace-nowrap text-ellipsis'
                          }
                        >
                          {user.email}
                        </p>
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <h2>Список учеников, завершивших курс, пуст.</h2>
              )}
            </>
          ) : (
            <h2>Не удалось загрузить список учеников, завершивших курс.</h2>
          )}
        </>
      )}
    </div>
  );
};

export default CompletersDay;
