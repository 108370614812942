/* eslint-disable react-hooks/exhaustive-deps */

import { ChangeEvent, FC, useEffect, useState } from 'react';

import { ICourse, ILesson, IModule, ISection } from 'api/api-courses';
import { useGetCourseQuery, useGetCoursesQuery } from 'hooks/course';
import { useMoveSectionMutation } from 'hooks/section/mutations';
import useDebounce from 'hooks/useDebounce';
import styles from './MoveSectionForm.module.css';

import Button from 'components/atoms/Button';
import { IconClose, IconPaste } from 'components/atoms/icons';
import SearchField from 'components/atoms/SearchField/SearchField';
import { MoveLessonCard } from 'components/molecules';
import MoveCourseCard from 'components/molecules/MoveCourseCard';
import MoveModuleCard from 'components/molecules/MoveModuleCard';

type MoveSectionFormProps = {
  courseId: string;
  currentLessonId: string;
  section: ISection;
  onCancel: () => void;
};

const MoveSectionForm: FC<MoveSectionFormProps> = ({
  courseId,
  currentLessonId,
  section,
  onCancel
}) => {
  const [step, setStep] = useState<'chooseCourse' | 'chooseModule' | 'chooseSection'>(
    'chooseCourse'
  );
  const [selectedCourse, setSelectedCourse] = useState<ICourse | null>(null);
  const [selectedModule, setSelectedModule] = useState<IModule | null>(null);
  const [selectedLesson, setSelectedLesson] = useState<ILesson | null>(null);

  const [currentCourses, setCurrentCourses] = useState<ICourse[]>([]);
  const [currentModules, setCurrentModules] = useState<IModule[]>([]);
  const [currentLessons, setCurrentLessons] = useState<ILesson[]>([]);

  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    setSearchValue(value);
  }, [debouncedSearchValue]);

  const { data: courses, isLoading: coursesLoading } = useGetCoursesQuery();
  const {
    data: course,
    isLoading: courseLoading,
    refetch: fetchCourse
  } = useGetCourseQuery({ courseId: selectedCourse?.id || courseId, enabled: false });
  const { mutate: moveSectionMutate, isLoading: moveSectionLoading } = useMoveSectionMutation();

  useEffect(() => {
    if (!courses) return;

    const filteredCourses = courses.filter(({ title }) =>
      title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setCurrentCourses(filteredCourses);

    const currentCourse = filteredCourses.find(({ id }) => id === courseId);
    if (currentCourse) {
      setSelectedCourse(currentCourse);
    }
  }, [courses, searchValue]);

  useEffect(() => {
    if (course) {
      setCurrentModules(course.modules);
      setCurrentLessons(course.lessons || []);
    }
  }, [course]);

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (step === 'chooseCourse' && selectedCourse && selectedCourse.withModules) {
      setStep('chooseModule');
      fetchCourse();
    }

    if (step === 'chooseCourse' && selectedCourse && !selectedCourse.withModules) {
      setStep('chooseSection');
      fetchCourse();
    }

    if (step === 'chooseModule' && selectedModule) {
      setStep('chooseSection');
    }

    if (step === 'chooseSection' && selectedLesson) {
      moveSectionMutate(
        { currentLessonId, lessonId: selectedLesson.id, sectionId: section.id },
        {
          onSuccess: onCancel
        }
      );
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <span className={styles.header__title}>Переместить раздел</span>
        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <div className={styles.preview}>
        <h5 className={styles.preview__text}>{section.title}</h5>
      </div>

      {step === 'chooseCourse' && (
        <form className={styles.form} onSubmit={handleSubmit}>
          <SearchField
            placeholder={'Поиск курса'}
            className='mb-[16px]'
            value={value}
            setState={setValue}
            type={'text'}
            id={'title'}
          />

          <h5 className='mb-[16px] text-[16px] font-[500] leading-[19px] text-[#71798F]'>
            Выбрать курс:
          </h5>

          <div className={`${styles.courses} ${currentCourses.length > 3 ? 'pr-[20px]' : ''}`}>
            {coursesLoading ? (
              <span>Загрузка...</span>
            ) : (
              <>
                {currentCourses.length > 0 ? (
                  currentCourses.map(course => (
                    <MoveCourseCard
                      key={course.id}
                      course={course}
                      isSelected={!!selectedCourse && selectedCourse.id === course.id}
                      select={() => {
                        if (!!selectedCourse && selectedCourse.id === course.id) {
                          setSelectedCourse(null);
                        } else {
                          setSelectedCourse(course);
                        }
                      }}
                    />
                  ))
                ) : (
                  <span>Список курсов пуст</span>
                )}
              </>
            )}
          </div>

          <div className={styles.buttons}>
            <Button
              type='reset'
              variant='secondary'
              size='big'
              title='Отменить'
              onClick={onCancel}
            />

            <Button
              type='submit'
              variant='primary'
              size='big'
              title='Далее'
              isDisabled={!selectedCourse}
            />
          </div>
        </form>
      )}

      {step === 'chooseModule' && selectedCourse && (
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className='mb-[16px] flex items-center gap-[8px]'>
            <IconPaste color='#71798F' className='h-[24px] w-[24px]' />
            <h5 className='text-[16px] font-[500] leading-[19px] text-[#71798F]'>В курс:</h5>
          </div>

          <div className='mb-[16px]'>
            <MoveCourseCard course={selectedCourse} isSelected={true} />
          </div>

          <h5 className='mb-[16px] text-[16px] font-[500] leading-[19px] text-[#71798F]'>
            Выбрать модуль:
          </h5>

          <div className={`${styles.modules} ${currentModules.length > 4 ? 'pr-[20px]' : ''}`}>
            {courseLoading ? (
              <span>Загрузка...</span>
            ) : (
              <>
                {currentModules.length > 0 ? (
                  currentModules.map((module, index) => (
                    <MoveModuleCard
                      key={module.id}
                      module={module}
                      isSelected={!!selectedModule && selectedModule.id === module.id}
                      select={() => {
                        if (!!selectedModule && selectedModule.id === module.id) {
                          setSelectedModule(null);
                        } else {
                          setSelectedModule(module);
                        }
                      }}
                    />
                  ))
                ) : (
                  <span>Список модулей пуст</span>
                )}
              </>
            )}
          </div>

          <div className={styles.buttons}>
            <Button
              type='reset'
              variant='secondary'
              size='big'
              title='Назад'
              onClick={() => setStep('chooseCourse')}
            />

            <Button
              type='submit'
              variant='primary'
              size='big'
              title='Далее'
              isDisabled={!selectedModule}
            />
          </div>
        </form>
      )}

      {step === 'chooseSection' && selectedCourse && (
        <>
          {selectedModule ? (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className='mb-[16px] flex items-center gap-[8px]'>
                <IconPaste color='#71798F' className='h-[24px] w-[24px]' />
                <h5 className='text-[16px] font-[500] leading-[19px] text-[#71798F]'>В курс:</h5>
              </div>

              <div className='mb-[16px]'>
                <MoveCourseCard course={selectedCourse} isSelected />
              </div>

              <div className='mb-[16px] flex items-center gap-[16px]'>
                <h4 className='text-[18px] font-[500] leading-[21px] text-[#71798F]'>Модуль</h4>
                <MoveModuleCard module={selectedModule} isSelected />
              </div>

              <h5 className='mb-[8px] text-[16px] font-[500] leading-[19px] text-[#71798F]'>
                Выбрать урок:
              </h5>

              <div
                className={`${styles.sections} ${
                  selectedModule.lessons.length > 4 ? 'pr-[20px]' : ''
                }`}
              >
                {selectedModule.lessons.length > 0 ? (
                  selectedModule.lessons.map(lesson => (
                    <MoveLessonCard
                      key={lesson.id}
                      lesson={lesson}
                      isSelected={!!selectedLesson && selectedLesson.id === lesson.id}
                      select={() => {
                        if (!!selectedLesson && selectedLesson.id === lesson.id) {
                          setSelectedLesson(null);
                        } else {
                          setSelectedLesson(lesson);
                        }
                      }}
                    />
                  ))
                ) : (
                  <span>Список уроков пуст</span>
                )}
              </div>

              <div className={styles.buttons}>
                <Button
                  type='reset'
                  variant='secondary'
                  size='big'
                  title='Назад'
                  onClick={() => setStep('chooseModule')}
                  isDisabled={moveSectionLoading}
                />

                <Button
                  type='submit'
                  variant='primary'
                  size='big'
                  title='Переместить'
                  isDisabled={!selectedLesson}
                  isLoading={moveSectionLoading}
                />
              </div>
            </form>
          ) : (
            <>
              <form className={styles.form} onSubmit={handleSubmit}>
                <div className='mb-[16px] flex items-center gap-[8px]'>
                  <IconPaste color='#71798F' className='h-[24px] w-[24px]' />
                  <h5 className='text-[16px] font-[500] leading-[19px] text-[#71798F]'>В курс:</h5>
                </div>

                <div className='mb-[16px]'>
                  <MoveCourseCard course={selectedCourse} isSelected />
                </div>

                <h5 className='mb-[8px] text-[16px] font-[500] leading-[19px] text-[#71798F]'>
                  Выбрать урок:
                </h5>

                <div
                  className={`${styles.sections} ${currentLessons.length > 4 ? 'pr-[20px]' : ''}`}
                >
                  {courseLoading ? (
                    <span>Загрузка...</span>
                  ) : (
                    <>
                      {currentLessons.length > 0 ? (
                        currentLessons.map(lesson => (
                          <MoveLessonCard
                            key={lesson.id}
                            lesson={lesson}
                            isSelected={!!selectedLesson && selectedLesson.id === lesson.id}
                            select={() => {
                              if (!!selectedLesson && selectedLesson.id === lesson.id) {
                                setSelectedLesson(null);
                              } else {
                                setSelectedLesson(lesson);
                              }
                            }}
                          />
                        ))
                      ) : (
                        <span>Список уроков пуст</span>
                      )}
                    </>
                  )}
                </div>

                <div className={styles.buttons}>
                  <Button
                    type='reset'
                    variant='secondary'
                    size='big'
                    title='Назад'
                    onClick={() => setStep('chooseCourse')}
                    isDisabled={moveSectionLoading}
                  />

                  <Button
                    type='submit'
                    variant='primary'
                    size='big'
                    title='Переместить'
                    isDisabled={!selectedLesson}
                    isLoading={moveSectionLoading}
                  />
                </div>
              </form>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MoveSectionForm;
