import React from 'react';

const IconUpload: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.75 13.9375H14.25V15.625H18.75C19.0312 15.625 19.3125 15.9062 19.3125 16.1875V19C19.3125 19.3164 19.0312 19.5625 18.75 19.5625H5.25C4.93359 19.5625 4.6875 19.3164 4.6875 19V16.1875C4.6875 15.9062 4.93359 15.625 5.25 15.625H9.75V13.9375H5.25C3.98438 13.9375 3 14.957 3 16.1875V19C3 20.2656 3.98438 21.25 5.25 21.25H18.75C19.9805 21.25 21 20.2656 21 19V16.1875C21 14.957 19.9805 13.9375 18.75 13.9375ZM7.78125 9.47266L11.1562 6.13281V15.625C11.1562 16.1172 11.5078 16.4688 12 16.4688C12.457 16.4688 12.8438 16.1172 12.8438 15.625V6.13281L16.1836 9.47266C16.3242 9.64844 16.5352 9.71875 16.7812 9.71875C16.9922 9.71875 17.2031 9.64844 17.3438 9.47266C17.6953 9.15625 17.6953 8.62891 17.3438 8.3125L12.5625 3.53125C12.2461 3.17969 11.7188 3.17969 11.4023 3.53125L6.62109 8.3125C6.26953 8.62891 6.26953 9.15625 6.62109 9.47266C6.9375 9.82422 7.46484 9.82422 7.78125 9.47266ZM18.1875 17.5938C18.1875 17.1367 17.8008 16.75 17.3438 16.75C16.8516 16.75 16.5 17.1367 16.5 17.5938C16.5 18.0859 16.8516 18.4375 17.3438 18.4375C17.8008 18.4375 18.1875 18.0859 18.1875 17.5938Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconUpload;
