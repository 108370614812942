import { FC } from 'react';

import { useGetCoursesInformationQuery } from 'hooks/general-analytics';

import { classNames } from 'utils';
import { NumbersSkeleton } from './NumbersSkeleton';

type NumbersProps = {
  className?: string;
};

const Numbers: FC<NumbersProps> = props => {
  const { className } = props;
  const { data: numbers, isLoading: numbersLoading } = useGetCoursesInformationQuery();

  if (numbersLoading) return <NumbersSkeleton />;
  if (!numbers) return <h2>Не удалось загрузить количественные показатели</h2>;

  return (
    <div className={classNames('flex gap-[8px]', {}, [className])}>
      <div
        className={
          'flex min-w-[120px] flex-col gap-[8px] p-[15px] bg-[#FFFFFF] rounded-[12px] ' +
          'border-[1px] border-solid border-[#E9ECF0] shadow-[0_6px_12px_0_rgba(0,0,0,0.06)]'
        }
      >
        <p className='text-[16px] font-[500] leading-[19px] text-[#71798F]'>Курсы</p>
        <h2 className='text-[30px] font-[700] leading-[36px] text-[#20233A]'>
          {numbers.coursesCount}
        </h2>
      </div>

      <div
        className={
          'flex min-w-[120px] flex-col gap-[8px] p-[15px] bg-[#FFFFFF] rounded-[12px] ' +
          'border-[1px] border-solid border-[#E9ECF0] shadow-[0_6px_12px_0_rgba(0,0,0,0.06)]'
        }
      >
        <p className='text-[16px] font-[500] leading-[19px] text-[#71798F]'>Модули</p>
        <h2 className='text-[30px] font-[700] leading-[36px] text-[#20233A]'>
          {numbers.modulesCount}
        </h2>
      </div>

      <div
        className={
          'flex min-w-[120px] flex-col gap-[8px] p-[15px] bg-[#FFFFFF] rounded-[12px] ' +
          'border-[1px] border-solid border-[#E9ECF0] shadow-[0_6px_12px_0_rgba(0,0,0,0.06)]'
        }
      >
        <p className='text-[16px] font-[500] leading-[19px] text-[#71798F]'>Уроки</p>
        <h2 className='text-[30px] font-[700] leading-[36px] text-[#20233A]'>
          {numbers.lessonsCount}
        </h2>
      </div>

      <div
        className={
          'flex min-w-[120px] flex-col gap-[8px] p-[15px] bg-[#FFFFFF] rounded-[12px] ' +
          'border-[1px] border-solid border-[#E9ECF0] shadow-[0_6px_12px_0_rgba(0,0,0,0.06)]'
        }
      >
        <p className='text-[16px] font-[500] leading-[19px] text-[#71798F]'>Разделы</p>
        <h2 className='text-[30px] font-[700] leading-[36px] text-[#20233A]'>
          {numbers.sectionsCount}
        </h2>
      </div>

      <div
        className={
          'flex min-w-[120px] flex-col gap-[8px] p-[15px] bg-[#FFFFFF] rounded-[12px] ' +
          'border-[1px] border-solid border-[#E9ECF0] shadow-[0_6px_12px_0_rgba(0,0,0,0.06)]'
        }
      >
        <p className='text-[16px] font-[500] leading-[19px] text-[#71798F]'>Шаги</p>
        <h2 className='text-[30px] font-[700] leading-[36px] text-[#20233A]'>
          {numbers.stepsCount}
        </h2>
      </div>
    </div>
  );
};

export default Numbers;
