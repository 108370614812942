import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { ICourse } from 'api/api-courses';
import { CHECKLIST_OPTIONS, COURSE_STATUS, FORM_ERROR_MESSAGES } from 'consts';
import { useUpdateCourseStatusMutation } from 'hooks/mutations';

import Button from 'components/atoms/Button';
import { IconEye, IconEyeBlocked, IconEyeCrossed } from 'components/atoms/icons';

const statusOptions: {
  value: COURSE_STATUS;
  label: COURSE_STATUS;
  icon: JSX.Element;
}[] = [
  {
    value: COURSE_STATUS.UNPUBLISHED,
    label: COURSE_STATUS.UNPUBLISHED,
    icon: <IconEyeCrossed />
  },
  {
    value: COURSE_STATUS.PUBLISHED_FOR_ALL,
    label: COURSE_STATUS.PUBLISHED_FOR_ALL,
    icon: <IconEye />
  },
  {
    value: COURSE_STATUS.PUBLISHED_BY_SUBSCRIPTION,
    label: COURSE_STATUS.PUBLISHED_BY_SUBSCRIPTION,
    icon: <IconEyeBlocked />
  }
];

interface FieldValues {
  status: COURSE_STATUS;
}

interface UpdateStatusProps {
  course: ICourse;
}

const UpdateStatus: FC<UpdateStatusProps> = ({ course }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      status: course.status
    },
    mode: 'onSubmit'
  });
  const { mutate: updateCourseStatusMutate, isLoading } = useUpdateCourseStatusMutation();

  const onSubmit = async (data: FieldValues) => {
    updateCourseStatusMutate(
      { courseId: course.id, body: { status: data.status } },
      {
        onSuccess: (_, variables) => {
          reset({ status: variables.body.status });
        }
      }
    );
  };

  return (
    <div className='flex h-full flex-col'>
      <header className='mb-[16px] flex h-[49px] w-full items-center justify-between'>
        <h2 className='text-[29px] font-[700] leading-[35px] text-[#20233A]'>Чек-лист</h2>
      </header>

      <form className='flex h-full flex-col' onSubmit={handleSubmit(onSubmit)}>
        <h3 className='mb-[8px] flex h-[49px] items-center text-[18px] font-[500] leading-[21px] text-[#20233A]'>
          Ваш курс готов к публикации, установите новый статус курса:
        </h3>

        <Controller
          control={control}
          name='status'
          rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className='mb-auto flex flex-wrap gap-[8px]'>
              {statusOptions.map((status, index) => (
                <button
                  key={index}
                  type='button'
                  className={
                    'flex items-center gap-[10px] px-[16px] py-[14px] text-[16px] leading-[19px] rounded-[12px] ' +
                    'transition-all select-none ' +
                    `${
                      status.value === value
                        ? 'bg-[#66C84D] text-[#FFFFFF] border-[1px] border-solid border-[#66C84D]'
                        : 'bg-[#FFFFFF] text-[#20233A] border-[1px] border-solid border-[#E9ECF0]'
                    }`
                  }
                  onClick={() => onChange(status.value)}
                >
                  <div
                    className={
                      'h-[24px] w-[24px] ' +
                      `${status.value === value ? 'text-[#F5F5F5]' : 'text-[#66C84D]'}`
                    }
                  >
                    {status.icon}
                  </div>
                  {status.label}
                </button>
              ))}

              {!!error && (
                <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
                  {error.message}
                </span>
              )}
            </div>
          )}
        />

        <div className='mt-[24px] flex justify-end gap-[8px]'>
          <Button
            type='button'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            onClick={() => {
              searchParams.set('checklistOption', CHECKLIST_OPTIONS.REVIEW);
              setSearchParams(searchParams);
            }}
            isDisabled={isLoading}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default UpdateStatus;
