import React from 'react';

const IconCheckSquareOn: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='1' y='1' width='22' height='22' rx='5' stroke='#E9ECF0' strokeWidth='2' />
      <path
        d='M19 8.03125L10.5 16.5312C10.375 16.6875 10.1875 16.75 10 16.75C9.78125 16.75 9.59375 16.6875 9.46875 16.5312L4.96875 12.0312C4.65625 11.75 4.65625 11.2812 4.96875 11C5.25 10.6875 5.71875 10.6875 6 11L10 14.9688L17.9688 7C18.25 6.6875 18.7188 6.6875 19 7C19.3125 7.28125 19.3125 7.75 19 8.03125Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconCheckSquareOn;
