import { FC } from 'react';

import { IPagination } from 'api/api-words';
import { IconDoubleStroke, IconStroke } from 'components/atoms/icons';
import styles from './BePagination.module.css';

interface BePagintaionProps {
  pagination: IPagination;
  setCurrentPage: (page: number) => void;
}

const BePagintaion: FC<BePagintaionProps> = ({
  pagination: { currentPage, hasNextPage, hasPrevPage, lastPage },
  setCurrentPage
}) => {
  const goToFirstPage = () => setCurrentPage(1);
  const goToPrevPage = () => setCurrentPage(currentPage - 1);
  const goToNextPage = () => setCurrentPage(currentPage + 1);
  const goToLastPage = () => setCurrentPage(lastPage);

  const pages = [
    currentPage === 1 ? currentPage : null,
    currentPage === 1 && currentPage + 1 <= lastPage ? currentPage + 1 : null,
    currentPage === 1 && currentPage + 2 <= lastPage ? currentPage + 2 : null,
    currentPage === 1 && currentPage + 3 <= lastPage ? currentPage + 3 : null,

    currentPage !== 1 && currentPage !== lastPage - 1 && currentPage !== lastPage
      ? currentPage - 1
      : null,
    currentPage !== 1 && currentPage !== lastPage - 1 && currentPage !== lastPage
      ? currentPage
      : null,
    currentPage !== 1 &&
    currentPage !== lastPage - 1 &&
    currentPage !== lastPage &&
    currentPage + 1 <= lastPage
      ? currentPage + 1
      : null,
    currentPage !== 1 &&
    currentPage !== lastPage - 1 &&
    currentPage !== lastPage &&
    currentPage + 2 <= lastPage
      ? currentPage + 2
      : null,

    currentPage !== 1 && currentPage === lastPage - 1 && currentPage - 2 >= 1
      ? currentPage - 2
      : null,
    currentPage !== 1 && currentPage === lastPage - 1 && currentPage - 1 >= 1
      ? currentPage - 1
      : null,
    currentPage !== 1 && currentPage === lastPage - 1 ? currentPage : null,
    currentPage !== 1 && currentPage === lastPage - 1 ? lastPage : null,

    currentPage === lastPage && currentPage - 3 >= 1 ? currentPage - 3 : null,
    currentPage === lastPage && currentPage - 2 >= 1 ? currentPage - 2 : null,
    currentPage === lastPage && currentPage - 1 >= 1 ? currentPage - 1 : null,
    currentPage === lastPage ? currentPage : null
  ];

  return (
    <div className={styles.pagination}>
      <button className={styles.pagination__button} disabled={!hasPrevPage} onClick={goToFirstPage}>
        <IconDoubleStroke />
      </button>

      <button className={styles.pagination__button} disabled={!hasPrevPage} onClick={goToPrevPage}>
        <IconStroke />
      </button>

      {pages.map(page => {
        return page ? (
          <button
            key={page}
            className={`${styles.pagination__page} ${
              page === currentPage ? styles.pagination__page_active : ''
            }`}
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </button>
        ) : null;
      })}

      <button className={styles.pagination__button} disabled={!hasNextPage} onClick={goToNextPage}>
        <IconStroke className='rotate-180' />
      </button>

      <button className={styles.pagination__button} disabled={!hasNextPage} onClick={goToLastPage}>
        <IconDoubleStroke className='rotate-180' />
      </button>
    </div>
  );
};

export default BePagintaion;
