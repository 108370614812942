import React, { SVGProps } from 'react';

const IconText: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.4998 4.5L19.6598 8.85L18.6998 9.11C18.2498 8.24 17.7898 7.37 17.2598 6.93C16.7298 6.5 16.1098 6.5 15.4998 6.5H12.9998V17C12.9998 17.5 12.9998 18 13.3298 18.25C13.6698 18.5 14.3298 18.5 14.9998 18.5V19.5H8.99984V18.5C9.66984 18.5 10.3298 18.5 10.6698 18.25C10.9998 18 10.9998 17.5 10.9998 17V6.5H8.49984C7.88984 6.5 7.26984 6.5 6.73984 6.93C6.20984 7.37 5.74984 8.24 5.29984 9.11L4.33984 8.85L5.49984 4.5H18.4998Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconText;
