import {
  BarControllerChartOptions,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  ScaleChartOptions
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';

export const chartOptions: _DeepPartialObject<
  CoreChartOptions<'bar'> &
    ElementChartOptions<'bar'> &
    PluginChartOptions<'bar'> &
    DatasetChartOptions<'bar'> &
    ScaleChartOptions<'bar'> &
    BarControllerChartOptions
> = {
  responsive: true,
  elements: {
    bar: {
      backgroundColor: 'rgba(172, 225, 255, 0.40)',
      hoverBackgroundColor: 'rgba(172, 225, 255, 0.60)'
    }
  },
  interaction: {
    intersect: false,
    mode: 'index'
  },
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 14
        },
        color: '#A6B0C9'
      }
    },
    y: {
      stacked: true,
      ticks: {
        stepSize: 1,
        color: '#A6B0C9',
        font: {
          size: 14
        }
      }
    }
  }
};

export const TOOLTIP_DEFAULT_HEIGHT = 244;
export const TOOLTIP_DEFAULT_WIDTH = 257;
