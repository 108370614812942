import { FC } from 'react';

import { Skeleton } from 'components/atoms';

const SkeletonCourseInfo: FC = () => {
  return (
    <div className='flex grow flex-col'>
      <header className='mb-[28px] flex h-[50px] items-center justify-between'>
        <Skeleton height={36} width={300} />
        <Skeleton height={48} width={218} border={14} />
      </header>

      <div className='mb-[8px] flex gap-[10px]'>
        <div className='flex flex-[3] flex-col gap-[8px]'>
          <Skeleton height={22} width={164} />
          <Skeleton height={56} border={14} />
        </div>
        <div className='flex flex-[1] flex-col gap-[8px]'>
          <Skeleton height={22} width={164} />
          <Skeleton height={56} border={14} />
        </div>
        <div className='flex flex-[1] flex-col gap-[8px]'>
          <Skeleton height={22} width={151} />
          <Skeleton height={56} border={14} />
        </div>
      </div>

      <Skeleton height={16} width={100} className='mb-[30px]' />

      <div className='mb-[30px] flex gap-[60px]'>
        <div className='flex w-[224px] shrink-0 flex-col'>
          <Skeleton height={22} width={160} className='mb-[16px]' />
          <Skeleton height={224} width={224} border={16} />
        </div>

        <div className='flex grow flex-col'>
          <Skeleton height={22} width={160} className='mb-[24px]' />
          <div className='mb-[24px] flex items-center gap-[20px]'>
            <Skeleton height={32} width={32} border='50%' />
            <Skeleton height={32} width={32} border='50%' />
            <Skeleton height={32} width={32} border='50%' />
            <Skeleton height={32} width={32} border='50%' />
            <Skeleton height={32} width={32} border='50%' />
            <Skeleton height={32} width={32} border='50%' />
          </div>
          <Skeleton height={160} width={335} border={30} />
        </div>
      </div>

      <div className='mb-[16px] flex items-center justify-between gap-[20px]'>
        <div className='flex grow flex-col gap-[4px]'>
          <Skeleton height={22} width={100} />
          <Skeleton height={16} width={120} />
        </div>

        <Skeleton height={48} width={221} border={62} className='shrink-0' />
      </div>

      <ul className='mb-[40px] flex flex-col gap-[8px]'>
        <li className='flex items-center gap-[8px]'>
          <Skeleton height={54} border={14} />
          <Skeleton height={48} width={56} border={12} className='shrink-0' />
        </li>
        <li className='flex items-center gap-[8px]'>
          <Skeleton height={54} border={14} />
          <Skeleton height={48} width={56} border={12} className='shrink-0' />
        </li>
        <li className='flex items-center gap-[8px]'>
          <Skeleton height={54} border={14} />
          <Skeleton height={48} width={56} border={12} className='shrink-0' />
        </li>
      </ul>

      <div className='mb-[16px] flex justify-between gap-[20px]'>
        <Skeleton height={22} width={100} />
        <Skeleton height={48} width={212} border={62} className='shrink-0' />
      </div>

      <ul className='mb-[30px] flex flex-wrap gap-[16px]'>
        <Skeleton height={54} width={254} border={27} />
        <Skeleton height={54} width={254} border={27} />
        <Skeleton height={54} width={254} border={27} />
      </ul>

      <div className='flex justify-end gap-[8px]'>
        <Skeleton height={64} width={198} border={16} />
        <Skeleton height={64} width={198} border={16} />
      </div>
    </div>
  );
};

export default SkeletonCourseInfo;
