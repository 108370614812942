import { useQuery } from 'react-query';
import { apiDealNotifications } from '../../api/api-deal-notifications';
import { handleError } from '../../helpers/handleError';
import { useNavigate, useParams } from 'react-router-dom';
import IconClose from '../../components/atoms/icons/IconClose';
import { formatDate } from '../DealNotificationsPage/utils/formatDate';
import styles from './DealNotificationPage.module.css';
import { paidFrom } from './utils';

type Params = {
  dealNotificationId: string;
};

export const DealNotificationPage = () => {
  const navigate = useNavigate();
  const { dealNotificationId } = useParams<keyof Params>() as Params;

  const { data: dealData, isLoading: dealDataLoading } = useQuery(
    [`deal-notifications/one/${dealNotificationId}`, dealNotificationId],
    async () => await apiDealNotifications.getDealNotification(dealNotificationId),
    {
      onError(error) {
        handleError(error);
      }
    }
  );

  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/deal-notifications', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  };

  if (dealDataLoading) {
    return <>Загрузка...</>;
  }

  return (
    <>
      <button className='mb-[30px] flex gap-[16px]' onClick={handleBack}>
        <IconClose color='#5770F3' />
        <span className='hover:text-[#5770F3]'>Закрыть</span>
      </button>
      <div className='mb-[46px] flex items-center justify-between'>
        <div className={styles.title}>Транзакция</div>
        {dealData && <div>{formatDate(dealData.createdAt)}</div>}
      </div>
      {dealData ? (
        <div>
          <div className='flex gap-[64px] border-b-[1px] border-[#E9ECF0] py-[16px]'>
            <div>
              <div className={styles.field__title}>ID транзакции:</div>
              <div className={styles.field__value}>{dealData.dealId}</div>
            </div>
            <div>
              <div className={styles.field__title}>Оплачено через:</div>
              <div className={styles.field__value}>{paidFrom(dealData.dealType)}</div>
            </div>
            <div>
              <div className={styles.field__title}>Статус транзакции:</div>
              <div className={styles.field__value}>
                {dealData.dealStatus === 'success' ? (
                  <span className={styles.status_success}>Успешно</span>
                ) : (
                  <span className={styles.status_error}>Ошибка!</span>
                )}
              </div>
            </div>
          </div>

          <div className='flex gap-[64px] border-b-[1px] border-[#E9ECF0] py-[16px]'>
            <div>
              <div className={styles.field__title}>Email:</div>
              <div className={styles.field__value}>
                {dealData.metadata?.userEmail ? (
                  <span className={styles.email}>{dealData.metadata.userEmail}</span>
                ) : (
                  <span className={styles.status_error}>Нет информации!</span>
                )}
              </div>
            </div>
            {'username' in dealData.metadata && (
              <div>
                <div className={styles.field__title}>Пользователь:</div>
                <div>
                  {'username' in dealData.metadata ? (
                    <span className={styles.field__value}>{dealData.metadata.username}</span>
                  ) : (
                    <span className={styles.status_error}>Нет информации!</span>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className='flex gap-[64px] border-b-[1px] border-[#E9ECF0] py-[16px]'>
            <div>
              <div className={styles.field__title}>Уровень курса:</div>
              {'courses' in dealData.metadata ? (
                <div className={styles.field__value}>{dealData.metadata.courses.join(', ')}</div>
              ) : 'courseTitle' in dealData.metadata ? (
                <div className={styles.field__value}>{dealData.metadata.courseTitle}</div>
              ) : (
                <span className={styles.status_error}>Нет информации!</span>
              )}
            </div>
            <div>
              <div className={styles.field__title}>Период:</div>
              <div className={styles.field__value}>
                {'period' in dealData.metadata && dealData.metadata.period}
              </div>
            </div>
          </div>

          {'errorDescription' in dealData.metadata && (
            <div className='flex gap-[64px] border-b-[1px] border-[#E9ECF0] py-[16px]'>
              <div>
                <div className={styles.field__title}>Причина ошибки:</div>
                <div className={styles.field__value}>{dealData.metadata.errorDescription}</div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DealNotificationPage;
