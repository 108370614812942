import {
  ActiveElement,
  BarElement,
  BubbleDataPoint,
  CategoryScale,
  Chart,
  ChartData,
  ChartEvent,
  Chart as ChartJS,
  ChartTypeRegistry,
  Filler,
  Legend,
  LinearScale,
  Point,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  TooltipModel
} from 'chart.js';
import { FC, useCallback, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import { MistakesLesson as IMistakesLesson, MistakesModule } from 'api/api-analytics';
import { useGetCourseMistakesQuery } from 'hooks/course';
import { findMaximum } from 'utils';
import { chartOptions } from './const';
import { TooltipData } from './model';

import Modal from 'components/atoms/Modal';
import { IconStroke } from 'components/atoms/icons';
import { MistakesLesson } from '..';
import MistakesSkeleton from './MistakesSkeleton';
import MistakesTooltip from './MistakesTooltip';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

interface MistakesProps {
  courseId: string;
}

const Mistakes: FC<MistakesProps> = ({ courseId }) => {
  const { data: courseMistakes, isLoading: courseMistakesLoading } =
    useGetCourseMistakesQuery(courseId);
  const [moduleIndex, setModuleIndex] = useState<number>(0);
  const [currentModule, setCurrentModule] = useState<MistakesModule>();
  const [currentLessons, setCurrentLessons] = useState<IMistakesLesson[]>([]);
  const [currentLesson, setCurrentLesson] = useState<IMistakesLesson>();
  const [lessonModalActive, setLessonModalActive] = useState<boolean>(false);
  const [tooltipData, setTooltipData] = useState<TooltipData>({
    showTooltip: false,
    x: undefined,
    y: undefined,
    height: undefined,
    width: undefined,
    xAlign: undefined,
    yAlign: undefined,
    mistakesLesson: undefined
  });

  const chartData = useMemo<ChartData<'bar', number[], string> | undefined>(() => {
    if (!courseMistakes || !courseMistakes.data[moduleIndex]) return;
    const module = courseMistakes.data.sort((a, b) => a.module.order - b.module.order)[moduleIndex];
    setCurrentModule(module.module);

    const lessons = module.lessons;
    setCurrentLessons(lessons);

    const data = lessons.map(lesson => lesson.mistakesCount);
    const labels = lessons.map(lesson => lesson.lesson.subtitle || `Урок ${lesson.lesson.order}`);

    return {
      labels,
      datasets: [
        {
          label: 'Ошибок в уроке',
          data
        }
      ]
    };
  }, [courseMistakes, moduleIndex]);

  const prevModule = () => setModuleIndex(prev => prev - 1);
  const nextModule = () => setModuleIndex(prev => prev + 1);

  const handleClick = (
    event: ChartEvent,
    elements: ActiveElement[],
    chart: ChartJS<
      keyof ChartTypeRegistry,
      (number | Point | [number, number] | BubbleDataPoint | null)[],
      unknown
    >
  ) => {
    if (!elements.length || !currentLessons.length) return;
    const index = elements[0].index;
    const lesson = currentLessons[index];
    if (!lesson) return;
    setCurrentLesson(lesson);
    setLessonModalActive(true);
  };

  const externalTooltipHandler = useCallback<
    (
      this: TooltipModel<'bar'>,
      args: {
        chart: Chart<
          keyof ChartTypeRegistry,
          (number | Point | [number, number] | BubbleDataPoint | null)[],
          unknown
        >;
        tooltip: TooltipModel<'bar'>;
      }
    ) => void
  >(
    ({ chart, tooltip }) => {
      // hide if no tooltip
      if (tooltip.opacity === 0 && tooltipData.showTooltip) {
        setTooltipData(tooltipData => ({ ...tooltipData, showTooltip: false }));
        return;
      }

      if (!currentLessons.length) return;
      const index = tooltip.dataPoints[0].dataIndex;
      const lesson = currentLessons[index];
      setCurrentLesson(lesson);
      if (!lesson) return;

      if (
        tooltipData.y !== tooltip.y ||
        tooltipData.x !== tooltip.x ||
        tooltipData.mistakesLesson !== currentLesson ||
        (tooltip.opacity === 1 && !tooltipData.showTooltip)
      ) {
        setTooltipData({
          mistakesLesson: lesson,
          showTooltip: tooltip.opacity === 1,
          height: tooltip.height,
          width: tooltip.width,
          x: tooltip.x,
          y: tooltip.y,
          xAlign: tooltip.xAlign,
          yAlign: tooltip.yAlign
        });
      }
    },
    [
      currentLesson,
      currentLessons,
      tooltipData.mistakesLesson,
      tooltipData.showTooltip,
      tooltipData.x,
      tooltipData.y
    ]
  );

  if (courseMistakesLoading) return <MistakesSkeleton />;

  return (
    <div className='flex flex-1 flex-col px-[15px] pb-[30px]'>
      <div className='relative mb-[30px] flex flex-1 cursor-pointer'>
        {chartData ? (
          <Bar
            data={chartData}
            options={{
              ...chartOptions,
              plugins: {
                ...chartOptions.plugins,
                tooltip: {
                  enabled: false,
                  external: externalTooltipHandler
                }
              },
              scales: {
                ...chartOptions.scales,
                y: {
                  suggestedMax: findMaximum(chartData.datasets[0].data) * 1.1,
                  ticks: {
                    stepSize: 10,
                    color: '#A6B0C9',
                    font: {
                      size: 14
                    }
                  }
                }
              },
              onClick: handleClick
            }}
          />
        ) : (
          <span>Для данного курса статистика не записывается</span>
        )}

        <MistakesTooltip currentModule={currentModule} tooltipData={tooltipData} />
      </div>

      <div className='flex select-none items-center gap-[12px] self-center'>
        <button
          type='button'
          className='flex h-[24px] w-[24px] items-center justify-center disabled:opacity-30'
          onClick={prevModule}
          disabled={moduleIndex === 0}
        >
          <IconStroke color='#5770F3' className='h-full w-full' />
        </button>
        <h5 className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>
          Модуль {moduleIndex + 1}
        </h5>
        <button
          type='button'
          className='flex h-[24px] w-[24px] items-center justify-center disabled:opacity-30'
          onClick={nextModule}
          disabled={moduleIndex + 1 === courseMistakes?.data?.length}
        >
          <IconStroke color='#5770F3' className='h-full w-full rotate-180' />
        </button>
      </div>

      {lessonModalActive && (
        <Modal onClose={() => setLessonModalActive(false)}>
          <MistakesLesson
            courseId={courseId}
            currentModule={currentModule}
            currentLesson={currentLesson}
            onCancel={() => setLessonModalActive(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Mistakes;
