import { useMutation, useQueryClient } from 'react-query';

import { apiWords } from 'api/api-words';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export type SetWordImageDto = {
  wordId: string;
  lessonId?: string;
  dictionaryId?: string;
  body: {
    image: File;
  };
};

export const useSetWordImageMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (dto: SetWordImageDto) => {
      return apiWords.setWordImage(dto);
    },
    {
      onSuccess(_, { lessonId, dictionaryId }) {
        notifySuc('Картинка загружена');

        if (lessonId) {
          queryClient.invalidateQueries([`lessons/one/${lessonId}`]);
        }

        if (dictionaryId) {
          queryClient.invalidateQueries([`dictionary/thematic-dictionary/one/${dictionaryId}`]);
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
