import { FC } from 'react';

import { Skeleton } from 'components/atoms';

const CourseNumbersSkeleton: FC = () => {
  return (
    <div className='mb-[40px] flex gap-[8px]'>
      <Skeleton height={95} width={120} />
      <Skeleton height={95} width={120} />
      <Skeleton height={95} width={120} />
      <Skeleton height={95} width={120} />
    </div>
  );
};

export default CourseNumbersSkeleton;
