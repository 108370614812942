import React from 'react';

const IconRefresh: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 6V9L8 5L12 1V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 13.57 19.54 15.03 18.76 16.26L17.3 14.8C17.7615 13.939 18.0021 12.9769 18 12C18 10.4087 17.3679 8.88258 16.2426 7.75736C15.1174 6.63214 13.5913 6 12 6ZM5.24 7.74L6.7 9.2C6.26 10.04 6 11 6 12C6 13.5913 6.63214 15.1174 7.75736 16.2426C8.88258 17.3679 10.4087 18 12 18V15L16 19L12 23V20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 10.43 4.46 8.97 5.24 7.74Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconRefresh;
