import React from 'react';

const IconClock: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 3.25C7.00781 3.25 3 7.29297 3 12.25C3 17.2422 7.00781 21.25 12 21.25C16.957 21.25 21 17.2422 21 12.25C21 7.29297 16.957 3.25 12 3.25ZM12 19.5625C7.95703 19.5625 4.6875 16.293 4.6875 12.25C4.6875 8.24219 7.95703 4.9375 12 4.9375C16.0078 4.9375 19.3125 8.24219 19.3125 12.25C19.3125 16.293 16.0078 19.5625 12 19.5625ZM15.5859 13.375L12.8438 11.793L12.8438 7.46875C12.8438 7.01172 12.457 6.625 12 6.625C11.5078 6.625 11.1563 7.01172 11.1563 7.46875L11.1563 12.25C11.1563 12.5664 11.2969 12.8477 11.5781 12.9883L14.7422 14.8164C14.8477 14.8867 14.9883 14.9219 15.1641 14.9219C15.4453 14.9219 15.7266 14.7812 15.8672 14.5C16.1133 14.1133 15.9727 13.5859 15.5859 13.375Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconClock;
