import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={25} fill='none' {...props}>
    <path
      stroke='#71798F'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M10 13.5a5.001 5.001 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71'
    />
    <path
      stroke='#71798F'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M14 11.5a5.002 5.002 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71'
    />
  </svg>
);
export default SvgComponent;
