import { ChangeEvent, FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { useLesson } from 'hooks';
import { ValidatedWordsData } from 'models';

import { notifySuc } from 'helpers/notification';
import { Content, Sidebar, UploadWords } from './components';
import NotionTextParse from './components/NotionTextParse/NotionTextParse';

type Params = {
  lessonId: string;
};

const LessonWordsPage: FC = () => {
  const { lessonId } = useParams<keyof Params>() as Params;
  const [lesson] = useLesson({ lessonId, sections: false, words: true });

  const [validatedWordsData, setValidatedWordsData] = useState<ValidatedWordsData | null>(null);
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);
  const [notionTextUploadLoading, setNotionTextUploadLoading] = useState<boolean>(false);
  const [isNotionTextParseOpen, setIsNotionTextParseOpen] = useState<boolean>(false);

  const toggleIsNotionTextParseOpen = () => {
    setIsNotionTextParseOpen(prev => !prev);
  };

  const validateFile = async (lessonId: string, file: File): Promise<void> => {
    setFileUploadLoading(true);

    try {
      const validatedWordsData = await apiCourses.getWordsFromFile(lessonId, file);

      setValidatedWordsData(validatedWordsData);
      notifySuc('Файл обработан');
    } catch (error) {
      handleError(error);
    }

    setFileUploadLoading(false);
  };

  const validateNotionText = async (lessonId: string, text: string): Promise<void> => {
    setIsNotionTextParseOpen(false);
    setNotionTextUploadLoading(true);

    try {
      const validatedWordsData = await apiCourses.getWordsFromNotionText(lessonId, text);

      setValidatedWordsData(validatedWordsData);
      notifySuc('Текст обработан');
    } catch (error) {
      handleError(error);
    }

    setNotionTextUploadLoading(false);
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>): void => {
    const uploadedFile = event.target?.files && event.target?.files[0];

    if (lesson && uploadedFile) {
      validateFile(lesson.id, uploadedFile);
    }
  };

  if (isNotionTextParseOpen) {
    return (
      <div className='flex h-full gap-[54px]'>
        <Sidebar />
        <NotionTextParse
          onClose={toggleIsNotionTextParseOpen}
          validateNotionText={validateNotionText}
        />
      </div>
    );
  }

  return (
    <div className='flex gap-[54px]'>
      <Sidebar />

      {validatedWordsData ? (
        <UploadWords
          validatedWordsData={validatedWordsData}
          setValidatedWordsData={setValidatedWordsData}
        />
      ) : (
        <Content
          handleUploadFile={handleUploadFile}
          fileUploadLoading={fileUploadLoading}
          notionTextUploadLoading={notionTextUploadLoading}
          toggleIsNotionTextParseOpen={toggleIsNotionTextParseOpen}
        />
      )}
    </div>
  );
};

export default LessonWordsPage;
