import { FC } from 'react';

import { IVideoStep } from 'api/api-courses';
import { useToggle } from 'hooks/common';

import { CheckMarkIcon } from 'components/atoms/icons';

interface VideoStepChangeProps {
  step: IVideoStep;
}

const VideoStepChange: FC<VideoStepChangeProps> = ({ step }) => {
  const [showUrl, toggleShowUrl] = useToggle(true);
  const [showDescription, toggleShowDescription] = useToggle(true);
  const [showTimecodes, toggleShowTimecodes] = useToggle(false);

  return (
    <div
      className={
        'flex flex-col rounded-[16px] bg-[#eef0ff] px-[24px]  ' +
        'max-h-[calc(80vh-210px)] flex-1 overflow-auto pt-[12px] pb-[24px]'
      }
    >
      <header className='mb-[24px] flex h-[56px] min-h-[56px] items-center'>
        <h4 className='text-[18px] font-[500] leading-[21px] text-[#20233A]'>
          {`Шаг ${step.order} | Видео`}
        </h4>
      </header>

      <div className='mb-[24px] flex flex-col gap-[16px]'>
        <button
          type='button'
          className='flex select-none items-center justify-between'
          onClick={toggleShowUrl}
        >
          <h6 className='text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            Ссылка на видео (Vimeo)
          </h6>
          <div className='flex h-[24px] w-[24px] items-center justify-center'>
            <CheckMarkIcon
              className={
                'text-[#71798F] transition-all duration-300 ' +
                `${showUrl ? '-rotate-90' : 'rotate-90'}`
              }
            />
          </div>
        </button>

        {showUrl && (
          <p title={step.url} className='break-all text-[16px] leading-[19px] text-[#20233A]'>
            {step.url}
          </p>
        )}
      </div>

      <div className='mb-[24px] flex flex-col gap-[16px]'>
        <button
          type='button'
          className='flex select-none items-center justify-between'
          onClick={toggleShowDescription}
        >
          <h6 className='text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            описание видео
          </h6>
          <div className='flex h-[24px] w-[24px] items-center justify-center'>
            <CheckMarkIcon
              className={
                'text-[#71798F] transition-all duration-300 ' +
                `${showDescription ? '-rotate-90' : 'rotate-90'}`
              }
            />
          </div>
        </button>

        {showDescription && (
          <p
            title={step.description}
            className='break-all text-[14px] leading-[16px] text-[#20233A]'
          >
            {step.description}
          </p>
        )}
      </div>

      <div className='flex flex-col gap-[16px]'>
        <button
          type='button'
          className='flex select-none items-center justify-between'
          onClick={toggleShowTimecodes}
        >
          <h6 className='text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            таймкоды
          </h6>
          <div className='flex h-[24px] w-[24px] items-center justify-center'>
            <CheckMarkIcon
              className={
                'text-[#71798F] transition-all duration-300 ' +
                `${showTimecodes ? '-rotate-90' : 'rotate-90'}`
              }
            />
          </div>
        </button>

        {showTimecodes && (
          <ul className='flex flex-col gap-[16px]'>
            {step.timeCodes.map((timecode, index) => (
              <li key={index}>
                <p className='break-all text-[14px] leading-[16px] text-[#20233A]'>
                  {timecode.time} - {timecode.description}
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default VideoStepChange;
