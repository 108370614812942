import ButtonAdd from 'components/atoms/ButtonAdd';
import { FC, useState } from 'react';
import { Control, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { Item } from './Item';

interface LearningObjectivesWithIconProps {
  control: Control<any, any>;
  name: string;
  fields: any[];
  append: UseFieldArrayAppend<any, any>;
  remove: UseFieldArrayRemove;
}

const LearningObjectiveWithIcon: FC<LearningObjectivesWithIconProps> = ({
  name,
  fields,
  append,
  control,
  remove
}) => {
  return (
    <>
      <div className='mb-8'>
        <div className='mb-4 flex items-center justify-between'>
          <div className=''>
            <h3 className='font-medium text-[#71798F]'>На этом курсе вы:</h3>
          </div>

          <ButtonAdd
            variant='light'
            type='button'
            title='Добавить описание'
            onClick={() => append({ iconLink: '', text: '' })}
          />
        </div>

        <div className='flex flex-col gap-2'>
          {fields.length > 0 ? (
            <>
              {fields.map((field, i) => (
                <Item remove={remove} name={name} control={control} i={i} key={field.id} />
              ))}
            </>
          ) : (
            <span>Пока у данного курса нет целей обучения</span>
          )}
        </div>
      </div>
    </>
  );
};

export default LearningObjectiveWithIcon;
