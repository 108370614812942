import { NOTIFICATION_STATUS } from 'models';
import { FormValues } from '../model';
import { generateFullDate } from './generateFullDate';

export const transformData = ({
  title,
  message,
  image,
  for: notificationFor,
  recipientsList,
  recipientsRole,
  sendTime,
  sendDateDay,
  sendDateTime,
  repeatDays,
  repeatTime,
  repeatType,
  repeatEveryMonthDay,
  repeatEveryMonthTime,
  repeatEveryYearDay,
  repeatEveryYearTime,
  repeatEveryYearMonth,
  startDate,
  endDate,
  status
}: FormValues) => {
  const sendDate =
    sendDateDay && sendDateTime ? generateFullDate(sendDateDay, sendDateTime) : undefined;

  // calculate repeatEveryMonth
  const repeatEveryMonth =
    repeatEveryMonthDay && repeatEveryMonthTime
      ? {
          day: Number(repeatEveryMonthDay),
          time: repeatEveryMonthTime
        }
      : undefined;

  // calculate repeatEveryYear
  const repeatEveryYear =
    repeatEveryYearDay && repeatEveryYearTime && repeatEveryYearMonth
      ? {
          day: Number(repeatEveryYearDay),
          time: repeatEveryYearTime,
          month: repeatEveryYearMonth
        }
      : undefined;

  return {
    title,
    message,
    image,
    for: notificationFor,
    recipientsList: recipientsList?.map(recipient => recipient.id),
    recipientsRole,
    sendTime,
    sendDate,
    repeatDays,
    repeatTime,
    repeatType,
    repeatEveryMonth,
    repeatEveryYear,
    startDate,
    endDate,
    status: status || NOTIFICATION_STATUS.ACTIVE
  };
};
