import { useMutation, useQueryClient } from 'react-query';

import { ILesson, UpdateSectionOrdersDto, apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useUpdateSectionOrdersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateSectionOrdersDto) => {
      return apiCourses.updateSectionOrders(data);
    },
    {
      onSuccess(data, { lessonId }) {
        notifySuc('Порядок разделов изменен');

        const oldLessonData = queryClient.getQueryData<ILesson>(`lessons/one/${lessonId}`);

        if (oldLessonData) {
          const newSections = data;

          queryClient.setQueryData<ILesson>(`lessons/one/${lessonId}`, {
            ...oldLessonData,
            sections: newSections
          });
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
