/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NOTIFICATIONS_PER_PAGE } from 'consts';
import { useDebounce } from 'hooks';
import { useGetNotificationsQuery } from 'hooks/queries';
import { NOTIFICATION_STATUS, RECIPIENTS_ROLE } from 'models';
import styles from './NotificationsPage.module.css';
import { filterRoleOptions, filterStatusOptions } from './const';

import { ControlledSelect } from 'components/atoms';
import Button from 'components/atoms/Button';
import SearchField from 'components/atoms/SearchField/SearchField';
import { IconClose, IconPlus } from 'components/atoms/icons';
import SortBottomIcon from 'components/atoms/icons/SortBottomIcon';
import SortDisabledIcon from 'components/atoms/icons/SortDisabledIcon';
import SortTopIcon from 'components/atoms/icons/SortTopIcon';
import BePagintaion from 'components/molecules/BePagination/BePagination';
import { NotificationCard } from './components';

const NotificationsPage: FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [status, setStatus] = useState<NOTIFICATION_STATUS>();
  const [recipient, setRecipient] = useState<RECIPIENTS_ROLE>();
  const [sortBy, setSortBy] = useState('');

  const { data, isLoading: notificationsLoading } = useGetNotificationsQuery({
    page,
    itemsPerPage: NOTIFICATIONS_PER_PAGE,
    search: searchValue,
    status,
    recipient,
    sortBy
  });

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    setPage(1);
    setSearchValue(value);
  }, [debouncedSearchValue]);

  const handleStartDate = () => {
    setPage(1);
    if (sortBy === 'startDate-asc') {
      setSortBy('startDate-desc');
    } else if (sortBy === 'startDate-desc') {
      setSortBy('');
    } else {
      setSortBy('startDate-asc');
    }
  };

  const handleEndDate = () => {
    setPage(1);
    if (sortBy === 'endDate-asc') {
      setSortBy('endDate-desc');
    } else if (sortBy === 'endDate-desc') {
      setSortBy('');
    } else {
      setSortBy('endDate-asc');
    }
  };

  const handleReset = () => {
    setValue('');
    setSearchValue('');
    setStatus(undefined);
    setRecipient(undefined);
    setSortBy('');
    setPage(1);
  };

  return (
    <div className='flex flex-col'>
      <header className='mb-[20px] flex h-[74px] items-center justify-between'>
        <h2 className='text-[36px] font-[700] leading-[42px] text-[#20233A]'>Уведомления</h2>

        <Button
          type='button'
          variant='dark'
          size='small'
          title='Создать новое уведомление'
          icon={<IconPlus color='#5770F3' />}
          iconPosition='left'
          className='h-[64px] rounded-[62px]'
          onClick={() => navigate('/notifications/create')}
        />
      </header>

      <div className='mb-[30px] flex items-center gap-[12px]'>
        <SearchField
          type='text'
          id='search'
          placeholder='Искать уведомление'
          value={value}
          setState={setValue}
          className='max-w-[360px]'
        />

        <ControlledSelect
          placeholder='Все роли'
          options={filterRoleOptions}
          value={filterRoleOptions.find(({ value }) => value === recipient) || filterRoleOptions[0]}
          onChange={option => {
            setPage(1);
            setRecipient(option.value as RECIPIENTS_ROLE);
          }}
        />

        <ControlledSelect
          placeholder='Все статусы'
          options={filterStatusOptions}
          value={
            filterStatusOptions.find(({ value }) => value === status) || filterStatusOptions[0]
          }
          onChange={option => {
            setPage(1);
            setStatus(option.value as NOTIFICATION_STATUS);
          }}
        />

        <button type='button' className={styles.buttonReset} onClick={handleReset}>
          <IconClose color='#71798F' />
          Сбросить все
        </button>
      </div>

      <div className={styles.table__headers}>
        <span className={`${styles.table__header} ${styles.table__header_preview}`}>
          Уведомление
        </span>

        <button
          className={`${styles.table__header} ${styles.table__header_startDate}`}
          onClick={handleStartDate}
        >
          Начало
          {sortBy === 'startDate-desc' ? (
            <SortBottomIcon />
          ) : sortBy === 'startDate-asc' ? (
            <SortTopIcon />
          ) : (
            <SortDisabledIcon />
          )}
        </button>

        <button
          className={`${styles.table__header} ${styles.table__header_endDate}`}
          onClick={handleEndDate}
        >
          Окончание
          {sortBy === 'endDate-desc' ? (
            <SortBottomIcon />
          ) : sortBy === 'endDate-asc' ? (
            <SortTopIcon />
          ) : (
            <SortDisabledIcon />
          )}
        </button>

        <span className={`${styles.table__header} ${styles.table__header_for}`}>Кому</span>
        <span className={`${styles.table__header} ${styles.table__header_status}`}>Статус</span>
        <span className={`${styles.table__header} ${styles.table__header_actions}`}>Действия</span>
      </div>

      <div className={styles.table__rows}>
        {!notificationsLoading ? (
          <>
            {!!data?.data.length ? (
              <>
                {data.data.map((notification, index) => (
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                    index={index}
                  />
                ))}
              </>
            ) : (
              <h2>Уведомлений не найдено</h2>
            )}
          </>
        ) : (
          <h2>Загрузка...</h2>
        )}
      </div>

      <div className='w-full flex-1'>
        {data && data.pagination.totalItems > NOTIFICATIONS_PER_PAGE && (
          <BePagintaion pagination={data.pagination} setCurrentPage={setPage} />
        )}
      </div>
    </div>
  );
};

export default NotificationsPage;
