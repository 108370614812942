import { ReactElement, SelectHTMLAttributes } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';
import RSelect, { SingleValue, StylesConfig } from 'react-select';

interface IOption {
  value: string;
  label: string;
  color?: string;
  Icon?: ReactElement;
}

const selectStyles: StylesConfig = {
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    height: '56px',
    minWidth: '180px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'rgba(140, 154, 175, 0.25)',
    '&:hover': {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'rgba(140, 154, 175, 0.25)'
    },
    boxShadow: isFocused ? 'none' : 'inherit',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  menu: styles => ({
    ...styles,
    zIndex: '3',
    minWidth: '300px'
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    color: '#A1AABC'
  }),
  option: (styles, { data, isFocused, isDisabled }) => ({
    ...styles,
    color: (data as IOption).color || '#20233A',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    backgroundColor: isFocused ? '#EFF1F4' : 'none',
    '&:active': {
      backgroundColor: '#EFF1F4'
    },
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  singleValue: (styles, { data, isDisabled }) => ({
    ...styles,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    color: (data as IOption).color || '#20233A',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  })
};

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  options: IOption[];
  placeholder?: string;
  rules?: RegisterOptions;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
}
export type SelectFieldProps<T extends FieldValues> = UseControllerProps<T> & SelectProps;

const Select = <T extends FieldValues>({
  name,
  options,
  placeholder = '',
  control,
  rules = {},
  className,
  containerClassName,
  disabled = false,
  loading = false,
  ...rest
}: SelectFieldProps<T>) => {
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules
  });

  return (
    <div className={`flex flex-col ${containerClassName}`}>
      <RSelect
        value={options.find(({ value }) => value === field.value)}
        classNamePrefix='custom-select'
        styles={selectStyles}
        placeholder={placeholder}
        options={options}
        onChange={(newValue: SingleValue<unknown>) => {
          field.onChange((newValue as IOption).value);
        }}
        isSearchable={false}
        className={className}
      />

      {!!fieldState.error && (
        <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
          {fieldState.error.message}
        </span>
      )}
    </div>
  );
};

export default Select;
