/* eslint-disable react-hooks/exhaustive-deps */

import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import ReactPlayer from 'react-player';
import { useMutation, useQueryClient } from 'react-query';

import {
  apiCourses,
  CreateVideoStepData,
  ISection,
  IVideoStep,
  STEP_TYPES,
  UpdateVideoStepData
} from 'api/api-courses';
import { DEFAULT_VIDEO_VOLUME, FORM_ERROR_MESSAGES } from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useGetCurrentUserQuery } from 'hooks/queries';
import { StepChange } from 'models';
import { useDeleteStep } from '../hooks';
import { saveToChangeHistory } from '../utils';
import styles from './VideoStep.module.css';

import Button from 'components/atoms/Button';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import { IconClose, IconTrash } from 'components/atoms/icons';
import Input from 'components/atoms/Input/Input';
import Modal from 'components/atoms/Modal';
import TextArea from 'components/atoms/TextArea';
import ChangeHistory from '../../ChangeHistory/ChangeHistory';
import { Timecodes } from '../components';

interface VideoStepProps {
  stepIndex: number | 'potential';
  step: IVideoStep;
  section: ISection;
  isPotential?: boolean;
  deletePotentialStep?: () => void;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  setOnSubmitCallback: Dispatch<SetStateAction<(() => void) | null>>;
  refetchSection: () => void;
}

const VideoStep: FC<VideoStepProps> = ({
  stepIndex,
  step,
  section,
  isPotential,
  deletePotentialStep,
  setIsDirty,
  setIsValid,
  setOnSubmitCallback,
  refetchSection
}) => {
  const { data: viewer } = useGetCurrentUserQuery();
  const [changeHistoryModalActive, setChangeHistoryModalActive] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isDirty, isValid }
  } = useForm<FieldValues>({
    defaultValues: {
      videoUrl: step.url,
      description: step.description,
      timecodes: step.timeCodes
    }
  });

  const videoUrlField = watch('videoUrl');

  // TODO separate mutation hook
  const { mutateAsync: addNewVideoStepMutate, isLoading: isLoadingCreate } = useMutation(
    (data: CreateVideoStepData) => {
      return apiCourses.addVideoStep(section.id, data);
    },
    {
      onSuccess(data) {
        const oldSectionData = queryClient.getQueryData<ISection>(`sections/one/${section.id}`);
        if (oldSectionData) {
          queryClient.setQueryData<ISection>(`sections/one/${section.id}`, {
            ...oldSectionData,
            steps: [...oldSectionData.steps, { ...data, type: STEP_TYPES.VIDEO }]
          });
        }

        notifySuc('Шаг создан');
        deletePotentialStep && deletePotentialStep();

        if (!viewer) return;
        const time = new Date(Date.now()).toISOString();
        saveToChangeHistory({
          stepId: data.id,
          change: { type: 'video', step: data, user: viewer, time }
        });
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  // TODO separate mutation hook
  const { mutateAsync: updateVideoStepMutate, isLoading: isLoadingUpdate } = useMutation(
    (data: UpdateVideoStepData) => {
      return apiCourses.updateVideoStep(step.id, data);
    },
    {
      onSuccess(data) {
        refetchSection();
        notifySuc('Шаг обновлен');

        if (!viewer) return;
        const time = new Date(Date.now()).toISOString();
        saveToChangeHistory({
          stepId: data.id,
          change: { type: 'video', step: data, user: viewer, time }
        });
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const { deleteStepMutate: deleteVideoStepMutate, isLoading: isLoadingDelete } = useDeleteStep({
    section,
    stepId: step.id,
    stepType: step.type,
    setIsDirty,
    setIsValid,
    setOnSubmitCallback
  });
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

  const deleteVideoStep = () => {
    if (isPotential) {
      if (deletePotentialStep) {
        deletePotentialStep();
      }
      return;
    }
    deleteVideoStepMutate();
  };

  const clearUrlField = () => {
    setValue('videoUrl', '', { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };

  const handleReset = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    reset();
  };

  const onSubmit = async (data: FieldValues) => {
    const { description, videoUrl, timecodes } = data;

    if (isPotential) {
      await addNewVideoStepMutate({
        order: section.steps?.length + 1 || 1,
        url: videoUrl,
        description: description,
        timeCodes: timecodes
      });
      return;
    } else {
      await updateVideoStepMutate({
        order: step.order,
        url: videoUrl,
        description: description,
        timeCodes: timecodes
      });
    }
  };

  useEffect(() => {
    setIsDirty(isDirty);
    setIsValid(isValid);

    setOnSubmitCallback(() => handleSubmit(async data => await onSubmit(data)));
  }, [isDirty, isValid]);

  const handleApplyVersion = async (data: StepChange) => {
    if (data.type !== 'video') return;
    await onSubmit({
      videoUrl: data.step.url,
      description: data.step.description,
      timecodes: data.step.timeCodes
    });
    setChangeHistoryModalActive(false);
  };

  return (
    <>
      <div className='mb-[16px] flex items-center justify-between'>
        <div className='flex h-[56px] items-center justify-between text-[18px] font-[500] leading-[21px] text-[#20233A]'>
          {`Шаг ${isPotential ? section.steps?.length + 1 : stepIndex} | Видео`}
        </div>

        <button
          className='flex h-[24px] w-[24px] items-center justify-center'
          onClick={() => setDeleteModalIsOpen(true)}
        >
          <IconTrash color='#71798F' />
        </button>
      </div>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
        <div className={`${styles.video} ${videoUrlField ? 'mb-[40px]' : 'mb-[16px]'}`}>
          <label className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            Ссылка на видео (Vimeo)
          </label>

          <Input
            type='text'
            variant='bordered'
            width='big'
            name='videoUrl'
            placeholder='Введите URL...'
            control={control}
            rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
            containerClassName={videoUrlField ? 'mb-[8px]' : ''}
          />

          {videoUrlField && (
            <div className='flex gap-[8px]'>
              <ReactPlayer
                className='flex-1 overflow-hidden rounded-[12px]'
                url={videoUrlField}
                controls
                volume={DEFAULT_VIDEO_VOLUME}
                width='100%'
                height='100%'
              />

              <button type='button' className={styles.buttonRemove} onClick={clearUrlField}>
                <IconClose color='#71798F' />
              </button>
            </div>
          )}
        </div>

        <div className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
          Описание видео
        </div>

        <TextArea
          name='description'
          variant='bordered'
          control={control}
          rules={{ required: false }}
          placeholder='Ваше описание...'
          rows={4}
          containerClassName='mb-[16px]'
        />

        <Timecodes control={control} className='mb-[36px]' />

        <div className={styles.buttons}>
          {!isPotential && (
            <Button
              type='button'
              variant='secondary'
              title='История правок'
              className='mr-auto w-[198px]'
              onClick={() => setChangeHistoryModalActive(true)}
            />
          )}

          <Button
            type='reset'
            variant='secondary'
            title='Сбросить'
            className='w-[198px]'
            isDisabled={!isDirty || isLoadingCreate || isLoadingUpdate}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={isLoadingCreate || isLoadingUpdate}
          />
        </div>
      </form>

      {deleteModalIsOpen && (
        <ConfirmationModal
          title={<>Удалить {isPotential ? 'Шаг | Видео' : `Шаг ${stepIndex} | Видео`}?</>}
          text={
            <>
              Шаг будет удален <strong>навсегда</strong>.
            </>
          }
          isDelete={true}
          confirmButtonText='Удалить шаг'
          onConfirm={deleteVideoStep}
          onClose={() => setDeleteModalIsOpen(false)}
          isLoading={isLoadingDelete}
        />
      )}

      {changeHistoryModalActive && step.id && (
        <Modal onClose={() => setChangeHistoryModalActive(false)} className='z-20'>
          <ChangeHistory
            stepId={step.id}
            applyVersion={handleApplyVersion}
            onClose={() => setChangeHistoryModalActive(false)}
            isLoading={isLoadingCreate || isLoadingUpdate}
          />
        </Modal>
      )}
    </>
  );
};

export default VideoStep;
