import { FC } from 'react';
import { Control, useController } from 'react-hook-form';

import avatarPlaceholder from 'assets/images/svg/avatar.svg';

import { FORM_ERROR_MESSAGES } from 'consts';
import { User } from 'models';
import { FormValues } from '../../model';

import { IconClose } from 'components/atoms/icons';

interface RecipientsProps {
  control: Control<FormValues>;
}

const Recipients: FC<RecipientsProps> = ({ control }) => {
  const { field, fieldState } = useController({
    name: 'recipientsList',
    control,
    rules: {
      required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
    }
  });

  const removeUser = (user: User) => {
    const recipientsList = field.value;
    if (!recipientsList) return;

    const updatedValue = recipientsList.filter((recipient: User) => recipient.id !== user.id);
    field.onChange(updatedValue);
  };

  return (
    <div className='flex flex-col'>
      <ul className='flex gap-[8px]'>
        {!!field.value ? (
          <>
            {field.value.map((recipient: User) => (
              <li
                key={recipient.id}
                className='flex h-[54px] items-center gap-[12px] rounded-[27px] bg-[#eef0ff] pr-[20px]'
              >
                <div className='h-[54px] w-[54px] overflow-hidden rounded-full'>
                  <img src={recipient.avatarUrl || avatarPlaceholder} alt={`${recipient.name}`} />
                </div>
                <h3 className='text-[16px] leading-[19px] text-[#20233a]'>{recipient.name}</h3>
                <button
                  type='button'
                  className='flex h-[24px] w-[24px] items-center justify-center'
                  onClick={() => removeUser(recipient)}
                >
                  <IconClose color='#71798F' />
                </button>
              </li>
            ))}
          </>
        ) : (
          <span>Добавьте пользователей</span>
        )}
      </ul>

      {!!fieldState.error && (
        <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
          {fieldState.error.message}
        </span>
      )}
    </div>
  );
};

export default Recipients;
