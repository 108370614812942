import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DEFAULT_AUDIO_VOLUME } from 'consts';
import { notifyErr } from 'helpers/notification';
import { useGetWordQuery } from 'hooks/queries';
import { WordReference } from '../../../../components/atoms';
import styles from './Content.module.css';

import ButtonAdd from 'components/atoms/ButtonAdd';
import Modal from 'components/atoms/Modal';
import { IconPen, IconSoundSmall, IconTrash } from 'components/atoms/icons';
import DeleteWordForm from 'components/organisms/DeleteWordForm';
import AddExampleForm from '../AddExampleForm';
import Example from '../Example';
import NaverUrl from '../NaverUrl';
import UpdateWordForm from '../UpdateWordForm';
import ContentSkeleton from './ContentSkeleton';

type Params = { wordId: string };

const Content: FC = () => {
  const navigate = useNavigate();
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isUpdateWordModalActive, setUpdateWordModalActive] = useState(false);
  const [isDeleteWordModalActive, setDeleteWordModalActive] = useState(false);
  const [addExampleModalActive, setAddExampleModalActive] = useState<boolean>(false);
  const { wordId } = useParams<keyof Params>() as Params;
  const {
    data: word,
    isLoading: wordLoading,
    refetch: refetchWord
  } = useGetWordQuery({
    wordId,
    isFavorite: false,
    withReferences: true
  });

  useEffect(() => {
    if (word && word.audioUrl) {
      const audio = new Audio(word.audioUrl);
      audio.volume = DEFAULT_AUDIO_VOLUME;

      setAudio(audio);
    }
  }, [word]);

  const playAudio = () => {
    if (audio) {
      audio.play();
    } else {
      notifyErr('Нет аудиофайла');
    }
  };

  if (wordLoading) return <ContentSkeleton />;
  if (!word) return <h2>Не удалось загрузить слово</h2>;

  return (
    <div className={styles.content}>
      <header className={styles.header}>
        <h2 className={styles.title}>{word.original}</h2>

        <button className={styles.buttonAudio} onClick={playAudio}>
          <IconSoundSmall color='#FFFFFF' />
        </button>

        <span className={styles.id}>ID: {word.id}</span>

        <div className={styles.controls}>
          <button className={styles.buttonEdit} onClick={() => setUpdateWordModalActive(true)}>
            <IconPen color='#71798F' />
          </button>

          <button className={styles.buttonDelete} onClick={() => setDeleteWordModalActive(true)}>
            <IconTrash color='#A1AABC' />
          </button>
        </div>
      </header>

      <span className={styles.transliteration}>[{word.transliteration || 'transliteration'}]</span>

      <div className={styles.translation}>
        <label className={styles.label}>На русском:</label>
        <span>{word.translation}</span>
      </div>

      <div className={styles.examples}>
        <header className={styles.examples__header}>
          <h3 className={styles.examples__title}>Примеры использования</h3>

          <ButtonAdd
            variant='light'
            type='button'
            title='Добавить пример'
            onClick={() => setAddExampleModalActive(true)}
          />
        </header>

        {word.examples.length > 0 && (
          <ul className={styles.examples__list}>
            {word.examples.map(example => (
              <Example
                key={example.id}
                wordId={word.id}
                example={example}
                refetchWordData={() => refetchWord()}
              />
            ))}
          </ul>
        )}
      </div>

      {word.references?.length && (
        <div className={styles.references}>
          <div className={styles.references__title}>Прикреплено к словарям</div>
          {word.references?.map((reference, index) => (
            <WordReference key={index} reference={reference} />
          ))}
        </div>
      )}

      <NaverUrl
        key={word.naverUrl}
        wordId={word.id}
        naverUrl={word.naverUrl}
        refetchWord={() => refetchWord()}
      />

      {isUpdateWordModalActive && (
        <Modal onClose={() => setUpdateWordModalActive(false)}>
          <UpdateWordForm
            wordId={word.id}
            original={word.original}
            transliteration={word.transliteration}
            translation={word.translation}
            audio={audio}
            refetchWord={() => refetchWord()}
            onCancel={() => setUpdateWordModalActive(false)}
          />
        </Modal>
      )}

      {isDeleteWordModalActive && (
        <Modal onClose={() => setDeleteWordModalActive(false)}>
          <DeleteWordForm
            wordId={word.id}
            currentWord={word.translation}
            onSuccess={() => navigate(-1)}
            onCancel={() => setDeleteWordModalActive(false)}
          />
        </Modal>
      )}

      {addExampleModalActive && (
        <Modal onClose={() => setAddExampleModalActive(false)}>
          <AddExampleForm
            wordId={word.id}
            onAdd={() => refetchWord()}
            onCancel={() => setAddExampleModalActive(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Content;
