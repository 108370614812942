import axios from 'axios';

import { serverString } from '../appConfig';
import { store } from '../store';
import { setLogin } from '../store/actionCreators/authActions';

export const instance = axios.create({
  baseURL: serverString,
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
});

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const response = await axios.post(`${serverString}/auth/refresh/${refreshToken}`);
  return response.data;
};

instance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      try {
        originalRequest._retry = true;
        const { accessToken, refreshToken } = await refreshAccessToken();

        if (accessToken && refreshToken) {
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;

          localStorage.setItem('token', accessToken);
          localStorage.setItem('refreshToken', refreshToken);

          return instance(originalRequest);
        }
      } catch (error) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        store.dispatch(setLogin(false));
      }
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  config => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  config => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
