import React from 'react';

const IconDealNotification: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M19.4336 16.0117C18.7656 15.2734 17.4648 14.1836 17.4648 10.5625C17.4648 7.85547 15.5664 5.67578 13 5.11328V4.375C13 3.77734 12.4727 3.25 11.875 3.25C11.2422 3.25 10.75 3.77734 10.75 4.375V5.11328C8.14844 5.67578 6.25 7.85547 6.25 10.5625C6.25 14.1836 4.94922 15.2734 4.28125 16.0117C4.07031 16.2227 4 16.5039 4 16.75C4 17.3477 4.45703 17.875 5.125 17.875H18.5898C19.293 17.875 19.7148 17.3477 19.75 16.75C19.75 16.5039 19.6445 16.2227 19.4336 16.0117ZM6.35547 16.1875C7.09375 15.2031 7.9375 13.5859 7.9375 10.5977V10.5625C7.9375 8.41797 9.69531 6.625 11.875 6.625C14.0195 6.625 15.8125 8.41797 15.8125 10.5625V10.5977C15.8125 13.5859 16.6211 15.2031 17.3594 16.1875H6.35547ZM11.8398 21.25C13.1055 21.25 14.125 20.2656 14.125 19H9.625C9.625 20.2656 10.6094 21.25 11.8398 21.25Z'
      fill='currentColor'
    />
  </svg>
);

export default IconDealNotification;
