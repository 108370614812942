import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { FormValues } from '../../../../model';

import Input from 'components/atoms/Input';

interface DateTimeProps {
  control: Control<FormValues>;
}

const DateTime: FC<DateTimeProps> = ({ control }) => {
  return (
    <div className='flex flex-col'>
      <h6 className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
        дата и время
      </h6>

      <div className='flex gap-[8px]'>
        <Controller
          control={control}
          name='sendDateDay'
          rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className='flex flex-col'>
              <input
                type='date'
                value={value || ''}
                onChange={onChange}
                className={
                  'rounded-[12px] px-[16px] py-[20px] h-[56px] w-[148px] ' +
                  'text-[16px] text-[#71798F] leading-[22px] font-[500] ' +
                  `${!!error ? 'bg-[rgba(255,68,68,0.1)]' : 'bg-[#eff1f4]'}`
                }
              />

              {!!error && (
                <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
                  {error.message}
                </span>
              )}
            </div>
          )}
        />

        <Input
          type='text'
          variant='dark'
          placeholder='Время'
          name='sendDateTime'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            validate: {
              validTime: (value: any) => {
                const errorMessage = 'Введите корректное время в формате hh:mm';
                const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
                if (!timeRegex.test(value)) {
                  return errorMessage;
                }
                const [hours, minutes] = value.split(':').map(Number);
                if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
                  return errorMessage;
                }
                return true;
              }
            }
          }}
          containerClassName=''
          className='!h-[56px] w-[100px] text-[16px] font-[500] leading-[22px] text-[#71798F]'
        />
      </div>
    </div>
  );
};

export default DateTime;
