import { useQuery } from 'react-query';

import { GetWordDto, apiWords } from 'api/api-words';
import { handleError } from 'helpers/handleError';

export const useGetWordQuery = (data: GetWordDto) => {
  return useQuery(['words/one', data.wordId], async () => await apiWords.getWord(data), {
    onError(error) {
      handleError(error);
    }
  });
};
