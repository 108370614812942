import { FC } from 'react';

import { useGetDropoutStudentsByPeriodQuery } from 'hooks/general-analytics';
import { classNames } from 'utils';
import { PeriodsSkeleton } from '../PeriodsSkeleton';

type Props = {
  className?: string;
};

export const Periods: FC<Props> = props => {
  const { className } = props;
  const { data: dropoutUsersByPeriod, isLoading: dropoutUsersByPeriodLoading } =
    useGetDropoutStudentsByPeriodQuery();

  if (dropoutUsersByPeriodLoading) {
    return <PeriodsSkeleton count={3} />;
  }

  if (!dropoutUsersByPeriod) {
    return null;
  }

  return (
    <header
      className={classNames(
        'flex flex-1 w-full justify-between p-[15px] ' +
          'bg-[#F5F5F5] border-b-[1px] border-solid border-[#E9ECF0]',
        {},
        [className]
      )}
    >
      <ul className='flex items-end overflow-hidden'>
        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] line-clamp-2'>
            Не обучаются
            <br />
            больше месяца
          </p>
          <h3 className='h3 text-[#20233A] truncate'>{dropoutUsersByPeriod.thirtyDaysCount}</h3>
        </li>

        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] line-clamp-2'>
            Не обучаются
            <br />
            14 дней
          </p>
          <h3 className='h3 text-[#20233A] truncate'>{dropoutUsersByPeriod.fourteenDaysCount}</h3>
        </li>

        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] line-clamp-2'>
            Не обучаются
            <br />
            больше недели
          </p>
          <h3 className='h3 text-[#20233A] truncate'>{dropoutUsersByPeriod.sevenDaysCount}</h3>
        </li>
      </ul>
    </header>
  );
};
