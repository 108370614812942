import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { apiCourses, ICourse, ILesson, UpdateOrderData } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { cn } from 'utils';
import styles from './LessonList.module.css';

import { LessonCard } from 'components/organisms';
import { AddLessonForm } from 'components/organisms/ModuleCard/components';
import { SelectCourseStatus } from '../../../SelectCourseStatus';

type LessonListProps = {
  course: ICourse;
  refetchCourse: () => void;
  className?: string;
};

export const LessonList: FC<LessonListProps> = props => {
  const {
    course: { id: courseId, lessons },
    refetchCourse,
    className
  } = props;
  const [isLessonsEdit, setIsLessonsEdit] = useState(false);

  // It is needed for optimistic updates.
  const [currentLessons, setCurrentLessons] = useState<ILesson[]>(
    () => lessons?.sort((a, b) => a.order - b.order) || []
  );

  useEffect(() => {
    const sortedLessons = lessons?.sort((a, b) => a.order - b.order) || [];
    setCurrentLessons(sortedLessons);
  }, [lessons]);

  const queryClient = useQueryClient();
  const { mutate: updateLessonOrdersMutate } = useMutation(
    (data: UpdateOrderData[]) => {
      return apiCourses.updateLessonOrders(data);
    },
    {
      onSuccess(data) {
        const oldCourseData = queryClient.getQueryData<ICourse>(`courses/one/${courseId}`);

        if (oldCourseData) {
          const newLessons = data;

          queryClient.setQueryData<ICourse>(`courses/one/${courseId}`, {
            ...oldCourseData,
            lessons: newLessons
          });
        }

        notifySuc('Порядок уроков изменен');
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = currentLessons.findIndex(lesson => lesson.id === active.id);
      const newIndex = currentLessons.findIndex(lesson => lesson.id === over.id);
      const newLessons = arrayMove(currentLessons, oldIndex, newIndex);
      setCurrentLessons(newLessons);

      const data: UpdateOrderData[] = newLessons.map((lesson, i) => ({
        id: lesson.id,
        newOrder: i + 1
      }));
      updateLessonOrdersMutate(data);
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <header className={styles.header}>
        <h2 className={styles.header__title}>Содержание курса</h2>
        <SelectCourseStatus />
      </header>

      <div className={styles.dropdown} onClick={e => e.stopPropagation()}>
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext items={currentLessons} strategy={verticalListSortingStrategy}>
            <ul className={styles.lessons} onClick={e => e.stopPropagation()}>
              {currentLessons.length > 0 ? (
                <>
                  {currentLessons.map(lesson => (
                    <LessonCard
                      key={lesson.id}
                      lesson={lesson}
                      isLessonsEdit={isLessonsEdit}
                      setIsLessonsEdit={setIsLessonsEdit}
                      refetchCourse={refetchCourse}
                      courseId={courseId}
                    />
                  ))}
                </>
              ) : (
                <span>Пока в данном курсе нет уроков</span>
              )}
            </ul>
          </SortableContext>
        </DndContext>
      </div>

      {!isLessonsEdit && <AddLessonForm courseId={courseId} order={lessons?.length || 0 + 1} />}
    </div>
  );
};
