import { useMutation, useQueryClient } from 'react-query';

import { apiNotifications } from 'api/api-notifications';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { UpdateNotificationDto } from 'models';

export const useUpdateNotificationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateNotificationDto) => {
      return apiNotifications.updateNotification(data);
    },
    {
      onSuccess(data) {
        notifySuc('Уведомление обновлено');

        // invalidate cache for all notifications
        queryClient.invalidateQueries('/notifications');

        // update cache for single notification
        queryClient.invalidateQueries([`/notifications/${data.id}`]);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
