import { FC } from 'react';

import 'react-tooltip/dist/react-tooltip.css';

import { Activity, CurrentCourse } from './components';

const UserStatistics: FC = () => {
  return (
    <div className='flex max-w-full flex-1 flex-col'>
      <header className='mb-[20px] flex h-[88px] items-center pt-[30px]'>
        <h2 className='text-[30px] font-[700] leading-[36px] text-[#20233A]'>Статистика</h2>
      </header>
      <Activity />
      <CurrentCourse />
    </div>
  );
};

export default UserStatistics;
