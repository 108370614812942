import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  COURSES_COLORS,
  COURSE_MAX_ATTEMPTS,
  COURSE_MAX_LEVEL,
  COURSE_MIN_ATTEMPTS,
  COURSE_MIN_LEVEL,
  DEFAULT_COURSE_ATTEMPTS_AMOUNT,
  FORM_ERROR_MESSAGES,
  courseAvatars
} from 'consts';
import { notifySuc } from 'helpers/notification';
import { useGetCourseQuery } from 'hooks/course';
import {
  useAddSpeakersMutation,
  useDeleteSpeakersMutation,
  useUpdateCourseInfoMutation,
  useUploadCourseImageMutation,
  useUploadCourseMiniImageMutation
} from 'hooks/mutations';
import { IColor } from 'models';
import styles from './CourseInfo.module.css';
import { FormValues } from './model';

import { Toggler } from 'components/atoms';
import Button from 'components/atoms/Button';
import ButtonAdd from 'components/atoms/ButtonAdd';
import Input from 'components/atoms/Input';
import Modal from 'components/atoms/Modal';
import { IconClose, IconCopyLink, IconUpload } from 'components/atoms/icons';
import { ColorSelect } from 'components/molecules';
import SpeakerCard from 'components/molecules/SpeakerCard';
import { creatLinkToCourse } from 'consts/links-to-web-app';
import { UpdateCourseData, apiCourses } from '../../../../api';
import { SelectCourseStatus } from '../SelectCourseStatus';
import SkeletonCourseInfo from './SkeletonCourseInfo';
import { AddSpeakerForm, CourseCardPreview, SelectAvatar, SelectAvatarForm } from './components';
import { LearningObjectiveWithIcon } from './components/LearningObjectiveWithIcon';

type Params = {
  courseId: string;
};

const CourseInfo: FC = () => {
  const queryClient = useQueryClient();
  const { courseId } = useParams<keyof Params>() as Params;
  const { data: course, isLoading: courseLoading } = useGetCourseQuery({ courseId });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty, errors }
  } = useForm<FormValues>({
    defaultValues: {
      title: course?.title,
      avatarId: null,
      level: course?.level,
      attempts: course?.attempts || DEFAULT_COURSE_ATTEMPTS_AMOUNT,
      color: course?.color,
      learningObjectives: course?.learningObjectives?.map(objective => ({ value: objective })) || [
        { value: '' }
      ],
      learningObjectivesWithIcons: course?.learningObjectivesWithIcons || [
        { iconLink: '', text: '' }
      ],
      currentSpeakers: course?.speakers || [],
      isShownCarrotQuestChat: course?.isShownCarrotQuestChat || true,
      isShownTheoreticalMaterial: course?.isShownTheoreticalMaterial || true,
      isShownDictionary: course?.isShownDictionary || true,
      bannerImageUrl: course?.bannerImageUrl || null,
      withModules: course?.withModules
    },
    mode: 'onSubmit'
  });

  const [selectedBannerFile, setSelectedBannerFile] = useState<File>();
  const bannerImageUrl = watch('bannerImageUrl');
  const isShownDictionaryField = watch('isShownDictionary');
  const isShownTheoreticalMaterialField = watch('isShownTheoreticalMaterial');

  const {
    fields: objectiveFieldsWithIcons,
    append: appendObjectiveWithIcons,
    remove: removeObjectiveWithIcons
  } = useFieldArray({
    control,
    name: 'learningObjectivesWithIcons'
  });

  const [addSpeakerModalActive, setAddSpeakerModalActive] = useState(false);
  const [selectAvatarModalActive, setSelectAvatarModalActive] = useState<boolean>(false);

  const { mutateAsync: updateCourseInfoMutate, isLoading: updateCourseInfoLoading } =
    useUpdateCourseInfoMutation();
  const { mutateAsync: uploadCourseImageMutate, isLoading: uploadCourseImageLoading } =
    useUploadCourseImageMutation();
  const { mutateAsync: uploadCourseMiniImageMutate, isLoading: uploadCourseMiniImageLoading } =
    useUploadCourseMiniImageMutation();
  const { mutateAsync: addSpeakersMutate, isLoading: addSpeakersLoading } =
    useAddSpeakersMutation();
  const { mutateAsync: deleteSpeakersMutate, isLoading: deleteSpeakersLoading } =
    useDeleteSpeakersMutation();

  const isLoading = useMemo(
    () =>
      updateCourseInfoLoading ||
      uploadCourseImageLoading ||
      uploadCourseMiniImageLoading ||
      addSpeakersLoading ||
      deleteSpeakersLoading,
    [
      updateCourseInfoLoading,
      uploadCourseImageLoading,
      uploadCourseMiniImageLoading,
      addSpeakersLoading,
      deleteSpeakersLoading
    ]
  );

  const onSubmit = async (data: FormValues) => {
    const {
      title,
      avatarId,
      level,
      attempts,
      color,
      learningObjectives,
      currentSpeakers,
      learningObjectivesWithIcons,
      isShownTheoreticalMaterial,
      isShownCarrotQuestChat,
      isShownDictionary,
      bannerImageUrl,
      withModules
    } = data;

    const avatar = courseAvatars.find(({ id }) => id === avatarId);

    const mutationData: UpdateCourseData = {
      courseId,
      body: {
        title,
        level: Number(level),
        attempts: Number(attempts),
        color,
        learningObjectives: learningObjectives.map(({ value }) => value.trim()),
        learningObjectivesWithIcons,
        isShownTheoreticalMaterial,
        isShownCarrotQuestChat,
        isShownDictionary,
        imageUrl: avatar?.url,
        miniImageUrl: avatar?.miniatureUrl,
        withModules
      }
    };

    if (selectedBannerFile) {
      const { url } = await apiCourses.getUrlFromImage(selectedBannerFile);
      mutationData.body.bannerImageUrl = url;
    } else if (!bannerImageUrl) {
      mutationData.body.bannerImageUrl = '';
    } else {
      mutationData.body.bannerImageUrl = course?.bannerImageUrl;
    }

    await updateCourseInfoMutate(mutationData);

    const speakerIds = course?.speakers?.map(({ id }) => id) || [];
    const currentSpeakerIds = currentSpeakers.map(({ id }) => id);

    const speakerToAddIds = currentSpeakerIds.filter(
      currentSpeakerId => !speakerIds.includes(currentSpeakerId)
    );
    if (speakerToAddIds.length > 0) {
      await addSpeakersMutate({ courseId, userIds: speakerToAddIds });
    }

    const speakerToDeleteIds = speakerIds.filter(speaker => !currentSpeakerIds.includes(speaker));
    if (speakerToDeleteIds.length > 0) {
      await deleteSpeakersMutate({ courseId, userIds: speakerToDeleteIds });
    }

    queryClient.invalidateQueries(['courses/all']);
    queryClient.invalidateQueries([`courses/one/${courseId}`]);
  };

  const handleChangeBanner = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target?.files && event.target?.files[0];
    fileUploaded && setSelectedBannerFile(fileUploaded);
  };

  const handleCopyLinkToClipboard = () => {
    navigator.clipboard
      .writeText(creatLinkToCourse(courseId))
      .then(() => notifySuc('Ссылка скопирована'));
  };

  const onAfterChangeIsShownTheoreticalMaterial = (value: boolean) => {
    if (!isShownDictionaryField && !value) {
      setValue('isShownDictionary', true);
    }
  };
  const onAfterChangeIsShownDictionary = (value: boolean) => {
    if (!isShownTheoreticalMaterialField && !value) {
      setValue('isShownTheoreticalMaterial', true);
    }
  };

  useEffect(() => {
    if (!course) return;
    const {
      title,
      level,
      attempts,
      color,
      learningObjectives,
      speakers,
      isShownCarrotQuestChat,
      isShownTheoreticalMaterial,
      learningObjectivesWithIcons,
      isShownDictionary,
      withModules
    } = course;

    reset({
      title,
      avatarId: null,
      level,
      attempts: attempts || DEFAULT_COURSE_ATTEMPTS_AMOUNT,
      color,
      learningObjectives: learningObjectives?.map(objective => ({ value: objective })) || [
        { value: '' }
      ],
      learningObjectivesWithIcons: learningObjectivesWithIcons || [{ iconLink: '', text: '' }],
      currentSpeakers: speakers,
      isShownTheoreticalMaterial,
      isShownCarrotQuestChat,
      isShownDictionary,
      bannerImageUrl: course?.bannerImageUrl || null,
      withModules
    });
  }, [course, reset]);

  useEffect(() => {
    if (!selectedBannerFile) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedBannerFile);
    setValue('bannerImageUrl', objectUrl, { shouldDirty: true });

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedBannerFile]);

  if (courseLoading) return <SkeletonCourseInfo />;
  if (!course) return <h2>Не удалось загрузить курс</h2>;

  return (
    <div className='flex w-[calc(100%-264px)] flex-col'>
      <header className='mb-[28px] flex h-[50px] items-center justify-between gap-4'>
        <h2 className='text-[29px] font-[700] leading-[35px] text-[#20233a]'>Информация курса</h2>
        <div className='flex items-center gap-4'>
          <button className='p-1' onClick={handleCopyLinkToClipboard}>
            <IconCopyLink />
          </button>
          <SelectCourseStatus />
        </div>
      </header>

      <form className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-[30px] flex items-start gap-[10px]'>
          <div className='flex flex-1 flex-col'>
            <label
              htmlFor='title'
              className='mb-[8px] text-[18px] font-[500] leading-[21px] text-[#71798f]'
            >
              Заголовок
            </label>

            <Input
              type='text'
              variant='bordered'
              name='title'
              width='big'
              placeholder='Название курса'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                maxLength: { value: 40, message: 'Максимальная длина 40 символов' },
                validate: {
                  doesntConsistOfSpaces: (value: any) => {
                    return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                  }
                }
              }}
              loading={isLoading}
              className='h-[57px]'
              containerClassName='mb-[8px]'
            />

            <p className='text-[14px] leading-[16px] text-[#71798F]'>Max 40 characters</p>
          </div>

          <div className='flex shrink-0 flex-col'>
            <label
              htmlFor='level'
              className='mb-[8px] text-[18px] font-[500] leading-[21px] text-[#71798f]'
            >
              Уровень курса
            </label>
            <Input
              type='number'
              name='level'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                min: {
                  value: COURSE_MIN_LEVEL,
                  message: `Минимальное значение: ${COURSE_MIN_LEVEL}`
                },
                max: {
                  value: COURSE_MAX_LEVEL,
                  message: `Максимальное значение: ${COURSE_MAX_LEVEL}`
                }
              }}
              onInput={e => {
                const input = e.target as HTMLInputElement;
                if (Number(input.value) < COURSE_MIN_LEVEL) {
                  input.value = COURSE_MIN_LEVEL.toString();
                }
              }}
              loading={isLoading}
              className='h-[56px] max-w-[180px]'
              containerClassName='items-end'
            />
          </div>

          <div className='flex shrink-0 flex-col'>
            <label
              htmlFor='attempts'
              className='mb-[8px] text-[18px] font-[500] leading-[21px] text-[#71798f]'
            >
              Попыток в тестах
            </label>
            <Input
              type='number'
              name='attempts'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                min: {
                  value: COURSE_MIN_ATTEMPTS,
                  message: `Минимальное количество: ${COURSE_MIN_ATTEMPTS}`
                },
                max: {
                  value: COURSE_MAX_ATTEMPTS,
                  message: `Максимальное количество: ${COURSE_MAX_ATTEMPTS}`
                }
              }}
              onInput={e => {
                const input = e.target as HTMLInputElement;
                if (Number(input.value) < COURSE_MIN_ATTEMPTS) {
                  input.value = COURSE_MIN_ATTEMPTS.toString();
                }
              }}
              loading={isLoading}
              className='h-[56px] max-w-[180px]'
              containerClassName='items-end'
            />
          </div>
        </div>

        <div className='mb-[30px] flex gap-[40px]'>
          <label htmlFor='withModules' className='flex cursor-pointer items-center gap-[16px]'>
            <span className='text-[18px] font-[500] leading-[21px] text-[#71798f]'>Модули</span>
            <Toggler name='withModules' control={control} />
          </label>

          <label
            htmlFor='isShownCarrotQuestChat'
            className='flex cursor-pointer items-center gap-[16px]'
          >
            <span className='text-[18px] font-[500] leading-[21px] text-[#71798f]'>Чат</span>
            <Toggler name='isShownCarrotQuestChat' control={control} />
          </label>

          <label
            htmlFor='isShownDictionary'
            className='flex cursor-pointer items-center gap-[16px]'
          >
            <span className='text-[18px] font-[500] leading-[21px] text-[#71798f]'>Словарь</span>
            <Toggler
              name='isShownDictionary'
              control={control}
              afterChange={onAfterChangeIsShownDictionary}
            />
          </label>

          <label
            htmlFor='isShownTheoreticalMaterial'
            className='flex cursor-pointer items-center gap-[16px]'
          >
            <span className='text-[18px] font-[500] leading-[21px] text-[#71798f]'>Теория</span>
            <Toggler
              name='isShownTheoreticalMaterial'
              control={control}
              afterChange={onAfterChangeIsShownTheoreticalMaterial}
            />
          </label>
        </div>

        <div className='mb-[30px] flex flex-1 gap-[60px]'>
          <div className='flex flex-col'>
            <label
              htmlFor=''
              className='mb-[16px] h-[22px] text-[18px] font-[500] leading-[21px] text-[#71798f]'
            >
              Картинка курса
            </label>

            <SelectAvatar
              control={control}
              cover={course?.imageUrl}
              openModal={() => setSelectAvatarModalActive(true)}
            />
          </div>

          <div className='flex flex-col'>
            <label
              htmlFor=''
              className='mb-[16px] h-[22px] text-[18px] font-[500] leading-[21px] text-[#71798f]'
            >
              Отображение курса
            </label>

            <Controller
              control={control}
              name='color'
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <>
                  <ColorSelect
                    colors={COURSES_COLORS}
                    currentColor={COURSES_COLORS.find(({ hex }) => hex === value) || null}
                    setCurrentColor={newValue =>
                      newValue ? onChange((newValue as IColor).hex) : onChange('')
                    }
                    className='mb-[24px]'
                  />

                  {!!error && (
                    <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
                      {error.message}
                    </span>
                  )}
                </>
              )}
            />

            <CourseCardPreview control={control} cover={course?.miniImageUrl} />
          </div>
        </div>

        <div className='mb-8 text-[21px] font-bold text-[#20233A]'>Описание курса</div>

        <LearningObjectiveWithIcon
          control={control}
          name='learningObjectivesWithIcons'
          fields={objectiveFieldsWithIcons}
          append={appendObjectiveWithIcons}
          remove={removeObjectiveWithIcons}
        />

        <div className={styles.speakers}>
          <div className={styles.speakers__header}>
            <h3 className={styles.speakers__title}>Спикеры курса</h3>

            <ButtonAdd
              variant='light'
              type='button'
              title='Добавить спикера'
              onClick={() => setAddSpeakerModalActive(true)}
            />
          </div>

          <div className={styles.speakers__list}>
            <Controller
              control={control}
              name='currentSpeakers'
              rules={{
                required: { value: false, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
              }}
              render={({ field: { value: speakers, onChange } }) => (
                <>
                  {speakers.map(speaker => (
                    <SpeakerCard
                      key={speaker.id}
                      speaker={speaker}
                      deleteSpeaker={() => {
                        const newValue = speakers.filter(({ id }) => id !== speaker.id);
                        onChange(newValue);
                      }}
                    />
                  ))}
                </>
              )}
            />

            {!!errors.currentSpeakers && (
              <span className={styles.errorMessage}>{errors.currentSpeakers.message}</span>
            )}
          </div>
        </div>

        <div className={styles.buttons}>
          <Button
            type='button'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            onClick={reset}
            isDisabled={isLoading || !isDirty}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={isLoading}
          />
        </div>
      </form>

      {addSpeakerModalActive && (
        <Modal onClose={() => setAddSpeakerModalActive(false)}>
          <Controller
            control={control}
            name='currentSpeakers'
            rules={{
              required: { value: false, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
            }}
            render={({ field: { value, onChange } }) => (
              <AddSpeakerForm
                currentSpeakers={value}
                setCurrentSpeakers={newValue => onChange(newValue)}
                onCancel={() => setAddSpeakerModalActive(false)}
              />
            )}
          />
        </Modal>
      )}

      {selectAvatarModalActive && (
        <Modal onClose={() => setSelectAvatarModalActive(false)}>
          <Controller
            control={control}
            name='avatarId'
            render={({ field: { value, onChange } }) => (
              <SelectAvatarForm
                currentAvatarId={value}
                confirmSelectAvatar={avatarId => {
                  onChange(avatarId);
                  setSelectAvatarModalActive(false);
                }}
                onCancel={() => setSelectAvatarModalActive(false)}
              />
            )}
          />
        </Modal>
      )}
    </div>
  );
};

export default CourseInfo;
