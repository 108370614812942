import { useQuery } from 'react-query';

import { GetCourseStudentsDto, apiCourses } from 'api/api-courses';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetCourseUsersQuery = (dto: GetCourseStudentsDto, enabled?: boolean) => {
  const { courseId, page, itemsPerPage, letters, sortBy } = dto;

  return useQuery(
    [`courses/${courseId}/users`, page, itemsPerPage, letters, sortBy],
    async () => await apiCourses.getCourseStudents(dto),
    {
      staleTime: DEFAULT_STALE_TIME,
      onError(error) {
        handleError(error);
      }
    }
  );
};
