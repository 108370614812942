export const findMaximum = (numbers: number[]): number => {
  if (numbers.length === 0) {
    return -1; // Return -1 for an empty array
  }

  let max = numbers[0]; // Initialize max with the first element

  for (let i = 1; i < numbers.length; i++) {
    if (numbers[i] > max) {
      max = numbers[i]; // Update max if a larger value is found
    }
  }

  return max;
};
