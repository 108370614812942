import { FC } from 'react';

import { DEFAULT_CARDS_AMOUNT } from './const';

import { Skeleton } from 'components/atoms';

const SkeletonCards: FC = () => {
  return (
    <>
      {Array.from({ length: DEFAULT_CARDS_AMOUNT }).map((_, i) => (
        <Skeleton key={i} height={160} width={358} border={30} />
      ))}
    </>
  );
};

export default SkeletonCards;
