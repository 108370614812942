import React from 'react';

const IconSound: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2 14V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H5.697C5.89453 9.00005 6.08765 8.9416 6.252 8.83201L10.445 6.03601C10.5957 5.93544 10.771 5.87771 10.952 5.86901C11.133 5.86031 11.313 5.90096 11.4726 5.98661C11.6323 6.07226 11.7658 6.19969 11.8586 6.35529C11.9515 6.51088 12.0004 6.6888 12 6.87001V17.13C12 17.3111 11.9509 17.4887 11.8579 17.644C11.7648 17.7993 11.6314 17.9265 11.4718 18.0119C11.3121 18.0973 11.1323 18.1378 10.9515 18.129C10.7707 18.1202 10.5956 18.0625 10.445 17.962L6.252 15.167C6.08754 15.0578 5.89443 14.9997 5.697 15H3C2.73478 15 2.48043 14.8947 2.29289 14.7071C2.10536 14.5196 2 14.2652 2 14V14Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M16.5 7.5C16.5 7.5 18 9 18 11.5C18 14 16.5 15.5 16.5 15.5M19.5 4.5C19.5 4.5 22 7 22 11.5C22 16 19.5 18.5 19.5 18.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconSound;
