import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetCourseQuery } from 'hooks/course';

import Button from 'components/atoms/Button';
import ButtonAdd from 'components/atoms/ButtonAdd';
import Modal from 'components/atoms/Modal';
import { IconBookPlus, IconMail } from 'components/atoms/icons';
import { SelectCourseStatus } from '../SelectCourseStatus';
import CourseStudentsSkeleton from './CourseStudentsSkeleton';
import { AddUserForm, CourseUsers } from './components';

type Params = {
  courseId: string;
};

const CourseStudents: FC = () => {
  const navigate = useNavigate();
  const { courseId } = useParams<keyof Params>() as Params;
  const { data: course, isLoading: courseLoading } = useGetCourseQuery({ courseId });
  const [addUsersModalActive, setAddUsersModalActive] = useState(false);

  const goToSendInvites = () => {
    navigate(`/courses/${courseId}/invite`);
  };

  if (courseLoading) return <CourseStudentsSkeleton />;
  if (!course) return <h2>Не удалось получить список учеников</h2>;

  return (
    <div className='flex w-[calc(100%-264px)] flex-col'>
      <header className='mb-[34px] flex h-[49px] w-full items-center justify-between'>
        <h2 className='text-[30px] font-[700] leading-[36px] text-[#20233A]'>Ученики</h2>
        <SelectCourseStatus />
      </header>

      <div className='mb-[24px] flex items-center gap-[8px]'>
        <h4 className='mr-auto text-[18px] font-[500] leading-[22px] text-[#20233a]'>
          Ученики, которым доступен курс:
        </h4>

        <button
          type='button'
          className='flex h-[48px] w-[243px] rounded-[62px] font-[500] bg-[#EEF0FF] items-center justify-center gap-[8px]'
          onClick={goToSendInvites}
        >
          <IconBookPlus className='text-[#71798F]' />
          <p>Выслать приглашения</p>
        </button>

        <ButtonAdd
          variant='primary'
          type='button'
          title='Добавить из списка'
          className='h-[48px] rounded-[62px]'
          onClick={() => setAddUsersModalActive(true)}
        />
      </div>

      <CourseUsers course={course} />

      {addUsersModalActive && (
        <Modal onClose={() => setAddUsersModalActive(false)}>
          <AddUserForm course={course} onCancel={() => setAddUsersModalActive(false)} />
        </Modal>
      )}
    </div>
  );
};

export default CourseStudents;
