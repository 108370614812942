import { components, StylesConfig } from 'react-select';

import { IOption } from 'models';

import { IconEye, IconEyeBlocked, IconEyeCrossed } from 'components/atoms/icons';

export enum FILTERS {
  COURSE = 'course',
  CATALOG = 'catalog',
  THEMATIC = 'thematic'
}

export const filterStatusOptions: IOption[] = [
  {
    value: 'Все курсы',
    label: 'Все курсы',
    color: '#20233A'
  },
  {
    value: 'Опубликованные для всех',
    label: 'Опубликованные для всех',
    color: '#20233A',
    Icon: <IconEye className='text-[#66C84D]' />
  },
  {
    value: 'Опубликованные по подписке',
    label: 'Опубликованные по подписке',
    color: '#20233A',
    Icon: <IconEyeBlocked className='text-[#66C84D]' />
  },
  {
    value: 'Не опубликованные',
    label: 'Не опубликованные',
    color: '#20233A',
    Icon: <IconEyeCrossed className='text-[#8C9AAF]' />
  }
];

const { Option, SingleValue: Value } = components;

export const IconOption = (props: any) => {
  const {
    data: { Icon, label }
  } = props;

  return (
    <Option {...props}>
      <div className='flex h-full items-center gap-[9px]'>
        {Icon && Icon}
        <span>{label}</span>
      </div>
    </Option>
  );
};

export const IconValue = (props: any) => {
  const {
    data: { Icon, label }
  } = props;

  return (
    <Value {...props}>
      <div className='flex h-full items-center gap-[9px]'>
        {Icon && Icon}
        <span>{label}</span>
      </div>
    </Value>
  );
};

export const filterSelectStyles: StylesConfig = {
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    height: '56px',
    width: '100%',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'rgba(140, 154, 175, 0.25)',
    '&:hover': {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'rgba(140, 154, 175, 0.25)'
    },
    boxShadow: isFocused ? 'none' : 'inherit',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  menu: styles => ({
    ...styles,
    zIndex: '3',
    minWidth: '300px',
    right: '0'
  }),
  option: (styles, { data, isFocused, isDisabled }) => ({
    ...styles,
    height: '54px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    color: (data as IOption).color,
    backgroundColor: isFocused ? '#EFF1F4' : 'none',
    '&:active': {
      backgroundColor: '#EFF1F4'
    },
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  singleValue: (styles, { data, isDisabled }) => ({
    ...styles,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    color: (data as IOption).color,
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  })
};
