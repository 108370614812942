import { FC } from 'react';
import { Controller, useController } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import styles from './Condition.module.css';

import TextArea from 'components/atoms/TextArea';
import { EditorStep } from 'components/organisms/EditorStep';

interface ConditionProps {
  stepId: string;
  control: any;
  className?: string;
}

const Condition: FC<ConditionProps> = ({ stepId, control, className }) => {
  const { field } = useController({ name: 'condition', control });

  return (
    <div className={`${styles.condition} ${className}`}>
      {!!field.value && (
        <>
          <label className={styles.label}>Условие (устаревшее)</label>
          <TextArea
            name='condition'
            variant='bordered'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                }
              }
            }}
            placeholder='Введите условие задания...'
            rows={4}
            containerClassName='mb-[16px]'
            disabled
            resize={false}
          />
        </>
      )}

      <label className={styles.label}>Условие (Выберите размер шрифта Заголовок 4)</label>
      <Controller
        control={control}
        name='editableCondition'
        rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <div className='z-[2] rounded-[16px] bg-[#eef0ff] px-[24px] py-[16px]'>
              <EditorStep
                data={value}
                onChange={onChange}
                holder={`editableCondition-${stepId}`}
                defaultBlock='header'
                key={`editableCondition-${stepId}`}
              />
            </div>

            {!!error && (
              <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
                {error.message}
              </span>
            )}
          </>
        )}
      />
    </div>
  );
};

export default Condition;
