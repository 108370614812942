import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { apiCourses, ICourse, ILesson, IModule, UpdateOrderData } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import styles from './ModuleCard.module.css';

import {
  CheckMarkIcon,
  IconCopy,
  IconDragHandle,
  IconPaste,
  IconPen,
  IconTrash
} from 'components/atoms/icons';
import Modal from 'components/atoms/Modal';
import { LessonCard } from 'components/organisms';
import { notifySuc } from 'helpers/notification';
import {
  AddLessonForm,
  CopyModuleForm,
  DeleteModuleForm,
  MoveModuleForm,
  UpdateModuleForm
} from './components';

interface ModuleCardProps {
  module: IModule;
  courseId: string;
  refetchCourse: () => void;
}

const ModuleCard: FC<ModuleCardProps> = ({
  courseId,
  module,
  module: { id: moduleId, title, lessons, order },
  refetchCourse
}) => {
  const queryClient = useQueryClient();

  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: moduleId
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const [isOpen, setIsOpen] = useState(true);
  const [isLessonsEdit, setIsLessonsEdit] = useState(false);

  const toogleOpen = () => {
    if (!isOpen) {
      setIsLessonsEdit(false);
    }

    setIsOpen(!isOpen);
  };

  // It is needed for optimistic updates.
  const [currentLessons, setCurrentLessons] = useState<ILesson[]>(() =>
    lessons.sort((a, b) => a.order - b.order)
  );

  useEffect(() => {
    const sortedLessons = lessons.sort((a, b) => a.order - b.order);
    setCurrentLessons(sortedLessons);
  }, [lessons]);

  const { mutate: updateLessonOrdersMutate } = useMutation(
    (data: UpdateOrderData[]) => {
      return apiCourses.updateLessonOrders(data);
    },
    {
      onSuccess(data) {
        const oldCourseData = queryClient.getQueryData<ICourse>(`courses/one/${courseId}`);

        if (oldCourseData) {
          const newLessons = data;

          const newModules = oldCourseData.modules.map(module => {
            if (module.id === moduleId) {
              return { ...module, lessons: newLessons };
            }
            return module;
          });

          queryClient.setQueryData<ICourse>(`courses/one/${courseId}`, {
            ...oldCourseData,
            modules: newModules
          });
        }

        notifySuc('Порядок уроков изменен');
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = currentLessons.findIndex(lesson => lesson.id === active.id);
      const newIndex = currentLessons.findIndex(lesson => lesson.id === over.id);
      const newLessons = arrayMove(currentLessons, oldIndex, newIndex);
      setCurrentLessons(newLessons);

      const data: UpdateOrderData[] = newLessons.map((lesson, i) => ({
        id: lesson.id,
        newOrder: i + 1
      }));
      updateLessonOrdersMutate(data);
    }
  };

  const [isCopyModuleModalActive, setCopyModuleModalActive] = useState(false);
  const [isPasteModuleModalActive, setPasteModuleModalActive] = useState(false);
  const [isUpdateModuleModalActive, setUpdateModuleModalActive] = useState(false);
  const [isDeleteModuleModalActive, setDeleteModuleModalActive] = useState(false);

  return (
    <>
      <li
        className={styles.container}
        ref={setNodeRef}
        style={style}
        {...attributes}
        onClick={toogleOpen}
      >
        <div className={`${styles.header} ${isOpen ? styles.header_open : ''}`}>
          <button className={styles.dragHandle} ref={setActivatorNodeRef} {...listeners}>
            <IconDragHandle color='#A6B0C9' />
          </button>

          <h3 className={styles.title} title={title}>
            {title}
          </h3>

          <div className={styles.controls}>
            <button
              title='Скопировать'
              className={styles.buttonCopy}
              onClick={event => {
                event.stopPropagation();
                setCopyModuleModalActive(true);
              }}
            >
              <IconCopy color='#71798F' />
            </button>

            <button
              title='Переместить'
              className={styles.buttonPaste}
              onClick={event => {
                event.stopPropagation();
                setPasteModuleModalActive(true);
              }}
            >
              <IconPaste color='#71798F' />
            </button>

            <button
              title='Редактировать'
              className={styles.buttonEdit}
              onClick={event => {
                event.stopPropagation();
                setUpdateModuleModalActive(true);
              }}
            >
              <IconPen color='#71798F' />
            </button>

            <button
              title='Удалить'
              className={styles.buttonDelete}
              onClick={e => {
                e.stopPropagation();
                setDeleteModuleModalActive(true);
              }}
            >
              <IconTrash color='#A1AABC' />
            </button>
          </div>

          <button className={styles.buttonOpen}>
            {isOpen ? (
              <CheckMarkIcon className='rotate-90 text-[#71798F]' />
            ) : (
              <CheckMarkIcon className='-rotate-90 text-[#71798F]' />
            )}
          </button>
        </div>

        {isOpen && (
          <div className={styles.dropdown} onClick={e => e.stopPropagation()}>
            <DndContext onDragEnd={handleDragEnd}>
              <SortableContext items={currentLessons} strategy={verticalListSortingStrategy}>
                <ul className={styles.lessons} onClick={e => e.stopPropagation()}>
                  {currentLessons.length > 0 ? (
                    <>
                      {currentLessons.map(lesson => (
                        <LessonCard
                          key={lesson.id}
                          lesson={lesson}
                          isLessonsEdit={isLessonsEdit}
                          setIsLessonsEdit={setIsLessonsEdit}
                          refetchCourse={refetchCourse}
                          courseId={courseId}
                          moduleId={moduleId}
                          moduleTitle={title}
                          moduleOrder={order}
                        />
                      ))}
                    </>
                  ) : (
                    <span>Пока в данном модуле нет уроков</span>
                  )}
                </ul>
              </SortableContext>
            </DndContext>
          </div>
        )}
      </li>

      {isOpen && !isLessonsEdit && (
        <AddLessonForm courseId={courseId} moduleId={moduleId} order={lessons.length + 1} />
      )}

      {isCopyModuleModalActive && (
        <Modal onClose={() => setCopyModuleModalActive(false)}>
          <CopyModuleForm
            courseId={courseId}
            module={module}
            refetchCourse={refetchCourse}
            onCancel={() => setCopyModuleModalActive(false)}
          />
        </Modal>
      )}

      {isPasteModuleModalActive && (
        <Modal onClose={() => setPasteModuleModalActive(false)}>
          <MoveModuleForm
            module={module}
            refetchCourse={refetchCourse}
            onCancel={() => setPasteModuleModalActive(false)}
          />
        </Modal>
      )}

      {isUpdateModuleModalActive && (
        <Modal onClose={() => setUpdateModuleModalActive(false)}>
          <UpdateModuleForm
            moduleId={moduleId}
            currentTitle={title}
            refetchCourse={refetchCourse}
            onCancel={() => setUpdateModuleModalActive(false)}
          />
        </Modal>
      )}

      {isDeleteModuleModalActive && (
        <Modal onClose={() => setDeleteModuleModalActive(false)}>
          <DeleteModuleForm
            courseId={courseId}
            moduleId={moduleId}
            currentTitle={title}
            refetchCourse={refetchCourse}
            onCancel={() => setDeleteModuleModalActive(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default ModuleCard;
