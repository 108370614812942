import { ChangeEvent, FC, useEffect, useMemo } from 'react';
import { IconUpload } from '../icons';

interface InputImageControlledProps {
  value?: File;
  setValue: (file: File) => void;
  name?: string;
  cover?: string;
  accept?: string;
  title?: string;
  subtitle?: string;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
}

const InputImageControlled: FC<InputImageControlledProps> = ({
  value,
  setValue,
  name,
  cover,
  accept,
  title = 'Загрузить изображение',
  subtitle = 'Файл в пропорции 1:1',
  className,
  containerClassName,
  disabled = false,
  loading = false
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target?.files && event.target.files[0];
    fileUploaded && setValue(fileUploaded);
  };

  const imageUrl = useMemo<string | undefined>(() => {
    if (value) {
      const objectUrl = URL.createObjectURL(value);
      return objectUrl;
    }
    return cover;
  }, [cover, value]);

  // clean up the object url on the component unmount
  useEffect(() => {
    return () => {
      if (imageUrl && imageUrl.startsWith('blob:')) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl]);

  return (
    <div className={`flex flex-col ${containerClassName}`}>
      <label
        htmlFor={name}
        style={{ backgroundImage: imageUrl ? `url(${imageUrl})` : 'none' }}
        className={
          'relative h-[200px] w-[200px] cursor-pointer overflow-hidden rounded-[16px] ' +
          `bg-contain bg-clip-padding bg-center bg-no-repeat ${className} ` +
          `${!imageUrl ? 'border-[1px] border-dashed border-[#5770F3] bg-[#EEF0FF]' : ''} `
        }
      >
        <input
          type='file'
          id={name}
          name={name}
          className='hidden'
          accept={accept}
          onChange={handleChange}
          disabled={disabled || loading}
        />

        {imageUrl ? (
          <>
            <span className='absolute inset-0 bg-[rgba(22,32,48,0.42)]' />
            <div className='absolute bottom-[20px] left-[16px] flex w-[168px] flex-col'>
              <IconUpload color='#FFFFFF' className='mb-[24px]' />
              <p className='text-[12px] leading-[14px] text-[#FFFFFF]'>{subtitle}</p>
            </div>
          </>
        ) : (
          <div className='absolute bottom-[20px] left-[16px] flex w-[168px] flex-col'>
            <IconUpload color='#5770F3' className='mb-[24px]' />
            <h6 className='mb-[4px] text-[16px] font-[500] leading-[19px] text-[#162030]'>
              {title}
            </h6>
            <p className='text-[12px] leading-[14px] text-[#71798F]'>{subtitle}</p>
          </div>
        )}
      </label>
    </div>
  );
};

export default InputImageControlled;
