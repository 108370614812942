import { useQuery } from 'react-query';

import { GetCompletedStudentsDto, apiAnalytics } from 'api/api-analytics';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetCompletedStudentsQuery = (data: GetCompletedStudentsDto) => {
  return useQuery(
    ['analytics/course-completion', data.courseId, data.startDate, data.endDate],
    async () => await apiAnalytics.getCompletedStudents(data),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};
