import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import RSelect, { SingleValue } from 'react-select';

import { ICourse } from 'api';
import { DEFAULT_USERS_PER_PAGE } from 'consts';
import { useDebounce } from 'hooks';
import { useGetCourseUsersQuery } from 'hooks/course';
import { IOption } from 'models';
import {
  DEFAULT_PAGE,
  SEARCH_PARAMS,
  USERS_TABLE_HEADERS,
  filterSelectStyles,
  usersPerPageOptions
} from './const';

import SearchField from 'components/atoms/SearchField/SearchField';
import { IconClose } from 'components/atoms/icons';
import SortBottomIcon from 'components/atoms/icons/SortBottomIcon';
import SortDisabledIcon from 'components/atoms/icons/SortDisabledIcon';
import SortTopIcon from 'components/atoms/icons/SortTopIcon';
import BePagintaion from 'components/molecules/BePagination/BePagination';
import { SkeletonRows } from 'pages/UsersPage/components';
import { CourseUserCard } from '../CourseUserCard';
import CourseStudentsSkeleton from '../../CourseStudentsSkeleton';

type CourseUsersProps = {
  course: ICourse;
};

const CourseUsers: FC<CourseUsersProps> = ({ course }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchValue = useMemo(() => {
    return searchParams.get(SEARCH_PARAMS.SEARCH) || '';
  }, [searchParams]);
  const sortBy = useMemo(() => {
    return searchParams.get(SEARCH_PARAMS.SORT_BY) || '';
  }, [searchParams]);
  const usersPerPage = useMemo(() => {
    return Number(searchParams.get(SEARCH_PARAMS.USERS_PER_PAGE)) || DEFAULT_USERS_PER_PAGE;
  }, [searchParams]);
  const currentPage = useMemo(() => {
    return Number(searchParams.get(SEARCH_PARAMS.PAGE)) || DEFAULT_PAGE;
  }, [searchParams]);

  const [value, setValue] = useState<string>(() => searchValue || '');
  const debouncedSearchValue = useDebounce(value, 500);

  useEffect(() => {
    if (debouncedSearchValue) {
      searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));
      searchParams.set(SEARCH_PARAMS.SEARCH, debouncedSearchValue);
      setSearchParams(searchParams);
    } else {
      if (searchParams.has(SEARCH_PARAMS.SEARCH)) {
        searchParams.delete(SEARCH_PARAMS.SEARCH);
        setSearchParams(searchParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  const handleResetFilters = useCallback(() => {
    if (!Array.from(searchParams).length) return;

    setValue('');
    searchParams.delete(SEARCH_PARAMS.SEARCH);
    searchParams.delete(SEARCH_PARAMS.USERS_PER_PAGE);
    searchParams.delete(SEARCH_PARAMS.SORT_BY);
    searchParams.delete(SEARCH_PARAMS.PAGE);
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const {
    data: courseUsersData,
    isLoading: courseUsersLoading,
    error: courseUsersError
  } = useGetCourseUsersQuery({
    courseId: course.id,
    page: currentPage,
    itemsPerPage: usersPerPage,
    letters: searchValue,
    sortBy
  });
  if (courseUsersLoading) return <CourseStudentsSkeleton />;
  if (courseUsersError || !courseUsersData) return <h2>Не удалось получить список учеников</h2>;
  return (
    <div className='relative flex flex-col'>
      <div className='mb-[24px] flex items-center justify-between gap-[12px]'>
        <SearchField
          type='text'
          id='title'
          placeholder='Поиск по имени/email'
          value={value}
          setState={setValue}
          // className='max-w-[360px]'
        />

        <button
          type='button'
          className={
            'shrink-0 mr-auto flex h-[46px] w-[169px] justify-center items-center gap-[4px] ' +
            'rounded-[16px] font-[500] text-[18px] leading-[21px] text-[#8c9aaf]'
          }
          onClick={handleResetFilters}
        >
          <IconClose color='#71798F' />
          Сбросить все
        </button>

        <RSelect
          value={usersPerPageOptions.find(({ value }) => value === usersPerPage)}
          classNamePrefix='custom-select'
          styles={filterSelectStyles}
          placeholder='По 10'
          options={usersPerPageOptions}
          onChange={(newValue: SingleValue<unknown>) => {
            searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));
            const usersPerPage = (newValue as IOption).value;
            searchParams.set(SEARCH_PARAMS.USERS_PER_PAGE, usersPerPage);
            setSearchParams(searchParams);
          }}
          isSearchable={false}
        />
      </div>

      <div className='flex h-[60px] border-y-[2px] border-solid border-[#e9ecf0]'>
        <button
          className={
            'w-[30%] flex px-[16px] py-[18px] justify-between items-center ' +
            'text-[16px] font-[500] leading-[19px] text-[#71798f] ' +
            'border-r-[2px] border-solid border-[#e9ecf0]'
          }
          onClick={() => {
            searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));

            if (sortBy === 'name-asc') {
              searchParams.set(SEARCH_PARAMS.SORT_BY, 'name-desc');
            } else if (sortBy === 'name-desc') {
              searchParams.delete(SEARCH_PARAMS.SORT_BY);
            } else {
              searchParams.set(SEARCH_PARAMS.SORT_BY, 'name-asc');
            }
            setSearchParams(searchParams);
          }}
        >
          {USERS_TABLE_HEADERS[0]}

          {sortBy === 'name-desc' ? (
            <SortBottomIcon />
          ) : sortBy === 'name-asc' ? (
            <SortTopIcon />
          ) : (
            <SortDisabledIcon />
          )}
        </button>

        <button
          className={
            'w-[30%] flex px-[16px] py-[18px] justify-between items-center ' +
            'text-[16px] font-[500] leading-[19px] text-[#71798f] ' +
            'border-r-[2px] border-solid border-[#e9ecf0]'
          }
          onClick={() => {
            searchParams.set(SEARCH_PARAMS.PAGE, String(DEFAULT_PAGE));

            if (sortBy === 'email-asc') {
              searchParams.set(SEARCH_PARAMS.SORT_BY, 'email-desc');
            } else if (sortBy === 'email-desc') {
              searchParams.delete(SEARCH_PARAMS.SORT_BY);
            } else {
              searchParams.set(SEARCH_PARAMS.SORT_BY, 'email-asc');
            }
            setSearchParams(searchParams);
          }}
        >
          {USERS_TABLE_HEADERS[1]}

          {sortBy === 'email-desc' ? (
            <SortBottomIcon />
          ) : sortBy === 'email-asc' ? (
            <SortTopIcon />
          ) : (
            <SortDisabledIcon />
          )}
        </button>

        <span
          className={
            'w-[25%] flex px-[16px] py-[18px] justify-between items-center ' +
            'text-[16px] font-[500] leading-[19px] text-[#71798f] ' +
            'border-r-[2px] border-solid border-[#e9ecf0]'
          }
        >
          {USERS_TABLE_HEADERS[2]}
        </span>

        <span
          className={
            'w-[15%] flex px-[16px] py-[18px] justify-between items-center ' +
            'text-[16px] font-[500] leading-[19px] text-[#71798f] '
          }
        >
          {USERS_TABLE_HEADERS[3]}
        </span>
      </div>

      <div className='mb-[24px] flex flex-col border-b-[2px] border-solid border-[#e9ecf0]'>
        {courseUsersData.users.length > 0 ? (
          <>
            {courseUsersData.users.map((userWithPurchase, index) => (
              <CourseUserCard
                key={userWithPurchase.user.id}
                userWithPurchase={userWithPurchase}
                course={course}
                isEven={index % 2 === 1}
              />
            ))}
          </>
        ) : (
          <span>Результатов не найдено</span>
        )}
      </div>

      {courseUsersData && courseUsersData.pagination.totalItems > usersPerPage && (
        <BePagintaion
          pagination={courseUsersData.pagination}
          setCurrentPage={page => {
            searchParams.set(SEARCH_PARAMS.PAGE, String(page));
            setSearchParams(searchParams);
          }}
        />
      )}
    </div>
  );
};

export default CourseUsers;
