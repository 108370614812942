import { useMutation, useQueryClient } from 'react-query';

import { apiSubscriptions } from 'api';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { AddSubscriptionsDto } from 'models/subscription';

export const useAddSubscriptionsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (dto: AddSubscriptionsDto) => {
      return apiSubscriptions.addSubscriptions(dto);
    },
    {
      onSuccess(_, { userId, usersPage }) {
        notifySuc('Подписки добавлены пользователю');

        if (usersPage) {
          queryClient.invalidateQueries('users/all');
        } else {
          queryClient.invalidateQueries([`purchases/subscriptions/${userId}`]);
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
