import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { COURSES_COLORS, FORM_ERROR_MESSAGES } from 'consts';
import { useCreateDictionaryMutation } from 'hooks/dictionary';
import { IColor } from 'models';
import styles from './CreateThematicDictionaryPage.module.css';

import { InputImage } from 'components/atoms';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import { IconClose, IconNext } from 'components/atoms/icons';
import { ColorSelect } from 'components/molecules';

type FormValues = {
  title: string;
  color?: string;
  image?: File;
};

const CreateThematicDictionaryPage: FC = () => {
  const navigate = useNavigate();
  const { mutate: createDictionaryMutate, isLoading: createDictionaryLoading } =
    useCreateDictionaryMutation();
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FormValues>({ mode: 'onSubmit' });

  const onSubmit = async ({ title, color, image }: FormValues) => {
    createDictionaryMutate(
      {
        title,
        color: color || COURSES_COLORS[0].hex,
        image
      },
      {
        onSuccess: data => {
          navigate(`/dictionaries/thematic/${data.id}`);
        }
      }
    );
  };

  const backToDictionaries = () => {
    navigate('/dictionaries?filter=thematic');
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonBack}>
        <button onClick={backToDictionaries}>
          <IconClose color='#5770F3' />
        </button>
        Создание словаря
      </div>

      <div className={styles.header}>
        <h2 className={styles.title}>Создать тематический словарь</h2>
      </div>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-auto flex gap-[32px]'>
          <InputImage
            type='file'
            name='image'
            control={control}
            rules={{
              required: { value: false, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
            }}
            accept='image/svg+xml'
            title='Загрузить иллюстрацию словаря'
            subtitle='Файл должен быть формата SVG, в пропорции 1:1'
            loading={createDictionaryLoading}
          />

          <div className='flex flex-1 flex-col gap-[40px]'>
            <div className='flex flex-col gap-[12px]'>
              <Input
                type='text'
                name='title'
                variant='dark'
                width='big'
                placeholder='Название тематического словаря'
                className='h-[76px] rounded-[16px] p-[24px] text-[24px] font-[500] leading-[28px]'
                control={control}
                rules={{
                  required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                  maxLength: { value: 64, message: 'Максимальная длина 64 символов' },
                  validate: {
                    doesntConsistOfSpaces: (value: any) => {
                      return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                    }
                  }
                }}
                loading={createDictionaryLoading}
              />
              <p className='text-[18px] leading-[21px] text-[#71798f]'>Max 64 characters</p>
            </div>

            <div className='flex flex-col gap-[16px]'>
              <label className='text-[16px] font-[500] leading-[19px] text-[#71798f]'>
                Выбрать цвет словаря:
              </label>

              <Controller
                control={control}
                name='color'
                rules={{
                  required: { value: false, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <>
                    <ColorSelect
                      colors={COURSES_COLORS}
                      currentColor={COURSES_COLORS.find(({ hex }) => hex === value) || null}
                      setCurrentColor={newValue =>
                        newValue ? onChange((newValue as IColor).hex) : onChange('')
                      }
                      loading={createDictionaryLoading}
                    />

                    {!!error && (
                      <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
                        {error.message}
                      </span>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        </div>

        <div className='flex items-center justify-end'>
          <Button
            type='submit'
            title={'Создать словарь'}
            icon={<IconNext />}
            iconPosition='right'
            className='px-[48px] py-[20px]'
            isDisabled={!isDirty}
            isLoading={createDictionaryLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default CreateThematicDictionaryPage;
