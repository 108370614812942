import { useMutation, useQueryClient } from 'react-query';

import { apiNotifications } from 'api/api-notifications';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { CreateNotificationDto } from 'models';

export const useCreateNotificationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CreateNotificationDto) => {
      return apiNotifications.createNotification(data);
    },
    {
      onSuccess() {
        notifySuc('Уведомление создано');
        queryClient.invalidateQueries(['/notifications']);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
