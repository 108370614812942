import { FC } from 'react';

import { Skeleton } from 'components/atoms';

const SidebarSkeleton: FC = () => {
  return (
    <div className='flex w-[210px] grow flex-col'>
      <Skeleton height={28} width={69} className='mb-[35px]' />
      <Skeleton height={100} width={100} border={6} className='mb-[24px]' />
      <Skeleton height={44} width={200} className='mb-[4px]' />
      <Skeleton height={36} width={180} />
    </div>
  );
};

export default SidebarSkeleton;
