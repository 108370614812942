export const extractDateAndTime = (fullDate: string): { date: string; time: string } => {
  const dateObj = new Date(fullDate);

  // Extract date in the format "YYYY-MM-DD"
  const date = dateObj.toISOString().slice(0, 10);

  // Extract daytime in the format "HH:mm"
  const hours = String(dateObj.getUTCHours()).padStart(2, '0');
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
  const time = `${hours}:${minutes}`;

  return { date, time };
};
