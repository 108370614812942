import { ChangeEvent, InputHTMLAttributes, KeyboardEvent } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  rules?: RegisterOptions;
  disabled?: boolean;
  loading?: boolean;
  afterChange?: (value: boolean) => void;
  className?: string;
};

export type TogglerProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const Toggler = <T extends FieldValues>({
  name,
  control,
  rules = {},
  disabled = false,
  loading = false,
  afterChange,
  className,
  ...rest
}: TogglerProps<T>) => {
  const {
    field: { value, onChange },
    fieldState
  } = useController<T>({
    name,
    control,
    rules
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    onChange(value);
    afterChange?.(value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <label
      htmlFor={name}
      className={
        'h-[24px] w-[48px] shrink-0 p-[2px] rounded-[12px] border-[1px] border-solid ' +
        'select-none transition-colors duration-[150ms] ease-in-out ' +
        `${
          value && !disabled && !fieldState.error
            ? 'bg-[rgba(22,32,48,0.08)]'
            : 'bg-[rgba(22,32,48,0.08)]'
        } ` +
        `${disabled ? 'cursor-auto' : 'cursor-pointer'} ` +
        `${fieldState.error ? '!border-error' : ''} ` +
        `${className ? className : ''}`
      }
    >
      <input
        type='checkbox'
        id={name}
        className='absolute appearance-none'
        disabled={disabled || loading}
        readOnly={disabled || loading}
        checked={value || false}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...rest}
      />

      <div
        className={
          'h-[18px] w-[18px] rounded-[50%] ' +
          'transition-all duration-[150ms] ease-in-out ' +
          `${value ? 'bg-[#66C84D] translate-x-[24px]' : 'bg-[rgba(113,121,143,0.7)]'}`
        }
      />
    </label>
  );
};

export default Toggler;
