import { FC } from 'react';
import { Control, useController } from 'react-hook-form';

import { courseAvatars } from 'consts';
import { FormValues } from '../../model';

interface CourseCardPreviewProps {
  control: Control<FormValues>;
  cover?: string;
}

const CourseCardPreview: FC<CourseCardPreviewProps> = ({ control, cover }) => {
  const {
    field: { value: color }
  } = useController({ name: 'color', control });
  const {
    field: { value: avatarId }
  } = useController({ name: 'avatarId', control });

  return (
    <div
      className='flex h-[160px] w-[335px] items-end justify-end overflow-hidden rounded-[30px]'
      style={{ backgroundColor: color }}
    >
      <img
        src={avatarId ? courseAvatars.find(({ id }) => id === avatarId)?.url || cover : cover}
        alt='course-avatar'
        className='h-[152px] w-[152px]'
      />
    </div>
  );
};

export default CourseCardPreview;
