import { FC } from 'react';

import { DEFAULT_CARDS_AMOUNT } from './const';

import { Skeleton } from 'components/atoms';

const ContentSkeleton: FC = () => {
  return (
    <div className='flex grow flex-col'>
      <Skeleton height={50} width={250} className='mb-[32px]' />
      <Skeleton height={16} width={150} className='mb-[8px]' />
      <Skeleton height={36} width={400} className='mb-[32px]' />

      <ul className='flex flex-col gap-[16px]'>
        {Array.from(Array(DEFAULT_CARDS_AMOUNT)).map((_, i) => (
          <Skeleton key={i} height={64} border={12} />
        ))}
      </ul>
    </div>
  );
};

export default ContentSkeleton;
