import { useQuery } from 'react-query';

import { apiNotifications } from 'api/api-notifications';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';
import { GetNotificationsDto } from 'models';

export const useGetNotificationsQuery = (data: GetNotificationsDto) => {
  const { page, itemsPerPage, search, status, recipient, sortBy } = data;

  return useQuery(
    ['/notifications', page, itemsPerPage, search, status, recipient, sortBy],
    async () => await apiNotifications.getNotifications(data),
    {
      staleTime: DEFAULT_STALE_TIME,
      onError(error) {
        handleError(error);
      }
    }
  );
};
