import { useQuery } from 'react-query';

import { GetNotStartedStudentsByPeriodDto, apiAnalytics } from 'api/api-analytics';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetNotStartedStudentsByPeriodQuery = (data: GetNotStartedStudentsByPeriodDto) => {
  return useQuery(
    [`/analytics/not-started-by-periods/${data.courseId}`],
    async () => await apiAnalytics.getNotStartedStudentsByPeriod(data),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};
