import { FC, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useLesson } from 'hooks';
import styles from './Sidebar.module.css';

import { IconBack } from 'components/atoms/icons';
import SidebarSkeleton from './SidebarSkeleton';

type Params = {
  lessonId: string;
};

const Sidebar: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const moduleTitle = useMemo(() => searchParams.get('moduleTitle'), [searchParams]);
  const { lessonId } = useParams<keyof Params>() as Params;
  const [lesson, lessonLoading] = useLesson({
    lessonId,
    sections: false,
    words: true
  });

  if (lessonLoading) return <SidebarSkeleton />;
  if (!lesson) return <h2>Не удалось загрузить курс</h2>;

  return (
    <div className={styles.container}>
      <button className={styles.sidebar__back} onClick={() => navigate(-1)}>
        <IconBack className='text-[#71798F]' />
        <p>К содержанию курса</p>
      </button>

      <div className={styles.sidebar__info}>
        {moduleTitle && (
          <div className={styles.module}>
            <p className={styles.module__name}>{moduleTitle}</p>
          </div>
        )}

        <div className={styles.lesson}>
          <div className={styles.lesson__image}>
            <img src={lesson.imageUrl} alt='word.svg' />
          </div>

          <p className={styles.lesson__order}>{lesson.subtitle || `Урок ${lesson.order}`}</p>
          <p className={styles.lesson__name}>{lesson.title}</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
