import { AllStudentsDateData } from 'api/general-analytics';

export const fillMissingDays = (data: AllStudentsDateData[], start: Date, end: Date) => {
  const startDate = start.getTime();
  const endDate = end.getTime();

  const filledData: AllStudentsDateData[] = [];
  const currentDate = new Date(startDate);

  while (currentDate.getTime() <= endDate) {
    const matchingData = data.find(item => isSameDay(item.date, currentDate.getTime()));

    if (matchingData) {
      filledData.push(matchingData);
    } else {
      filledData.push({
        date: currentDate.getTime(),
        cumulativeCount: 0
      });
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return filledData;
};

const isSameDay = (timestamp1: number, timestamp2: number): boolean => {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};
