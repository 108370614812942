import { useQuery } from 'react-query';

import {
  GetAllStudentsDto,
  GetCompletedStudentsDto,
  GetDropoutStudentsDto,
  GetNewStudentsDayDto,
  GetNewStudentsDto,
  GetNotStartedStudentsDto,
  apiGeneralAnalytics
} from 'api/general-analytics';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetCoursesInformationQuery = () => {
  return useQuery(
    ['/general-analytics/courses-information'],
    async () => await apiGeneralAnalytics.getCoursesInformation(),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};

export const useGetAllStudentsQuery = (data: GetAllStudentsDto) => {
  return useQuery(
    [
      `/general-analytics/cumulative-student-count?startData=${data.startDate}&endDate=${data.endDate}`
    ],
    async () => await apiGeneralAnalytics.getAllStudents(data),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};

export const useGetAllStudentsByPeriodQuery = () => {
  return useQuery(
    ['/general-analytics/student-count'],
    async () => await apiGeneralAnalytics.getAllStudentsByPeriod(),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};

export const useGetNewStudentsQuery = (data: GetNewStudentsDto) => {
  return useQuery(
    [
      `/general-analytics/students-by-date-range?startDate=${data.startDate}&endDate=${data.endDate}`
    ],
    async () => await apiGeneralAnalytics.getNewStudents(data),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};

export const useGetNewStudentsByPeriodQuery = () => {
  return useQuery(
    ['/general-analytics/students-count-by-periods'],
    async () => await apiGeneralAnalytics.getNewStudentsByPeriod(),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};

export const useGetNewStudentsDayQuery = (data: GetNewStudentsDayDto) => {
  return useQuery(
    [`/general-analytics/students-by-date?date=${data.date}`],
    async () => await apiGeneralAnalytics.getNewStudentsDay(data),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};

export const useGetNotStartedStudentsQuery = (data: GetNotStartedStudentsDto) => {
  return useQuery(
    [`/general-analytics/not-started-studying?startDate=${data.startDate}&endDate=${data.endDate}`],
    async () => await apiGeneralAnalytics.getNotStartedStudents(data),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};

export const useGetNotStartedStudentsByPeriodQuery = () => {
  return useQuery(
    ['/general-analytics/not-started-by-periods'],
    async () => await apiGeneralAnalytics.getNotStartedStudentsByPeriod(),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};

export const useGetDropoutStudentsQuery = (data: GetDropoutStudentsDto) => {
  return useQuery(
    [
      `/general-analytics/inactive-students-by-period?startDate=${data.startDate}&endDate=${data.endDate}`
    ],
    async () => await apiGeneralAnalytics.getDropoutStudents(data),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};

export const useGetDropoutStudentsByPeriodQuery = () => {
  return useQuery(
    ['/general-analytics/inactive-students-by-periods'],
    async () => await apiGeneralAnalytics.getDropoutStudentsByPeriod(),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};

export const useGetCompletedStudentsQuery = (data: GetCompletedStudentsDto) => {
  return useQuery(
    [
      `/general-analytics/completed-course-by-period?startDate=${data.startDate}&endDate=${data.endDate}`
    ],
    async () => await apiGeneralAnalytics.getCompletedStudents(data),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};

export const useGetCompletedStudentsByPeriodQuery = () => {
  return useQuery(
    ['/general-analytics/completed-course-by-periods'],
    async () => await apiGeneralAnalytics.getCompletedStudentsByPeriod(),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};
