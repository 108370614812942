import { useQuery } from 'react-query';

import { GetAllStudentsDto, apiAnalytics } from 'api/api-analytics';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetAllStudentsQuery = (data: GetAllStudentsDto) => {
  return useQuery(
    [
      `/analytics/cumulative-new-students/${data.courseId}?startData=${data.startDate}&endDate=${data.endDate}`
    ],
    async () => await apiAnalytics.getAllStudents(data),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};
