import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './DictionaryPage.module.css';

import { IconBack } from 'components/atoms/icons';
import { Content } from './components';

const DictionaryPage: FC = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/dictionaries');
  };

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <button className={styles.sidebar__back} onClick={goBack}>
          <IconBack className='text-[#71798F]' />

          <p>Назад к списку курсов</p>
        </button>
      </div>

      <Content />
    </div>
  );
};

export default DictionaryPage;
