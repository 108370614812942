import { Skeleton } from 'components/atoms';
import { FC } from 'react';

const SkeletonUpdateUserForm: FC = () => {
  return (
    <div className='flex grow flex-col'>
      <Skeleton height={34} width={400} className='mb-[26px]' />
      <div className='flex w-[404px] flex-col'>
        <Skeleton height={62} width={404} border={12} className='mb-[8px]' />
        <Skeleton height={62} width={404} border={12} className='mb-[8px]' />
        <Skeleton height={62} width={404} border={12} className='mb-[24px]' />
        <div className='mb-[24px] flex gap-[8px]'>
          <Skeleton height={56} width={198} border={14} />
          <Skeleton height={56} width={198} border={14} />
        </div>
        <div className='flex gap-[8px]'>
          <Skeleton height={64} width={198} border={14} />
          <Skeleton height={64} width={198} border={14} />
        </div>
      </div>
    </div>
  );
};

export default SkeletonUpdateUserForm;
