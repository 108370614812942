import { ChangeEvent, TextareaHTMLAttributes, useCallback } from 'react';
import { FieldValues, RegisterOptions, useController, UseControllerProps } from 'react-hook-form';

import styles from './TextArea.module.css';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: 'dark' | 'bordered';
  rows?: number;
  name: string;
  placeholder?: string;
  rules?: RegisterOptions;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  resize?: boolean;
  autoComplete?: 'off' | 'on';
}

export type TextFieldProps<T extends FieldValues> = UseControllerProps<T> & TextareaProps;

const TextArea = <T extends FieldValues>(props: TextFieldProps<T>) => {
  const {
    variant = 'bordered',
    rows = 4,
    name,
    placeholder = '',
    control,
    rules = {},
    className,
    containerClassName,
    disabled,
    loading,
    resize = true,
    autoComplete = 'off',
    ...rest
  } = props;

  const { field, fieldState } = useController<T>({
    name,
    control,
    rules
  });

  const getVariantClass = useCallback(() => {
    switch (variant) {
      case 'dark':
        return styles.textarea_dark;
      case 'bordered':
        return styles.textarea_bordered;
    }
  }, [variant]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    field.onChange(event.target.value);
  };

  return (
    <div
      className={
        `${styles.container} ` +
        `${disabled ? styles.container_disabled : ''} ` +
        `${containerClassName ? containerClassName : ''} `
      }
    >
      {resize && (
        <div
          className={`${styles['pull-tab']} ${!!fieldState.error ? styles['pull-tab_error'] : ''}`}
        ></div>
      )}

      <textarea
        id={name}
        className={
          `${styles.textarea} ` +
          `${getVariantClass()} ` +
          `${resize ? '' : 'resize-none'} ` +
          `${!!fieldState.error ? styles.textarea_error : ''} ` +
          `${className ? className : ''}`
        }
        placeholder={placeholder}
        disabled={disabled || loading}
        readOnly={disabled || loading}
        autoComplete={autoComplete}
        rows={rows}
        value={field.value || ''}
        onChange={handleChange}
        {...rest}
      />

      {!!fieldState.error && (
        <span className={styles.errorMessage}>{fieldState.error.message}</span>
      )}
    </div>
  );
};

export default TextArea;
