import { FC } from 'react';

import { IModule } from 'api/api-courses';
import styles from './MoveModuleCard.module.css';

interface MoveModuleCardProps {
  module: IModule;
  isSelected: boolean;
  select?: () => void;
}

const MoveModuleCard: FC<MoveModuleCardProps> = ({ module: { title }, isSelected, select }) => {
  return (
    <button
      type='button'
      className={`${styles.container} ${isSelected ? styles.container_selected : ''}`}
      onClick={select}
      disabled={!select}
    >
      <h3 className={styles.title}>{title}</h3>
    </button>
  );
};

export default MoveModuleCard;
