import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetUserCourseQuery } from 'hooks/queries';

import Button from 'components/atoms/Button';
import CurrentCourseSkeleton from './CurrentCourseSkeleton';

type Params = {
  id: string;
};

const CurrentCourse: FC = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams<keyof Params>() as Params;
  const { data, isLoading } = useGetUserCourseQuery(userId);

  const goToSection = () => {
    if (!data) return;
    const {
      courseInformation: { course, module, lesson, section }
    } = data;
    if (!course || !module || !lesson || !section) return;

    navigate(
      `/courses/${course.id}/${module.id}/${lesson.id}?moduleOrder=${module.order}&moduleTitle=${module.title}&edit=true&step=1&sectionId=${section.id}`
    );
  };

  if (isLoading) return <CurrentCourseSkeleton />;
  if (!data) return <h2>Пользователь еще не приступил к курсам</h2>;

  return (
    <div className='flex max-w-full flex-col gap-[20px] pb-[40px]'>
      <h6 className='text-[13px] font-[500] uppercase leading-[15px] text-[#20233A]'>
        текущий курс
      </h6>

      <div className='flex items-center gap-[16px]'>
        <img
          src={data.courseInformation.course.imageUrl}
          alt={data.courseInformation.course.title}
          height={64}
          width={64}
          className='h-[64px] w-[64px]'
        />

        <h3
          className={
            'text-[21px] font-[700] leading-[24px] text-[#20233A] ' +
            'overflow-hidden text-ellipsis whitespace-nowrap'
          }
          title={data.courseInformation.course.title}
        >
          {data.courseInformation.course.title}
        </h3>
      </div>

      <div className='flex gap-[10px]'>
        <div className='flex w-[335px] flex-col gap-[16px] rounded-[24px] bg-[#EEF0FF] p-[24px]'>
          <h6 className='text-[13px] font-[500] uppercase leading-[15px] text-[#20233A]'>
            остановился на:
          </h6>

          {data.courseInformation.module && (
            <div className='flex items-end gap-[4px]'>
              <span
                className={
                  'w-[92px] shrink-0 text-start text-[14px] leading-[16px] text-[#515381] ' +
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                }
                title={`Модуль №${data.courseInformation.module.order}.`}
              >
                Модуль №{data.courseInformation.module.order}.
              </span>
              <h5
                className={
                  'text-[16px] font-[500] leading-[19px] text-[#1C2438] ' +
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                }
                title={data.courseInformation.module.title}
              >
                {data.courseInformation.module.title}
              </h5>
            </div>
          )}

          {data.courseInformation.lesson && (
            <div className='flex items-end gap-[4px]'>
              <span
                className={
                  'w-[92px] shrink-0 text-start text-[14px] leading-[16px] text-[#515381] ' +
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                }
                title={`Урок №${data.courseInformation.lesson.order}.`}
              >
                Урок №{data.courseInformation.lesson.order}.
              </span>
              <h5
                className={
                  'text-[16px] font-[500] leading-[19px] text-[#1C2438] ' +
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                }
                title={data.courseInformation.lesson.title}
              >
                {data.courseInformation.lesson.title}
              </h5>
            </div>
          )}

          {data.courseInformation.section && (
            <div className='flex items-end gap-[4px]'>
              <span
                className={
                  'w-[92px] shrink-0 text-start text-[14px] leading-[16px] text-[#515381] ' +
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                }
                title={`Раздел №${data.courseInformation.section.order}.`}
              >
                Раздел №{data.courseInformation.section.order}.
              </span>
              <h5
                className={
                  'text-[16px] font-[500] leading-[19px] text-[#1C2438] ' +
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                }
                title={data.courseInformation.section.title}
              >
                {data.courseInformation.section.title}
              </h5>
            </div>
          )}

          <Button
            type='button'
            variant='primary'
            title='Перейти к разделу'
            className='mt-auto h-[48px] w-[214px] rounded-[62px]'
            onClick={goToSection}
          />
        </div>

        <div className='flex max-w-[335px] flex-col gap-[10px]'>
          <div className='flex flex-col gap-[8px] rounded-[24px] bg-[#EEF0FF] px-[24px] pt-[20px] pb-[13.5px]'>
            <h5 className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>
              Занимался максимум
            </h5>

            <div className='flex items-center gap-[8px]'>
              <h2
                className={
                  'text-[36px] leading-[42px] font-[700] text-[#5770F3] ' +
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                }
                title={`${data.maxConsecutiveDaysStudied} дней подряд`}
              >
                {data.maxConsecutiveDaysStudied || 0}
              </h2>
              <h6 className='shrink-0 text-start text-[13px] font-[500] uppercase leading-[15px] text-[#20233A]'>
                дней подряд
              </h6>
            </div>
          </div>

          <div className='flex flex-col gap-[8px] rounded-[24px] bg-[#EEF0FF] px-[24px] py-[13.5px]'>
            <div className='flex items-center gap-[8px]'>
              <h2
                className={
                  'text-[36px] leading-[42px] font-[700] text-[#5770F3] ' +
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                }
                title={`${data.learnedLessonsCount} уроков пройдено`}
              >
                {data.learnedLessonsCount || 0}
              </h2>
              <h6 className='shrink-0 text-start text-[13px] font-[500] uppercase leading-[15px] text-[#20233A]'>
                уроков пройдено
              </h6>
            </div>
          </div>

          <div className='flex flex-col gap-[8px] rounded-[24px] bg-[#EEF0FF] px-[24px] py-[13.5px]'>
            <div className='flex items-center gap-[8px]'>
              <h2
                className={
                  'text-[36px] leading-[42px] font-[700] text-[#5770F3] ' +
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                }
                title={`${data.learnedWordsCount} слов изучено`}
              >
                {(data.learnedWordsCount || 0) + (data.adoptedWordCount || 0)}
              </h2>
              <h6 className='shrink-0 text-start text-[13px] font-[500] uppercase leading-[15px] text-[#20233A]'>
                слов изучено
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentCourse;
