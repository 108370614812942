import { FC, useMemo } from 'react';
import { Control, useFieldArray } from 'react-hook-form';

import { DEFAULT_MIN_COURSES_TO_ADD } from 'consts';
import { AddSubscriptionsFormValues } from 'models/subscription';
import { timestampToInput } from 'utils';

import ButtonAdd from 'components/atoms/ButtonAdd';
import { CourseCard } from '..';

type CourseCardsProps = {
  control: Control<AddSubscriptionsFormValues>;
  className?: string;
};

const CourseCards: FC<CourseCardsProps> = ({ control, className }) => {
  const {
    fields: courseFields,
    append: appendCourse,
    remove: removeCourse
  } = useFieldArray<AddSubscriptionsFormValues>({
    control,
    name: 'courses'
  });
  const isAllowToRemove = useMemo(
    () => courseFields.length > DEFAULT_MIN_COURSES_TO_ADD,
    [courseFields.length]
  );

  return (
    <div className={`flex flex-col gap-[28px] ${className}`}>
      <ul className='flex flex-col gap-[8px]'>
        {courseFields.map((course, index) => (
          <CourseCard
            key={course.id}
            control={control}
            index={index}
            removeCourse={removeCourse}
            isAllowToRemove={isAllowToRemove}
          />
        ))}
      </ul>

      <ButtonAdd
        variant='light'
        type='button'
        title='Добавить еще курс'
        onClick={() => appendCourse({ from: timestampToInput(Date.now()) })}
      />
    </div>
  );
};

export default CourseCards;
