/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useRef, useState } from 'react';
import { Control, useController } from 'react-hook-form';

import avatarPlaceholder from 'assets/images/svg/avatar.svg';

import { FORM_ERROR_MESSAGES, RECIPIENTS_PER_PAGE } from 'consts';
import { useDebounce } from 'hooks';
import { useClickOutside } from 'hooks/common';
import { useGetUsersQuery } from 'hooks/queries';
import { User } from 'models';
import { resolveUserStatus, sortByMainRole } from 'utils';
import { FormValues } from '../../model';

import Button from 'components/atoms/Button';
import SearchField from 'components/atoms/SearchField/SearchField';
import { IconCheckOff, IconCheckOn } from 'components/atoms/icons';

interface SearchUsersProps {
  control: Control<FormValues>;
}

const SearchUsers: FC<SearchUsersProps> = ({ control }) => {
  const { field } = useController({
    name: 'recipientsList',
    control,
    rules: {
      required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
    }
  });

  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setOpen(false));

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    setSearchValue(value);
  }, [debouncedSearchValue]);

  const { data, isLoading } = useGetUsersQuery({
    page: 1,
    itemsPerPage: RECIPIENTS_PER_PAGE,
    letters: searchValue
  });

  const handleUser = (user: User) => {
    const recipientsList = field.value;

    if (!recipientsList) {
      field.onChange([user]);
      return;
    }

    if (recipientsList.find((recipient: User) => recipient.id === user.id)) {
      const updatedValue = recipientsList.filter((recipient: User) => recipient.id !== user.id);
      field.onChange(updatedValue);
    } else {
      field.onChange([...recipientsList, user]);
    }
  };

  return (
    <div ref={ref} tabIndex={1} className='relative flex flex-col gap-[8px]'>
      <SearchField
        type='text'
        id='search-users'
        placeholder='Искать пользователя по Имени или Email'
        value={value}
        setState={setValue}
        onFocus={() => setOpen(true)}
      />

      <div
        className={
          'absolute top-[54px] w-full flex-col px-[28px] py-[32px] ' +
          'bg-[#FFFFFF] shadow-lg ' +
          `${open ? 'flex' : 'hidden'}`
        }
      >
        <div className='mb-[24px]'>
          {isLoading ? (
            <span>Загрузка...</span>
          ) : (
            <>
              {!!data?.data.length ? (
                <>
                  <ul
                    className={
                      'flex max-h-[302px] flex-col gap-[8px] overflow-y-auto ' +
                      `${data.data.length > 5 ? 'pr-[12px]' : ''}`
                    }
                  >
                    {data.data.map(user => {
                      const isActive = !!field.value?.find(
                        (recipient: User) => recipient.id === user.id
                      );

                      return (
                        <button
                          key={user.id}
                          type='button'
                          className={
                            'flex cursor-pointer rounded-[27px] items-center select-none ' +
                            `${isActive ? 'bg-[#F5F5F5]' : ''}`
                          }
                          onClick={() => handleUser(user)}
                        >
                          <img
                            src={user.avatarUrl || avatarPlaceholder}
                            alt={`${user.name}`}
                            className='mr-[16px] h-[54px] w-[54px] shrink-0'
                          />

                          <p
                            title={user.name}
                            className={
                              'text-[16px] font-[500] leading-[19px] text-[#20233A] text-start ' +
                              'flex-[2] mr-[24px] overflow-hidden whitespace-nowrap text-ellipsis'
                            }
                          >
                            {user.name}
                          </p>

                          <span className='mr-[24px] h-[54px] w-[2px] shrink-0 bg-[#E9ECF0]' />

                          <p
                            title={user.email}
                            className={
                              'text-[16px] leading-[19px] text-[#20233A] text-start ' +
                              'flex-[2] mr-[24px] overflow-hidden whitespace-nowrap text-ellipsis'
                            }
                          >
                            {user.email}
                          </p>

                          <span className='mr-[24px] h-[54px] w-[2px] shrink-0 bg-[#E9ECF0]' />

                          <p
                            title={sortByMainRole(user.roles)[0]}
                            className={
                              'text-[16px] leading-[19px] text-[#20233A] text-start ' +
                              'flex-[1] mr-[24px] overflow-hidden whitespace-nowrap text-ellipsis'
                            }
                          >
                            {sortByMainRole(user.roles)[0]}
                          </p>

                          <span className='mr-[24px] h-[54px] w-[2px] shrink-0 bg-[#E9ECF0]' />

                          <p
                            title={resolveUserStatus(user)}
                            className={
                              'text-[16px] leading-[19px] text-[#20233A] text-start ' +
                              'flex-[1] mr-[24px] overflow-hidden whitespace-nowrap text-ellipsis'
                            }
                          >
                            {resolveUserStatus(user)}
                          </p>

                          <span className='mr-[24px] h-[54px] w-[2px] shrink-0 bg-[#E9ECF0]' />

                          {isActive ? (
                            <IconCheckOn
                              className='mr-[8px] h-[32px] w-[32px] shrink-0'
                              color='#5770F3'
                            />
                          ) : (
                            <IconCheckOff
                              className='mr-[8px] h-[32px] w-[32px] shrink-0'
                              color='#EFF1F4'
                            />
                          )}
                        </button>
                      );
                    })}
                  </ul>
                </>
              ) : (
                <span>Результатов не найдено</span>
              )}
            </>
          )}
        </div>

        <div className='flex gap-[8px] self-end'>
          <Button
            type='button'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            onClick={() => setOpen(false)}
          />
          <Button
            type='button'
            variant='primary'
            title='Добавить'
            className='w-[198px]'
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchUsers;
