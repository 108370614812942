import { apiWords } from 'api';
import { GenerateIcon, IconClose } from 'components/atoms/icons';
import Modal from 'components/atoms/Modal';
import { handleError } from 'helpers/handleError';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import cls from './GeneratedWordImageModal.module.css';
import EmptyWord from 'assets/images/png/emptyGenerate.png';
import Button from 'components/atoms/Button';
import { notifyErr, notifySuc, notifyWarn } from 'helpers/notification';

interface GeneratedWordImageModalProps {
  wordId: string;
  handleClose: () => void;
  afterUpload?: () => void;
  dictionaryId?: string;
  lessonId?: string;
  autoGenerate?: boolean;
}

const GeneratedWordImageModal: FC<GeneratedWordImageModalProps> = ({
  wordId,
  handleClose,
  afterUpload,
  dictionaryId,
  lessonId,
  autoGenerate = false
}) => {
  const [images, setImages] = useState<{ id: string; image: string | null }[]>(
    Array.from({ length: 1 }, (_, index) => ({ id: index.toString(), image: null }))
  );
  const [generatedImage, setGeneratedImage] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: generateImage, isLoading: isGenerateImageLoading } = useMutation(
    () => {
      return apiWords.generateImage({ wordId });
    },
    {
      onSuccess(data) {
        setGeneratedImage(true);
        setImages(data.map((image, index) => ({ id: index.toString(), image })));
        if (lessonId) {
          queryClient.invalidateQueries([`lessons/one/${lessonId}`]);
        }

        if (dictionaryId) {
          queryClient.invalidateQueries([`dictionary/thematic-dictionary/one/${dictionaryId}`]);
        }
      },
      onSettled(data, error: any) {
        if (error) {
          if (error.response.status === 429) {
            notifyWarn(error.response.data.message);
          } else {
            notifyErr(error.response.data.message);
          }
        }
      }
    }
  );

  useEffect(() => {
    if (autoGenerate) {
      generateImage();
    }
  }, [autoGenerate, generateImage]);

  const { mutate: uploadImage, isLoading: isUploadImageLoading } = useMutation(
    () => {
      const selected = '0';
      return apiWords.uploadImageFromUrl({ wordId, url: images[Number(selected)].image! });
    },
    {
      onSuccess() {
        afterUpload?.();
        notifySuc('Изображение загружено');
        handleClose();
        if (lessonId) {
          queryClient.invalidateQueries([`lessons/one/${lessonId}`]);
        }

        if (dictionaryId) {
          queryClient.invalidateQueries([`dictionary/thematic-dictionary/one/${dictionaryId}`]);
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  return (
    <Modal onClose={handleClose}>
      <div className={cls.container}>
        <header className={cls.header}>
          <h2 className={cls.title}>Сгенерировать картинку</h2>
          <button onClick={handleClose}>
            <IconClose />
          </button>
        </header>
        <div className={cls.imagesBlock}>
          {images.map(({ id, image }) => (
            <img key={id} src={image ? image : EmptyWord} className={cls.image} alt='' />
          ))}
        </div>
        {generatedImage && (
          <Button
            title='Сгенерировать другой вариант'
            iconPosition='left'
            icon={<GenerateIcon />}
            onClick={generateImage}
            isLoading={isGenerateImageLoading || isUploadImageLoading}
            className={cls.generateButton}
          />
        )}
        <div className={cls.buttons}>
          <Button title='Отменить' variant='secondary' onClick={handleClose} />
          <Button
            title='Добавить'
            onClick={uploadImage}
            isLoading={isGenerateImageLoading || isUploadImageLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default GeneratedWordImageModal;
