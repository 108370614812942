import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { LessonReview } from 'api/api-analytics';
import avatarPlaceholder from 'assets/images/svg/avatar.svg';

import { IconBad, IconGood, IconNeutral } from 'components/atoms/icons';

// TODO move to hooks folder
export const useLessonReview = (review: LessonReview) => {
  switch (review) {
    case LessonReview.BAD:
      return {
        icon: IconBad,
        color: '#FF4444'
      };
    case LessonReview.NEUTRAL:
      return {
        icon: IconNeutral,
        color: '#71798F'
      };
    case LessonReview.GOOD:
      return {
        icon: IconGood,
        color: '#66C84D'
      };
  }
};

interface UserCardProps {
  user: {
    id: string;
    name: string;
    email: string;
    avatarUrl?: string | undefined;
    review: LessonReview;
  };
}

const UserCard: FC<UserCardProps> = ({ user }) => {
  const navigate = useNavigate();
  const { icon: Icon, color } = useLessonReview(user.review);

  const goToUserPage = (userId: string): void => {
    navigate(`/users/${userId}`);
  };

  return (
    <li>
      <button
        type='button'
        className={
          'flex h-[54px] gap-[24px] w-full select-none items-center justify-between rounded-[27px] text-[#20233a] ' +
          'hover:text-[#5770F3] hover:bg-[#EEF0FF] transition-all duration-300 pr-[8px]'
        }
        onClick={() => goToUserPage(user.id)}
      >
        <div className='flex w-[calc(100%-64px)] items-center gap-[16px] overflow-hidden'>
          <div className='h-[54px] w-[54px]'>
            <img src={user.avatarUrl || avatarPlaceholder} alt={`${user.name}`} />
          </div>
          <p
            title={user.name}
            className={
              'text-[16px] font-[500] leading-[19px] text-inherit ' +
              'max-w-[calc(50%-43px)] overflow-hidden whitespace-nowrap text-ellipsis'
            }
          >
            {user.name}
          </p>
          <p
            title={user.email}
            className={
              'text-[16px] leading-[19px] text-[#71798F] ' +
              'max-w-[calc(50%-43px)] overflow-hidden whitespace-nowrap text-ellipsis'
            }
          >
            {user.email}
          </p>
        </div>

        <Icon color={color} className='h-[32px] w-[32px]' />
      </button>
    </li>
  );
};

export default UserCard;
