import { useMutation, useQueryClient } from 'react-query';

import { apiUsers } from 'api/api-users';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (userId: string) => {
      return apiUsers.deleteUser(userId);
    },
    {
      onSuccess() {
        notifySuc('Пользователь удален');
        queryClient.invalidateQueries('users/all');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
