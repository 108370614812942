/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import {
  IChooseAnswerStep,
  ChooseSentenceStep as IChooseSentenceStep,
  DialogStep as IDialogStep,
  FillingWordsStep as IFillingWordsStep,
  InsertingWordsStep as IInsertingWordsStep,
  ILesson,
  MakingSentenceStep as IMakingSentenceStep,
  IMatchingPairsStep,
  ISection,
  IStep,
  ITextStep,
  IVideoStep,
  STEP_TYPES,
  UpdateStepsOrderData,
  apiCourses
} from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import styles from './EditSection.module.css';

import ConfirmationModal from 'components/atoms/ConfirmationModal';
import Modal from 'components/atoms/Modal';
import { IconPen, IconTrash } from 'components/atoms/icons';
import DeleteSectionForm from 'components/organisms/DeleteSectionForm';
import { notifySuc } from 'helpers/notification';
import { StepCard } from '../StepCard';
import { StepSelect } from '../StepSelect';
import {
  ChooseAnswerStep,
  ChooseSentenceStep,
  DialogStep,
  FillingWordsStep,
  InsertingWordsStep,
  MakingSentenceStep,
  MatchingPairsStep,
  TextStep,
  VideoStep
} from '../Steps';
import { EditSectionForm } from './components';

interface EditSectionProps {
  section: ISection;
  lesson: ILesson;
  currentStepIndex: number | 'potential';
  stepSelect: (step: number | 'potential') => void;
  changeCurrentStepType: (type: STEP_TYPES) => void;
  refetchSection: () => void;
}

export interface PotentialSection {
  type: STEP_TYPES;
  isPotential: boolean;
  [key: string]: any | undefined;
}

const EditSection: FC<EditSectionProps> = ({
  section,
  lesson,
  currentStepIndex,
  stepSelect,
  changeCurrentStepType,
  refetchSection
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { courseId } = useParams();

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);

  const [currentSteps, setCurrentSteps] = useState<IStep[]>(() => {
    if (section && section.steps) {
      return section.steps.sort((a, b) => a.order - b.order);
    }
    return [];
  });

  useEffect(() => {
    if (section && section.steps) {
      const sortedSteps = section.steps.sort((a, b) => a.order - b.order);
      setCurrentSteps(sortedSteps);
    }
  }, [section]);

  const [key, setKey] = useState<number>(0);

  const [currentStep, setCurrentStep] = useState<IStep | null>(() => {
    if (section && section.steps) {
      const step = typeof currentStepIndex === 'number' && section.steps[currentStepIndex - 1];
      return step || null;
    }
    return null;
  });
  useEffect(() => {
    if (section && section.steps) {
      const step = typeof currentStepIndex === 'number' && section.steps[currentStepIndex - 1];
      setCurrentStep(step || null);

      setKey(prev => prev + 1);
    }
  }, [currentStepIndex, section]);

  const toggleEditModalHandle = () => {
    setEditModalIsOpen(prev => !prev);
  };

  const toggleDeleteModalHandle = () => {
    setDeleteModalIsOpen(prev => !prev);
  };

  const [currentStepIsDirty, setCurrentStepIsDirty] = useState<boolean>(false);
  const [currentStepIsValid, setCurrentStepIsValid] = useState<boolean>(false);
  const [onSubmitCallback, setOnSubmitCallback] = useState<Function | null>(null);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false);
  const [switchToStep, setSwitchToStep] = useState<number | 'potential' | null>(null);
  const [potentialStep, setPotentialStep] = useState<PotentialSection>();
  const [secondPotentialStep, setSecondPotentialStep] = useState<PotentialSection>();

  // intercept steps switching and check if current step is dirty.
  const handleStepSelect = (step: number | 'potential') => {
    if (!currentStepIsDirty) {
      stepSelect(step);
    } else {
      setConfirmModalIsOpen(true);
      setSwitchToStep(step);
    }
  };

  const confirmStepSave = async (save: boolean) => {
    setConfirmModalIsOpen(false);

    if (save) {
      if (onSubmitCallback) {
        await onSubmitCallback();

        if (currentStepIsValid) {
          if (switchToStep) {
            if (switchToStep === 'potential' && currentStepIndex === 'potential') {
              stepSelect(switchToStep);
              setPotentialStep(secondPotentialStep);
              setSecondPotentialStep(undefined);
            } else {
              stepSelect(switchToStep);
            }
          }
        }
      }
    } else {
      if (switchToStep) {
        stepSelect(switchToStep);
      }
    }

    setCurrentStepIsDirty(false);
    setCurrentStepIsValid(false);
  };

  const addNewStepToLesson = (data?: PotentialSection) => {
    if (currentStepIndex === 'potential') {
      if (!currentStepIsDirty) {
        setPotentialStep(data);
      } else {
        setSecondPotentialStep(data);
        setSwitchToStep('potential');
        setConfirmModalIsOpen(true);
      }
    } else {
      setPotentialStep(data);

      if (!currentStepIsDirty) {
        handleStepSelect('potential');
      } else {
        setConfirmModalIsOpen(true);
        setSwitchToStep('potential');
      }
    }
  };

  const deletePotentialStepHandle = () => {
    setPotentialStep(undefined);
    setSecondPotentialStep(undefined);
    stepSelect(section.steps.length + 1);
    setCurrentStepIsDirty(false);
    setCurrentStepIsValid(false);
  };

  useEffect(() => {
    if (currentStep && currentStepIndex && currentStepIndex !== 'potential') {
      changeCurrentStepType(currentStep.type);
      return;
    }
    if (
      currentStepIndex &&
      (currentStepIndex = 'potential') &&
      potentialStep &&
      potentialStep.type
    ) {
      changeCurrentStepType(potentialStep.type);
      return;
    }
  }, [currentStepIndex, potentialStep, currentStep]);

  // TODO separate mutation hook
  const { mutate: updateStepOrdersMutate } = useMutation(
    (data: UpdateStepsOrderData) => {
      return apiCourses.updateStepsOrder(data);
    },
    {
      onSuccess() {
        const oldSectionData = queryClient.getQueryData<ISection>(`sections/one/${section.id}`);
        if (oldSectionData) {
          const newSteps = currentSteps.map((step, i) => ({
            ...step,
            order: i + 1
          }));

          queryClient.setQueryData<ISection>(`sections/one/${section.id}`, {
            ...oldSectionData,
            steps: newSteps
          });
        }

        notifySuc('Порядок шагов изменен');
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = currentSteps.findIndex(step => step.id === active.id);
      const newIndex = currentSteps.findIndex(step => step.id === over.id);
      const newSteps = arrayMove(currentSteps, oldIndex, newIndex);
      setCurrentSteps(newSteps);

      setImmediate(() => {
        const newData = newSteps.map((step, i) => ({
          id: step.id,
          newOrder: i + 1,
          type: step.type
        }));
        updateStepOrdersMutate({ data: newData });
      });
    }
  };

  return (
    <div className='flex flex-col'>
      <div className='mb-[8px] text-[14px] leading-[16px] text-[#71798F]'>
        Раздел №{section.order}
      </div>

      <div className='mb-[50px] flex items-center justify-between'>
        <div
          title={section.title}
          className={
            'text-[30px] font-[700] leading-[36px] text-[#20233A] ' +
            'overflow-hidden text-ellipsis whitespace-nowrap'
          }
        >
          {section.title}
        </div>

        <div className='flex'>
          <button className='mr-[20px] p-[6px]' onClick={() => setEditModalIsOpen(true)}>
            <IconPen color='#71798F' />
          </button>
          <button className='p-[6px]' onClick={toggleDeleteModalHandle}>
            <IconTrash color='#71798F' />
          </button>
        </div>
      </div>

      <div className={styles.steps}>
        <div className='mb-[16px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
          Шаги
        </div>

        <div className={styles.steps__list}>
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext items={currentSteps} strategy={horizontalListSortingStrategy}>
              <ul className='flex'>
                {currentSteps.map((step, index) => {
                  return (
                    <StepCard
                      key={step.id}
                      isPotential={false}
                      stepId={step.id}
                      stepIndex={index + 1}
                      stepType={step.type}
                      isSelected={currentStepIndex === index + 1}
                      onSelectStep={handleStepSelect}
                    />
                  );
                })}
              </ul>
            </SortableContext>
          </DndContext>

          {potentialStep && (
            <div className='mr-[12px]'>
              <StepSelect
                isPotential={true}
                stepIndex='potential'
                stepType={potentialStep.type}
                onSelectStep={handleStepSelect}
                isSelected={currentStepIndex === 'potential'}
              />
            </div>
          )}

          <StepSelect onSelectValue={addNewStepToLesson} />
        </div>
      </div>

      {currentStep && currentStep.type === STEP_TYPES.TEXT && (
        <TextStep
          key={currentStep.id}
          stepIndex={currentStepIndex}
          step={currentStep as ITextStep}
          section={section}
          isDirty={currentStepIsDirty}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStepIndex === 'potential' && potentialStep?.type === STEP_TYPES.TEXT && (
        <TextStep
          key={key + 1}
          stepIndex={currentStepIndex}
          step={potentialStep as unknown as ITextStep}
          isPotential
          deletePotentialStep={deletePotentialStepHandle}
          section={section}
          isDirty={currentStepIsDirty}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStep && currentStep.type === STEP_TYPES.VIDEO && (
        <VideoStep
          key={key}
          stepIndex={currentStepIndex}
          step={currentStep as IVideoStep}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStepIndex === 'potential' && potentialStep?.type === STEP_TYPES.VIDEO && (
        <VideoStep
          stepIndex={currentStepIndex}
          step={potentialStep as unknown as IVideoStep}
          isPotential
          deletePotentialStep={deletePotentialStepHandle}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStep && currentStep.type === STEP_TYPES.MATCHING_PAIRS && (
        <MatchingPairsStep
          key={key}
          stepIndex={currentStepIndex}
          step={currentStep as unknown as IMatchingPairsStep}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStepIndex === 'potential' && potentialStep?.type === STEP_TYPES.MATCHING_PAIRS && (
        <MatchingPairsStep
          stepIndex={currentStepIndex}
          step={potentialStep as unknown as IMatchingPairsStep}
          isPotential
          deletePotentialStep={deletePotentialStepHandle}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStep && currentStep.type === STEP_TYPES.CHOOSE_ANSWER && (
        <ChooseAnswerStep
          key={key}
          stepIndex={currentStepIndex}
          step={currentStep as unknown as IChooseAnswerStep}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStepIndex === 'potential' && potentialStep?.type === STEP_TYPES.CHOOSE_ANSWER && (
        <ChooseAnswerStep
          stepIndex={currentStepIndex}
          step={potentialStep as unknown as IChooseAnswerStep}
          isPotential
          deletePotentialStep={deletePotentialStepHandle}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStep && currentStep.type === STEP_TYPES.CHOOSE_SENTENCE && (
        <ChooseSentenceStep
          key={key}
          stepIndex={currentStepIndex}
          step={currentStep as unknown as IChooseSentenceStep}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStepIndex === 'potential' && potentialStep?.type === STEP_TYPES.CHOOSE_SENTENCE && (
        <ChooseSentenceStep
          stepIndex={currentStepIndex}
          step={potentialStep as unknown as IChooseSentenceStep}
          isPotential
          deletePotentialStep={deletePotentialStepHandle}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStep && currentStep.type === STEP_TYPES.MAKING_SENTENCE && (
        <MakingSentenceStep
          key={key}
          stepIndex={currentStepIndex}
          step={currentStep as unknown as IMakingSentenceStep}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStepIndex === 'potential' && potentialStep?.type === STEP_TYPES.MAKING_SENTENCE && (
        <MakingSentenceStep
          stepIndex={currentStepIndex}
          step={potentialStep as unknown as IMakingSentenceStep}
          isPotential
          deletePotentialStep={deletePotentialStepHandle}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStep && currentStep.type === STEP_TYPES.FILLING_MISSING_WORDS && (
        <FillingWordsStep
          key={key}
          stepIndex={currentStepIndex}
          step={currentStep as unknown as IFillingWordsStep}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStepIndex === 'potential' &&
        potentialStep?.type === STEP_TYPES.FILLING_MISSING_WORDS && (
          <FillingWordsStep
            stepIndex={currentStepIndex}
            step={potentialStep as unknown as IFillingWordsStep}
            isPotential
            deletePotentialStep={deletePotentialStepHandle}
            section={section}
            setIsDirty={setCurrentStepIsDirty}
            setIsValid={setCurrentStepIsValid}
            setOnSubmitCallback={setOnSubmitCallback}
            refetchSection={refetchSection}
          />
        )}

      {currentStep && currentStep.type === STEP_TYPES.INSERTING_MISSING_WORDS && (
        <InsertingWordsStep
          key={key}
          stepIndex={currentStepIndex}
          step={currentStep as unknown as IInsertingWordsStep}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStepIndex === 'potential' &&
        potentialStep?.type === STEP_TYPES.INSERTING_MISSING_WORDS && (
          <InsertingWordsStep
            stepIndex={currentStepIndex}
            step={potentialStep as unknown as IInsertingWordsStep}
            isPotential
            deletePotentialStep={deletePotentialStepHandle}
            section={section}
            setIsDirty={setCurrentStepIsDirty}
            setIsValid={setCurrentStepIsValid}
            setOnSubmitCallback={setOnSubmitCallback}
            refetchSection={refetchSection}
          />
        )}

      {currentStep && currentStep.type === STEP_TYPES.DIALOG && (
        <DialogStep
          key={key}
          stepIndex={currentStepIndex}
          step={currentStep as unknown as IDialogStep}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {currentStepIndex === 'potential' && potentialStep?.type === STEP_TYPES.DIALOG && (
        <DialogStep
          stepIndex={currentStepIndex}
          step={potentialStep as unknown as IDialogStep}
          isPotential
          deletePotentialStep={deletePotentialStepHandle}
          section={section}
          setIsDirty={setCurrentStepIsDirty}
          setIsValid={setCurrentStepIsValid}
          setOnSubmitCallback={setOnSubmitCallback}
          refetchSection={refetchSection}
        />
      )}

      {editModalIsOpen && (
        <Modal onClose={toggleEditModalHandle}>
          <EditSectionForm
            sectionId={section.id}
            sectionOrder={section.order}
            sectionTitle={section.title}
            refetchSection={refetchSection}
            onClose={toggleEditModalHandle}
          />
        </Modal>
      )}

      {deleteModalIsOpen && (
        <Modal onClose={() => setDeleteModalIsOpen(false)}>
          <DeleteSectionForm
            lessonId={lesson.id}
            sectionId={section.id}
            currentTitle={section.title}
            onSuccess={() => navigate(`/courses/${courseId}/content`)}
            onCancel={() => setDeleteModalIsOpen(false)}
          />
        </Modal>
      )}

      {confirmModalIsOpen && (
        <ConfirmationModal
          title={
            <span className='text-[22px] font-[700] leading-[26px] text-[#20233A]'>
              Вы отредактировали урок,
              <br />
              но не сохранили его.
            </span>
          }
          text={
            <span className='text-[22px] font-[700] leading-[26px] text-[#5770F3]'>
              Сохранить изменения в уроке?
            </span>
          }
          isDelete={false}
          confirmButtonText='Сохранить'
          cancelButtonText='Не сохранять'
          onConfirm={() => confirmStepSave(true)}
          onCancel={() => confirmStepSave(false)}
          onClose={() => setConfirmModalIsOpen(false)}
        />
      )}
    </div>
  );
};

export default EditSection;
