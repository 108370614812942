import { FC, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { IWord } from 'api/api-words';
import { FORM_ERROR_MESSAGES } from 'consts';
import { useToggle } from 'hooks/common';
import { useDeleteWordMutation, useDeleteWordsFromLessonMutation } from 'hooks/mutations';
import styles from './DeleteWordForm.module.css';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import { IconCheckboxOff, IconCheckboxOn, IconClose } from 'components/atoms/icons';

interface DeleteWordFormProps {
  lessonId: string;
  word: IWord;
  onSuccess: () => void;
  onCancel: () => void;
}

const DeleteWordForm: FC<DeleteWordFormProps> = ({ lessonId, word, onSuccess, onCancel }) => {
  const [fromCatalog, toggleFromCatalog] = useToggle(false);
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FieldValues>({ mode: 'onSubmit' });

  const { mutate: deleteWordsFromLessonMutate, isLoading: deleteWordsFromLessonLoading } =
    useDeleteWordsFromLessonMutation();
  const { mutate: deleteWordFromCatalogMutate, isLoading: deleteWordFromCatalogLoading } =
    useDeleteWordMutation();

  const isLoading = useMemo(
    () => deleteWordsFromLessonLoading || deleteWordFromCatalogLoading,
    [deleteWordsFromLessonLoading, deleteWordFromCatalogLoading]
  );

  const onSubmit = async () => {
    if (fromCatalog) {
      // remove from catalog and all lessons
      deleteWordFromCatalogMutate(
        { wordId: word.id, lessonId },
        {
          onSuccess
        }
      );
    } else {
      // remove only from current lesson
      deleteWordsFromLessonMutate({ lessonId, wordIds: [word.id] }, { onSuccess });
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <span className={styles.title} title={`${word.original} - ${word.translation}`}>
          Удалить слово из урока?
          <br />
          {word.original} - {word.translation}
        </span>
        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <button
        type='button'
        className='mb-[24px] flex select-none gap-[10px]'
        onClick={toggleFromCatalog}
      >
        <div className='h-[24px] w-[24px]'>
          {fromCatalog ? <IconCheckboxOn /> : <IconCheckboxOff />}
        </div>
        <span className='text-start text-[16px] leading-[24px] text-[#20233A]'>
          Удалить слово также полностью из системы (из <b>Общего словаря</b>, <b>всех уроков</b> и{' '}
          <b>тематических словарей</b>)
        </span>
      </button>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {fromCatalog && (
          <div className={styles.confirmation}>
            <p className={styles.text}>
              Чтобы подтвердить, пожалуйста, <b>введите это слово на русском языке</b>:
            </p>

            <Input
              type='text'
              name='value'
              variant='dark'
              width='big'
              placeholder='Слово на русском'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
                validate: {
                  doesntConsistOfSpaces: (value: any) => {
                    return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                  },
                  equalToCurrentValue: (value: string) =>
                    value.trim() === word.translation.trim()
                      ? true
                      : FORM_ERROR_MESSAGES.INCORRECT_WORD
                }
              }}
              loading={isLoading}
            />
          </div>
        )}

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='min-w-[136px]'
            isDisabled={isLoading}
            onClick={onCancel}
          />

          <Button
            type='submit'
            variant='warning'
            title={fromCatalog ? 'Удалить слово из системы' : 'Удалить слово из урока'}
            className='flex-1'
            isLoading={isLoading}
            isDisabled={fromCatalog && !isDirty}
          />
        </div>
      </form>
    </div>
  );
};

export default DeleteWordForm;
