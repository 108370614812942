import { useQuery } from 'react-query';

import { apiCourses } from 'api/api-courses';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

type Params = {
  courseId: string;
  enabled?: boolean;
};

export const useGetCourseQuery = ({ courseId, enabled }: Params) => {
  return useQuery([`courses/one/${courseId}`], async () => await apiCourses.getCourse(courseId), {
    onError(error) {
      handleError(error);
    },
    staleTime: DEFAULT_STALE_TIME,
    enabled
  });
};
