import { FC, useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import emptyAvatar from 'assets/images/png/emptyAvatar.png';
import {
  EMAIL_REGEXP,
  FORM_ERROR_MESSAGES,
  PASSWORD_NO_MATCH_REGEXP,
  USER_ROLES_OPTIONS
} from 'consts';
import { useGetUserInfoQuery } from 'hooks/queries';
import { useUpdateUserInfoMutation } from 'hooks/user';
import styles from './UpdateUser.module.css';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input/Input';
import Modal from 'components/atoms/Modal';
import { IconClose, IconRefresh, IconUpload } from 'components/atoms/icons';
import RolesDropdown from 'components/molecules/RolesDropdown';
import { SelectAvatarForm, SkeletonUpdateUserForm } from '..';

type IParams = {
  id: string;
};

const UpdateUser: FC = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams<keyof IParams>() as IParams;
  const { data: user, isLoading: userLoading } = useGetUserInfoQuery(userId);
  const { mutate: updateUserMutate, isLoading: updateUserLoading } = useUpdateUserInfoMutation();
  const [avatarModalActive, setAvatarModalActive] = useState(false);
  const [updatePassword, setUpdatePassword] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty }
  } = useForm<FieldValues>({
    defaultValues: {
      name: user?.name,
      email: user?.email,
      password: '********',
      roles: user?.roles || [],
      position: user?.position,
      achievements: user?.achievements
    }
  });

  useEffect(() => {
    if (!user) return;

    reset({
      name: user.name,
      email: user.email,
      password: '********',
      roles: user.roles,
      position: user?.position,
      achievements: user?.achievements
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onCancel = () => {
    navigate(`/users/${userId}`);
  };

  const handleNewPassword = () => {
    setUpdatePassword(true);
    setValue('password', '');
  };

  const onSubmit = async (data: FieldValues) => {
    const { name, email, password, roles, position, achievements, isLinearPassageActive } = data;

    updateUserMutate(
      {
        id: userId,
        body: {
          name,
          email,
          password: password.includes('*') ? undefined : password,
          roles,
          position,
          achievements,
          isLinearPassageActive
        }
      },
      {
        onSuccess: onCancel
      }
    );
  };

  if (userLoading) return <SkeletonUpdateUserForm />;
  if (!user) return <h2>Не удалось загрузить информацию о пользователе</h2>;

  return (
    <div className='flex h-full grow flex-col'>
      <button className='flex select-none items-center gap-[16px] self-start' onClick={onCancel}>
        <IconClose className='h-[24px] w-[24px] text-[#5770F3]' />
        <span className='text-[16px] leading-[16px] text-[#20233A]'>Отменить</span>
      </button>

      <form onSubmit={handleSubmit(onSubmit)} className='flex grow flex-col'>
        <header className='mb-[24px] flex items-center justify-between'>
          <h2 className='h-[88px] pt-[30px] text-[30px] font-[700] leading-[36px] text-[#20233A]'>
            Редактировать информацию
          </h2>
        </header>

        <div className='mb-auto flex gap-[32px]'>
          <label className={styles.image} onClick={() => setAvatarModalActive(true)}>
            <img src={user.avatarUrl || emptyAvatar} alt='' />
            <div className={styles.uploadIcon}>
              <IconUpload className='h-[24px] w-[24px]' />
            </div>
          </label>

          <div className='flex w-[360px] flex-col gap-[8px]'>
            <Input
              type='text'
              name='name'
              placeholder='Имя'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                minLength: { value: 1, message: 'Имя должно быть более 1 символа' },
                maxLength: { value: 32, message: 'Имя должно быть не более 32 символов' },
                validate: {
                  doesntConsistOfSpaces: (value: string) => {
                    return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                  }
                }
              }}
            />

            <Input
              type='text'
              name='email'
              placeholder='Email'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                maxLength: { value: 50, message: 'Максимальная длина - 50 символов' },
                validate: {
                  doesntContainSpaces: (value: string) => {
                    return !value.includes(' ') ? true : FORM_ERROR_MESSAGES.DOESNT_CONTAIN_SPACES;
                  },
                  validEmail: (value: string) => {
                    return EMAIL_REGEXP.test(value) ? true : FORM_ERROR_MESSAGES.INCORRECT_EMAIL;
                  }
                }
              }}
            />

            <div className='relative'>
              <Input
                type='text'
                name='password'
                placeholder='Пароль'
                control={control}
                rules={{
                  required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                  minLength: { value: 4, message: 'Пароль должен быть не менее 4 символов' },
                  maxLength: { value: 20, message: 'Пароль должен быть от 4 до 20 символов' },
                  validate: {
                    doesntContainSpaces: (value: string) => {
                      return !value.includes(' ')
                        ? true
                        : FORM_ERROR_MESSAGES.DOESNT_CONTAIN_SPACES;
                    },
                    validPassword: (value: string) => {
                      return !value.match(PASSWORD_NO_MATCH_REGEXP)
                        ? true
                        : FORM_ERROR_MESSAGES.INCORRECT_PASSWORD;
                    }
                  }
                }}
                disabled={!updatePassword}
                isGenerateable
              />

              {!updatePassword && (
                <button type='button' className={styles.buttonRefresh} onClick={handleNewPassword}>
                  <IconRefresh color='#FFFFFF' />
                </button>
              )}
            </div>

            <div className='mb-4 flex flex-col'>
              <Controller
                control={control}
                name='roles'
                rules={{
                  required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
                }}
                render={({ field: { value, onChange } }) => (
                  <RolesDropdown
                    options={USER_ROLES_OPTIONS}
                    values={value}
                    setValues={newRoles => {
                      onChange(newRoles);
                    }}
                    wide
                  />
                )}
              />

              {!!errors.roles && (
                <span className={styles.errorMessage}>{errors.roles.message}</span>
              )}
            </div>

            <div className='flex w-[440px] flex-col gap-4'>
              <div>
                <div className='mb-2 text-[13px] font-medium text-[#71798F]'>ДОЛЖНОСТЬ</div>
                <Input
                  type='text'
                  name='position'
                  placeholder='Должность'
                  control={control}
                  rules={{
                    maxLength: { value: 60, message: 'Поле не должно быть длиннее 60 символов' }
                  }}
                />
              </div>

              <div>
                <div className='mb-2 text-[13px] font-medium text-[#71798F]'>ДОСТИЖЕНИЯ</div>
                <Input
                  type='text'
                  name='achievements'
                  placeholder='Достижения'
                  control={control}
                  rules={{
                    maxLength: { value: 60, message: 'Поле не должно быть длиннее 60 символов' }
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='mt-[24px] flex gap-[8px] self-end'>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            onClick={onCancel}
            isDisabled={updateUserLoading}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={updateUserLoading}
          />
        </div>
      </form>

      {avatarModalActive && (
        <Modal onClose={() => setAvatarModalActive(false)}>
          <SelectAvatarForm onClose={() => setAvatarModalActive(false)} />
        </Modal>
      )}
    </div>
  );
};

export default UpdateUser;
