import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useUpdateNotificationMutation } from 'hooks/mutations';
import { INotification, NOTIFICATION_STATUS } from 'models';
import { FormValues } from '../../model';
import { useStepper } from '../../useStepper';
import { extractDateAndTime, transformData } from '../../utils';

import { Step1, Step2, Step3, Step4, Stepper } from '../../components';

interface UpdateFormProps {
  notification: INotification;
}

const UpdateForm: FC<UpdateFormProps> = ({ notification }) => {
  const navigate = useNavigate();
  const { activeStep, setStep, prevStep, nextStep, isLastStep } = useStepper();
  const { mutate: updateNotificationMutate, isLoading: updateNotificationLoading } =
    useUpdateNotificationMutation();

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      title: notification.title,
      message: notification.message,
      for: notification.for,
      recipientsList: notification.recipientsList,
      recipientsRole: notification.recipientsRole,
      sendTime: notification.sendTime,
      sendDateDay: notification.sendDate && extractDateAndTime(notification.sendDate).date,
      sendDateTime: notification.sendDate && extractDateAndTime(notification.sendDate).time,
      repeatDays: notification.repeatDays,
      repeatTime: notification.repeatTime,
      repeatType: notification.repeatType,
      repeatEveryMonthDay: notification.repeatEveryMonth?.day.toString(),
      repeatEveryMonthTime: notification.repeatEveryMonth?.time.toString(),
      repeatEveryYearDay: notification.repeatEveryYear?.day.toString(),
      repeatEveryYearTime: notification.repeatEveryYear?.time.toString(),
      repeatEveryYearMonth: notification.repeatEveryYear?.month,
      startDate: notification.startDate && extractDateAndTime(notification.startDate).date,
      endDate: notification.endDate && extractDateAndTime(notification.endDate).date,
      status: notification.status
    }
  });

  const onSubmit = (data: FormValues) => {
    if (!isLastStep) {
      nextStep();
    } else {
      const normalizedData = transformData(data);
      updateNotificationMutate(
        { id: notification.id, body: normalizedData },
        { onSuccess: () => navigate('/notifications') }
      );
    }
  };

  const saveAsDraft = () => {
    trigger();
    if (!isValid) return;

    const data = getValues();
    const normalizedData = transformData(data);
    updateNotificationMutate(
      { id: notification.id, body: { ...normalizedData, status: NOTIFICATION_STATUS.DRAFT } },
      { onSuccess: () => navigate('/notifications') }
    );
  };

  return (
    <>
      <div className='mb-[30px]'>
        <Stepper activeStep={activeStep} setStep={setStep} />
      </div>

      <form className='flex flex-1 flex-col' onSubmit={handleSubmit(onSubmit)}>
        {activeStep === 1 && <Step1 control={control} isDirty cover={notification.imageUrl} />}
        {activeStep === 2 && <Step2 control={control} isDirty onPrev={prevStep} />}
        {activeStep === 3 && (
          <Step3
            control={control}
            isDirty
            onPrev={prevStep}
            saveAsDraft={saveAsDraft}
            isLoading={updateNotificationLoading}
          />
        )}
        {activeStep === 4 && (
          <Step4
            control={control}
            isDirty
            getValues={getValues}
            onPrev={prevStep}
            saveAsDraft={saveAsDraft}
            isLoading={updateNotificationLoading}
            isEdit
          />
        )}
      </form>
    </>
  );
};

export default UpdateForm;
