import { useMutation, useQueryClient } from 'react-query';

import { ICourse, apiCourses, updateModuleOrdersDto } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useUpdateModuleOrdersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: updateModuleOrdersDto) => {
      return apiCourses.updateModuleOrders(data);
    },
    {
      onSuccess(data, variables) {
        queryClient.invalidateQueries([`courses/one/${variables.courseId}`], {
          refetchActive: false
        });

        const oldCourseData = queryClient.getQueryData<ICourse>(
          `courses/one/${variables.courseId}`
        );

        if (oldCourseData) {
          queryClient.setQueryData<ICourse>(`courses/one/${variables.courseId}`, {
            ...oldCourseData,
            modules: data
          });
        }

        notifySuc('Порядок модулей изменен');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
