import { useMutation, useQueryClient } from 'react-query';

import { UpdateLessonDto, apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useUpdateLessonMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateLessonDto) => {
      return apiCourses.updateLesson(data);
    },
    {
      onSuccess(_, { courseId }) {
        notifySuc('Урок обновлен');
        queryClient.invalidateQueries([`courses/one/${courseId}`]);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
