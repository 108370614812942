export const courseLearningObjectiveIcons: string[] = [
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(1).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(2).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(3).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(4).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(5).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(6).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(7).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(8).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(9).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(10).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(11).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(12).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(13).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(14).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(15).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(16).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(17).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(18).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(19).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(20).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(21).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(22).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(23).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(24).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(25).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(26).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(27).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(28).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(29).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(30).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(31).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(32).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(33).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(34).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(35).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(36).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(37).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(38).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(39).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(40).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(41).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(42).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(43).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(44).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(45).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(46).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(47).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(48).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(49).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(50).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(51).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(52).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(53).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(54).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(55).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(56).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(57).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(58).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(59).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(60).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(61).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(62).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(63).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(64).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(65).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(66).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(67).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(68).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(69).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(70).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(71).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(72).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(73).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(74).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(75).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(76).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(77).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(78).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(79).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(80).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(81).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(82).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(83).svg',
  'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/learning-objective-icon+(84).svg'
];
