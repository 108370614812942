import { FC } from 'react';
import { Controller, useController } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import styles from './Purpose.module.css';

import TextArea from 'components/atoms/TextArea';
import { EditorStep } from 'components/organisms/EditorStep';

interface PurposeProps {
  stepId: string;
  text: string;
  control: any;
}

const Purpose: FC<PurposeProps> = ({ stepId, text, control }) => {
  const { field } = useController({ name: 'purpose', control });

  return (
    <div className={styles.purpose}>
      {!!field.value && (
        <>
          <label className={styles.label}>{text} (устаревшее)</label>
          <TextArea
            name='purpose'
            variant='bordered'
            control={control}
            rules={{
              required: false,
              maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !value.length || !!value.trim()
                    ? true
                    : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                }
              }
            }}
            rows={4}
            containerClassName='mb-[16px]'
            disabled
            resize={false}
          />
        </>
      )}

      <label className={styles.label}>{text}</label>
      <Controller
        control={control}
        name='editablePurpose'
        rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <div className='rounded-[16px] bg-[#eef0ff] px-[24px] py-[16px]'>
              <EditorStep
                data={value}
                onChange={onChange}
                holder={`editablePurpose-${stepId}`}
                defaultBlock='header'
                key={`editablePurpose-${stepId}`}
              />
            </div>

            {!!error && (
              <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
                {error.message}
              </span>
            )}
          </>
        )}
      />
    </div>
  );
};

export default Purpose;
