import { webAppDomainString } from '../appConfig';

export const creatLinkToCourse = (courseId: string) => {
  const url = new URL(`${webAppDomainString}/navigation-to/course`);
  url.searchParams.set('courseId', courseId);

  return url.toString();
};

export const creatLinkToCourseLesson = ({
  courseId,
  moduleId,
  lessonId
}: {
  courseId: string;
  moduleId?: string;
  lessonId: string;
}) => {
  const url = new URL(`${webAppDomainString}/navigation-to/course`);
  url.searchParams.set('courseId', courseId);
  moduleId && url.searchParams.set('moduleId', moduleId);
  url.searchParams.set('lessonId', lessonId);

  return url.toString();
};

export const creatLinkToCourseSection = ({
  courseId,
  moduleId,
  lessonId,
  sectionId
}: {
  courseId: string;
  moduleId?: string;
  lessonId: string;
  sectionId: string;
}) => {
  const url = new URL(`${webAppDomainString}/navigation-to/course`);
  url.searchParams.set('courseId', courseId);
  moduleId && url.searchParams.set('moduleId', moduleId);
  url.searchParams.set('lessonId', lessonId);
  url.searchParams.set('sectionId', sectionId);

  return url.toString();
};

export const creatLinkToCourseVocabulary = ({ courseId }: { courseId: string }) => {
  const url = new URL(`${webAppDomainString}/navigation-to/vocabulary`);
  url.searchParams.set('courseId', courseId);

  return url.toString();
};

export const creatLinkToCourseVocabularyLesson = ({
  courseId,
  moduleId,
  lessonId
}: {
  courseId: string;
  moduleId?: string;
  lessonId: string;
}) => {
  const url = new URL(`${webAppDomainString}/navigation-to/vocabulary`);
  url.searchParams.set('courseId', courseId);
  moduleId && url.searchParams.set('moduleId', moduleId);
  url.searchParams.set('lessonId', lessonId);

  return url.toString();
};

export const createLinkToThematicDictionary = ({ vocabularyId }: { vocabularyId: string }) => {
  const url = new URL(`${webAppDomainString}/navigation-to/vocabulary/thematic`);
  url.searchParams.set('vocabularyId', vocabularyId);

  return url.toString();
};
