import React, { Dispatch, SetStateAction } from 'react';

import { IColor } from 'models';
import styles from './ColorSelect.module.css';

interface ColorSelectProps {
  colors: IColor[];
  currentColor: IColor | null;
  setCurrentColor: Dispatch<SetStateAction<IColor | null>>;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
}

const ColorSelect: React.FC<ColorSelectProps> = ({
  colors,
  currentColor,
  setCurrentColor,
  className = '',
  disabled = false,
  loading = false
}) => {
  const handleColorPick = (color: IColor) => {
    if (color === currentColor) {
      setCurrentColor(null);
    } else {
      setCurrentColor(color);
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <label className='text-[16px] font-[500] leading-[19px] text-[#71798f]'>Цвет:</label>

      <ul className={styles.list}>
        {colors.map(color => (
          <li
            key={color.name}
            title={color.name}
            className={`${styles.item} ${color === currentColor ? styles.item_active : ''}`}
          >
            <button
              type='button'
              className={styles.button}
              style={{ backgroundColor: color.hex, borderColor: color.hex }}
              onClick={() => handleColorPick(color)}
              disabled={disabled || loading}
            ></button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ColorSelect;
