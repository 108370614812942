import React from 'react';

const IconPlus: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19.3125 12.25C19.3125 12.7422 18.9258 13.0938 18.4688 13.0938H12.8438V18.7188C12.8438 19.2109 12.457 19.5977 12 19.5977C11.5078 19.5977 11.1562 19.2109 11.1562 18.7188V13.0938H5.53125C5.03906 13.0938 4.6875 12.7422 4.6875 12.2852C4.6875 11.793 5.03906 11.4062 5.53125 11.4062H11.1562V5.78125C11.1562 5.32422 11.5078 4.97266 12 4.97266C12.457 4.97266 12.8438 5.32422 12.8438 5.78125V11.4062H18.4688C18.9258 11.4062 19.3125 11.793 19.3125 12.25Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconPlus;
