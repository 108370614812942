import { IOption, NOTIFICATION_STATUS } from 'models';

export const notificationStatusOptions: IOption[] = [
  {
    value: NOTIFICATION_STATUS.ACTIVE,
    label: 'Активный',
    color: '#66C84D'
  },
  {
    value: NOTIFICATION_STATUS.DRAFT,
    label: 'Черновик',
    color: '#5770F3'
  },
  {
    value: NOTIFICATION_STATUS.COMPLETED,
    label: 'Завершён',
    color: '#71798F'
  }
];
