import React from 'react';

const IconMatchingPairs: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g id='Icons'>
      <rect
        id='Rectangle 664'
        opacity='0.5'
        x='5'
        y='6'
        width='6'
        height='3'
        rx='1'
        fill='currentColor'
      />
      <rect id='Rectangle 669' x='13' y='6' width='6' height='3' rx='1' fill='currentColor' />
      <rect id='Rectangle 667' x='5' y='11' width='6' height='3' rx='1' fill='currentColor' />
      <rect
        id='Rectangle 670'
        opacity='0.5'
        x='13'
        y='11'
        width='6'
        height='3'
        rx='1'
        fill='currentColor'
      />
      <rect
        id='Rectangle 668'
        opacity='0.5'
        x='5'
        y='16'
        width='6'
        height='3'
        rx='1'
        fill='currentColor'
      />
      <rect
        id='Rectangle 671'
        opacity='0.5'
        x='13'
        y='16'
        width='6'
        height='3'
        rx='1'
        fill='currentColor'
      />
    </g>
  </svg>
);

export default IconMatchingPairs;
