import { useMutation, useQueryClient } from 'react-query';

import { DeleteCourseUsersDto, apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useDeleteCourseUsersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: DeleteCourseUsersDto) => {
      return apiCourses.deleteCourseUsers(data);
    },
    {
      onSuccess(_, { courseId }) {
        notifySuc('Курс удален у пользователей');

        queryClient.invalidateQueries([`courses/${courseId}/users`]);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
