import { FC, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RSelect, { SingleValue } from 'react-select';

import catalogSVG from 'assets/images/svg/catalog.svg';
import { IOption } from 'models';
import styles from './DictionariesPage.module.css';
import { FILTERS, IconOption, IconValue, filterSelectStyles, filterStatusOptions } from './const';

import { CourseDictionaries, ThematicDictionaries } from './components';

const DictionariesPage: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activeFilter = useMemo(() => searchParams.get('filter'), [searchParams]);
  const [selectedStatus, setSelectedStatus] = useState('Все курсы');

  const handleFilter = (filter: FILTERS) => {
    if (activeFilter === filter) {
      navigate('/dictionaries', { replace: true });
    } else {
      navigate(`/dictionaries?filter=${filter}`, { replace: true });
    }
  };

  const goToCatalog = () => {
    navigate('/dictionaries/catalog');
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className='flex items-center gap-[20px]'>
          <p className='text-[36px] font-[700] leading-[42px] text-[#20233A]'>Словари</p>
          <div className='flex gap-[8px]'>
            <button
              type='button'
              className={
                'rounded-[12px] px-[24px] py-[16px] text-[16px] leading-[19px] font-[500] ' +
                'transition-all duration-300 select-none ' +
                `${
                  !activeFilter || activeFilter === FILTERS.COURSE
                    ? 'bg-[#162030] text-[#FFFFFF]'
                    : 'bg-[#EEF0FF] text-[#20233A]'
                }`
              }
              onClick={() => handleFilter(FILTERS.COURSE)}
            >
              Словари курсов
            </button>
            <button
              type='button'
              className={
                'rounded-[12px] px-[24px] py-[16px] text-[16px] leading-[19px] font-[500] ' +
                'transition-all duration-300 select-none ' +
                `${
                  activeFilter === FILTERS.CATALOG
                    ? 'bg-[#162030] text-[#FFFFFF]'
                    : 'bg-[#EEF0FF] text-[#20233A]'
                }`
              }
              onClick={() => handleFilter(FILTERS.CATALOG)}
            >
              Общий словарь
            </button>
            <button
              type='button'
              className={
                'rounded-[12px] px-[24px] py-[16px] text-[16px] leading-[19px] font-[500] ' +
                'transition-all duration-300 select-none ' +
                `${
                  activeFilter === FILTERS.THEMATIC
                    ? 'bg-[#162030] text-[#FFFFFF]'
                    : 'bg-[#EEF0FF] text-[#20233A]'
                }`
              }
              onClick={() => handleFilter(FILTERS.THEMATIC)}
            >
              Тематические словари
            </button>
          </div>
        </div>

        {(!activeFilter || activeFilter === FILTERS.COURSE) && (
          <RSelect
            value={filterStatusOptions.find(({ value }) => value === selectedStatus)}
            classNamePrefix='custom-select'
            styles={filterSelectStyles}
            placeholder={'Все курсы'}
            options={filterStatusOptions}
            components={{ Option: IconOption, SingleValue: IconValue }}
            onChange={(newValue: SingleValue<unknown>) => {
              setSelectedStatus((newValue as IOption).value);
            }}
            isSearchable={false}
          />
        )}
      </header>

      <div className='flex flex-wrap gap-[20px]'>
        {(!activeFilter || activeFilter === FILTERS.COURSE) && (
          <CourseDictionaries selectedStatus={selectedStatus} />
        )}

        {activeFilter === FILTERS.CATALOG && (
          <div className={styles.catalogCard} onClick={goToCatalog}>
            <header className={styles.catalogCard__header}>
              <span className={styles.catalogCard__level}>Каталог слов</span>
            </header>

            <div className={styles.catalogCard__content}>
              <div className={styles.catalogCard__titleAndButton}>
                <p className={styles.catalogCard__title}>Общий словарь</p>

                <button className={styles.buttonGo}>Перейти</button>
              </div>

              <div className={styles.catalogCard__image}>
                <img src={catalogSVG} alt='catalog.svg' />
              </div>
            </div>
          </div>
        )}

        {activeFilter === FILTERS.THEMATIC && <ThematicDictionaries />}
      </div>
    </div>
  );
};

export default DictionariesPage;
