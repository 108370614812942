import React from 'react';

const IconExit: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.60345 3.45455C4.05015 3.45455 3.51952 3.66526 3.12828 4.04033C2.73704 4.41541 2.51724 4.92411 2.51724 5.45455V14.5455C2.51724 15.0759 2.73704 15.5846 3.12828 15.9597C3.51952 16.3347 4.05015 16.5455 4.60345 16.5455H8.39655C8.94985 16.5455 9.48048 16.3347 9.87172 15.9597C10.263 15.5846 10.4828 15.0759 10.4828 14.5455V13.6364C10.4828 13.2347 10.8224 12.9091 11.2414 12.9091C11.6604 12.9091 12 13.2347 12 13.6364V14.5455C12 15.4617 11.6204 16.3403 10.9446 16.9882C10.2688 17.636 9.35224 18 8.39655 18H4.60345C3.64775 18 2.7312 17.636 2.05543 16.9882C1.37965 16.3403 1 15.4617 1 14.5455V5.45455C1 4.53834 1.37965 3.65967 2.05543 3.01181C2.7312 2.36396 3.64775 2 4.60345 2H8.39655C9.35225 2 10.2688 2.36396 10.9446 3.01181C11.6204 3.65967 12 4.53834 12 5.45455V6.36364C12 6.7653 11.6604 7.09091 11.2414 7.09091C10.8224 7.09091 10.4828 6.7653 10.4828 6.36364V5.45455C10.4828 4.92411 10.263 4.4154 9.87172 4.04033C9.48048 3.66526 8.94985 3.45455 8.39655 3.45455H4.60345Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.8407 5.24408C14.1411 4.91864 14.6281 4.91864 14.9285 5.24408L18.7747 9.41074C18.919 9.56703 19 9.77899 19 10C19 10.221 18.919 10.433 18.7747 10.5893L14.9285 14.7559C14.6281 15.0814 14.1411 15.0814 13.8407 14.7559C13.5403 14.4305 13.5403 13.9028 13.8407 13.5774L16.3737 10.8333H5.76923C5.3444 10.8333 5 10.4602 5 10C5 9.53976 5.3444 9.16667 5.76923 9.16667H16.3737L13.8407 6.42259C13.5403 6.09715 13.5403 5.56951 13.8407 5.24408Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconExit;
