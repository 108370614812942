import { FC, useEffect, useState } from 'react';

import avatarPlaceholder from 'assets/images/svg/avatar.svg';

import { HistoryRecord, StepChange } from 'models';
import { extractDateAndTime } from 'pages/create-edit-notification/utils';
import { formatDate } from 'pages/NotificationsPage/utils';
import { getMainRole } from 'utils';

import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';
import { TextStepChange, VideoStepChange } from './components';

interface ChangeHistoryProps {
  stepId: string;
  applyVersion: (data: StepChange) => Promise<void>;
  onClose: () => void;
  isLoading: boolean;
}

const ChangeHistory: FC<ChangeHistoryProps> = ({ stepId, onClose, applyVersion, isLoading }) => {
  const [currentChanges, setCurrentChanges] = useState<StepChange[]>([]);
  const [currentChange, setCurrentChange] = useState<StepChange>();

  useEffect(() => {
    const changeHistory = localStorage.getItem('changeHistory');
    if (!changeHistory) return;

    const records = JSON.parse(changeHistory) as HistoryRecord[];
    const record = records.find(record => record.stepId === stepId);

    if (!record) return;
    setCurrentChanges(record.changes);
    setCurrentChange(record.changes[0]);
  }, [stepId]);

  const handleApplyVersion = () => {
    if (!currentChange) return;
    applyVersion(currentChange);
  };

  return (
    <div className='flex w-[1057px] flex-col'>
      <header className='mb-[24px] flex h-[32px] items-center justify-between gap-[8px] pr-[8px]'>
        <h6 className='text-[22px] font-[700] leading-[26px] text-[#20233A]'>История изменений</h6>
        <button type='button' className='h-[24px] w-[24px]' onClick={onClose}>
          <IconClose color='#71798F' />
        </button>
      </header>

      {!!currentChanges.length ? (
        <div className='flex gap-[24px]'>
          <ul className='flex max-h-[calc(80vh-120px)] flex-col gap-[10px] overflow-auto'>
            {currentChanges.map((change, index) => (
              <li key={index}>
                <button
                  type='button'
                  className={
                    'flex p-[24px] w-[300px] rounded-[24px] transition-all duration-300 ' +
                    `${
                      currentChange?.time === change.time
                        ? 'bg-[#EEF0FF] border-[1px] border-solid border-[#EEF0FF]'
                        : ' border-[1px] border-solid border-[#E9ECF0]'
                    }`
                  }
                  onClick={() => setCurrentChange(change)}
                >
                  <img
                    src={change.user.avatarUrl || avatarPlaceholder}
                    alt={`${change.user.name}`}
                    className='mr-[12px] h-[40px] w-[40px] overflow-hidden rounded-full'
                  />

                  <div className='flex flex-col'>
                    <p className='mb-[2px] text-start text-[16px] font-[600] leading-[19px] text-[#5770F3]'>
                      {change.user.name}
                    </p>

                    <p className='mb-[19px] text-start text-[16px] font-[500] leading-[19px] text-[#71798F]'>
                      {getMainRole(change.user.roles)}
                    </p>

                    <div className='flex gap-[9px]'>
                      <p className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>
                        {formatDate(extractDateAndTime(change.time).date)}
                      </p>

                      <p className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>
                        {extractDateAndTime(change.time).time}
                      </p>
                    </div>
                  </div>
                </button>
              </li>
            ))}
          </ul>

          <div className='flex flex-1 flex-col'>
            <div className='mb-[24px] flex'>
              {currentChange?.type === 'text' && (
                <TextStepChange step={currentChange.step} time={currentChange.time} />
              )}
              {currentChange?.type === 'video' && <VideoStepChange step={currentChange.step} />}
            </div>

            <div className='flex justify-start gap-[8px]'>
              <Button
                type='button'
                variant='primary'
                title='Использовать эту версию'
                isLoading={isLoading}
                isDisabled={!currentChange}
                onClick={handleApplyVersion}
              />
              <Button
                type='button'
                variant='secondary'
                title='Закрыть'
                className='w-[198px]'
                isDisabled={isLoading}
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      ) : (
        <h2>Список изменений пуст</h2>
      )}
    </div>
  );
};

export default ChangeHistory;
