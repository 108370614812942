import { ThematicDictionary, ValidatedWord, ValidatedWordData, ValidatedWordsData } from 'models';
import { instance } from './api';

export type CreateDictionaryDto = {
  title: string;
  color: string;
  image?: File;
};

export type UpdateDictionaryDto = {
  dictionaryId: string;
  body: {
    title?: string;
    color?: string;
  };
};

export type UploadImageDto = {
  dictionaryId: string;
  body: {
    file: File;
  };
};

export type AddWordsDto = {
  dictionaryId: string;
  body: {
    wordIds: string[];
  };
};

export type DeleteDictionaryWordsDto = {
  dictionaryId: string;
  body: {
    wordIds: string[];
  };
};

export type CreateAndAddWordsDto = {
  dictionaryId: string;
  body: {
    words: ValidatedWordData[];
  };
};

export type GetWordsFromFileDto = {
  dictionaryId: string;
  body: {
    file: File;
  };
};

export type ValidataWordDto = {
  dictionaryId: string;
  body: ValidatedWordData;
};

export const apiDictionary = {
  createDictionary: async ({ title, image, color }: CreateDictionaryDto) => {
    const formData = new FormData();

    formData.append('title', title);
    image && formData.append('image', image);
    formData.append('color', color);

    const response = await instance.post<ThematicDictionary>(
      'dictionary/thematic-dictionary',
      formData
    );
    return response.data;
  },
  updateDictionary: async ({ dictionaryId, body }: UpdateDictionaryDto) => {
    const response = await instance.patch<ThematicDictionary>(
      `dictionary/thematic-dictionary/${dictionaryId}`,
      body
    );
    return response.data;
  },
  uploadImage: async ({ dictionaryId, body }: UploadImageDto) => {
    const formData = new FormData();
    formData.append('image', body.file);

    const response = await instance.patch<ThematicDictionary>(
      `dictionary/thematic-dictionary/${dictionaryId}/upload-image`,
      formData
    );
    return response.data;
  },
  deleteDictionary: async (dictionaryId: string) => {
    const response = await instance.delete<ThematicDictionary>(
      `dictionary/thematic-dictionary/${dictionaryId}`
    );
    return response.data;
  },
  getDictionary: async (dictionaryId: string) => {
    const response = await instance.get<ThematicDictionary>(
      `dictionary/thematic-dictionary/one/${dictionaryId}`
    );
    return response.data;
  },
  getDictionaries: async () => {
    const response = await instance.get<ThematicDictionary[]>('dictionary/thematic-dictionary/all');
    return response.data;
  },
  addWords: async ({ dictionaryId, body }: AddWordsDto) => {
    const response = await instance.patch<ThematicDictionary>(
      `dictionary/thematic-dictionary/${dictionaryId}/words`,
      body
    );
    return response.data;
  },
  deleteWords: async ({ dictionaryId, body }: DeleteDictionaryWordsDto) => {
    const response = await instance.delete<ThematicDictionary>(
      `dictionary/thematic-dictionary/${dictionaryId}/words`,
      {
        data: body
      }
    );
    return response.data;
  },
  createAndAddWords: async ({ dictionaryId, body }: CreateAndAddWordsDto) => {
    const response = await instance.post<ThematicDictionary>(
      `dictionary/thematic-dictionary/${dictionaryId}/words`,
      body
    );
    return response.data;
  },
  getWordsFromFile: async ({ dictionaryId, body }: GetWordsFromFileDto) => {
    const formData = new FormData();
    formData.append('file', body.file);

    const response = await instance.post<ValidatedWordsData>(
      `dictionary/thematic-dictionary/${dictionaryId}/words/file`,
      formData
    );
    return response.data;
  },
  validateWord: async ({ dictionaryId, body }: ValidataWordDto) => {
    const response = await instance.post<ValidatedWord>(
      `dictionary/thematic-dictionary/${dictionaryId}/words/validation`,
      body
    );
    return response.data;
  },
  getWordsFromNotionTextThematic: async (dictionaryId: string, text: string) => {
    const response = await instance.post<ValidatedWordsData>(
      `dictionary/thematic-dictionary/${dictionaryId}/word/markdown`,
      {
        markdown: text
      }
    );
    return response.data;
  }
};
