import { MONTHS } from 'consts';

export const convertToLocalDate = (timestamp: number): string => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${day} ${MONTHS[monthIndex]} ${year}`;
};
