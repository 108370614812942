import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import spinner from 'assets/images/svg/spinner.svg';
import { CHECKLIST_LINKS, CHECKLIST_OPTIONS } from 'consts';
import { useStepper } from './useStepper';

import { ICourse } from 'api/api-courses';
import Button from 'components/atoms/Button';
import { IconBack, IconCheckboxOff, IconCheckboxOn, IconNext } from 'components/atoms/icons';
import { SelectCourseStatus } from '../../../SelectCourseStatus';
import { CourseReady } from '../CourseReady';

interface StepsProps {
  course: ICourse;
}

const Steps: FC<StepsProps> = ({ course: { id: courseId, checklist: steps } }) => {
  const {
    currentStepIndex,
    setStep,
    prevStep,
    nextStep,
    isFirstStep,
    isLastStep,
    toggleStep,
    toggleLoading
  } = useStepper(steps);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <div className='flex h-full flex-col'>
      <header className='mb-[16px] flex h-[49px] w-full items-center justify-between'>
        <h2 className='text-[29px] font-[700] leading-[35px] text-[#20233A]'>Чек-лист</h2>

        <SelectCourseStatus />
      </header>

      <div className='mb-[24px] flex h-[49px] items-center justify-between'>
        <h3 className='text-[21px] font-[700] leading-[24px] text-[#20233A]'>
          Оценить готовность курса:
        </h3>
        <CourseReady steps={steps} />
      </div>

      <div className='mb-[40px] flex flex-col gap-[16px]'>
        <h3 className='text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>Шаги</h3>

        <div className='flex gap-[12px]'>
          {steps.map((step, index) => (
            <div key={step.id} className='flex items-center gap-[12px]'>
              <button
                type='button'
                className={
                  'flex h-[40px] w-[40px] items-center justify-center rounded-[50%] bg-[#F5F5F5] ' +
                  'text-[16px] font-[500] leading-[19px] text-[#71798F] select-none ' +
                  `${step.condition ? 'bg-[rgba(102,200,77,0.1)] text-[#66C84D]' : ''} ` +
                  `${currentStepIndex === index ? '!bg-[#EEF0FF] text-[#5770F3]' : ''}`
                }
                onClick={() => setStep(index)}
              >
                {index + 1}
              </button>

              {index < steps.length - 1 && <span className='h-[2px] w-[8px] bg-[#EFF1F4]' />}
            </div>
          ))}
        </div>
      </div>

      <div className='mb-auto flex flex-col'>
        <h3 className='mb-[8px] text-[18px] font-[500] leading-[21px] text-[#20233A]'>
          {steps[currentStepIndex].name}
        </h3>
        <p className='mb-[24px] text-[16px] leading-[24px] text-[#71798F]'>
          {steps[currentStepIndex].description}
        </p>
        {steps[currentStepIndex].link === CHECKLIST_LINKS.COURSE_CONTENT && (
          <button
            type='button'
            className={
              'h-[54px] select-none self-start rounded-[27px] bg-[#EEF0FF] px-[22px] py-[17.5px] ' +
              'text-[16px] font-[500] leading-[19px] text-[#5770F3]'
            }
            onClick={() => navigate(`/courses/${courseId}/content`)}
          >
            Перейти к Содержанию курса
          </button>
        )}
      </div>

      <div className='flex justify-between'>
        <Button
          type='button'
          variant='secondary'
          title='Назад'
          iconPosition='left'
          icon={<IconBack className='text-[#71798F]' />}
          onClick={prevStep}
          isDisabled={isFirstStep}
        />

        {steps[currentStepIndex].condition ? (
          <button
            type='button'
            className={
              'h-[64px] select-none self-start rounded-[13px] bg-[#66C84D] px-[22px] py-[17.5px] ' +
              'flex gap-[12px] items-center text-[16px] font-[500] leading-[19px] text-[#F5F5F5]'
            }
            onClick={toggleStep}
            disabled={toggleLoading}
          >
            {toggleLoading ? (
              <img src={spinner} alt='spinner' className='block h-[24px] w-[24px] animate-spin' />
            ) : (
              <IconCheckboxOn />
            )}
            Готово
          </button>
        ) : (
          <button
            type='button'
            className={
              'h-[64px] select-none self-start rounded-[13px] bg-[#71798F] px-[22px] py-[17.5px] ' +
              'flex gap-[12px] items-center text-[16px] font-[500] leading-[19px] text-[#F5F5F5]'
            }
            onClick={toggleStep}
            disabled={toggleLoading}
          >
            {toggleLoading ? (
              <img src={spinner} alt='spinner' className='block h-[24px] w-[24px] animate-spin' />
            ) : (
              <IconCheckboxOff />
            )}
            Готово
          </button>
        )}

        {isLastStep ? (
          <Button
            type='button'
            variant='primary'
            title='Проверить'
            iconPosition='right'
            icon={<IconNext />}
            onClick={() => {
              searchParams.set('checklistOption', CHECKLIST_OPTIONS.REVIEW);
              setSearchParams(searchParams);
            }}
          />
        ) : (
          <Button
            type='button'
            variant='primary'
            title='Далее'
            iconPosition='right'
            icon={<IconNext />}
            onClick={nextStep}
          />
        )}
      </div>
    </div>
  );
};

export default Steps;
