import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CreateCourseData } from 'api/api-courses';
import { DEFAULT_COURSE_AVATAR, FORM_ERROR_MESSAGES } from 'consts';
import { useCreateCourseMutation } from 'hooks/course';
import styles from './CreateCourse.module.css';

import { Toggler } from 'components/atoms';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import { IconClose, IconNext } from 'components/atoms/icons';

interface CreateCourseProps {
  backToCourses: () => void;
}

const CreateCourse: FC<CreateCourseProps> = ({ backToCourses }) => {
  const navigate = useNavigate();
  const { mutate: createCourseMutate, isLoading: createCourseLoading } = useCreateCourseMutation();
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FieldValues>({
    mode: 'onSubmit',
    defaultValues: {
      withModules: true
    }
  });

  const onSubmit = async (data: FieldValues) => {
    const { title, withModules } = data;

    const createCourseData: CreateCourseData = {
      title: title.trim(),
      withModules,
      image: new File([DEFAULT_COURSE_AVATAR.avatarSvg!], 'course-avatar-default.svg', {
        type: 'image/svg+xml'
      }),
      miniimage: new File([DEFAULT_COURSE_AVATAR.miniatureSvg!], 'course-avatar-default.svg', {
        type: 'image/svg+xml'
      }),
      isShownCarrotQuestChat: false,
      isShownDictionary: false,
      level: '1'
    };

    createCourseMutate(createCourseData, {
      onSuccess: data => {
        navigate(`/courses/${data.id}`);
      }
    });
  };

  return (
    <>
      <div className={styles.buttonBack}>
        <button onClick={backToCourses}>
          <IconClose color='#5770F3' />
        </button>
        Создание курса
      </div>

      <div className={styles.header}>
        <h2 className={styles.title}>Создать новый курс</h2>
      </div>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.form__top}>
          <div className={styles.name}>
            <Input
              type='text'
              name='title'
              variant='dark'
              width='big'
              placeholder='Название курса'
              containerClassName='mb-[12px]'
              className='h-[76px] rounded-[16px] p-[24px] text-[24px] font-[500] leading-[28px]'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                maxLength: { value: 40, message: 'Максимальная длина 40 символов' },
                validate: {
                  doesntConsistOfSpaces: (value: any) => {
                    return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                  }
                }
              }}
              loading={createCourseLoading}
            />

            <p className={styles.name__hint}>Max 40 characters</p>
          </div>

          <label htmlFor='withModules' className='flex cursor-pointer items-center gap-[16px]'>
            <span className='text-[18px] font-[500] leading-[21px] text-[#71798f]'>Модули</span>
            <Toggler name='withModules' control={control} />
          </label>
        </div>

        <div className={styles.form__bottom}>
          <span>Начните работать над проектом вашего курса</span>

          <Button
            type='submit'
            title={'Создать курс'}
            icon={<IconNext />}
            iconPosition='right'
            className='px-[48px] py-[20px]'
            isDisabled={!isDirty}
            isLoading={createCourseLoading}
          />
        </div>
      </form>
    </>
  );
};

export default CreateCourse;
