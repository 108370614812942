import { FC } from 'react';

import { Skeleton } from 'components/atoms';

const MistakesSkeleton: FC = () => {
  return (
    <div className='flex w-full grow flex-col'>
      <Skeleton height={405} border={0} />
    </div>
  );
};

export default MistakesSkeleton;
