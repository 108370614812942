import React from 'react';

const IconChooseSentence: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Icons'>
        <rect
          id='Rectangle 663'
          opacity='0.5'
          x='5'
          y='13'
          width='14'
          height='2'
          rx='1'
          fill='currentColor'
        />
        <rect
          id='Rectangle 669'
          opacity='0.5'
          x='5'
          y='17'
          width='14'
          height='2'
          rx='1'
          fill='currentColor'
        />
        <rect
          id='Rectangle 668'
          opacity='0.5'
          x='5'
          y='9'
          width='14'
          height='2'
          rx='1'
          fill='currentColor'
        />
        <rect id='Rectangle 670' x='5' y='5' width='2' height='1' rx='0.5' fill='currentColor' />
        <rect id='Rectangle 671' x='15' y='5' width='4' height='1' rx='0.5' fill='currentColor' />
        <rect id='Rectangle 672' x='8' y='5' width='6' height='1' rx='0.5' fill='currentColor' />
      </g>
    </svg>
  );
};

export default IconChooseSentence;
