import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { FormValues } from '../../../../model';
import { repeatMonthsOptions } from '../Repeat/const';

import Input from 'components/atoms/Input';
import { IconCheckOff, IconCheckOn } from 'components/atoms/icons';

interface RepeatYearProps {
  control: Control<FormValues>;
}

const RepeatYear: FC<RepeatYearProps> = ({ control }) => {
  return (
    <div className='flex flex-col gap-[16px]'>
      <Controller
        control={control}
        name='repeatEveryYearMonth'
        rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <div className='flex flex-col'>
            <div className='flex gap-[16px]'>
              {repeatMonthsOptions.map((month, index) => {
                const isActive = value === month.value;

                return (
                  <button
                    key={index}
                    type='button'
                    onClick={() => {
                      if (isActive) {
                        onChange(undefined);
                      } else {
                        onChange(month.value);
                      }
                    }}
                    className='flex select-none flex-col items-center'
                  >
                    <div className='mb-[5px]'>
                      {isActive ? (
                        <IconCheckOn className='h-[32px] w-[32px]' color='#5770F3' />
                      ) : (
                        <IconCheckOff className='h-[32px] w-[32px]' color='#EFF1F4' />
                      )}
                    </div>
                    <span className='text-[16px] font-[500] lowercase leading-[19px] text-[#71798F]'>
                      {month.label}
                    </span>
                  </button>
                );
              })}
            </div>

            {!!error && (
              <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
                {error.message}
              </span>
            )}
          </div>
        )}
      />

      <Input
        type='text'
        variant='dark'
        width='big'
        placeholder='Число'
        name='repeatEveryYearDay'
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          validate: {
            validTime: (value: any) => {
              const dayNumber = Number(value);
              if (Number.isInteger(dayNumber) && dayNumber >= 1 && dayNumber <= 31) {
                return true;
              }
              return 'Введите корректное число месяца (1-31)';
            }
          }
        }}
        containerClassName=''
        className='h-[56px] w-[100px] text-[16px] font-[500] leading-[22px] text-[#71798F]'
      />

      <Input
        type='text'
        variant='dark'
        placeholder='Время'
        name='repeatEveryYearTime'
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          validate: {
            validTime: (value: any) => {
              const errorMessage = 'Введите корректное время в формате hh:mm';
              const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
              if (!timeRegex.test(value)) {
                return errorMessage;
              }
              const [hours, minutes] = value.split(':').map(Number);
              if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
                return errorMessage;
              }
              return true;
            }
          }
        }}
        containerClassName=''
        className='h-[56px] w-[100px] text-[16px] font-[500] leading-[22px] text-[#71798F]'
      />
    </div>
  );
};

export default RepeatYear;
