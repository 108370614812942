import { useMutation, useQueryClient } from 'react-query';

import { DeleteWordDto, apiWords } from 'api/api-words';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useDeleteWordMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (dto: DeleteWordDto) => {
      return apiWords.deleteWord(dto);
    },
    {
      onSuccess(_, { lessonId, dictionaryId }) {
        notifySuc('Слово удалено из общего словаря, всех уроков и тематических словарей');

        queryClient.invalidateQueries(['/words/all']);

        if (lessonId) {
          queryClient.invalidateQueries([`lessons/one/${lessonId}`]);
        }

        if (dictionaryId) {
          queryClient.invalidateQueries([`dictionary/thematic-dictionary/one/${dictionaryId}`]);
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
