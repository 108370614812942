export enum authActionConstTypes {
  LOGIN = 'LOGIN',
  SET_LOGGED_IN = 'SET_LOGGED_IN'
}

export interface setLoginActionType {
  type: authActionConstTypes.SET_LOGGED_IN;
  payload: boolean;
}

export interface LoginActionType {
  type: authActionConstTypes.LOGIN;
  payload: {
    email: string;
    password: string;
  };
}
