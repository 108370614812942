import { FC, FormEventHandler } from 'react';

import { useDeleteDictionaryMutation } from 'hooks/dictionary';
import { ThematicDictionary } from 'models';
import styles from './DeleteDictionaryForm.module.css';

import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';
import { useNavigate } from 'react-router-dom';

type DeleteDictionaryFormProps = {
  dictionary: ThematicDictionary;
  onCancel: () => void;
};

const DeleteDictionaryForm: FC<DeleteDictionaryFormProps> = ({
  dictionary: { id, title },
  onCancel
}) => {
  const navigate = useNavigate();
  const { mutate: deleteDictionaryMutate, isLoading: deleteDictionaryLoading } =
    useDeleteDictionaryMutation();

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    deleteDictionaryMutate(id, {
      onSuccess: () => {
        onCancel();
        navigate('/dictionaries?filter=thematic');
      }
    });
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <p className={styles.title}>Удалить словарь</p>
        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <div className='mb-[24px] flex h-[32px] items-center py-[3px] pr-[8px]'>
        <p
          title={title}
          className={
            'text-[22px] font-[700] leading-[26px] text-[#20233A] ' +
            'overflow-hidden text-ellipsis whitespace-nowrap'
          }
        >
          {title}?
        </p>
      </div>

      <p className={styles.text}>
        Тематический словарь будет <b>удален навсегда</b>, но
        <br />
        карточки слов останутся в большом словаре.
      </p>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='min-w-[136px]'
            isDisabled={deleteDictionaryLoading}
            onClick={onCancel}
          />

          <Button
            type='submit'
            variant='warning'
            size='big'
            title='Удалить словарь'
            isLoading={deleteDictionaryLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default DeleteDictionaryForm;
