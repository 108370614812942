import { FC } from 'react';
import RSelect, { SingleValue, StylesConfig } from 'react-select';

import { IOption } from 'models';

const filterSelectStyles: StylesConfig = {
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    height: '54px',
    minWidth: '200px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'rgba(140, 154, 175, 0.25)',
    '&:hover': {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'rgba(140, 154, 175, 0.25)'
    },
    boxShadow: isFocused ? 'none' : 'inherit',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  option: (styles, { data, isFocused, isDisabled }) => ({
    ...styles,
    color: (data as IOption).color,
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: '500',
    backgroundColor: isFocused && !isDisabled ? '#EFF1F4' : 'none',
    '&:active': {
      backgroundColor: !isDisabled ? '#EFF1F4' : 'none'
    },
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  singleValue: (styles, { data, isDisabled }) => ({
    ...styles,
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: '500',
    color: (data as IOption).color,
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  })
};

interface SelectProps {
  options: IOption[];
  value?: IOption;
  onChange: (option: IOption) => void;
  placeholder?: string;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
}

const ControlledSelect: FC<SelectProps> = ({
  options,
  value,
  onChange,
  placeholder = '',
  className,
  containerClassName,
  disabled = false,
  loading = false
}) => {
  return (
    <RSelect
      classNamePrefix='custom-select'
      styles={filterSelectStyles}
      className={className}
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={(newValue: SingleValue<unknown>) => onChange(newValue as IOption)}
      isSearchable={false}
    />
  );
};

export default ControlledSelect;
