import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useGetCourseMistakesByPeriodQuery } from 'hooks/queries';
import { classNames } from 'utils';
import { PeriodsSkeleton } from '../PeriodsSkeleton';

interface Params {
  courseId: string;
}

type Props = {
  className?: string;
};

export const Periods: FC<Props> = props => {
  const { className } = props;
  const { courseId } = useParams<keyof Params>() as Params;
  const { data: courseMistakesByPeriod, isLoading: courseMistakesByPeriodLoading } =
    useGetCourseMistakesByPeriodQuery({ courseId });

  if (courseMistakesByPeriodLoading) {
    return (
      <>
        {/* <PeriodsSkeleton count={4} /> */}
        <PeriodsSkeleton count={3} className='bg-[#FAFAFA]' />
      </>
    );
  }

  if (!courseMistakesByPeriod) {
    return null;
  }

  const {
    lastYear,
    lastMonth,
    lastWeek,
    yesterday,
    lessonWithMaxMistakes,
    maxMistakesLessonCount
  } = courseMistakesByPeriod;

  return (
    <>
      {/* <header
        className={classNames(
          'flex flex-1 w-full justify-between p-[15px] ' +
            'bg-[#F5F5F5] border-b-[1px] border-solid border-[#E9ECF0]',
          {},
          [className]
        )}
      >
        <ul className='flex items-end overflow-hidden'>
          <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
            <p className='mb-[8px] caption-small text-[#71798F] line-clamp-2'>В прошлом году</p>
            <h3 className='h3 text-[#20233A] truncate'>{lastYear}</h3>
          </li>
          <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
            <p className='mb-[8px] caption-small text-[#71798F] line-clamp-2'>В прошлом месяце</p>
            <h3 className='h3 text-[#20233A] truncate'>{lastMonth}</h3>
          </li>
          <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
            <p className='mb-[8px] caption-small text-[#71798F] line-clamp-2'>На прошлой неделе</p>
            <h3 className='h3 text-[#20233A] truncate'>{lastWeek}</h3>
          </li>
          <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
            <p className='mb-[8px] caption-small text-[#71798F] line-clamp-2'>Вчера</p>
            <h3 className='h3 text-[#20233A] truncate'>{yesterday}</h3>
          </li>
        </ul>
      </header> */}

      {lessonWithMaxMistakes && maxMistakesLessonCount > 0 && (
        <header
          className={classNames(
            'flex flex-1 w-full justify-between p-[15px] ' +
              'bg-[#FAFAFA] border-b-[1px] border-solid border-[#E9ECF0]',
            {},
            [className]
          )}
        >
          <ul className='flex items-center overflow-hidden'>
            <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
              <h4 className='h4 text-[#20233A]'>
                Самый
                <br />
                сложный урок:
              </h4>
            </li>
            <li className='max-w-[40%] p-[0_32px_0_16px] flex items-center overflow-hidden'>
              <div className='mr-[16px] shrink-0 h-[40px] w-[40px]'>
                <img
                  src={lessonWithMaxMistakes.imageUrl}
                  alt={lessonWithMaxMistakes.title}
                  className='h-auto w-full'
                />
              </div>
              <div className='flex flex-col overflow-hidden'>
                <p className='mb-[4px] text-[14px] leading-[16px] text-[#71798F]'>
                  Урок {lessonWithMaxMistakes.order}.
                </p>
                <h5
                  title={lessonWithMaxMistakes.title}
                  className='text-[16px] font-[500] leading-[19px] text-[#20233A] line-clamp-2'
                >
                  {lessonWithMaxMistakes.title}
                </h5>
              </div>
            </li>
            <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
              <h3 className='mb-[4px] h3 text-[#20233A] truncate'>
                {maxMistakesLessonCount} ошибки
              </h3>
              <p className='mb-[4px] text-[14px] leading-[16px] text-[#71798F]'>
                сделали студенты в этом уроке
              </p>
            </li>
          </ul>
        </header>
      )}
    </>
  );
};
