import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC, useMemo } from 'react';

import { STEP_TYPES } from 'api/api-courses';
import styles from './StepCard.module.css';

import {
  IconChooseAnswer,
  IconChooseSentence,
  IconDialog,
  IconDragHandle,
  IconFillingWords,
  IconInsertingWords,
  IconMakingSentence,
  IconMatchingPairs,
  IconPlay,
  IconPlus,
  IconText
} from 'components/atoms/icons';

interface StepCardProps {
  stepId: string;
  stepType: STEP_TYPES;
  stepIndex: number | 'potential';
  onSelectStep: (step: number | 'potential') => void;
  isSelected: boolean;
  isPotential: boolean;
}

const StepCard: FC<StepCardProps> = ({
  stepId,
  stepType,
  stepIndex,
  isSelected,
  onSelectStep,
  isPotential
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: stepId
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const iconElement = useMemo(() => {
    let elem;
    const fill = isSelected ? '#FFFFFF' : '#5770F3';
    switch (stepType) {
      case STEP_TYPES.TEXT:
        elem = <IconText color={fill} />;
        break;
      case STEP_TYPES.VIDEO:
        elem = <IconPlay color={fill} />;
        break;
      case STEP_TYPES.CHOOSE_ANSWER:
        elem = <IconChooseAnswer color={fill} />;
        break;
      case STEP_TYPES.CHOOSE_SENTENCE:
        elem = <IconChooseSentence color={fill} />;
        break;
      case STEP_TYPES.FILLING_MISSING_WORDS:
        elem = <IconFillingWords color={fill} />;
        break;
      case STEP_TYPES.INSERTING_MISSING_WORDS:
        elem = <IconInsertingWords color={fill} />;
        break;
      case STEP_TYPES.MAKING_SENTENCE:
        elem = <IconMakingSentence color={fill} />;
        break;
      case STEP_TYPES.MATCHING_PAIRS:
        elem = <IconMatchingPairs color={fill} />;
        break;
      case STEP_TYPES.DIALOG:
        elem = <IconDialog color={fill} />;
        break;
      default:
        elem = <IconPlus color='#5770F3' />;
    }
    return elem;
  }, [stepType, isSelected]);

  return (
    <li
      className='relative mr-[12px] flex h-[66px] flex-col items-center'
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <button
        className={`mb-[2px] flex h-[40px] w-[64px] cursor-pointer items-center rounded-[12px]  
      ${stepType ? 'pl-[10px]' : 'justify-center'} 
      ${isSelected && stepType ? styles['is-selected-element'] : ''} 
      ${!isSelected && stepType ? styles['not-is-selected-element'] : ''} 
      `}
        onClick={() => onSelectStep(stepIndex)}
      >
        {stepType && (
          <div
            className={`text-[14px] leading-5 ${
              isSelected ? 'text-[#EEF0FF]' : 'text-[#71798F]'
            } mr-[6px]`}
          >
            {isPotential ? '?' : stepIndex}.
          </div>
        )}
        {iconElement}
      </button>

      <button
        className='flex h-[24px] w-[24px] cursor-grab items-center justify-center'
        ref={setActivatorNodeRef}
        {...listeners}
      >
        <IconDragHandle fill='#A6B0C9' className='rotate-90' />
      </button>
    </li>
  );
};

export default StepCard;
