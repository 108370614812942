/* eslint-disable no-useless-escape */

import { FC, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { apiAuth } from 'api/api-auth';
import { EMAIL_REGEXP, FORM_ERROR_MESSAGES, PASSWORD_NO_MATCH_REGEXP } from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { Login } from 'store/actionCreators/authActions';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input/Input';
import Header from 'components/molecules/Header';

const LoginPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm({ mode: 'onSubmit' });

  const [isLoading, setLoading] = useState(false);

  const loginHandler = (accessToken: string, refreshToken: string) => {
    setLoading(false);
    dispatch(Login(accessToken, refreshToken));
    navigate('/courses');
  };

  const onSubmit = async (data: FieldValues) => {
    const { email, password } = data;

    setLoading(true);

    try {
      const response = await apiAuth.login(email, password);
      const { accessToken, refreshToken } = response;

      loginHandler(accessToken, refreshToken);
      notifySuc('Выполнен вход');
    } catch (error) {
      handleError(error);
    }

    setLoading(false);
  };

  return (
    <div className='mx-[80px] mt-[60px] h-full'>
      <Header isOnLogin={true} type={'dark'} />

      <div
        className={'absolute left-[50%] top-[50%] flex w-[410px] flex-col centerAlign'}
        onClick={e => e.stopPropagation()}
      >
        <h1 className={'mb-[28px] text-[44px] font-[700] leading-[52px]'}>Авторизация</h1>

        <form className={'flex flex-col'} onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-[12px] flex w-full flex-col rounded-xl'>
            <label
              htmlFor='email'
              className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'
            >
              Логин (email)
            </label>

            <Input
              type='text'
              name='email'
              className='max-w-none'
              placeholder='Введите e-mail'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                maxLength: { value: 50, message: 'Максимальная длина - 50 символов' },
                validate: {
                  doesntContainSpaces: (value: string) => {
                    return !value.includes(' ') ? true : FORM_ERROR_MESSAGES.DOESNT_CONTAIN_SPACES;
                  },
                  validEmail: (value: string) => {
                    return EMAIL_REGEXP.test(value) ? true : FORM_ERROR_MESSAGES.INCORRECT_EMAIL;
                  }
                }
              }}
              loading={isLoading}
              autoComplete='on'
            />
          </div>

          <div className='mb-[48px] flex w-full flex-col rounded-xl'>
            <label
              htmlFor='password'
              className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'
            >
              Пароль
            </label>

            <Input
              type='password'
              name='password'
              className='max-w-none'
              placeholder='Введите пароль'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                minLength: { value: 4, message: 'Пароль должен быть не менее 4 символов' },
                maxLength: { value: 20, message: 'Пароль должен быть от 4 до 20 символов' },
                validate: {
                  doesntContainSpaces: (value: string) => {
                    return !value.includes(' ') ? true : FORM_ERROR_MESSAGES.DOESNT_CONTAIN_SPACES;
                  },
                  validPassword: (value: string) => {
                    return !value.match(PASSWORD_NO_MATCH_REGEXP)
                      ? true
                      : FORM_ERROR_MESSAGES.INCORRECT_PASSWORD;
                  }
                }
              }}
              loading={isLoading}
              autoComplete='on'
            />
          </div>

          <Button
            type='submit'
            title='Войти'
            className='uppercase'
            isDisabled={!isDirty}
            isLoading={isLoading}
          />
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
