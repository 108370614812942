import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetDictionariesQuery } from 'hooks/dictionary';

import { IconPlusBig } from 'components/atoms/icons';
import { ThematicDictionaryCard } from '..';
import ThematicDictionariesSkeleton from './ThematicDictionariesSkeleton';

const ThematicDictionaries: FC = () => {
  const navigate = useNavigate();
  const { data: thematicDictionaries, isLoading: thematicDictionariesLoading } =
    useGetDictionariesQuery();

  const goToAddThematicDictionary = () => {
    navigate('/dictionaries/create-thematic-dictionary');
  };

  if (thematicDictionariesLoading) return <ThematicDictionariesSkeleton />;
  if (!thematicDictionaries) return <h2>Не удалось загрузить тематические словари</h2>;

  return (
    <>
      <button
        className={
          'flex h-[160px] w-[335px] flex-col items-center justify-center gap-[16px] ' +
          'bg-[#EEF0FF] rounded-[19px] border-[2px] border-dashed border-[#5770F3] '
        }
        onClick={goToAddThematicDictionary}
      >
        <div className='flex h-[68px] w-[68px] items-center justify-center rounded-[50%] bg-[rgba(87,112,243,0.18)]'>
          <IconPlusBig className='text-primary' />
        </div>

        <p className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>
          Добавить тематический словарь
        </p>
      </button>

      {thematicDictionaries.map(dictionary => (
        <ThematicDictionaryCard key={dictionary.id} dictionary={dictionary} />
      ))}
    </>
  );
};

export default ThematicDictionaries;
