import { ChecklistItem } from 'models';

export enum CHECKLIST_LINKS {
  COURSE_CONTENT = 'courseContent'
}

export const DEFAULT_CHECKLIST: ChecklistItem[] = [
  {
    id: '64afed49bcf4641ada34b1c9',
    condition: false,
    name: 'Больше 1 модуля',
    description: 'Разбейте курс хотя бы на два модуля, чтобы структурировать содержание.',
    link: CHECKLIST_LINKS.COURSE_CONTENT
  },
  {
    id: '64afed49bcf4641ada34b1ca',
    condition: false,
    name: 'Количество уроков в модуле',
    description: 'Хорошо, когда урок можно пройти за один раз, за 15-30 минут.',
    link: CHECKLIST_LINKS.COURSE_CONTENT
  },
  {
    id: '64afed49bcf4641ada34b1cb',
    condition: false,
    name: 'Нет пустых модулей',
    description:
      'В каждом модуле должен быть хотя бы один урок, иначе курс выглядит недоделанным. Удалите пустые модули или заполните их уроками.',
    link: CHECKLIST_LINKS.COURSE_CONTENT
  },
  {
    id: '64afed49bcf4641ada34b1cc',
    condition: false,
    name: 'У модулей и уроков содержательные названия',
    description:
      'Замените стандартные названия модулей «Новый модуль» или «New module» на говорящие.',
    link: CHECKLIST_LINKS.COURSE_CONTENT
  },
  {
    id: '64afed49bcf4641ada34b1cd',
    condition: false,
    name: 'Всё видео и аудио в шагах загружены',
    description: 'Убедитесь в том, что в шагах с видео и аудио сами видео или аудио загружены.',
    link: CHECKLIST_LINKS.COURSE_CONTENT
  },
  {
    id: '64afed49bcf4641ada34b1ce',
    condition: false,
    name: 'Все задачи прорешены',
    description:
      'Проверьте шаги разделов уроков: каждый шаг должен быть решен верно хотя бы один раз.',
    link: CHECKLIST_LINKS.COURSE_CONTENT
  },
  {
    id: '64afed49bcf4641ada34b1cf',
    condition: false,
    name: 'Есть аватары курса и уроков',
    description: 'Одно из первых, что видят учащиеся в курсе — аватары вашего курса и уроков',
    link: CHECKLIST_LINKS.COURSE_CONTENT
  },
  {
    id: '64afed49bcf4641ada34b1d0',
    condition: false,
    name: 'Краткое описание курса',
    description:
      'Попробуйте ёмко выразить, о чём ваш курс. Это описание учащиеся увидят при входе в курс.',
    link: CHECKLIST_LINKS.COURSE_CONTENT
  }
];
