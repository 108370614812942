import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { useUpdateDictionaryMutation } from 'hooks/dictionary';
import { ThematicDictionary } from 'models';
import styles from './UpdateDictionaryForm.module.css';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import { IconClose } from 'components/atoms/icons';

type FormValues = {
  newTitle: string;
};

type UpdateDictionaryFormProps = {
  dictionary: ThematicDictionary;
  onCancel: () => void;
};

const UpdateDictionaryForm: FC<UpdateDictionaryFormProps> = ({
  dictionary: { id, title },
  onCancel
}) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      newTitle: title
    }
  });

  const { mutate: updateDictionaryMutate, isLoading: updateDictionaryLoading } =
    useUpdateDictionaryMutation();

  const onSubmit = async ({ newTitle }: FormValues) => {
    updateDictionaryMutate(
      { dictionaryId: id, body: { title: newTitle } },
      {
        onSuccess: onCancel
      }
    );
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <span className={styles.title}>Редактировать название</span>
        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          type='text'
          name='newTitle'
          placeholder='Название словаря'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
            validate: {
              doesntConsistOfSpaces: (value: any) => {
                return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
              }
            }
          }}
        />

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            onClick={onCancel}
            isDisabled={updateDictionaryLoading}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={updateDictionaryLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default UpdateDictionaryForm;
