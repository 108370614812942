import { User } from 'models';

export const resolveUserStatus = (user: User) => {
  if (user.isBlocked) {
    return 'Заблокирован';
  }

  if (user.isHasCourses && user.subscriptionsCount) {
    let course = 'курс';

    if (user.subscriptionsCount > 1 && user.subscriptionsCount < 5) {
      course = 'курса';
    } else if (user.subscriptionsCount >= 5) {
      course = 'курсов';
    }

    return `Подписан на ${user.subscriptionsCount} ${course}`;
  }

  if (user.isHasCourses) {
    return 'Подписан';
  }

  return 'Не подписан';
};
