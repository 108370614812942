import { User } from 'models';

export enum NOTIFICATION_FOR {
  ALL = 'all',
  SELECTED = 'selected'
}

export enum RECIPIENTS_STATUS {
  NOT_SUBSCRIBED = 'Не подписан',
  SUBSCRIBED = 'Подписан',
  DISABLED = 'Выключен '
}

export enum RECIPIENTS_ROLE {
  STUDENT = 'Ученик',
  TEACHER = 'Учитель',
  ADMIN = 'Администратор',
  ASSISTANT = 'Ассистент'
}

export enum SEND_TIME {
  NOW = 'now',
  DATE = 'date',
  REPEAT = 'repeat'
}

export enum REPEAT_DAYS {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday'
}
export const REPEAT_DAYS_LIST = [
  REPEAT_DAYS.MONDAY,
  REPEAT_DAYS.TUESDAY,
  REPEAT_DAYS.WEDNESDAY,
  REPEAT_DAYS.THURSDAY,
  REPEAT_DAYS.FRIDAY,
  REPEAT_DAYS.SATURDAY,
  REPEAT_DAYS.SUNDAY
];

export enum REPEAT_TYPE {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export interface RepeatMonth {
  day: number;
  time: string;
}

export enum MONTHS {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December'
}

export interface RepeatYear {
  day: number;
  time: string;
  month: MONTHS;
}

export enum NOTIFICATION_STATUS {
  ACTIVE = 'active',
  DRAFT = 'draft',
  COMPLETED = 'completed'
}

export interface INotification {
  id: string;
  title: string;
  message: string;
  imageUrl?: string;
  for: NOTIFICATION_FOR;
  recipientsList?: User[];
  recipientsRole?: RECIPIENTS_ROLE;
  sendTime: SEND_TIME;
  sendDate?: string;
  repeatDays?: REPEAT_DAYS[];
  repeatTime?: string;
  repeatType?: REPEAT_TYPE;
  repeatEveryMonth?: RepeatMonth;
  repeatEveryYear?: RepeatYear;
  startDate?: string;
  endDate?: string;
  status: NOTIFICATION_STATUS;
  createdAt?: string;
  updatedAt?: string;
}

export interface GetNotificationsDto {
  page: number;
  itemsPerPage: number;
  search?: string;
  status?: NOTIFICATION_STATUS;
  recipient?: RECIPIENTS_ROLE;
  sortBy: string;
}

export interface CreateNotificationDto {
  title: string;
  message: string;
  image?: File;
  for: NOTIFICATION_FOR;
  recipientsList?: string[];
  recipientsRole?: RECIPIENTS_ROLE;
  sendTime: SEND_TIME;
  sendDate?: string;
  repeatDays?: REPEAT_DAYS[];
  repeatTime?: string;
  repeatType?: REPEAT_TYPE;
  repeatEveryMonth?: RepeatMonth;
  repeatEveryYear?: RepeatYear;
  startDate?: string;
  endDate?: string;
  status: NOTIFICATION_STATUS;
}

export interface UpdateNotificationDto {
  id: string;
  body: {
    title?: string;
    message?: string;
    image?: File;
    for?: NOTIFICATION_FOR;
    recipientsList?: string[];
    recipientsRole?: RECIPIENTS_ROLE;
    sendTime?: SEND_TIME;
    sendDate?: string;
    repeatDays?: REPEAT_DAYS[];
    repeatTime?: string;
    repeatType?: REPEAT_TYPE;
    repeatEveryMonth?: RepeatMonth;
    repeatEveryYear?: RepeatYear;
    startDate?: string;
    endDate?: string;
    status?: NOTIFICATION_STATUS;
  };
}
