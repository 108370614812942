import { ValidatedWord, ValidatedWordStatus } from 'models';

export const filterWordsForAddingToLesson = (words: ValidatedWord[]): ValidatedWord[] => {
  const filteredWords = words.filter(
    word =>
      word.status === ValidatedWordStatus.NEW || word.status === ValidatedWordStatus.IN_VOCABULARY
  );

  return filteredWords;
};
