import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC, useEffect, useState } from 'react';

import { ICourse, IModule } from 'api/api-courses';
import { useUpdateModuleOrdersMutation } from 'hooks/mutations';
import { cn } from 'utils';
import styles from './ModuleList.module.css';

import ButtonAdd from 'components/atoms/ButtonAdd';
import Modal from 'components/atoms/Modal';
import ModuleCard from 'components/organisms/ModuleCard';
import { SelectCourseStatus } from '../../../SelectCourseStatus';
import CreateModuleForm from '../CreateModuleForm';

type ModuleListProps = {
  course: ICourse;
  refetchCourse: () => void;
  className?: string;
};

export const ModuleList: FC<ModuleListProps> = props => {
  const { course, refetchCourse, className } = props;
  const [isAddModuleModalActive, setAddModuleModalActive] = useState(false);
  const { mutate: updateModuleOrdersMutate } = useUpdateModuleOrdersMutation();

  // It is needed for optimistic updates.
  const [currentModules, setCurrentModules] = useState<IModule[]>(
    () => course?.modules?.sort((a, b) => a.order - b.order) || []
  );

  useEffect(() => {
    if (!course) return;

    const sortedModules = course.modules.sort((a, b) => a.order - b.order);
    setCurrentModules(sortedModules);
  }, [course]);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = currentModules.findIndex(module => module.id === active.id);
      const newIndex = currentModules.findIndex(module => module.id === over.id);
      const newModules = arrayMove(currentModules, oldIndex, newIndex);
      setCurrentModules(newModules);

      const data = newModules.map((module, i) => ({
        id: module.id,
        newOrder: i + 1
      }));
      updateModuleOrdersMutate({ courseId: course.id, data });
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <header className={styles.header}>
        <h2 className={styles.header__title}>Содержание курса</h2>
        <SelectCourseStatus />
      </header>

      <DndContext onDragEnd={handleDragEnd}>
        <SortableContext items={currentModules} strategy={verticalListSortingStrategy}>
          <ul className={styles.modules}>
            {currentModules.length > 0 ? (
              <>
                {currentModules.map(module => (
                  <ModuleCard
                    key={module.id}
                    courseId={course.id}
                    module={module}
                    refetchCourse={refetchCourse}
                  />
                ))}
              </>
            ) : (
              <span>Пока у данного курса нет модулей</span>
            )}
          </ul>
        </SortableContext>
      </DndContext>

      <div className={styles.addModule}>
        <span className={styles.line}></span>

        <ButtonAdd
          variant='primary'
          type='button'
          title='Добавить новый модуль'
          onClick={() => setAddModuleModalActive(true)}
        />

        <span className={styles.line}></span>
      </div>

      {isAddModuleModalActive && (
        <Modal onClose={() => setAddModuleModalActive(false)}>
          <CreateModuleForm
            courseId={course.id}
            order={course.modules.length + 1}
            refetchCourse={refetchCourse}
            onCancel={() => setAddModuleModalActive(false)}
          />
        </Modal>
      )}
    </div>
  );
};
