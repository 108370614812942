/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';

import { IWord } from 'api/api-words';
import { WORDS_TABLE_HEADERS } from 'consts';
import useDebounce from 'hooks/useDebounce';
import { useGetWordsQuery } from 'hooks/word';
import styles from './AddWordForm.module.css';
import { DEFAULT_PAGE, WORDS_PER_PAGE } from './const';

import { WordReference } from 'components/atoms';
import ButtonAdd from 'components/atoms/ButtonAdd';
import SearchField from 'components/atoms/SearchField/SearchField';
import { IconClose } from 'components/atoms/icons';
import CreateWordModal from 'components/organisms/CreateWordModal';
import { useAddWordsMutation } from 'hooks/dictionary';

interface AddWordFormProps {
  dictionaryId: string;
  wordIds: string[];
  onCancel: () => void;
}

const AddWordForm: FC<AddWordFormProps> = ({ dictionaryId, wordIds, onCancel }) => {
  const { mutate: addWordMutate, isLoading: addWordLoading } = useAddWordsMutation();
  const [addNewWordModalActive, setAddNewWordModalActive] = useState<boolean>(false);

  // TODO add useSearch hook
  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    setSearchValue(value);
  }, [debouncedSearchValue]);

  const {
    data: wordsData,
    isFetching,
    refetch: refetchWords
  } = useGetWordsQuery({
    page: DEFAULT_PAGE,
    itemsPerPage: WORDS_PER_PAGE,
    letters: searchValue,
    withFavorites: false,
    withReferences: true,
    withExamples: true,
    enabled: false,
    keepPreviousData: true
  });

  const [currentWords, setCurrentWords] = useState<IWord[]>([]);
  useEffect(() => {
    if (wordsData) {
      setCurrentWords(wordsData.data);
    }
  }, [wordsData]);

  useEffect(() => {
    if (searchValue.length > 0) {
      refetchWords();
    }
  }, [searchValue]);

  const addWord = (wordId: string) => {
    addWordMutate({ dictionaryId, body: { wordIds: [wordId] } });
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h3 className={styles.title}>Добавить слово к уроку</h3>

        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <SearchField
        id={'search'}
        type={'text'}
        className='mb-[16px] flex-auto'
        placeholder={'Поиск слова на корейском, на русском'}
        required={false}
        value={value}
        setState={setValue}
      />

      <div className={styles.table}>
        {searchValue.length > 0 ? (
          <>
            {isFetching ? (
              <span>Загрузка...</span>
            ) : (
              <>
                {currentWords.length > 0 ? (
                  <>
                    <div className={styles.table__headers}>
                      <span className={`${styles.table__header} ${styles.table__header_kr}`}>
                        {WORDS_TABLE_HEADERS[2]}
                      </span>

                      <span className={`${styles.table__header} ${styles.table__header_ru}`}>
                        {WORDS_TABLE_HEADERS[3]}
                      </span>

                      <span
                        className={`${styles.table__header} ${styles.table__header_references}`}
                      >
                        {WORDS_TABLE_HEADERS[5]}
                      </span>

                      <span className={`${styles.table__header} ${styles.table__header_examples}`}>
                        Примеры
                      </span>

                      <span className={`${styles.table__header} ${styles.table__header_add}`}>
                        Добавить
                      </span>
                    </div>

                    <div className={styles.table__rows}>
                      {currentWords.map(
                        (
                          { id: wordId, original, translation, audioUrl, references, examples },
                          i
                        ) => (
                          <div
                            className={`${styles.table__row} ${
                              i % 2 === 1 ? styles.table__row_even : ''
                            }`}
                            key={wordId}
                          >
                            <div className={`${styles.table__cell} ${styles.table__cell_original}`}>
                              <span>{original}</span>
                            </div>

                            <div
                              className={`${styles.table__cell} ${styles.table__cell_translation}`}
                            >
                              <span>{translation}</span>
                            </div>

                            <div
                              className={`${styles.table__cell} ${styles.table__cell_references} py-[10px]`}
                            >
                              <div className='flex flex-col gap-1'>
                                {references?.map((reference, index) => (
                                  <WordReference
                                    reference={reference}
                                    key={index}
                                    className='gap-1'
                                  />
                                ))}
                              </div>
                            </div>

                            <div className={`${styles.table__cell} ${styles.table__cell_examples}`}>
                              {examples?.length ? (
                                <ul className='flex flex-col gap-[4px]'>
                                  {examples.map(example => (
                                    <li key={example.id} className='flex flex-col'>
                                      <p className='mb-[4px] text-[16px] font-[400] leading-[19px] text-[#25334F]'>
                                        {example.original}
                                      </p>
                                      <p className='text-[14px] font-[400] leading-[16px] text-[#71798F]'>
                                        {example.translation}
                                      </p>
                                    </li>
                                  ))}
                                </ul>
                              ) : null}
                            </div>

                            <div
                              className={`${styles.table__cell} ${styles.table__cell_buttonAdd}`}
                            >
                              <ButtonAdd
                                variant='primary'
                                type='button'
                                className='self-center'
                                isDisabled={addWordLoading || wordIds.includes(wordId) || !audioUrl}
                                title={wordIds.includes(wordId) ? 'Добавлено' : 'Добавить'}
                                onClick={() => addWord(wordId)}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </>
                ) : (
                  <div className={styles.noResultsMessage}>
                    <span>
                      К сожалению, ничего не найдено :( <br /> Попробуйте ещё!
                    </span>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className={styles.emptySearchMessage}>
            <span>
              Начните вводить слово в поиск
              <br /> и результаты отобразятся здесь.
            </span>
          </div>
        )}
      </div>

      <ButtonAdd
        variant='dark'
        type='button'
        title='Добавить новое слово'
        className='mt-auto self-center'
        onClick={() => setAddNewWordModalActive(true)}
      />

      <CreateWordModal
        isOpen={addNewWordModalActive}
        onClose={() => setAddNewWordModalActive(false)}
        onCreate={() => refetchWords()}
      />
    </div>
  );
};

export default AddWordForm;
