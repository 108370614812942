import { FC } from 'react';
import { Control, useFieldArray } from 'react-hook-form';

import { CreateUserWithCoursesFormValues } from 'models/user';
import { timestampToInput } from 'utils';

import ButtonAdd from 'components/atoms/ButtonAdd';
import { CourseCard } from '..';

type CourseCardsProps = {
  control: Control<CreateUserWithCoursesFormValues>;
  className?: string;
};

const CourseCards: FC<CourseCardsProps> = ({ control, className }) => {
  const {
    fields: courseFields,
    append: appendCourse,
    remove: removeCourse
  } = useFieldArray<CreateUserWithCoursesFormValues>({
    control,
    name: 'courses'
  });

  return (
    <div className={`flex flex-col gap-[28px] ${className}`}>
      <ul className='flex flex-col gap-[8px]'>
        {courseFields.map((course, index) => (
          <CourseCard key={course.id} control={control} index={index} removeCourse={removeCourse} />
        ))}
      </ul>

      <ButtonAdd
        variant='light'
        type='button'
        title='Добавить еще курс'
        onClick={() => appendCourse({ from: timestampToInput(Date.now()) })}
      />
    </div>
  );
};

export default CourseCards;
