import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { OutputData } from '@editorjs/editorjs';
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  AddDialogStepDto,
  DialogPart as IDialogPart,
  DialogStep as IDialogStep,
  ISection,
  STEP_TYPES,
  UpdateDialogStep,
  UpdateDialogStepDto,
  apiCourses
} from 'api/api-courses';
import { DEFAULT_EDITABLE_CONDITION_DIALOG, DEFAULT_EDITABLE_PURPOSE_DATA } from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useUploadStepImageMutation } from 'hooks/mutations';
import { AudioPart, StepFormat } from 'models';
import { normalizeData } from 'utils';
import { useDeleteStep } from '../hooks';
import styles from './DialogStep.module.css';
import { DEFAULT_MIN_DIALOG_PARTS } from './const';

import Button from 'components/atoms/Button';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import { IconDialog, IconMessage, IconTrash } from 'components/atoms/icons';
import { Condition, Format, Hint, Purpose } from '../components';
import { DialogPart } from './components';

type FieldValues = {
  editableCondition: OutputData;
  editablePurpose: OutputData;
  contentParts: Omit<IDialogPart, 'order'>[];
  hint?: string;
  format: StepFormat;
  audioParts?: AudioPart[];
  url?: string;
  picture?: FileList;
  videoUrl?: string;
};

type DialogStepProps = {
  step: IDialogStep;
  section: ISection;
  stepIndex: number | 'potential';
  isPotential?: boolean;
  deletePotentialStep?: () => void;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  setOnSubmitCallback: Dispatch<SetStateAction<(() => void) | null>>;
  refetchSection: () => void;
};

const DialogStep: FC<DialogStepProps> = ({
  step,
  section,
  stepIndex,
  isPotential,
  deletePotentialStep,
  setIsDirty,
  setIsValid,
  setOnSubmitCallback,
  refetchSection
}) => {
  const { mutate: setStepImageMutate, isLoading: setStepImageLoading } =
    useUploadStepImageMutation();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    formState: { isDirty, isValid, errors }
  } = useForm<FieldValues>({
    mode: 'onSubmit',
    defaultValues: {
      editableCondition: step.editableCondition || DEFAULT_EDITABLE_CONDITION_DIALOG,
      editablePurpose: step.editablePurpose || DEFAULT_EDITABLE_PURPOSE_DATA,
      contentParts: step.contentParts?.sort((a, b) => a.order - b.order) || [
        { text: '' },
        { text: '' }
      ],
      hint: step.hint || '',
      format: step.format || 'text',
      audioParts: !!step.audioList?.length ? step.audioList : [],
      url: step.url,
      videoUrl: step.format === 'video' ? step.url : undefined
    }
  });

  const format = watch('format');
  const {
    fields: contentPartFields,
    append: appendContentPart,
    move: moveContentPart,
    remove: removeContentPart
  } = useFieldArray({ control, name: 'contentParts' });

  const allowRemove = useMemo(() => {
    return contentPartFields.length > DEFAULT_MIN_DIALOG_PARTS;
  }, [contentPartFields]);

  // TODO separate mutation hook
  const { mutateAsync: addDialogStepMutate, isLoading: isLoadingCreate } = useMutation(
    (dto: AddDialogStepDto) => {
      return apiCourses.addDialogStep(dto);
    },
    {
      async onSuccess(data, variables) {
        const onSuccess = () => {
          deletePotentialStep && deletePotentialStep();

          notifySuc('Шаг добавлен');
          refetchSection();
        };

        if (variables.image) {
          setStepImageMutate(
            {
              stepId: data.id,
              image: variables.image,
              type: STEP_TYPES.DIALOG
            },
            {
              onSuccess
            }
          );
        } else {
          onSuccess();
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  // TODO separate mutation hook
  const { mutateAsync: updateDialogStepMutate, isLoading: isLoadingUpdate } = useMutation(
    (dto: UpdateDialogStepDto) => {
      return apiCourses.updateDialogStep(dto);
    },
    {
      async onSuccess(data, variables) {
        const onSuccess = () => {
          notifySuc('Шаг обновлен');
          refetchSection();
        };

        if (variables.image) {
          setStepImageMutate(
            {
              stepId: data.id,
              image: variables.image,
              type: STEP_TYPES.DIALOG
            },
            {
              onSuccess
            }
          );
        } else {
          onSuccess();
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const { deleteStepMutate: deleteDialogStepMutate, isLoading: isLoadingDelete } = useDeleteStep({
    section,
    stepId: step.id,
    stepType: step.type,
    setIsDirty,
    setIsValid,
    setOnSubmitCallback
  });
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

  const deleteDialogStep = () => {
    if (isPotential) {
      if (deletePotentialStep) {
        deletePotentialStep();
      }
      return;
    }
    deleteDialogStepMutate();
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = contentPartFields.findIndex(({ id }) => id === active.id);
      const newIndex = contentPartFields.findIndex(({ id }) => id === over.id);

      moveContentPart(oldIndex, newIndex);
    }
  };

  const handleReset = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    reset();
  };

  const onSubmit = async (data: FieldValues) => {
    const {
      editableCondition,
      editablePurpose,
      contentParts: rawDialogParts,
      hint,
      format,
      picture,
      audioParts,
      url,
      videoUrl
    } = data;

    const contentParts: IDialogPart[] = rawDialogParts.map(({ text }, index) => ({
      order: index + 1,
      text: text.trim()
    }));

    const stepData: UpdateDialogStep = {
      order: step.order || section.steps?.length + 1 || 1,
      editableCondition: normalizeData(editableCondition),
      editablePurpose: normalizeData(editablePurpose),
      contentParts,
      hint,
      format,
      audioList: format === 'audio' ? audioParts : undefined,
      url: format === 'video' ? videoUrl : url
    };

    if (isPotential) {
      if (format === 'image' && picture && picture.length) {
        await addDialogStepMutate({ sectionId: section.id, body: stepData, image: picture[0] });
      } else {
        await addDialogStepMutate({ sectionId: section.id, body: stepData });
      }
    } else {
      if (format === 'image' && picture && picture.length) {
        await updateDialogStepMutate({ stepId: step.id, body: stepData, image: picture[0] });
      } else {
        await updateDialogStepMutate({ stepId: step.id, body: stepData });
      }
    }
  };

  useEffect(() => {
    setIsDirty(isDirty);
    setIsValid(isValid);

    setOnSubmitCallback(() => handleSubmit(async data => await onSubmit(data)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isValid]);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h3 className={styles.title}>
          {`Шаг ${isPotential ? section.steps?.length + 1 : stepIndex} | Тест — `}
          <IconDialog color='#5770F3' className='mx-[8px]' /> Диалог
        </h3>

        <button
          className='flex h-[24px] w-[24px] items-center justify-center'
          onClick={() => setDeleteModalIsOpen(true)}
        >
          <IconTrash color='#71798F' />
        </button>
      </header>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
        <Condition stepId={step.id || 'potential'} control={control} />

        <Purpose
          stepId={step.id || 'potential'}
          control={control}
          text='Цель (перевод текста задания) (Выберите размер шрифта Заголовок 3)'
        />

        <div className={styles.dialogParts}>
          <label className={`${styles.label} ${styles.dialogParts__label}`}>
            Текст задания (на корейском), разбитый по частям
          </label>

          {contentPartFields.length > 0 && (
            <DndContext onDragEnd={handleDragEnd}>
              <SortableContext items={contentPartFields} strategy={verticalListSortingStrategy}>
                <ul className={styles.dialogParts__list}>
                  {contentPartFields.map((contentPart, index) => (
                    <DialogPart
                      key={contentPart.id}
                      contentPart={contentPart}
                      index={index}
                      control={control}
                      register={register}
                      remove={removeContentPart}
                      allowRemove={allowRemove}
                    />
                  ))}
                </ul>
              </SortableContext>
            </DndContext>
          )}

          <div className={styles.dialogParts__buttons}>
            <Button
              variant='light'
              type='button'
              title='Добавить реплику'
              icon={<IconMessage color='#5770F3' />}
              iconPosition='left'
              className='h-[48px] rounded-[62px] font-[500]'
              onClick={() => appendContentPart({ text: '' })}
            />
          </div>
        </div>

        <Hint control={control} />

        <Format
          savedFormat={step.format}
          format={format}
          register={register}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          imageError={!!errors.picture}
        />

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Сбросить'
            className='w-[198px]'
            isDisabled={!isDirty || isLoadingCreate || isLoadingUpdate || setStepImageLoading}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={isLoadingCreate || isLoadingUpdate || setStepImageLoading}
          />
        </div>
      </form>

      {deleteModalIsOpen && (
        <ConfirmationModal
          title={
            <>Удалить {isPotential ? 'Шаг | Тест - Диалог' : `Шаг ${stepIndex} | Тест - Диалог`}?</>
          }
          text={
            <>
              Шаг будет удален <strong>навсегда</strong>.
            </>
          }
          isDelete={true}
          confirmButtonText='Удалить шаг'
          onConfirm={deleteDialogStep}
          onClose={() => setDeleteModalIsOpen(false)}
          isLoading={isLoadingDelete}
        />
      )}
    </div>
  );
};

export default DialogStep;
