import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetNotificationQuery } from 'hooks/queries';

import { IconClose } from 'components/atoms/icons';
import { UpdateForm } from './components';

interface IParams {
  notificationId: string;
}

const NotificationPage: FC = () => {
  const navigate = useNavigate();
  const { notificationId } = useParams<keyof IParams>() as IParams;
  const { data: notification, isLoading: notificationLoading } =
    useGetNotificationQuery(notificationId);

  if (notificationLoading) return <h2>Уведомление загружается...</h2>;
  if (!notification) return <h2>Не удалось загрузить уведомление</h2>;

  return (
    <div className='flex h-full flex-col'>
      <header className='flex flex-col items-start'>
        <button
          type='button'
          onClick={() => navigate('/notifications')}
          className='mb-[17px] flex translate-x-[-40px] select-none items-center gap-[16px]'
        >
          <IconClose color='#5770F3' />
          <span className='text-[16px] leading-[19px] text-[#20233A]'>Отменить</span>
        </button>

        <div className='flex h-[74px] items-center'>
          <h1 className='text-[36px] font-[700] leading-[42px] text-[#20233A]'>
            Редактировать уведомление
          </h1>
        </div>
      </header>

      <UpdateForm notification={notification} />
    </div>
  );
};

export default NotificationPage;
