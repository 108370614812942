import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useGetCourseQuery } from 'hooks/course';

import CourseContentSkeleton from './CourseContentSkeleton';
import { LessonList } from './components/LessonList';
import { ModuleList } from './components/ModuleList';

type Params = {
  courseId: string;
};

const CourseContent: FC = () => {
  const { courseId } = useParams<keyof Params>() as Params;
  const {
    data: course,
    refetch: refetchCourse,
    isLoading: courseLoading
  } = useGetCourseQuery({ courseId });

  if (courseLoading) return <CourseContentSkeleton />;
  if (!course) return <h2>Не удалось загрузить курс</h2>;

  return course.withModules ? (
    <ModuleList course={course} refetchCourse={refetchCourse} />
  ) : (
    <LessonList course={course} refetchCourse={refetchCourse} />
  );
};

export default CourseContent;
