import { useQuery } from 'react-query';

import { apiNotifications } from 'api/api-notifications';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetNotificationQuery = (id: string) => {
  return useQuery(
    [`/notifications/${id}`],
    async () => await apiNotifications.getNotification(id),
    {
      staleTime: DEFAULT_STALE_TIME,
      onError(error) {
        handleError(error);
      }
    }
  );
};
