import { FC } from 'react';
import { Control, Controller, useController } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { REPEAT_TYPE } from 'models';
import { FormValues } from '../../../../model';
import { repeatTypeOptions } from './const';

import { Select } from 'components/atoms';
import { RepeatDay, RepeatMonth, RepeatWeek, RepeatYear } from '..';

interface RepeatProps {
  control: Control<FormValues>;
}

const Repeat: FC<RepeatProps> = ({ control }) => {
  const { field } = useController({
    name: 'repeatType',
    control,
    rules: {
      required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
    }
  });

  return (
    <div className='flex flex-col'>
      <div className='mb-[30px]'>
        <h6 className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
          повторять
        </h6>

        <Select
          name='repeatType'
          placeholder='Временной интервал'
          options={repeatTypeOptions}
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
          }}
          className='w-[240px]'
        />
      </div>

      <div className='mb-[30px]'>
        {field.value === REPEAT_TYPE.DAY && <RepeatDay control={control} />}
        {field.value === REPEAT_TYPE.WEEK && <RepeatWeek control={control} />}
        {field.value === REPEAT_TYPE.MONTH && <RepeatMonth control={control} />}
        {field.value === REPEAT_TYPE.YEAR && <RepeatYear control={control} />}
      </div>

      <div className='flex gap-[30px]'>
        <div className='flex flex-col'>
          <h6 className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            начало
          </h6>

          <Controller
            control={control}
            name='startDate'
            rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className='flex flex-col'>
                <input
                  type='date'
                  value={value || ''}
                  onChange={onChange}
                  className={
                    'rounded-[12px] px-[16px] py-[20px] h-[56px] w-[148px] ' +
                    'text-[16px] text-[#71798F] leading-[22px] font-[500] ' +
                    `${!!error ? 'bg-[rgba(255,68,68,0.1)]' : 'bg-[#eff1f4]'}`
                  }
                />

                {!!error && (
                  <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
                    {error.message}
                  </span>
                )}
              </div>
            )}
          />
        </div>

        <div className='flex flex-col'>
          <h6 className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            окончание
          </h6>

          <Controller
            control={control}
            name='endDate'
            rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className='flex flex-col'>
                <input
                  type='date'
                  value={value || ''}
                  onChange={onChange}
                  className={
                    'rounded-[12px] px-[16px] py-[20px] h-[56px] w-[148px] ' +
                    'text-[16px] text-[#71798F] leading-[22px] font-[500] ' +
                    `${!!error ? 'bg-[rgba(255,68,68,0.1)]' : 'bg-[#eff1f4]'}`
                  }
                />

                {!!error && (
                  <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
                    {error.message}
                  </span>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Repeat;
