import { FC } from 'react';

import { DEFAULT_TABS_AMOUNT } from './const';

import { Skeleton } from 'components/atoms';

const SkeletonSidebar: FC = () => {
  return (
    <div className='flex w-[210px] flex-col'>
      <Skeleton height={28} width={69} className='mb-[30px]' />
      <Skeleton height={105} width={105} border={16} className='mb-[24px]' />
      <Skeleton height={44} width={140} className='mb-[24px]' />
      <ul className='flex grow flex-col gap-[8px]'>
        {Array.from({ length: DEFAULT_TABS_AMOUNT }).map((_, i) => (
          <Skeleton key={i} height={44} width={202} />
        ))}
      </ul>
    </div>
  );
};

export default SkeletonSidebar;
