import { FC } from 'react';

import { FORM_ERROR_MESSAGES } from 'consts';
import styles from './Hint.module.css';

import { IconHint } from 'components/atoms/icons';
import TextArea from 'components/atoms/TextArea';

interface HintProps {
  control: any;
}

const Hint: FC<HintProps> = ({ control }) => {
  return (
    <div className={styles.hint}>
      <label className={styles.label}>
        Подсказка
        <IconHint color='#71798F' />
      </label>

      <TextArea
        variant='dark'
        name='hint'
        control={control}
        rules={{
          required: false,
          maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
          validate: {
            doesntConsistOfSpaces: (value: any) => {
              return !value.length || !!value.trim()
                ? true
                : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
            }
          }
        }}
        rows={4}
      />

      <span className={styles.hint__description}>Подсказка будет скрыта по умолчанию</span>
    </div>
  );
};

export default Hint;
