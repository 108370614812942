import { Dispatch, FC, SetStateAction } from 'react';
import RSelect, { SingleValue, StylesConfig } from 'react-select';

import { COURSES_LEVELS } from 'consts';
import { IOption } from 'models';

const levelOptions: IOption[] = [...COURSES_LEVELS].map(level => ({
  value: level,
  label: level
}));

const singleSelectStyles: StylesConfig = {
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    height: '54px',
    width: '200px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'rgba(140, 154, 175, 0.25)',
    '&:hover': {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'rgba(140, 154, 175, 0.25)'
    },
    boxShadow: isFocused ? 'none' : 'inherit',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  menu: styles => ({
    ...styles,
    zIndex: '3',
    width: '175px'
  }),
  option: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    color: '#20233A',
    backgroundColor: isFocused ? '#EFF1F4' : 'none',
    '&:active': {
      backgroundColor: '#EFF1F4'
    },
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    color: '#20233A',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  })
};

interface SelectCourseLevelProps {
  currentLevel: string;
  setCurrentLevel: Dispatch<SetStateAction<string>>;
}

const SelectCourseLevel: FC<SelectCourseLevelProps> = ({ currentLevel, setCurrentLevel }) => {
  return (
    <RSelect
      value={levelOptions.find(({ value }) => value === currentLevel)}
      classNamePrefix='custom-select'
      styles={singleSelectStyles}
      placeholder={'Выберите уровень'}
      options={levelOptions}
      onChange={(newValue: SingleValue<unknown>) => {
        setCurrentLevel((newValue as IOption).value);
      }}
      isSearchable={false}
    />
  );
};

export default SelectCourseLevel;
