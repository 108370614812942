import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC } from 'react';
import { FieldArrayWithId, UseFieldArrayRemove, UseFormGetValues } from 'react-hook-form';

import { FORM_ERROR_MESSAGES, TEST_STEPS_ERROR_MESSAGES } from 'consts';
import { FieldValues } from '../../MatchingPairsStep';
import styles from './Pair.module.css';

import { IconClose, IconDragHandle } from 'components/atoms/icons';
import Input from 'components/atoms/Input';

interface PairProps {
  pair: FieldArrayWithId<FieldValues, 'pairs', 'id'>;
  index: number;
  control: any;
  getValues: UseFormGetValues<FieldValues>;
  remove: UseFieldArrayRemove;
  fieldsAmount: number;
}

const Pair: FC<PairProps> = ({ pair, index, control, getValues, remove, fieldsAmount }) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: pair.id
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <li
      className='flex items-center text-[16px] font-[500] leading-[22px] text-[#20233A]'
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <button type='button' className={styles.dragHandle} ref={setActivatorNodeRef} {...listeners}>
        <IconDragHandle color='#A6B0C9' />
      </button>

      <Input
        type='text'
        variant='bordered'
        width='big'
        name={`pairs.${index}.original` as const}
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
          validate: {
            doesntConsistOfSpaces: (value: any) => {
              return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
            },
            isNotDuplicate: (value: any) => {
              const pairs = getValues('pairs');
              const variants = [
                ...pairs.map(pair => pair.original),
                ...pairs.map(pair => pair.translation)
              ];
              const copies = variants.reduce(
                (acc, variant) => (variant.trim() === value.trim() ? acc + 1 : acc),
                0
              );
              return copies < 2 ? true : TEST_STEPS_ERROR_MESSAGES.CHOOSE_ANSWER;
            }
          }
        }}
      />

      <span className='mx-[8px]'>—</span>

      <Input
        type='text'
        variant='bordered'
        width='big'
        name={`pairs.${index}.translation` as const}
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
          validate: {
            doesntConsistOfSpaces: (value: any) => {
              return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
            },
            isNotDuplicate: (value: any) => {
              const pairs = getValues('pairs');
              const variants = [
                ...pairs.map(pair => pair.original),
                ...pairs.map(pair => pair.translation)
              ];
              const copies = variants.reduce(
                (acc, variant) => (variant.trim() === value.trim() ? acc + 1 : acc),
                0
              );
              return copies < 2 ? true : TEST_STEPS_ERROR_MESSAGES.CHOOSE_ANSWER;
            }
          }
        }}
        containerClassName='mr-[8px]'
      />

      {fieldsAmount > 3 && (
        <button
          type='button'
          className='flex h-[48px] w-[56px] items-center justify-center rounded-[12px] bg-[#EEF0FF]'
          onClick={() => remove(index)}
        >
          <IconClose color='#71798F' />
        </button>
      )}
    </li>
  );
};

export default Pair;
