import { useMutation, useQueryClient } from 'react-query';

import { AddCourseUsersDto, apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useAddCourseUsersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: AddCourseUsersDto) => {
      return apiCourses.addCourseUsers(data);
    },
    {
      onSuccess(_, variables) {
        notifySuc('Курс добавлен пользователям');

        queryClient.invalidateQueries([`courses/${variables.courseId}/users`]);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
