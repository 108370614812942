import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useUpdateCourseChecklistMutation } from 'hooks/mutations';
import { ChecklistItem } from 'models';

interface Params {
  courseId: string;
}

export const useStepper = (steps: ChecklistItem[]) => {
  const { courseId } = useParams<keyof Params>() as Params;
  const { mutate: updateCourseChecklistMutate, isLoading: toggleLoading } =
    useUpdateCourseChecklistMutation();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentStepIndex = useMemo(() => {
    const stepIndex = Number(searchParams.get('checklistStep'));
    if (stepIndex >= 0 && stepIndex < steps.length) {
      return stepIndex;
    } else {
      return 0;
    }
  }, [searchParams, steps.length]);

  const [isFirstStep, setFirstStep] = useState<boolean>(false);
  const [isLastStep, setLastStep] = useState<boolean>(false);

  useEffect(() => {
    if (currentStepIndex === 0) {
      setFirstStep(true);
    } else {
      setFirstStep(false);
    }
    if (currentStepIndex === steps.length - 1) {
      setLastStep(true);
    } else {
      setLastStep(false);
    }
  }, [currentStepIndex, steps.length]);

  const prevStep = () => {
    if (!isFirstStep) {
      searchParams.set('checklistStep', String(currentStepIndex - 1));
      setSearchParams(searchParams);
    }
  };

  const nextStep = () => {
    if (!isLastStep) {
      searchParams.set('checklistStep', String(currentStepIndex + 1));
      setSearchParams(searchParams);
    }
  };

  const setStep = (stepIndex: number) => {
    if (stepIndex >= 0 && stepIndex < steps.length) {
      searchParams.set('checklistStep', String(stepIndex));
      setSearchParams(searchParams);
    }
  };

  const toggleStep = async () => {
    const currentStep = steps[currentStepIndex];
    const updatedStep: ChecklistItem = { ...currentStep, condition: !currentStep.condition };
    const updatedChecklist = steps.map(step => (step.id === currentStep.id ? updatedStep : step));
    updateCourseChecklistMutate({ courseId, body: { checklist: updatedChecklist } });
  };

  return {
    currentStepIndex,
    setStep,
    prevStep,
    nextStep,
    isFirstStep,
    isLastStep,
    toggleStep,
    toggleLoading
  };
};
