import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useCreateNotificationMutation } from 'hooks/mutations';
import { NOTIFICATION_STATUS } from 'models';
import { FormValues } from './model';
import { useStepper } from './useStepper';
import { transformData } from './utils';

import { IconClose } from 'components/atoms/icons';
import { Step1, Step2, Step3, Step4, Stepper } from './components';

const CreateNotificationPage: FC = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { isValid, isDirty }
  } = useForm<FormValues>({
    mode: 'onSubmit'
  });
  const { activeStep, setStep, prevStep, nextStep, isLastStep } = useStepper();
  const { mutate: createNotificationMutate, isLoading: createNotificationLoading } =
    useCreateNotificationMutation();

  const onSubmit = (data: FormValues) => {
    if (!isLastStep) {
      nextStep();
    } else {
      const normalizedData = transformData(data);
      createNotificationMutate(normalizedData, { onSuccess: () => navigate('/notifications') });
    }
  };

  const saveAsDraft = () => {
    trigger();
    if (!isValid) return;

    const data = getValues();
    const normalizedData = transformData(data);
    createNotificationMutate(
      { ...normalizedData, status: NOTIFICATION_STATUS.DRAFT },
      { onSuccess: () => navigate('/notifications') }
    );
  };

  return (
    <div className='flex h-full flex-col'>
      <header className='flex flex-col items-start'>
        <button
          type='button'
          onClick={() => navigate('/notifications')}
          className='mb-[17px] flex translate-x-[-40px] select-none items-center gap-[16px]'
        >
          <IconClose color='#5770F3' />
          <span className='text-[16px] leading-[19px] text-[#20233A]'>Отменить</span>
        </button>

        <div className='flex h-[74px] items-center'>
          <h1 className='text-[36px] font-[700] leading-[42px] text-[#20233A]'>
            Создать новое уведомление
          </h1>
        </div>
      </header>

      <div className='mb-[30px]'>
        <Stepper activeStep={activeStep} setStep={setStep} />
      </div>

      <form className='flex flex-1 flex-col' onSubmit={handleSubmit(onSubmit)}>
        {activeStep === 1 && <Step1 control={control} isDirty={isDirty} />}
        {activeStep === 2 && <Step2 control={control} isDirty={isDirty} onPrev={prevStep} />}
        {activeStep === 3 && (
          <Step3
            control={control}
            isDirty={isDirty}
            onPrev={prevStep}
            saveAsDraft={saveAsDraft}
            isLoading={createNotificationLoading}
          />
        )}
        {activeStep === 4 && (
          <Step4
            control={control}
            isDirty={isDirty}
            getValues={getValues}
            onPrev={prevStep}
            saveAsDraft={saveAsDraft}
            isLoading={createNotificationLoading}
          />
        )}
      </form>
    </div>
  );
};

export default CreateNotificationPage;
