import CourseAvatarDefaultSVG from 'assets/images/svg/course-avatars/course-avatar-default.svg';

import CourseAvatarMiniatureDefaultSVG from 'assets/images/svg/course-avatars/course-avatar-miniature-default.svg';

export type OldCourseAvatar = {
  id: string;
  url: string;
  miniatureUrl: string;
  avatarSvg: string;
  miniatureSvg: string;
};

export const DEFAULT_COURSE_AVATAR: OldCourseAvatar = {
  id: '0',
  url: CourseAvatarDefaultSVG,
  miniatureUrl: CourseAvatarMiniatureDefaultSVG,
  avatarSvg:
    '<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="none"><path fill="#FA0747" d="M126.5 63.46 99.18 99.85l27.32 36.7h-9.52c-.46 0-.9-.23-1.17-.6L89.54 100.9c-.4-.53-.4-1.25 0-1.77l26.38-35.07c.27-.37.7-.6 1.17-.6h9.4Z"/><path fill="#232988" d="M83.7 136.07v-9.26c0-1.15-.69-1.93-1.88-1.93s-1.88.83-1.88 1.93v6.54c0 1.89-1.23 3.28-3.2 3.28-1.91 0-3.24-1.35-3.24-3.28v-9.28h1.35v9.28c0 1.15.72 1.93 1.88 1.93 1.19 0 1.86-.78 1.86-1.93v-6.54c0-1.89 1.26-3.28 3.23-3.28 1.9 0 3.23 1.39 3.23 3.28v9.26h-1.34ZM73.5 120.72h11.55v1.35H73.5v-1.35ZM73.5 118.92v-1.48l8.97-6-8.97-6.04v-1.46h11.55v1.35h-9.33l9.13 6.14-9.13 6.15h9.33v1.34H73.5ZM79.27 92.32c0-1.15-.77-1.93-2.2-1.93-1.44 0-2.22.78-2.22 1.93v8.48h4.42v-8.48Zm-5.77 9.82v-9.82c0-1.89 1.42-3.28 3.57-3.28 2.13 0 3.54 1.4 3.54 3.28v8.48h4.44v1.34H73.5ZM73.5 87.58v-1.35h10.2v-9.08h1.35v10.43H73.5ZM73.5 75.68v-12.3h1.35v10.96h3.74V64h1.35v10.34h3.77V63.37h1.34v12.31H73.5Z"/><path fill="#2C3996" d="M100 36a64 64 0 0 0-64 64h3.54a60.46 60.46 0 0 1 120.92 0H164a64 64 0 0 0-64-64Z"/><path fill="#FA0747" d="M100 160.46A60.46 60.46 0 0 1 39.54 100H36a64 64 0 1 0 128 0h-3.54A60.46 60.46 0 0 1 100 160.46Z"/></svg>',
  miniatureSvg:
    '<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="none"><path fill="#FA0747" d="M126.5 63.46 99.18 99.85l27.32 36.7h-9.52c-.46 0-.9-.23-1.17-.6L89.54 100.9c-.4-.53-.4-1.25 0-1.77l26.38-35.07c.27-.37.7-.6 1.17-.6h9.4Z"/><path fill="#232988" d="M83.7 136.07v-9.26c0-1.15-.69-1.93-1.88-1.93s-1.88.83-1.88 1.93v6.54c0 1.89-1.23 3.28-3.2 3.28-1.91 0-3.24-1.35-3.24-3.28v-9.28h1.35v9.28c0 1.15.72 1.93 1.88 1.93 1.19 0 1.86-.78 1.86-1.93v-6.54c0-1.89 1.26-3.28 3.23-3.28 1.9 0 3.23 1.39 3.23 3.28v9.26h-1.34ZM73.5 120.72h11.55v1.35H73.5v-1.35ZM73.5 118.92v-1.48l8.97-6-8.97-6.04v-1.46h11.55v1.35h-9.33l9.13 6.14-9.13 6.15h9.33v1.34H73.5ZM79.27 92.32c0-1.15-.77-1.93-2.2-1.93-1.44 0-2.22.78-2.22 1.93v8.48h4.42v-8.48Zm-5.77 9.82v-9.82c0-1.89 1.42-3.28 3.57-3.28 2.13 0 3.54 1.4 3.54 3.28v8.48h4.44v1.34H73.5ZM73.5 87.58v-1.35h10.2v-9.08h1.35v10.43H73.5ZM73.5 75.68v-12.3h1.35v10.96h3.74V64h1.35v10.34h3.77V63.37h1.34v12.31H73.5Z"/><path fill="#2C3996" d="M100 36a64 64 0 0 0-64 64h3.54a60.46 60.46 0 0 1 120.92 0H164a64 64 0 0 0-64-64Z"/><path fill="#FA0747" d="M100 160.46A60.46 60.46 0 0 1 39.54 100H36a64 64 0 1 0 128 0h-3.54A60.46 60.46 0 0 1 100 160.46Z"/></svg>'
};

export type CourseAvatar = {
  id: string;
  url: string;
  miniatureUrl: string;
};

export const courseAvatars: CourseAvatar[] = [
  {
    id: '1',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/1.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-1.png'
  },
  {
    id: '2',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/2.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-2.png'
  },
  {
    id: '3',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/3.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-3.png'
  },
  {
    id: '4',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/4.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-4.png'
  },
  {
    id: '5',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/5.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-5.png'
  },
  {
    id: '6',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/6.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-6.png'
  },
  {
    id: '7',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/7.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-7.png'
  },
  {
    id: '8',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/8.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-8.png'
  },
  {
    id: '9',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/9.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-9.png'
  },
  {
    id: '10',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/10.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-10.png'
  },
  {
    id: '11',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/11.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-11.png'
  },
  {
    id: '12',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/12.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-12.png'
  },
  {
    id: '13',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/13.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-13.png'
  },
  {
    id: '14',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/14.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-14.png'
  },
  {
    id: '15',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/15.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-15.png'
  },
  {
    id: '16',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/16.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-16.png'
  },
  {
    id: '17',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/17.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-17.png'
  },
  {
    id: '18',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/18.png',
    miniatureUrl: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/courses/miniature-18.png'
  }
];
