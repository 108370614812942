import { FC } from 'react';

import { Skeleton } from 'components/atoms';

const CurrentCourseSkeleton: FC = () => {
  return (
    <div className='flex grow flex-col'>
      <Skeleton height={15} width={100} className='mb-[20px]' />
      <Skeleton height={64} width={335} border={24} className='mb-[20px]' />
      <div className='flex gap-[10px]'>
        <Skeleton height={266} width={335} border={24} />
        <div className='flex grow flex-col gap-[10px]'>
          <Skeleton height={82} border={24} width={240} />
          <Skeleton height={82} border={24} width={240} />
          <Skeleton height={82} border={24} width={240} />
        </div>
      </div>
    </div>
  );
};

export default CurrentCourseSkeleton;
