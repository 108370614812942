import { useQuery } from 'react-query';

import { apiUsers } from 'api/api-users';
import { DEFAULT_STALE_TIME } from 'consts';

export const useGetUserCourseQuery = (userId: string) => {
  return useQuery(
    ['/courses/current-course-of-user', userId],
    async () => await apiUsers.getUserCourse(userId),
    {
      staleTime: DEFAULT_STALE_TIME,
      retry: false,
      onError(error) {
        // handleError(error);
      }
    }
  );
};
