import { FC, SVGProps } from 'react';

const IconDragHandle: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M10.625 7.5V5.25C10.625 4.61719 10.0977 4.125 9.5 4.125L7.25 4.125C6.61719 4.125 6.125 4.61719 6.125 5.25L6.125 7.5C6.125 8.09766 6.61719 8.625 7.25 8.625H9.5C10.0977 8.625 10.625 8.09766 10.625 7.5ZM10.625 13.125V10.875C10.625 10.2422 10.0977 9.75 9.5 9.75H7.25C6.61719 9.75 6.125 10.2422 6.125 10.875L6.125 13.125C6.125 13.7227 6.61719 14.25 7.25 14.25H9.5C10.0977 14.25 10.625 13.7227 10.625 13.125ZM10.625 18.75V16.5C10.625 15.8672 10.0977 15.375 9.5 15.375H7.25C6.61719 15.375 6.125 15.8672 6.125 16.5L6.125 18.75C6.125 19.3477 6.61719 19.875 7.25 19.875H9.5C10.0977 19.875 10.625 19.3477 10.625 18.75ZM17.375 7.5V5.25C17.375 4.61719 16.8477 4.125 16.25 4.125L14 4.125C13.3672 4.125 12.875 4.61719 12.875 5.25V7.5C12.875 8.09766 13.3672 8.625 14 8.625H16.25C16.8477 8.625 17.375 8.09766 17.375 7.5ZM17.375 13.125V10.875C17.375 10.2422 16.8477 9.75 16.25 9.75H14C13.3672 9.75 12.875 10.2422 12.875 10.875V13.125C12.875 13.7227 13.3672 14.25 14 14.25H16.25C16.8477 14.25 17.375 13.7227 17.375 13.125ZM17.375 18.75V16.5C17.375 15.8672 16.8477 15.375 16.25 15.375H14C13.3672 15.375 12.875 15.8672 12.875 16.5V18.75C12.875 19.3477 13.3672 19.875 14 19.875H16.25C16.8477 19.875 17.375 19.3477 17.375 18.75Z' />
    </svg>
  );
};

export default IconDragHandle;
