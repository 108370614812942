import React from 'react';

const IconDoubleStroke: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.22 12.7199C11.0793 12.5793 11.0002 12.3887 11 12.1899V11.8099C11.0023 11.6114 11.0811 11.4216 11.22 11.2799L16.36 6.14985C16.4539 6.0552 16.5817 6.00195 16.715 6.00195C16.8483 6.00195 16.9761 6.0552 17.07 6.14985L17.78 6.85985C17.8741 6.95202 17.9271 7.07816 17.9271 7.20985C17.9271 7.34154 17.8741 7.46769 17.78 7.55985L13.33 11.9999L17.78 16.4399C17.8747 16.5337 17.9279 16.6615 17.9279 16.7949C17.9279 16.9282 17.8747 17.056 17.78 17.1499L17.07 17.8499C16.9761 17.9445 16.8483 17.9978 16.715 17.9978C16.5817 17.9978 16.4539 17.9445 16.36 17.8499L11.22 12.7199Z'
        fill='currentColor'
      />
      <path
        d='M6.22 12.7199C6.07931 12.5793 6.00018 12.3887 6 12.1899V11.8099C6.0023 11.6114 6.08112 11.4216 6.22 11.2799L11.36 6.14985C11.4539 6.0552 11.5817 6.00195 11.715 6.00195C11.8483 6.00195 11.9761 6.0552 12.07 6.14985L12.78 6.85985C12.8741 6.95202 12.9271 7.07816 12.9271 7.20985C12.9271 7.34154 12.8741 7.46769 12.78 7.55985L8.33 11.9999L12.78 16.4399C12.8747 16.5337 12.9279 16.6615 12.9279 16.7949C12.9279 16.9282 12.8747 17.056 12.78 17.1499L12.07 17.8499C11.9761 17.9445 11.8483 17.9978 11.715 17.9978C11.5817 17.9978 11.4539 17.9445 11.36 17.8499L6.22 12.7199Z'
        fill='currentColor'
      />
    </svg>
  );
};
export default IconDoubleStroke;
