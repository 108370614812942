import { useMutation, useQueryClient } from 'react-query';

import { apiUsers } from 'api/api-users';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useGetCurrentUserQuery } from 'hooks/queries';

export const useUploadUserAvatarMutation = () => {
  const queryClient = useQueryClient();
  const { data: viewer } = useGetCurrentUserQuery();

  return useMutation(
    ({ userId, imageFile }: { userId: string; imageFile: File }) => {
      return apiUsers.uploadAvatar(userId, imageFile);
    },
    {
      onSuccess(_, { userId }) {
        notifySuc('Аватар обновлен');

        queryClient.invalidateQueries(`users/one/${userId}`);

        if (viewer?.id === userId) {
          queryClient.invalidateQueries('users/current');
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
