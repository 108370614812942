import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC } from 'react';
import { FieldArrayWithId, UseFieldArrayRemove, UseFormRegister } from 'react-hook-form';

import { ContentPartType, ContentPart as IContentPart } from 'api/api-courses';
import { FORM_ERROR_MESSAGES } from 'consts';
import { StepFormat } from 'models';
import styles from './ContentPart.module.css';

import { IconClose, IconDragHandle, IconSwap } from 'components/atoms/icons';
import Input from 'components/atoms/Input';

export interface FieldValues {
  condition: string;
  purpose: string;
  contentParts: Omit<IContentPart, 'order'>[];
  extraWords?: string;
  format: StepFormat;
  url?: string;
}

interface ContentPartProps {
  contentPart: FieldArrayWithId<FieldValues, 'contentParts', 'id'>;
  index: number;
  control: any;
  register: UseFormRegister<any>;
  remove: UseFieldArrayRemove;
  allowCorrectWordsRemove: boolean;
}

const ContentPart: FC<ContentPartProps> = ({
  contentPart: { isWord, id, type },
  index,
  control,
  remove,
  allowCorrectWordsRemove
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <li
      className='flex flex-col text-[16px] font-[500] leading-[135%] text-[#20233A]'
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      {type !== ContentPartType.WRAP && (
        <label className={styles.label}>{isWord ? 'пропущенное слово' : 'текст'}</label>
      )}

      <div className='flex flex-auto items-center'>
        <button
          type='button'
          className={styles.dragHandle}
          ref={setActivatorNodeRef}
          {...listeners}
        >
          <IconDragHandle color='#A6B0C9' />
        </button>

        {type === ContentPartType.WRAP ? (
          <div className='mr-[8px] flex flex-1 items-center gap-[8px]'>
            <h6 className='text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
              переход строки
            </h6>
            <span className='h-[2px] flex-1 bg-[#A6B0C9]' />
            <IconSwap color='#A6B0C9' />
          </div>
        ) : (
          <Input
            type='text'
            variant={isWord ? 'dark' : 'bordered'}
            width='big'
            name={`contentParts.${index}.text` as const}
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                }
              }
            }}
            containerClassName='mr-[8px]'
          />
        )}

        {(!isWord || allowCorrectWordsRemove) && (
          <button
            type='button'
            className='flex h-[48px] w-[56px] items-center justify-center rounded-[12px] bg-[#EEF0FF]'
            onClick={() => remove(index)}
          >
            <IconClose color='#71798F' />
          </button>
        )}
      </div>
    </li>
  );
};

export default ContentPart;
