import React, { SVGProps } from 'react';

const IconTrash: React.FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19.0312 6.0625H16.3945L15.1992 4.09375C14.8828 3.56641 14.3555 3.25 13.7578 3.25H10.207C9.60938 3.25 9.08203 3.56641 8.76562 4.09375L7.57031 6.0625H4.93359C4.47656 6.0625 4.125 6.44922 4.125 6.90625C4.125 7.32812 4.47656 7.75 4.93359 7.75H5.25L5.98828 19.668C6.02344 20.582 6.76172 21.25 7.67578 21.25H16.2891C17.2031 21.25 17.9414 20.582 17.9766 19.668L18.75 7.75H19.0312C19.4883 7.75 19.875 7.39844 19.875 6.90625C19.875 6.44922 19.4883 6.0625 19.0312 6.0625ZM10.207 4.9375H13.7578L14.4258 6.0625H9.53906L10.207 4.9375ZM16.2891 19.5625H7.67578L6.9375 7.75H17.0273L16.2891 19.5625Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconTrash;
