import { FC, MouseEvent, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { apiCourses, ICourse } from 'api/api-courses';
import miniAvatarSVG from 'assets/images/svg/mini-avatar.svg';
import { COURSES_COLORS } from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useCourseStatusIcon } from 'hooks/common';
import styles from './CourseCard.module.css';

import ConfirmationModal from 'components/atoms/ConfirmationModal';
import { IconPen, IconTrash } from 'components/atoms/icons';

interface CourseCardProps {
  course: ICourse;
  refetchCourse?: () => void;
}

const CourseCard: FC<CourseCardProps> = ({
  course: { id: courseId, title: courseTitle, status, color, miniImageUrl, level },
  refetchCourse
}) => {
  const navigate = useNavigate();
  const StatusIcon = useCourseStatusIcon(status);
  const goToCard = () => {
    navigate(`/courses/${courseId}`);
  };

  const [confirmationModalActive, setConfirmationModalActive] = useState(false);

  const { mutate: deleteCourseMutate, isLoading: deleteCourseLoading } = useMutation(
    (id: string) => {
      return apiCourses.deleteCourse(id);
    },
    {
      onSuccess() {
        notifySuc('Курс удален');

        if (refetchCourse) {
          refetchCourse();
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setConfirmationModalActive(true);
  };

  return (
    <>
      <div
        className={styles.container}
        style={{ backgroundColor: color || COURSES_COLORS[0].hex }}
        onClick={goToCard}
      >
        <header className={styles.header}>
          <span title={`Уровень ${level}`} className={styles.courseLevel}>
            {`Уровень ${level}`}
          </span>

          <button className={styles.buttonDelete} onClick={handleClick}>
            <IconTrash className='text-[#A1AABC]' />
          </button>
        </header>

        <div className={styles.content}>
          <div className={styles.titleAndEdit}>
            <p title={courseTitle} className={styles.title}>
              {courseTitle}
            </p>

            <button className={styles.buttonEdit}>
              <IconPen className='h-[20px] w-[20px] text-[#5770F3]' />
              Редактировать
            </button>
          </div>

          <div className={styles.image}>
            <img src={miniImageUrl || miniAvatarSVG} alt={courseTitle} />
          </div>

          {StatusIcon}
        </div>
      </div>

      {confirmationModalActive && (
        <ConfirmationModal
          title={
            <>
              Удалить курс <br />
              {courseTitle}?
            </>
          }
          text={
            <>
              Курс будет удален <b>навсегда и безвозвратно</b> вместе со всеми модулями, уроками и
              шагами.
            </>
          }
          isDelete
          confirmButtonText='Удалить курс'
          onConfirm={() => deleteCourseMutate(courseId)}
          onClose={() => setConfirmationModalActive(false)}
          isLoading={deleteCourseLoading}
        />
      )}
    </>
  );
};

export default CourseCard;
