import { FC, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { DEFAULT_AUDIO_VOLUME } from 'consts';
import styles from './AudioPlayer.module.css';

import { IconPause, IconPlayFilled, IconSound } from 'components/atoms/icons';
import { notifyErr } from 'helpers/notification';

interface AudioPlayerProps {
  url: string;
}

const AudioPlayer: FC<AudioPlayerProps> = ({ url }) => {
  const audioRef = useRef<any>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [audioInfo, setAudioInfo] = useState<any>(null);
  const [audioLength, setAudioLength] = useState<number | null>(null);
  const [getDurationCounter, setGetDurationCounter] = useState(0);
  const [audioPosition, setAudioPosition] = useState(0);

  const handlePlayAudio = () => {
    setIsPlaying(prev => !prev);
  };

  return (
    <div className={styles.container}>
      <div className={styles.iconAudio}>
        <IconSound color='#5770F3' />
      </div>

      <button type='button' className={styles.buttonPlay} onClick={handlePlayAudio}>
        {isPlaying ? <IconPause color='#5770F3' /> : <IconPlayFilled color='#5770F3' />}
      </button>

      <ReactPlayer
        muted={false}
        volume={DEFAULT_AUDIO_VOLUME}
        config={{ file: { forceAudio: true } }}
        url={url}
        onError={(error: unknown) => notifyErr('ERROR: ' + error)}
        onDuration={e => {
          if (e !== Infinity) {
            setAudioLength(e);
          }
        }}
        onProgress={e => {
          // For some reason, onDuration returns Infinity for some
          // recordings, so we first need to fast forward to the end
          // and rewind to the beginning to get the actual duration.
          // When fast forwarding to the end, we get the loadedSeconds
          // in the audioInfo and then display that as the duration (see below).
          // onProgress is called once when the file is loaded and then
          // continuously while the file isPlaying, so we must fast forward
          // and rewind only once (hence the getDurationCounter).

          // causes problems with long links
          if (getDurationCounter === 0 && !audioLength) {
            audioRef?.current.seekTo(1);
            audioRef?.current.seekTo(0);
            setGetDurationCounter(1);
          }

          setAudioInfo(e);

          // Current time/"position"
          setAudioPosition(e.playedSeconds);
        }}
        ref={audioRef}
        height={0}
        width={0}
        progressInterval={100}
        onEnded={() => {
          setIsPlaying(false);
        }}
        playing={isPlaying}
      />

      <div className={styles.audioPlayer}>
        <div className={styles.inputCont}>
          <input
            value={audioPosition}
            type='range'
            className={styles.audioRoad}
            onChange={e => {
              setAudioPosition(Number.parseFloat(e.target.value));
              audioRef.current.seekTo(e.target.value);
            }}
            min={0}
            max={
              (audioLength && Math.floor(audioLength)) || Math.floor(audioInfo?.loadedSeconds) || 0
            }
          />
          <div
            className={styles.progressBar}
            style={{
              left: `${(audioInfo?.played * 100 + 1 || 0).toFixed()}%`
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
