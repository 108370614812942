import { FC } from 'react';

import { Skeleton } from 'components/atoms';
import { PeriodsSkeleton } from '../PeriodsSkeleton';

const NewUsersSkeleton: FC = () => {
  return (
    <div className='flex w-full grow flex-col'>
      <PeriodsSkeleton />

      <header className='flex grow items-center justify-between py-[16px] px-[15px]'>
        <Skeleton height={24} width={170} />

        <div className='flex grow items-center justify-center gap-[8px]'>
          <Skeleton height={65} width={160} border={14} />
          <span className='h-[2px] w-[10px] bg-[#20233A]' />
          <Skeleton height={65} width={160} border={14} />
        </div>

        <Skeleton height={48} width={230} border={62} />
      </header>

      <Skeleton height={405} border={0} />
    </div>
  );
};

export default NewUsersSkeleton;
