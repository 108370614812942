import React from 'react';

const IconPen: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M18.9962 3.91797C18.5744 3.49609 17.9767 3.25 17.4142 3.25C16.8517 3.25 16.2541 3.49609 15.8322 3.91797L4.58218 15.168C4.26577 15.4844 4.05483 15.8711 3.94936 16.293L3.00014 20.582C2.92983 20.9336 3.21108 21.25 3.52749 21.25C3.56264 21.25 3.63296 21.25 3.66811 21.25C3.66811 21.25 6.62124 20.6172 7.95718 20.3008C8.37905 20.2305 8.73061 20.0195 9.04702 19.7031L20.3322 8.41797C21.2111 7.53906 21.2111 6.13281 20.3322 5.25391L18.9962 3.91797ZM7.85171 18.5078C7.78139 18.6133 7.67593 18.6484 7.57046 18.6836C6.93764 18.8242 5.91811 19.0352 5.03921 19.2461L5.60171 16.6445C5.63686 16.5391 5.67202 16.4336 5.77749 16.3633L14.2501 7.85547L16.3947 10L7.85171 18.5078Z'
      fill='currentColor'
    />
  </svg>
);

export default IconPen;
