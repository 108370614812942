import { FC } from 'react';
import { Control, useController } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { SEND_TIME } from 'models';
import { FormValues } from '../../model';

import Button from 'components/atoms/Button';
import { IconBack } from 'components/atoms/icons';
import { DateTime, Repeat } from './components';

interface Step3Props {
  control: Control<FormValues>;
  isDirty: boolean;
  onPrev: () => void;
  saveAsDraft: () => void;
  isLoading: boolean;
}

const Step3: FC<Step3Props> = ({ control, isDirty, onPrev, saveAsDraft, isLoading }) => {
  const { field, fieldState } = useController({
    name: 'sendTime',
    control,
    rules: {
      required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
    }
  });

  return (
    <div className='flex flex-1 flex-col'>
      <h4 className='mb-[30px] text-[18px] font-[500] leading-[21px] text-[#20233A]'>Расписание</h4>

      <div className='mb-auto flex flex-col'>
        <div className='mb-[30px] flex flex-col'>
          <h6 className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            когда
          </h6>

          <div className='flex gap-[8px]'>
            <button
              type='button'
              className={
                'rounded-[12px] py-[16px] px-[24px] text-[16px] font-[500] leading-[19px] ' +
                `${
                  field.value === SEND_TIME.NOW
                    ? 'bg-[#162030] text-[#FFFFFF]'
                    : 'bg-[#EEF0FF] text-[#20233A]'
                }`
              }
              onClick={() => field.onChange(SEND_TIME.NOW)}
            >
              Cейчас
            </button>
            <button
              type='button'
              className={
                'rounded-[12px] py-[16px] px-[24px] text-[16px] font-[500] leading-[19px] ' +
                `${
                  field.value === SEND_TIME.DATE
                    ? 'bg-[#162030] text-[#FFFFFF]'
                    : 'bg-[#EEF0FF] text-[#20233A]'
                }`
              }
              onClick={() => field.onChange(SEND_TIME.DATE)}
            >
              Выбрать дату и время
            </button>
            <button
              type='button'
              className={
                'rounded-[12px] py-[16px] px-[24px] text-[16px] font-[500] leading-[19px] ' +
                `${
                  field.value === SEND_TIME.REPEAT
                    ? 'bg-[#162030] text-[#FFFFFF]'
                    : 'bg-[#EEF0FF] text-[#20233A]'
                }`
              }
              onClick={() => field.onChange(SEND_TIME.REPEAT)}
            >
              Повторять
            </button>
          </div>

          {!!fieldState.error && (
            <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
              {fieldState.error.message}
            </span>
          )}
        </div>

        {field.value === SEND_TIME.DATE && <DateTime control={control} />}
        {field.value === SEND_TIME.REPEAT && <Repeat control={control} />}
      </div>

      <div className='mt-[30px] flex justify-between'>
        <Button
          type='button'
          variant='secondary'
          title='Назад'
          iconPosition='left'
          icon={<IconBack className='text-[#71798F]' />}
          onClick={onPrev}
          isDisabled={isLoading}
        />
        <div className='flex gap-[8px]'>
          <Button
            type='button'
            variant='secondary'
            title='Сохранить как черновик'
            onClick={saveAsDraft}
            isDisabled={!isDirty}
            isLoading={isLoading}
          />
          <Button
            type='submit'
            variant='primary'
            title='Проверить'
            className='min-w-[198px]'
            isDisabled={!isDirty || isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Step3;
