import {
  CreateNotificationDto,
  GetNotificationsDto,
  INotification,
  Pagination,
  UpdateNotificationDto
} from 'models';
import { instance } from './api';

interface GetNotificationsReponse {
  data: INotification[];
  pagination: Pagination;
}

export const apiNotifications = {
  createNotification: async ({
    title,
    message,
    image,
    for: notificationFor,
    recipientsList,
    recipientsRole,
    sendTime,
    sendDate,
    repeatDays,
    repeatTime,
    repeatType,
    repeatEveryMonth,
    repeatEveryYear,
    startDate,
    endDate,
    status
  }: CreateNotificationDto) => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('message', message);
    image && formData.append('image', image);
    formData.append('for', notificationFor);
    recipientsList && formData.append('recipientsList[]', JSON.stringify(recipientsList));
    recipientsRole && formData.append('recipientsRole', recipientsRole);
    formData.append('sendTime', sendTime);
    sendDate && formData.append('sendDate', sendDate);
    repeatDays && formData.append('repeatDays[]', JSON.stringify(repeatDays));
    repeatTime && formData.append('repeatTime', repeatTime);
    repeatType && formData.append('repeatType', repeatType);
    repeatEveryMonth && formData.append('repeatEveryMonth', JSON.stringify(repeatEveryMonth));
    repeatEveryYear && formData.append('repeatEveryYear', JSON.stringify(repeatEveryYear));
    startDate && formData.append('startDate', startDate);
    endDate && formData.append('endDate', endDate);
    formData.append('status', status);

    const response = await instance.post<INotification>('/notifications', formData);
    return response.data;
  },
  getNotifications: async ({
    page,
    itemsPerPage,
    search,
    status,
    recipient,
    sortBy
  }: GetNotificationsDto) => {
    let query = `/notifications?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (!!search) {
      query += `&search=${search}`;
    }
    if (!!status) {
      query += `&status=${status}`;
    }
    if (!!recipient) {
      query += `&recipient=${recipient}`;
    }
    if (!!sortBy) {
      const [sort, sortOrder] = sortBy.split('-');
      query += `&sort=${sort}&sortOrder=${sortOrder}`;
    }

    const response = await instance.get<GetNotificationsReponse>(query);
    return response.data;
  },
  getNotification: async (id: string) => {
    const response = await instance.get<INotification>(`/notifications/${id}`);
    return response.data;
  },
  updateNotification: async ({
    id,
    body,
    body: {
      title,
      message,
      image,
      for: notificationFor,
      recipientsList,
      recipientsRole,
      sendTime,
      sendDate,
      repeatDays,
      repeatTime,
      repeatType,
      repeatEveryMonth,
      repeatEveryYear,
      startDate,
      endDate,
      status
    }
  }: UpdateNotificationDto) => {
    const formData = new FormData();
    title && formData.append('title', title);
    message && formData.append('message', message);
    image && formData.append('image', image);
    notificationFor && formData.append('for', notificationFor);
    recipientsList && formData.append('recipientsList[]', JSON.stringify(recipientsList));
    recipientsRole && formData.append('recipientsRole', recipientsRole);
    sendTime && formData.append('sendTime', sendTime);
    sendDate && formData.append('sendDate', sendDate);
    repeatDays && formData.append('repeatDays[]', JSON.stringify(repeatDays));
    repeatTime && formData.append('repeatTime', repeatTime);
    repeatType && formData.append('repeatType', repeatType);
    repeatEveryMonth && formData.append('repeatEveryMonth', JSON.stringify(repeatEveryMonth));
    repeatEveryYear && formData.append('repeatEveryYear', JSON.stringify(repeatEveryYear));
    startDate && formData.append('startDate', startDate);
    endDate && formData.append('endDate', endDate);
    status && formData.append('status', status);

    const response = await instance.put<INotification>(`/notifications/${id}`, formData);
    return response.data;
  },
  deleteNotification: async (id: string) => {
    const response = await instance.delete<INotification>(`/notifications/${id}`);
    return response.data;
  }
};
