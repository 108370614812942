import React, { ChangeEvent, FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { FORM_ERROR_MESSAGES } from 'consts';
import { useUploadUserAvatarMutation } from 'hooks/mutations';
import { useGetUserInfoQuery } from 'hooks/queries';
import { useUpdateUserInfoMutation } from 'hooks/user';
import { userAvatars } from './const';

import Button from 'components/atoms/Button';
import { IconCamera, IconClose } from 'components/atoms/icons';
import Modal from 'components/atoms/Modal';
import { Cropper, CropperRef, RectangleStencil } from 'react-advanced-cropper';

import 'react-advanced-cropper/dist/style.css';

type IParams = {
  id: string;
};

type FormValues = {
  avatarUrl: string;
};

type SelectAvatarFormProps = {
  onClose?: () => void;
};

const SelectAvatarForm: FC<SelectAvatarFormProps> = ({ onClose }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { id: userId } = useParams<keyof IParams>() as IParams;
  const { data: user } = useGetUserInfoQuery(userId);
  const { mutate: updateUserMutate, isLoading: updateUserLoading } = useUpdateUserInfoMutation();
  const { mutate: uploadAvatarMutate, isLoading: uploadAvatarLoading } =
    useUploadUserAvatarMutation();

  const [isOpenCropperModal, setIsOpenCropperModal] = useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [fileType, setFilType] = useState<string>();

  const cropperRef = useRef<CropperRef>(null);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm<FormValues>({
    mode: 'onSubmit'
  });

  useEffect(() => {
    if (!user) return;
    reset({ avatarUrl: user.avatarUrl });
  }, [reset, user]);

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || !user) return;

    const imageFile = files[0];

    setFileName(imageFile.name);
    setFilType(imageFile.type);
    const blob = URL.createObjectURL(imageFile);
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedImage(blob);
      handleToggleCropperModal();
    };
    reader.readAsArrayBuffer(imageFile);
  };

  const handleSaveCropped = () => {
    cropperRef?.current?.getCanvas()?.toBlob(blob => {
      if (blob && fileName) {
        const imageFile = new File([blob], fileName, { type: fileType });
        uploadAvatarMutate({ userId: user!.id, imageFile }, { onSuccess: onClose });
      } else {
        handleToggleCropperModal();
      }
    }, fileType);
  };

  const handleToggleCropperModal = () => setIsOpenCropperModal(prev => !prev);

  const onSubmit = ({ avatarUrl }: FormValues) => {
    if (!user || !avatarUrl) return;
    updateUserMutate({ id: user.id, body: { avatarUrl } }, { onSuccess: onClose });
  };

  if (isOpenCropperModal) {
    return (
      <Modal onClose={handleToggleCropperModal}>
        <div>
          <Cropper
            ref={cropperRef}
            className='mb-8 max-h-[400px] min-h-[400px] min-w-[400px] max-w-[800px]'
            backgroundWrapperClassName='w-auto'
            src={uploadedImage}
            stencilComponent={RectangleStencil}
            stencilProps={{
              aspectRatio: 1 / 1
            }}
          />
          <div className='flex justify-between'>
            <Button
              type='button'
              variant='secondary'
              onClick={handleToggleCropperModal}
              title='Отменить'
              className='w-[180px]'
            />
            <Button
              type='button'
              variant='primary'
              onClick={handleSaveCropped}
              isLoading={uploadAvatarLoading}
              isDisabled={uploadAvatarLoading}
              title='Сохранить'
              className='w-[180px]'
            />
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <form className='flex w-[664px] flex-col' onSubmit={handleSubmit(onSubmit)}>
      <header className='mb-[32px] flex h-[32px] items-center justify-between'>
        <h2 className='text-[22px] font-[700] leading-[26px] text-[#20233a]'>Выбрать аватар</h2>
        <button className='h-[24px] w-[24px]' onClick={onClose}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <Controller
        control={control}
        name='avatarUrl'
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <div className='mb-[24px] flex flex-col'>
            <div className='flex flex-wrap gap-[12px]'>
              {userAvatars.map(avatar => (
                <button
                  type='button'
                  key={avatar.url}
                  onClick={() => onChange(avatar.url)}
                  className={
                    'h-[100px] w-[100px] rounded-[50%] cursor-pointer overflow-hidden ' +
                    `${avatar.url === value ? 'border-[#5770F3] border-[4px] border-solid' : ''}`
                  }
                >
                  <img src={avatar.url} alt={avatar.label} className='h-full w-full' />
                </button>
              ))}
            </div>

            {!!error && (
              <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
                {error.message}
              </span>
            )}
          </div>
        )}
      />

      <div className='flex justify-between gap-[8px]'>
        <button
          type='button'
          className={
            'h-[64px] py-[12px] pr-[16px] pl-[20px] flex items-center gap-[10px] ' +
            'rounded-[16px] bg-[#EEF0FF] text-[16px] leading-[19px] font-[700] ' +
            'text-[#5770F3] cursor-pointer'
          }
          onClick={() => inputRef.current?.click()}
        >
          <input
            type='file'
            accept='image/*'
            ref={inputRef}
            className='hidden'
            onChange={handleUpload}
            onClick={(event: MouseEvent<HTMLInputElement>) => {
              (event.target as HTMLInputElement).value = '';
            }}
          />
          <IconCamera className='h-[32px] w-[32px]' />
          Загрузить свое фото
        </button>

        <div className='flex gap-[8px]'>
          <Button
            type='button'
            variant='secondary'
            title='Отменить'
            className='w-[180px]'
            onClick={onClose}
            isDisabled={updateUserLoading || uploadAvatarLoading}
          />
          <Button
            type='submit'
            variant='primary'
            title='Выбрать'
            className='w-[180px]'
            isDisabled={!isDirty}
            isLoading={updateUserLoading || uploadAvatarLoading}
          />
        </div>
      </div>
    </form>
  );
};

export default SelectAvatarForm;
