import { useMutation, useQueryClient } from 'react-query';

import { apiSubscriptions } from 'api';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { DeleteSubscriptionsDto } from 'models/subscription';

export const useDeleteSubscriptionsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (dto: DeleteSubscriptionsDto) => {
      return apiSubscriptions.deleteSubscription(dto);
    },
    {
      onSuccess(_, { userId }) {
        notifySuc('Подписка удалена');

        queryClient.invalidateQueries([`purchases/subscriptions/${userId}`]);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
