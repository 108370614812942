import { FC } from 'react';

import { FORM_ERROR_MESSAGES, MAKING_SENTENCE_REGEXP, TEST_STEPS_ERROR_MESSAGES } from 'consts';
import styles from './ExtraWords.module.css';

import Input from 'components/atoms/Input';

interface ExtraWordsProps {
  control: any;
}

const ExtraWords: FC<ExtraWordsProps> = ({ control }) => {
  return (
    <div className={styles.extraWords}>
      <label className={styles.label}>лишние слова</label>

      <Input
        type='text'
        variant='dark'
        width='big'
        name='extraWords'
        control={control}
        rules={{
          required: false,
          maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
          validate: {
            doesntConsistOfSpaces: (value: any) => {
              return !value.length || !!value.trim()
                ? true
                : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
            },
            noSpaceBeforeOrAfterSeparator: (value: any) => {
              return !MAKING_SENTENCE_REGEXP.test(value)
                ? true
                : TEST_STEPS_ERROR_MESSAGES.MAKING_SENTENCE;
            }
          }
        }}
      />

      <span className={styles.extraWords__description}>
        Разделите слова при помощи символа “ / “ (до и после символа “ / ” должны быть пробелы).
        Например: «собака / колбаса / солнце»
      </span>
    </div>
  );
};

export default ExtraWords;
