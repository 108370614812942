import React from 'react';

const IconMakingSentence: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g id='Icons'>
      <rect
        id='Rectangle 666'
        opacity='0.5'
        x='5'
        y='16'
        width='4'
        height='3'
        rx='1'
        fill='currentColor'
      />
      <rect
        id='Rectangle 675'
        opacity='0.5'
        x='10'
        y='16'
        width='4'
        height='3'
        rx='1'
        fill='currentColor'
      />
      <rect
        id='Rectangle 676'
        opacity='0.5'
        x='15'
        y='16'
        width='4'
        height='3'
        rx='1'
        fill='currentColor'
      />
      <rect id='Rectangle 667' x='5' y='7' width='3' height='1' rx='0.5' fill='currentColor' />
      <rect id='Rectangle 669' x='5' y='11' width='9' height='1' rx='0.5' fill='currentColor' />
      <rect id='Rectangle 668' x='10' y='7' width='9' height='1' rx='0.5' fill='currentColor' />
      <rect id='Rectangle 670' x='16' y='11' width='3' height='1' rx='0.5' fill='currentColor' />
    </g>
  </svg>
);

export default IconMakingSentence;
