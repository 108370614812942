import {
  addDays,
  addMonths,
  addYears,
  differenceInDays,
  endOfMonth,
  endOfYear,
  format,
  getDaysInMonth,
  getDaysInYear,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { Calendar as CalendarIcon, ChevronLeft, ChevronRight } from 'lucide-react';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { DateRange } from 'react-day-picker';

import { cn } from 'utils';
import { Button } from './button';
import { Calendar } from './calendar';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from './popover';

type DateRangePickerProps = React.HTMLAttributes<HTMLDivElement> & {
  date: DateRange;
  setDate: Dispatch<SetStateAction<DateRange>>;
  className?: string;
};

export enum Presets {
  TODAY = 'Сегодня',
  YESTERDAY = 'Вчера',
  THIS_WEEK = 'Эта неделя',
  LAST_WEEK = 'Прошлая неделя',
  LAST_2_WEEKS = 'Последние две недели',
  THIS_MONTH = 'Этот месяц',
  LAST_MONTH = 'Прошлый месяц',
  LAST_30_DAYS = 'Последние 30 дней',
  LAST_90_DAYS = 'Последние 90 дней',
  LAST_12_MONTHS = 'Последние 12 месяцев',
  THIS_YEAR = 'Этот год',
  LAST_YEAR = 'Прошлый год'
}

export const presets: { label: Presets; value: DateRange }[] = [
  { label: Presets.TODAY, value: { from: new Date() } },
  { label: Presets.YESTERDAY, value: { from: addDays(new Date(), -1) } },
  {
    label: Presets.THIS_WEEK,
    value: {
      from: startOfWeek(new Date(), { weekStartsOn: 1, locale: ru }),
      to: addDays(startOfWeek(new Date()), 7)
    }
  },
  {
    label: Presets.LAST_WEEK,
    value: {
      from: startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1, locale: ru }),
      to: addDays(startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1, locale: ru }), 6)
    }
  },
  {
    label: Presets.LAST_2_WEEKS,
    value: {
      from: startOfWeek(addDays(new Date(), -14), { weekStartsOn: 1 }),
      to: addDays(startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }), 6)
    }
  },
  {
    label: Presets.THIS_MONTH,
    value: {
      from: startOfMonth(new Date()),
      to: addDays(startOfMonth(new Date()), getDaysInMonth(new Date()) - 1)
    }
  },
  {
    label: Presets.LAST_MONTH,
    value: {
      from: startOfMonth(addMonths(new Date(), -1)),
      to: addDays(
        startOfMonth(addMonths(new Date(), -1)),
        getDaysInMonth(addMonths(new Date(), -1)) - 1
      )
    }
  },
  {
    label: Presets.LAST_30_DAYS,
    value: {
      from: addDays(new Date(), -30),
      to: new Date()
    }
  },
  {
    label: Presets.LAST_90_DAYS,
    value: {
      from: addDays(new Date(), -90),
      to: new Date()
    }
  },
  {
    label: Presets.LAST_12_MONTHS,
    value: {
      from: addMonths(new Date(), -12),
      to: new Date()
    }
  },
  {
    label: Presets.THIS_YEAR,
    value: {
      from: startOfYear(new Date()),
      to: addDays(startOfYear(new Date()), getDaysInYear(new Date()) - 1)
    }
  },
  {
    label: Presets.LAST_YEAR,
    value: {
      from: startOfYear(addYears(new Date(), -1)),
      to: addDays(
        startOfYear(addYears(new Date(), -1)),
        getDaysInYear(addYears(new Date(), -1)) - 1
      )
    }
  }
];

const getNewDateRange = (date: DateRange, preset?: Presets, isLeft = false): DateRange => {
  const from = date.from || new Date();
  const to = date.to || date.from || new Date();

  switch (preset) {
    case Presets.TODAY:
    case Presets.YESTERDAY:
      return { from: addDays(from, isLeft ? -1 : 1) };
    case Presets.THIS_WEEK:
    case Presets.LAST_WEEK:
      return {
        from: addDays(from, isLeft ? -7 : 7),
        to: addDays(to, isLeft ? -7 : 7)
      };
    case Presets.LAST_2_WEEKS:
      return {
        from: addDays(from, isLeft ? -14 : 14),
        to: addDays(to, isLeft ? -14 : 14)
      };
    case Presets.THIS_MONTH:
    case Presets.LAST_MONTH:
      return {
        from: startOfMonth(isLeft ? addDays(from, -1) : addDays(to, 1)),
        to: endOfMonth(isLeft ? addDays(from, -1) : addDays(to, 1))
      };
    case Presets.LAST_30_DAYS:
      return {
        from: addDays(from, isLeft ? -31 : 31),
        to: addDays(to, isLeft ? -31 : 31)
      };
    case Presets.LAST_90_DAYS:
      return {
        from: addDays(from, isLeft ? -91 : 91),
        to: addDays(to, isLeft ? -91 : 91)
      };
    case Presets.LAST_12_MONTHS:
      return {
        from: addMonths(from, isLeft ? -12 : 12),
        to: addMonths(to, isLeft ? -12 : 12)
      };
    case Presets.THIS_YEAR:
    case Presets.LAST_YEAR:
      return {
        from: startOfYear(isLeft ? addDays(from, -1) : addDays(to, 1)),
        to: endOfYear(isLeft ? addDays(from, -1) : addDays(to, 1))
      };
    default: {
      const offset = (differenceInDays(to, from) || 0) + 1;
      return {
        from: from && addDays(from, isLeft ? -offset : offset),
        to: to && addDays(to, isLeft ? -offset : offset)
      };
    }
  }
};

const DateRangePicker = (props: DateRangePickerProps) => {
  const { date, setDate, className } = props;
  const [preset, setPreset] = useState<Presets | undefined>();

  const handleLeft = useCallback(() => {
    const newDateRange = getNewDateRange(date, preset, true);
    setDate(newDateRange);
  }, [date, preset]);

  const handleRight = useCallback(() => {
    const newDateRange = getNewDateRange(date, preset, false);
    setDate(newDateRange);
  }, [date, preset]);

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <div className='flex items-center'>
          <Button
            id='date'
            variant={'outline'}
            onClick={handleLeft}
            className={cn('', !date && 'text-muted-foreground')}
          >
            <ChevronLeft className='h-4 w-4' />
          </Button>

          <PopoverTrigger asChild>
            <Button
              id='date'
              variant={'outline'}
              className={cn(
                'max-w-[300px] justify-start text-left font-normal',
                !date && 'text-muted-foreground'
              )}
            >
              <CalendarIcon className='mr-2 h-4 w-4' />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, 'dd.MM.yyyy')} - {format(date.to, 'dd.MM.yyyy')}
                  </>
                ) : (
                  format(date.from, 'dd.MM.yyyy')
                )
              ) : (
                <span>Выберите дату</span>
              )}
            </Button>
          </PopoverTrigger>

          <Button
            id='date'
            variant={'outline'}
            onClick={handleRight}
            className={cn('', !date && 'text-muted-foreground')}
          >
            <ChevronRight className='h-4 w-4' />
          </Button>
        </div>

        <PopoverContent className='w-auto p-0 flex' align='start'>
          <div className='flex flex-col'>
            {presets.map(({ label, value: { from, to } }, index) => (
              <PopoverClose asChild key={index}>
                <Button
                  id='date'
                  variant={'outline'}
                  onClick={() => {
                    setDate({
                      from,
                      to
                    });
                    setPreset(label);
                  }}
                  className={cn('py-1 px-2', { 'bg-accent': preset === label })}
                >
                  {label}
                </Button>
              </PopoverClose>
            ))}
          </div>

          <Calendar
            initialFocus
            weekStartsOn={1}
            mode='range'
            defaultMonth={date?.from}
            selected={date}
            onSelect={range => {
              setPreset(undefined);
              range && setDate(range);
            }}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export { DateRangePicker, addDays, type DateRange };
