import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { apiWords } from 'api/api-words';
import { handleError } from 'helpers/handleError';
import styles from './CreateWordModal.module.css';

import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';
import Input from 'components/atoms/Input/Input';
import Modal from 'components/atoms/Modal';
import { FORM_ERROR_MESSAGES } from 'consts';
import { notifySuc } from 'helpers/notification';

interface CreateWordModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate: () => void;
}

const CreateWordModal: FC<CreateWordModalProps> = ({ isOpen, onClose, onCreate }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset
  } = useForm<FieldValues>({ mode: 'onSubmit' });

  const { mutateAsync: addNewWordMutate, isLoading: addNewWordLoading } = useMutation(
    ({ original, translation }: { original: string; translation: string }) => {
      return apiWords.createWord(original, translation);
    },
    {
      onSuccess() {
        notifySuc('Слово добавлено в урок');

        onCreate();
        onClose();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const onSubmit = async (data: FieldValues) => {
    const { original, translation } = data;
    await addNewWordMutate({ original: original.trim(), translation: translation.trim() });
    reset();
  };

  return isOpen ? (
    <Modal className='z-20' onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Добавить новое слово в словарь</div>

          <button className={styles.buttonClose} onClick={onClose}>
            <IconClose color='#71798F' />
          </button>
        </div>

        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.fields}>
            <Input
              type='text'
              name='original'
              placeholder='Слово на корейском'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
                validate: {
                  doesntConsistOfSpaces: (value: any) => {
                    return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                  }
                }
              }}
            />

            <Input
              type='text'
              name='translation'
              placeholder='Перевод на русский'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
                validate: {
                  doesntConsistOfSpaces: (value: any) => {
                    return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                  }
                }
              }}
            />
          </div>

          <div className={styles.buttons}>
            <Button
              type='reset'
              variant='secondary'
              title='Отменить'
              className='w-[198px]'
              onClick={onClose}
              isDisabled={addNewWordLoading}
            />

            <Button
              type='submit'
              variant='primary'
              title='Добавить'
              className='w-[198px]'
              isDisabled={!isDirty}
              isLoading={addNewWordLoading}
            />
          </div>
        </form>
      </div>
    </Modal>
  ) : null;
};

export default CreateWordModal;
