import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { useDeleteWordMutation } from 'hooks/mutations';
import styles from './DeleteWordForm.module.css';

import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';
import Input from 'components/atoms/Input';

interface DeleteWordFormProps {
  wordId: string;
  currentWord: string;
  onSuccess: () => void;
  onCancel: () => void;
}

const DeleteWordForm: React.FC<DeleteWordFormProps> = ({
  wordId,
  currentWord,
  onSuccess,
  onCancel
}) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FieldValues>({ mode: 'onSubmit' });
  const { mutate: deleteWordMutate, isLoading: deleteWordLoading } = useDeleteWordMutation();

  const onSubmit = async () => {
    deleteWordMutate(
      { wordId },
      {
        onSuccess: () => {
          onCancel();
          onSuccess();
        }
      }
    );
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <span className={styles.title}>Удалить карточку слова?</span>
        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <p className={styles.text}>
        Слово будет{' '}
        <b>
          удалено навсегда из Общего словаря и
          <br />
          из всех курсов и уроков
        </b>
        , к которым оно относится.
        <br />
        <br />
        Чтобы подтвердить, пожалуйста, <b>введите это слово на русском языке</b>:
      </p>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          <Input
            type='text'
            name='value'
            variant='dark'
            width='big'
            placeholder='Слово на русском'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 64, message: 'Максимальная длина 64 символа' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                },
                equalToCurrentValue: (value: string) =>
                  value.trim() === currentWord.trim() ? true : FORM_ERROR_MESSAGES.INCORRECT_WORD
              }
            }}
            loading={deleteWordLoading}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            isDisabled={deleteWordLoading}
            onClick={onCancel}
          />

          <Button
            type='submit'
            variant='warning'
            title='Удалить слово'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={deleteWordLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default DeleteWordForm;
