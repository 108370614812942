import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { apiWords } from 'api/api-words';
import { FORM_ERROR_MESSAGES } from 'consts';
import { handleError } from 'helpers/handleError';
import styles from './AddExampleForm.module.css';

import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';
import Input from 'components/atoms/Input/Input';
import { notifySuc } from 'helpers/notification';

interface AddExampleFormProps {
  wordId: string;
  onAdd: () => void;
  onCancel: () => void;
}

const AddExampleForm: FC<AddExampleFormProps> = ({ wordId, onAdd, onCancel }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FieldValues>({ mode: 'onSubmit' });

  const { mutate: addExampleMutate, isLoading: addExampleLoading } = useMutation(
    ({ original, translation }: { original: string; translation: string }) => {
      return apiWords.addExample(wordId, original, translation);
    },
    {
      onSuccess() {
        notifySuc('Пример добавлен');

        onCancel();
        onAdd();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const onSubmit = async (data: FieldValues) => {
    const { original, translation } = data;

    addExampleMutate({ original: original.trim(), translation: translation.trim() });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Добавить пример</div>

        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose />
        </button>
      </div>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          <Input
            type='text'
            name='original'
            placeholder='Выражение на корейском'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                }
              }
            }}
          />

          <Input
            type='text'
            name='translation'
            placeholder='Перевод на русский'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                }
              }
            }}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            onClick={onCancel}
            isDisabled={addExampleLoading}
          />

          <Button
            type='submit'
            variant='primary'
            title='Добавить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={addExampleLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default AddExampleForm;
