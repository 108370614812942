import { notifySuc } from 'helpers/notification';

import { CreateCourseData, ICourse, apiCourses } from 'api';
import { handleError } from 'helpers/handleError';
import { useMutation, useQueryClient } from 'react-query';

export const useCreateCourseMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (createCourseData: CreateCourseData) => {
      return apiCourses.createCourse(createCourseData);
    },
    {
      onSuccess(data) {
        notifySuc('Курс создан');

        const coursesQuery = 'courses/all';

        queryClient.invalidateQueries(['courses/all'], {
          refetchActive: false,
          refetchInactive: false
        });

        const oldData = queryClient.getQueryData<ICourse[]>(coursesQuery);
        if (!oldData) return;

        queryClient.setQueryData<ICourse[]>(coursesQuery, [...oldData, data]);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
