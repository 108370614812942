import { FC } from 'react';

import { Skeleton } from 'components/atoms';

const CourseContentSkeleton: FC = () => {
  return (
    <div className='flex grow flex-col'>
      <header className='mb-[32px] flex h-[50px] items-center justify-between'>
        <Skeleton height={36} width={300} />
        <Skeleton height={48} width={218} border={14} />
      </header>

      <ul className='mb-[16px] flex flex-col gap-[8px]'>
        <Skeleton height={64} />
        <Skeleton height={64} />
        <Skeleton height={64} />
        <Skeleton height={64} />
        <Skeleton height={64} />
        <Skeleton height={64} />
        <Skeleton height={64} />
        <Skeleton height={64} />
      </ul>

      <Skeleton height={48} width={260} border={62} className='self-center' />
    </div>
  );
};

export default CourseContentSkeleton;
