import { useMutation } from 'react-query';

import { SetStepImageDto, apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';

export const useUploadStepImageMutation = () => {
  return useMutation(
    ({ stepId, image, type }: SetStepImageDto) => {
      return apiCourses.setStepImage({ stepId, type, image });
    },
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
