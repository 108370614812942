import React from 'react';

const IconCheckOn: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='32' height='32' rx='16' fill='currentColor' />
      <path d='M8 15L14 21L24 11' stroke='white' strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
};

export default IconCheckOn;
