/* eslint-disable react-hooks/exhaustive-deps */

import { OutputData } from '@editorjs/editorjs';
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  CreateMakingSentenceStepData,
  MakingSentenceStep as IMakingSentenceStep,
  ISection,
  STEP_TYPES,
  UpdateMakingSentenceStepData,
  apiCourses
} from 'api/api-courses';
import {
  DEFAULT_EDITABLE_CONDITION_MAKING_SENTENCE,
  DEFAULT_EDITABLE_PURPOSE_DATA,
  FORM_ERROR_MESSAGES,
  MAKING_SENTENCE_REGEXP,
  TEST_STEPS_ERROR_MESSAGES
} from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useUploadStepImageMutation } from 'hooks/mutations';
import { AudioPart, StepFormat } from 'models';
import { normalizeData } from 'utils';
import { useDeleteStep } from '../hooks';
import styles from './MakingSentenceStep.module.css';

import Button from 'components/atoms/Button';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import Input from 'components/atoms/Input';
import { IconMakingSentence, IconTrash } from 'components/atoms/icons';
import { Condition, ExtraWords, Format, Hint, Purpose } from '../components';

export interface FieldValues {
  condition?: string;
  editableCondition: OutputData;
  purpose?: string;
  editablePurpose: OutputData;
  answer: string;
  extraWords: string;
  hint?: string;
  format: StepFormat;
  textForAudio?: string;
  audioParts?: AudioPart[];
  url?: string;
  picture?: FileList;
  audio?: FileList;
  videoUrl?: string;
}

interface MakingSentenceStepProps {
  step: IMakingSentenceStep;
  section: ISection;
  stepIndex: number | 'potential';
  isPotential?: boolean;
  deletePotentialStep?: () => void;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  setOnSubmitCallback: Dispatch<SetStateAction<(() => void) | null>>;
  refetchSection: () => void;
}

const MakingSentenceStep: FC<MakingSentenceStepProps> = ({
  step,
  section,
  stepIndex,
  isPotential,
  deletePotentialStep,
  setIsDirty,
  setIsValid,
  setOnSubmitCallback,
  refetchSection
}) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    formState: { isDirty, isValid, errors }
  } = useForm<FieldValues>({
    mode: 'onSubmit',
    defaultValues: {
      condition: step.condition,
      editableCondition: step.editableCondition || DEFAULT_EDITABLE_CONDITION_MAKING_SENTENCE,
      purpose: step.purpose,
      editablePurpose: step.editablePurpose || DEFAULT_EDITABLE_PURPOSE_DATA,
      answer: step.answer || '',
      extraWords: step.extraWords?.join(' / ') || '',
      hint: step.hint || '',
      format: step.format || 'text',
      textForAudio: step.textForAudio || '',
      audioParts: !!step.audioList?.length ? step.audioList : [],
      url: step.url,
      videoUrl: step.format === 'video' ? step.url : undefined
    }
  });

  const format = watch('format');

  const { mutate: setStepImageMutate, isLoading: setStepImageLoading } =
    useUploadStepImageMutation();

  const { mutateAsync: addMakingSentenceStepMutate, isLoading: isLoadingCreate } = useMutation(
    ({ data }: { data: CreateMakingSentenceStepData; image?: File; audio?: File }) => {
      return apiCourses.addMakingSentenceStep(section.id, data);
    },
    {
      async onSuccess(data, variables) {
        const onSuccess = () => {
          deletePotentialStep && deletePotentialStep();

          notifySuc('Шаг добавлен');
          refetchSection();
        };

        if (variables.image) {
          setStepImageMutate(
            {
              stepId: data.id,
              image: variables.image,
              type: STEP_TYPES.MAKING_SENTENCE
            },
            {
              onSuccess
            }
          );
        } else {
          onSuccess();
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const { mutateAsync: updateMakingSentenceStepMutate, isLoading: isLoadingUpdate } = useMutation(
    ({ data }: { data: UpdateMakingSentenceStepData; image?: File; audio?: File }) => {
      return apiCourses.updateMakingSentenceStep(step.id, data);
    },
    {
      async onSuccess(data, variables) {
        const onSuccess = () => {
          notifySuc('Шаг обновлен');
          refetchSection();
        };

        if (variables.image) {
          setStepImageMutate(
            {
              stepId: data.id,
              image: variables.image,
              type: STEP_TYPES.MAKING_SENTENCE
            },
            {
              onSuccess
            }
          );
        } else {
          onSuccess();
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const { deleteStepMutate: deleteMakingSentenceStepMutate, isLoading: isLoadingDelete } =
    useDeleteStep({
      section,
      stepId: step.id,
      stepType: step.type,
      setIsDirty,
      setIsValid,
      setOnSubmitCallback
    });
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

  const deleteMakingSentenceStep = () => {
    if (isPotential) {
      if (deletePotentialStep) {
        deletePotentialStep();
      }
      return;
    }
    deleteMakingSentenceStepMutate();
  };

  const handleReset = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    reset();
  };

  const onSubmit = async (data: FieldValues) => {
    const {
      condition,
      editableCondition,
      purpose,
      editablePurpose,
      answer,
      extraWords,
      hint,
      format,
      picture,
      audio,
      textForAudio,
      audioParts,
      url,
      videoUrl
    } = data;

    const stepData: CreateMakingSentenceStepData & UpdateMakingSentenceStepData = {
      order: step.order || section.steps?.length + 1 || 1,
      condition,
      editableCondition: normalizeData(editableCondition),
      purpose,
      editablePurpose: normalizeData(editablePurpose),
      answer,
      extraWords: extraWords.trim()
        ? extraWords
            .trim()
            .split('/')
            .map(word => word.trim())
        : [],
      hint,
      format,
      textForAudio: format === 'audio' && !audio?.length ? textForAudio : '',
      audioList: format === 'audio' ? audioParts : undefined,
      url: format === 'video' ? videoUrl : url
    };

    if (isPotential) {
      if (format === 'image' && picture && picture.length) {
        await addMakingSentenceStepMutate({ data: stepData, image: picture[0] });
      } else {
        await addMakingSentenceStepMutate({ data: stepData });
      }
    } else {
      if (format === 'image' && picture && picture.length) {
        await updateMakingSentenceStepMutate({ data: stepData, image: picture[0] });
      } else {
        await updateMakingSentenceStepMutate({ data: stepData });
      }
    }
  };

  useEffect(() => {
    setIsDirty(isDirty);
    setIsValid(isValid);

    setOnSubmitCallback(() => handleSubmit(async data => await onSubmit(data)));
  }, [isDirty, isValid]);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h3 className={styles.title}>
          {`Шаг ${isPotential ? section.steps?.length + 1 : stepIndex} | Тест — `}
          <IconMakingSentence color='#5770F3' className='mx-[8px]' /> Составьте предложение
        </h3>

        <button
          className='flex h-[24px] w-[24px] items-center justify-center'
          onClick={() => setDeleteModalIsOpen(true)}
        >
          <IconTrash color='#71798F' />
        </button>
      </header>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
        <Condition stepId={step.id || 'potential'} control={control} />

        <Purpose
          stepId={step.id || 'potential'}
          control={control}
          text='Цель (фраза для перевода) (Выберите размер шрифта Заголовок 3)'
        />

        <div className={styles.answer}>
          <label className={styles.label}>Правильный ответ</label>

          <Input
            type='text'
            variant='bordered'
            width='big'
            name='answer'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                },
                noSpaceBeforeOrAfterSeparator: (value: any) => {
                  return !MAKING_SENTENCE_REGEXP.test(value)
                    ? true
                    : TEST_STEPS_ERROR_MESSAGES.MAKING_SENTENCE;
                }
              }
            }}
            className='text-[20px] leading-[23px]'
            containerClassName='mb-[8px]'
          />

          <span className={styles.answer__tip}>
            Разделите слова при помощи символа “ / “ (до и после символа “ / ” должны быть пробелы).
            Например: «Сегодня / солнечная / погода»
          </span>
        </div>

        <ExtraWords control={control} />

        <Hint control={control} />

        <Format
          savedFormat={step.format}
          format={format}
          control={control}
          register={register}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          imageError={!!errors.picture}
        />

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Сбросить'
            className='w-[198px]'
            isDisabled={!isDirty || isLoadingCreate || isLoadingUpdate || setStepImageLoading}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={isLoadingCreate || isLoadingUpdate || setStepImageLoading}
          />
        </div>
      </form>

      {deleteModalIsOpen && (
        <ConfirmationModal
          title={
            <>
              Удалить{' '}
              {isPotential
                ? 'Шаг | Тест - Составьте предложение'
                : `Шаг ${stepIndex} | Тест - Составьте предложение`}
              ?
            </>
          }
          text={
            <>
              Шаг будет удален <strong>навсегда</strong>.
            </>
          }
          isDelete={true}
          confirmButtonText='Удалить шаг'
          onConfirm={deleteMakingSentenceStep}
          onClose={() => setDeleteModalIsOpen(false)}
          isLoading={isLoadingDelete}
        />
      )}
    </div>
  );
};

export default MakingSentenceStep;
