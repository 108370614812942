import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import imagePlaceholder from 'assets/images/svg/avatar.svg';
import { useCreateNotificationMutation, useDeleteNotificationMutation } from 'hooks/mutations';
import { INotification, NOTIFICATION_STATUS } from 'models';
import { formatDate, getNotificationFor, getNotificationStatus } from '../../utils';
import styles from './NotificationCard.module.css';

import ConfirmationModal from 'components/atoms/ConfirmationModal';
import { IconCopy, IconPen, IconTrash } from 'components/atoms/icons';

interface NotificationCardProps {
  notification: INotification;
  index: number;
}

const NotificationCard: FC<NotificationCardProps> = ({ notification, index }) => {
  const navigate = useNavigate();
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [copyModalActive, setCopyModalActive] = useState(false);
  const { mutate: deleteNotificationMutate, isLoading: deleteNotificationLoading } =
    useDeleteNotificationMutation();
  const { mutate: createNotificationMutate, isLoading: createNotificationLoading } =
    useCreateNotificationMutation();

  const deleteNotification = () => {
    deleteNotificationMutate(notification.id, {
      onSuccess: () => setDeleteModalActive(false)
    });
  };

  const copyNotification = () => {
    const { id, imageUrl, createdAt, updatedAt, recipientsList, ...createNotificationData } =
      notification;

    createNotificationMutate(
      {
        ...createNotificationData,
        recipientsList: recipientsList?.map(recipient => recipient.id),
        status: NOTIFICATION_STATUS.DRAFT
      },
      {
        onSuccess: data => {
          setCopyModalActive(false);
          navigate(`/notifications/${data.id}`);
        }
      }
    );
  };

  return (
    <div className={`${styles.table__row} ${index % 2 === 1 ? styles.table__row_even : ''}`}>
      <div className={`${styles.table__cell} ${styles.table__cell_preview}`}>
        <img
          src={notification.imageUrl || imagePlaceholder}
          alt={notification.title}
          className='mr-[15px] h-[40px] w-[40px] rounded-[8px] border-[1px] border-solid border-[#E9ECF0]'
        />
        <div className='flex flex-col'>
          <h6 className='mb-[5px] text-[16px] font-[500] leading-[19px] text-[#20233A] transition-[all] duration-300 overflow-hidden h-[17px]'>
            {notification.title}
          </h6>
          <p className='text-[14px] leading-[16px] text-[#20233A] flex overflow-hidden h-[15px]'>
            {notification.message}
          </p>
        </div>
      </div>

      <div className={`${styles.table__cell} ${styles.table__cell_startDate}`}>
        <span className='text-[16px] leading-[19px] text-[#20233A]'>
          {notification.startDate ? formatDate(notification.startDate) : '—'}
        </span>
      </div>

      <div className={`${styles.table__cell} ${styles.table__cell_endDate}`}>
        <span className='text-[16px] leading-[19px] text-[#20233A]'>
          {notification.endDate ? formatDate(notification.endDate) : '—'}
        </span>
      </div>

      <div className={`${styles.table__cell} ${styles.table__cell_for}`}>
        {getNotificationFor(notification.for)}
      </div>

      <div className={`${styles.table__cell} ${styles.table__cell_status}`}>
        {getNotificationStatus(notification.status)}
      </div>

      <div className={`${styles.table__cell} ${styles.table__cell_actions}`}>
        <button
          onClick={() => navigate(`/notifications/${notification.id}`)}
          className='h-[24px] w-[24px]'
        >
          <IconPen color='#71798F' />
        </button>

        <button
          onClick={() => setCopyModalActive(true)}
          className='h-[24px] w-[24px] opacity-30'
          disabled
        >
          <IconCopy color='#71798F' />
        </button>

        <button
          type='button'
          onClick={() => setDeleteModalActive(true)}
          className='h-[24px] w-[24px]'
        >
          <IconTrash color='#71798F' />
        </button>
      </div>

      {deleteModalActive && (
        <ConfirmationModal
          title={
            <>
              Удалить уведомление <br />
              {notification.title}?
            </>
          }
          text={
            <>
              Уведомление будет удалено <b>навсегда</b> и <b>безвозвратно</b>
            </>
          }
          isDelete={true}
          confirmButtonText='Удалить'
          onConfirm={deleteNotification}
          onClose={() => setDeleteModalActive(false)}
          isLoading={deleteNotificationLoading}
        />
      )}

      {copyModalActive && (
        <ConfirmationModal
          title={
            <>
              Дублировать уведомление <br />
              {notification.title}?
            </>
          }
          text={
            <>
              Будет создано <b>новое</b> уведомление с <b>теми же</b> данными.
            </>
          }
          isDelete={false}
          confirmButtonText='Дублировать'
          onConfirm={copyNotification}
          onClose={() => setCopyModalActive(false)}
          isLoading={createNotificationLoading}
        />
      )}
    </div>
  );
};

export default NotificationCard;
