import { useMutation, useQueryClient } from 'react-query';

import { ICourse, apiCourses } from 'api/api-courses';
import { COURSE_STATUS } from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

interface UpdateCourseStatusData {
  courseId: string;
  body: {
    status: COURSE_STATUS;
  };
}

export const useUpdateCourseStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ courseId, body }: UpdateCourseStatusData) => {
      return apiCourses.updateCourseInfo({ courseId, body });
    },
    {
      onSuccess(_, variables) {
        notifySuc('Статус обновлен');
        queryClient.invalidateQueries([`courses/one/${variables.courseId}`], {
          refetchActive: false
        });

        const oldCourseInfoData = queryClient.getQueryData<ICourse>(
          `courses/one/${variables.courseId}`
        );
        if (!oldCourseInfoData) return;

        queryClient.setQueryData(`courses/one/${variables.courseId}`, {
          ...oldCourseInfoData,
          status: variables.body.status
        });
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
