import React from 'react';

const IconBack: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.6172 19.5978L6.18359 12.883C6.00781 12.672 5.9375 12.4611 5.9375 12.2502C5.9375 12.0744 6.00781 11.8635 6.14844 11.6877L12.582 4.97283C12.8984 4.62127 13.4609 4.62127 13.7773 4.93767C14.1289 5.25408 14.1289 5.78142 13.8125 6.13299L7.94141 12.2502L13.8477 18.4377C14.1641 18.7541 14.1641 19.3166 13.8125 19.633C13.4961 19.9494 12.9336 19.9494 12.6172 19.5978Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconBack;
