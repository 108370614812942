import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';

interface Params {
  lessonId: string;
  sections?: boolean;
  learnedSections?: boolean;
  words?: boolean;
  learnedWords?: boolean;
  favoriteWords?: boolean;
  amountSteps?: boolean;
  enabled?: boolean;
  keepPreviousData?: boolean;
}

const useLesson = ({ lessonId, enabled = true, keepPreviousData = false, ...rest }: Params) => {
  const {
    data: lessonData,
    isLoading: lessonLoading,
    refetch: refetchLesson
  } = useQuery(
    [`lessons/one/${lessonId}`],
    async () =>
      await apiCourses.getLesson({
        lessonId,
        ...rest
      }),
    {
      // enabled,
      onError(error) {
        handleError(error);
      }
    }
  );

  const lesson = useMemo(() => lessonData, [lessonData]);

  return [lesson, lessonLoading, refetchLesson] as const;
};

export default useLesson;
