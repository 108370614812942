import { COURSE_STATUS } from 'consts';

import { IconEye, IconEyeBlocked, IconEyeCrossed } from 'components/atoms/icons';

export const useCourseStatusIcon = (status: COURSE_STATUS) => {
  switch (status) {
    case COURSE_STATUS.PUBLISHED_FOR_ALL:
      return (
        <div
          className={
            'absolute right-[12px] bottom-[12px] flex h-[40px] w-[40px] ' +
            'bg-[#66C84D] justify-center items-center rounded-full'
          }
        >
          <IconEye color='#FFFFFF' />
        </div>
      );
    case COURSE_STATUS.PUBLISHED_BY_SUBSCRIPTION:
      return (
        <div
          className={
            'absolute right-[12px] bottom-[12px] flex h-[40px] w-[40px] ' +
            'bg-[#66C84D] justify-center items-center rounded-full'
          }
        >
          <IconEyeBlocked color='#FFFFFF' />
        </div>
      );
    case COURSE_STATUS.UNPUBLISHED:
      return (
        <div
          className={
            'absolute right-[12px] bottom-[12px] flex h-[40px] w-[40px] ' +
            'bg-[#8C9AAF] justify-center items-center rounded-full'
          }
        >
          <IconEyeCrossed color='#FFFFFF' />
        </div>
      );
  }
};
