import { FC } from 'react';

import { Skeleton } from 'components/atoms';

const ActivitySkeleton: FC = () => {
  return (
    <div className='flex grow flex-col'>
      <Skeleton height={36} width={200} className='mb-[42px]' />
      <Skeleton height={21} width={253} className='mb-[20px]' />
      <Skeleton height={172} width={1300} border={12} className='mb-[40px]' />
    </div>
  );
};

export default ActivitySkeleton;
