import { useMutation, useQueryClient } from 'react-query';

import { ICourse, UpdateCourseData, apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useUpdateCourseInfoMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ courseId, body }: UpdateCourseData) => {
      return apiCourses.updateCourseInfo({ courseId, body });
    },
    {
      onSuccess(_, variables) {
        notifySuc('Информация курса обновлена');
        queryClient.invalidateQueries([`courses/one/${variables.courseId}`], {
          refetchActive: false
        });

        const oldCourseInfoData = queryClient.getQueryData<ICourse>(
          `courses/one/${variables.courseId}`
        );
        if (!oldCourseInfoData) return;

        queryClient.setQueryData(`courses/one/${variables.courseId}`, {
          ...oldCourseInfoData,
          ...variables.body
        });
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
