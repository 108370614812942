export const generateFullDate = (date: string, time: string) => {
  // Parse the date and time strings to get individual components
  const [year, month, day] = date.split('-').map(Number);
  const [hours, minutes] = time.split(':').map(Number);

  // Create a new Date object using the components
  const newDate = new Date(Date.UTC(year, month - 1, day, hours, minutes));

  // Return the date in ISO format
  return newDate.toISOString();
};
