import { FC } from 'react';

import { DEFAULT_COURSES_AMOUNT } from './const';

import { Skeleton } from 'components/atoms';

const SkeletonUserCourses: FC = () => {
  return (
    <div className='flex grow flex-col'>
      <header className='flex h-[100px] items-center justify-between gap-[20px]'>
        <Skeleton height={36} width={300} />
        <Skeleton height={56} width={185} border={28} />
      </header>

      <ul className='flex flex-col gap-[10px]'>
        {Array.from({ length: DEFAULT_COURSES_AMOUNT }).map((_, i) => (
          <li
            key={i}
            className={
              'grow flex gap-[12px] py-[12px] h-[113px] ' +
              'border-b-[1px] border-solid border-[#E9ECF0]'
            }
          >
            <Skeleton height={88} width={88} border={20} />
            <div className='flex grow flex-col gap-[4.5px] px-[16px] pb-[24px] pt-[20px]'>
              <Skeleton height={15} width={240} />
              <Skeleton height={24} width={609} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SkeletonUserCourses;
