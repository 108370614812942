export type StepFormat = 'audio' | 'video' | 'text' | 'image';

export enum AudioType {
  TEXT = 'text',
  FILE = 'file'
}

type AudioTextPart = {
  type: AudioType.TEXT;
  order?: number;
  textForAudio?: string;
  url?: string;
};

type AudioFilePart = {
  type: AudioType.FILE;
  order?: number;
  url?: string;
};

export type AudioPart = AudioTextPart | AudioFilePart;
