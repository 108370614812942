import { FC } from 'react';
import { Control, FieldValues, useFieldArray } from 'react-hook-form';

import ButtonAdd from 'components/atoms/ButtonAdd';
import { Timecode } from '../Timecode';

interface TimecodesProps {
  control: Control<FieldValues>;
  className?: string;
}

const Timecodes: FC<TimecodesProps> = ({ control, className }) => {
  const {
    fields: timecodeFields,
    append: appendTimecode,
    remove: removeTimecode
  } = useFieldArray({ control, name: 'timecodes' });

  return (
    <div className={`flex flex-col items-start ${className}`}>
      <div className={'mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'}>
        таймкоды
      </div>

      <ul className='mb-[8px] flex w-full flex-1 flex-col gap-[8px]'>
        {timecodeFields.map((timecode, index) => (
          <Timecode key={timecode.id} index={index} control={control} remove={removeTimecode} />
        ))}
      </ul>

      <ButtonAdd
        variant='light'
        type='button'
        title='Добавить таймкод'
        onClick={() => appendTimecode({ time: '', description: '' })}
      />
    </div>
  );
};

export default Timecodes;
