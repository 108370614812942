import React, { useEffect, useMemo, useRef, useState, type ChangeEvent } from 'react';

interface Props {
  cover?: string;
  onChange: (file: File) => void;
  children: React.ReactNode;
  className?: string;
  accept: string;
  disabled?: boolean;
}

const CourseImgUpload: React.FC<Props> = ({
  cover,
  onChange,
  children,
  className,
  accept,
  disabled
}) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const [selectedFile, updateSelectedFile] = useState<File>();
  const [filePreviewUrl, setFilePreviewUrl] = useState<string>();

  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setFilePreviewUrl(objectUrl);

    onChange && onChange(selectedFile);

    // free memory when component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const displayImage = useMemo<string | undefined>(
    () => filePreviewUrl || cover,
    [cover, filePreviewUrl]
  );

  // const handleClick = useCallback(() => {
  //   fileInput.current && fileInput.current.click()
  // }, [fileInput])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target?.files && event.target?.files[0];
    fileUploaded && updateSelectedFile(fileUploaded);
  };

  return (
    <label
      style={{
        backgroundImage: `url(${displayImage})` || 'none'
      }}
      className={
        ' cursor-pointer bg-contain bg-clip-padding bg-center bg-no-repeat ' +
        (className ? className : '')
      }
    >
      <input
        type='file'
        className='hidden'
        disabled={disabled || false}
        accept={accept}
        ref={fileInput}
        onChange={handleChange}
      />
      {children}
    </label>
  );
};

export default CourseImgUpload;
