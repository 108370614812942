import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { apiUsers } from 'api/api-users';
import {
  EMAIL_REGEXP,
  FORM_ERROR_MESSAGES,
  PASSWORD_NO_MATCH_REGEXP,
  USER_ROLE,
  USER_ROLES_OPTIONS
} from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { SubscriptionTerm } from 'models/subscription';
import { CreateUserWithCoursesDto, CreateUserWithCoursesFormValues } from 'models/user';
import { addMonths, timestampToInput } from 'utils';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input/Input';
import { IconCheckSquareOff, IconCheckSquareOn, IconClose } from 'components/atoms/icons';
import RolesDropdown from 'components/molecules/RolesDropdown';
import { CourseCards } from './components';

const CreateUserPage: FC = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm<CreateUserWithCoursesFormValues>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      roles: [USER_ROLE.STUDENT],
      courses: [{ from: timestampToInput(Date.now()) }],
      isSendEmail: true
    }
  });

  const { mutate: createUserMutate, isLoading: createUserLoading } = useMutation(
    (dto: CreateUserWithCoursesDto) => {
      return apiUsers.createUserWithCourses(dto);
    },
    {
      onSuccess() {
        notifySuc('Пользователь создан');
        onCancel();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const onCancel = () => {
    navigate(-1);
  };

  const handleReset = () => {
    reset();
  };

  const onSubmit = async (data: CreateUserWithCoursesFormValues) => {
    const { name, email, password, roles, courses: rawCourses, isSendEmail } = data;

    const courses = rawCourses.map(({ course: { id: courseId }, subscriptionTerm, from, to }) => {
      const purchaseDate = from ? new Date(from).getTime() : undefined;
      let expirationDate = to ? new Date(to).getTime() : undefined;
      const isPermanent = subscriptionTerm === SubscriptionTerm.PERMANENT;

      switch (subscriptionTerm) {
        case SubscriptionTerm.THREE_MONTHS:
          expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 3).getTime();
          break;
        case SubscriptionTerm.SIX_MONTHS:
          expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 6).getTime();
          break;
        case SubscriptionTerm.NINE_MONTHS:
          expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 9).getTime();
          break;
        case SubscriptionTerm.TWELVE_MONTHS:
          expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 12).getTime();
          break;
        case SubscriptionTerm.TWENTY_FOUR_MONTHS:
          expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 24).getTime();
          break;
      }

      return {
        courseId,
        isPermanent,
        purchaseDate,
        expirationDate
      };
    });

    createUserMutate({
      email: email.trim(),
      password: password.trim(),
      name: name.trim(),
      roles,
      courses,
      isSendEmail
    });
  };

  return (
    <div className='flex h-full flex-col'>
      <button className='flex select-none items-center gap-[16px] self-start' onClick={onCancel}>
        <IconClose className='h-[24px] w-[24px] text-[#5770F3]' />
        <span className='text-[16px] leading-[16px] text-[#20233A]'>Отменить</span>
      </button>

      <form className='flex grow flex-col' onSubmit={handleSubmit(onSubmit)}>
        <header className='mb-[24px] flex items-center justify-between'>
          <h2 className='h-[88px] pt-[30px] text-[30px] font-[700] leading-[36px] text-[#20233A]'>
            Создать пользователя
          </h2>

          {/* TODO separate RoleSelect component */}
          <div className='flex flex-col gap-[6px]'>
            <Controller
              control={control}
              name='roles'
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
              }}
              render={({ field: { value, onChange } }) => (
                <RolesDropdown
                  options={USER_ROLES_OPTIONS}
                  values={value}
                  setValues={newRoles => onChange(newRoles)}
                />
              )}
            />

            {!!errors.roles && (
              <span className='ml-[16px] block text-[12px] leading-[14px] text-[#FF4444]'>
                {errors.roles.message}
              </span>
            )}
          </div>
        </header>

        <div className='mb-[32px] flex gap-[8px]'>
          <Input
            type='text'
            name='name'
            label='Имя'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              minLength: { value: 1, message: 'Имя должно быть более 1 символа' },
              maxLength: { value: 32, message: 'Имя должно быть не более 32 символов' },
              validate: {
                doesntConsistOfSpaces: (value: string) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                }
              }
            }}
          />

          <Input
            type='text'
            name='email'
            label='Email'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 50, message: 'Максимальная длина - 50 символов' },
              validate: {
                doesntContainSpaces: (value: string) => {
                  return !value.includes(' ') ? true : FORM_ERROR_MESSAGES.DOESNT_CONTAIN_SPACES;
                },
                validEmail: (value: string) => {
                  return EMAIL_REGEXP.test(value) ? true : FORM_ERROR_MESSAGES.INCORRECT_EMAIL;
                }
              }
            }}
          />

          <Input
            type='text'
            name='password'
            label='Пароль'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              minLength: { value: 4, message: 'Пароль должен быть не менее 4 символов' },
              maxLength: { value: 20, message: 'Пароль должен быть от 4 до 20 символов' },
              validate: {
                doesntContainSpaces: (value: string) => {
                  return !value.includes(' ') ? true : FORM_ERROR_MESSAGES.DOESNT_CONTAIN_SPACES;
                },
                validPassword: (value: string) => {
                  return !value.match(PASSWORD_NO_MATCH_REGEXP)
                    ? true
                    : FORM_ERROR_MESSAGES.INCORRECT_PASSWORD;
                }
              }
            }}
            isGenerateable
          />
        </div>

        <div className='mb-[28px] h-[1px] w-full bg-[#E9ECF0]' />

        <h3 className='mb-[20px] text-[21px] font-[700] leading-[24px] text-[#20233A]'>Курсы</h3>

        <CourseCards control={control} className='mb-auto' />

        <div className='mt-[24px] flex items-center gap-[24px] self-end'>
          {/* TODO separate SendEmail component */}
          <Controller
            control={control}
            name='isSendEmail'
            render={({ field: { value, onChange } }) => (
              <label
                htmlFor='sendEmailInput'
                className='flex cursor-pointer items-center gap-[8px]'
              >
                {value ? <IconCheckSquareOn color='#5770F3' /> : <IconCheckSquareOff />}
                <span className='text-[16px] leading-[24px] text-[#20233a]'>
                  Выслать приглашение на email
                </span>
                <input
                  type='checkbox'
                  id='sendEmailInput'
                  checked={value || false}
                  onChange={onChange}
                  className='hidden'
                />
              </label>
            )}
          />

          <div className='flex gap-[8px]'>
            <Button
              type='button'
              variant='secondary'
              title='Сбросить'
              onClick={handleReset}
              isDisabled={!isDirty || createUserLoading}
              className='w-[198px]'
            />

            <Button
              type='submit'
              variant='primary'
              title='Создать'
              isDisabled={!isDirty}
              isLoading={createUserLoading}
              className='w-[198px]'
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateUserPage;
