import { ChangeEvent, InputHTMLAttributes, useEffect, useMemo } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';
import { IconUpload } from '../icons';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  cover?: string;
  label?: string;
  accept?: string;
  title?: string;
  subtitle?: string;
  rules?: RegisterOptions;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
}

export type InputImageProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const InputImage = <T extends FieldValues>({
  name,
  cover,
  label,
  accept,
  title = 'Загрузить изображение',
  subtitle = 'Файл в пропорции 1:1',
  control,
  rules = {},
  className,
  containerClassName,
  disabled = false,
  loading = false,
  ...rest
}: InputImageProps<T>) => {
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target?.files && event.target.files[0];
    fileUploaded && field.onChange(fileUploaded);
  };

  const imageUrl = useMemo<string | undefined>(() => {
    if (field.value) {
      const objectUrl = URL.createObjectURL(field.value);
      return objectUrl;
    }
    return cover;
  }, [cover, field.value]);

  // clean up the object url on the component unmount
  useEffect(() => {
    return () => {
      if (imageUrl && imageUrl.startsWith('blob:')) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl]);

  return (
    <div className={`flex flex-col ${containerClassName}`}>
      <label
        htmlFor={name}
        style={{ backgroundImage: imageUrl ? `url(${imageUrl})` : 'none' }}
        className={
          'relative h-[200px] w-[200px] cursor-pointer overflow-hidden rounded-[16px] ' +
          `bg-contain bg-clip-padding bg-center bg-no-repeat ${className} ` +
          `${!imageUrl ? 'border-[1px] border-dashed border-[#5770F3] bg-[#EEF0FF]' : ''} `
        }
      >
        <input
          type='file'
          id={name}
          name={name}
          className='hidden'
          accept={accept}
          onChange={handleChange}
        />

        {imageUrl ? (
          <>
            <span className='absolute inset-0 bg-[rgba(22,32,48,0.42)]' />
            <div className='absolute bottom-[20px] left-[16px] flex w-[168px] flex-col'>
              <IconUpload color='#FFFFFF' className='mb-[24px]' />
              <p className='text-[12px] leading-[14px] text-[#FFFFFF]'>{subtitle}</p>
            </div>
          </>
        ) : (
          <div className='absolute bottom-[20px] left-[16px] flex w-[168px] flex-col'>
            <IconUpload color='#5770F3' className='mb-[24px]' />
            <h6 className='mb-[4px] text-[16px] font-[500] leading-[19px] text-[#162030]'>
              {title}
            </h6>
            <p className='text-[12px] leading-[14px] text-[#71798F]'>{subtitle}</p>
          </div>
        )}
      </label>

      {!!fieldState.error && (
        <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
          {fieldState.error.message}
        </span>
      )}
    </div>
  );
};

export default InputImage;
