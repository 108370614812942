import { templateString } from './const';

export const handleFileDownload = () => {
  const blob = new Blob([templateString], { type: 'text/csv;charset=utf-8' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'template.csv';
  link.click();
  URL.revokeObjectURL(url);
};
