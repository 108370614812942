import { useQuery } from 'react-query';

import { GetUserStatsDto, apiUsers } from 'api/api-users';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetUserStatsQuery = (data: GetUserStatsDto) => {
  return useQuery(
    [
      'stats/users',
      data.userId,
      data.visitDates,
      data.visitStreak,
      data.learnedLessonDates,
      data.learnedWordDates
    ],
    async () => await apiUsers.getUserStats(data),
    {
      keepPreviousData: true,
      staleTime: DEFAULT_STALE_TIME,
      onError(error) {
        handleError(error);
      }
    }
  );
};
