import { useQuery } from 'react-query';

import { apiUsers } from 'api/api-users';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetUserInfoQuery = (userId: string) => {
  return useQuery([`users/one/${userId}`], async () => await apiUsers.getUserInfo(userId), {
    staleTime: DEFAULT_STALE_TIME,
    onError(error) {
      handleError(error);
    }
  });
};
