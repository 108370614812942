import {
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  LineControllerChartOptions,
  PluginChartOptions,
  ScaleChartOptions
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';

export const chartOptions: _DeepPartialObject<
  CoreChartOptions<'line'> &
    ElementChartOptions<'line'> &
    PluginChartOptions<'line'> &
    DatasetChartOptions<'line'> &
    ScaleChartOptions<'line'> &
    LineControllerChartOptions
> = {
  responsive: true,
  elements: {
    point: {
      radius: 0,
      hoverRadius: 7,
      hoverBackgroundColor: '#5770F3',
      hoverBorderWidth: 2,
      hoverBorderColor: '#FFFFFF',
      hitRadius: 10
    },
    line: {
      borderWidth: 2,
      borderColor: '#5770F3'
    }
  },
  interaction: {
    intersect: false,
    mode: 'index'
  },
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    x: {
      ticks: {
        // maxTicksLimit: 12,
        font: {
          size: 14
        },
        color: '#A6B0C9'
      },
      time: {
        unit: 'day'
      }
    },
    y: {
      ticks: {
        stepSize: 1,
        color: '#A6B0C9',
        font: {
          size: 14
        }
      }
    }
  }
};
