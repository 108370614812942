import { FC } from 'react';

import styles from './WordPage.module.css';

import { Content, Sidebar } from './components';

const WordPage: FC = () => {
  return (
    <div className={styles.container}>
      <Sidebar />
      <Content />
    </div>
  );
};

export default WordPage;
