/* eslint-disable react-hooks/exhaustive-deps */

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC } from 'react';
import { Control, useController, useFieldArray } from 'react-hook-form';

import { AudioType } from 'models';

import Button from 'components/atoms/Button';
import ButtonAdd from 'components/atoms/ButtonAdd';
import { IconTrash, IconUpload } from 'components/atoms/icons';
import AudioPlayer from 'components/molecules/AudioPlayer';
import { AudioPart } from './components';

interface FormatAudioProps {
  control: Control<any>;
}

const FormatAudio: FC<FormatAudioProps> = ({ control }) => {
  const {
    field: { value: oldUrl, onChange: oldUrlChange }
  } = useController({
    control,
    name: 'url'
  });

  const {
    fields: audioPartFields,
    append: appendAudioPart,
    remove: removeAudioPart,
    move: moveAudioPart
  } = useFieldArray({
    control,
    name: 'audioParts'
  });

  // remove outdated audio url field
  const removeOldUrl = () => {
    oldUrlChange(null);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = audioPartFields.findIndex(({ id }) => id === active.id);
      const newIndex = audioPartFields.findIndex(({ id }) => id === over.id);

      moveAudioPart(oldIndex, newIndex);
    }
  };

  return (
    <div className='flex flex-col gap-[40px]'>
      {!!oldUrl && (
        <div className='flex flex-col'>
          <label className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798f]'>
            Загруженное аудио (устаревшее)
          </label>

          <div className='flex items-center justify-between gap-[16px]'>
            {oldUrl && <AudioPlayer key={oldUrl} url={oldUrl} />}

            <Button
              type='button'
              variant='primary'
              title='Удалить'
              className='w-[176px]'
              icon={<IconTrash />}
              iconPosition='left'
              onClick={removeOldUrl}
            />
          </div>
        </div>
      )}

      {!!audioPartFields.length && (
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext items={audioPartFields} strategy={verticalListSortingStrategy}>
            <ul className='flex flex-col gap-[40px]'>
              {audioPartFields.map((audioPart, index) => (
                <AudioPart
                  key={audioPart.id}
                  id={audioPart.id}
                  index={index}
                  control={control}
                  remove={removeAudioPart}
                />
              ))}
            </ul>
          </SortableContext>
        </DndContext>
      )}

      <div className='flex gap-[8px]'>
        <ButtonAdd
          variant='light'
          type='button'
          title='Из текста'
          onClick={() => appendAudioPart({ type: AudioType.TEXT, textForAudio: '' })}
        />

        <Button
          type='button'
          variant='primary'
          title='Загрузить .mp3'
          icon={<IconUpload />}
          iconPosition='left'
          className='h-[48px] rounded-[62px]'
          onClick={() => appendAudioPart({ type: AudioType.FILE })}
        />
      </div>
    </div>
  );
};

export default FormatAudio;
