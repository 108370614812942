/* eslint-disable react-hooks/exhaustive-deps */

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Control, Controller, UseFieldArrayRemove, useController } from 'react-hook-form';

import spinner from 'assets/images/svg/spinner.svg';
import { FORM_ERROR_MESSAGES } from 'consts';
import { useGenerateAudioFromTextMutation, useGenerateUrlFromAudioMutation } from 'hooks/mutations';
import { AudioType } from 'models';
import styles from './AudioPart.module.css';

import Button from 'components/atoms/Button';
import TextArea from 'components/atoms/TextArea';
import { IconClose, IconDragHandle, IconPen, IconSound, IconUpload } from 'components/atoms/icons';
import AudioPlayer from 'components/molecules/AudioPlayer';

type AudioPartProps = {
  id: string;
  index: number;
  control: Control<any>;
  remove: UseFieldArrayRemove;
};

const AudioPart: FC<AudioPartProps> = ({ id, index, control, remove }) => {
  const [currentAudioUrl, setCurrentAudioUrl] = useState<string | null>(null);
  const { mutate: generateUrlFromAudioMutate, isLoading: generateUrlFromAudioLoading } =
    useGenerateUrlFromAudioMutation();
  const { mutate: generateAudioFromTextMutate, isLoading: generateAudioFromTextLoading } =
    useGenerateAudioFromTextMutation();

  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const {
    field: { value: type }
  } = useController({
    name: `audioParts.${index}.type`,
    control
  });

  const {
    field: { value: url, onChange: urlChange }
  } = useController({
    name: `audioParts.${index}.url`,
    control
  });

  const {
    field: { value: textForAudio, onChange: textForAudioChange }
  } = useController({
    name: `audioParts.${index}.textForAudio`,
    control
  });

  // check if the step already has a url.
  useEffect(() => {
    if (url) {
      setCurrentAudioUrl(url);
    }
  }, []);

  const handleAudioUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const audioFile = event.target?.files && event.target.files[0];
    if (!audioFile) return;

    generateUrlFromAudioMutate(audioFile, {
      onSuccess: data => {
        setCurrentAudioUrl(data.url);
        urlChange(data.url);
        textForAudioChange('');
      }
    });
  };

  // generate temporary url to listen to the text.
  const generateAudio = async () => {
    generateAudioFromTextMutate(textForAudio, {
      onSuccess: data => {
        setCurrentAudioUrl(data.url);
      }
    });
  };

  const handleNewAudio = () => {
    setCurrentAudioUrl(null);
    urlChange(null);
  };

  return (
    <li className='flex !cursor-auto' ref={setNodeRef} style={style} {...attributes}>
      <div className='flex flex-1 flex-col'>
        {type === AudioType.TEXT && (
          <div className='flex flex-1 flex-col'>
            <label className={styles.label}>Слово или фраза для аудирования (на корейском)</label>

            {!currentAudioUrl && (
              <div className='flex flex-col'>
                <TextArea
                  variant='bordered'
                  name={`audioParts.${index}.textForAudio`}
                  control={control}
                  rules={{
                    required: {
                      value: !currentAudioUrl,
                      message: FORM_ERROR_MESSAGES.REQUIRED_FIELD
                    },
                    maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
                    validate: {
                      doesntConsistOfSpaces: (value: string) => {
                        return !value.length || !!value.trim()
                          ? true
                          : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                      }
                    }
                  }}
                  placeholder='Введите текст на корейском...'
                  rows={4}
                  containerClassName='mb-[8px]'
                  disabled={!!currentAudioUrl}
                />

                <label className='mb-[20px] text-[14px] font-[400] leading-[19px] text-[#71798F]'>
                  Вставьте символ “$” там, где хотите сделать небольшую паузу
                </label>

                <Button
                  type='button'
                  variant='primary'
                  title='Сгенерировать аудио'
                  className='w-[243px]'
                  icon={<IconSound />}
                  iconPosition='left'
                  onClick={generateAudio}
                  isDisabled={!textForAudio}
                  isLoading={generateAudioFromTextLoading}
                />
              </div>
            )}
          </div>
        )}

        {type === AudioType.FILE && (
          <div className='flex flex-1 flex-col'>
            <label className={styles.label}>Загруженное аудио (.mp3)</label>

            {!currentAudioUrl && (
              <Controller
                control={control}
                name={`audioParts.${index}.url`}
                rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
                render={({ fieldState: { error } }) => (
                  <div className={styles.upload}>
                    <label
                      htmlFor='audio'
                      className={
                        `${styles.upload__label} disabled:opacity-50 ` +
                        `${error ? styles.upload__label_error : ''}`
                      }
                    >
                      <div className='flex h-[68px] items-center justify-center gap-[10px]'>
                        {generateUrlFromAudioLoading ? (
                          <img
                            src={spinner}
                            alt='spinner'
                            className='block h-[24px] w-[24px] animate-spin'
                          />
                        ) : (
                          <IconUpload color='#5770F3' />
                        )}
                        <span>Выбрать аудиофайл в формате .mp3</span>
                      </div>
                    </label>

                    <input
                      type='file'
                      accept='audio/mp3'
                      id='audio'
                      className={styles.input}
                      onChange={handleAudioUpload}
                      disabled={generateUrlFromAudioLoading}
                    />

                    {!!error && (
                      <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
                        {error.message}
                      </span>
                    )}
                  </div>
                )}
              />
            )}
          </div>
        )}

        {currentAudioUrl && (
          <div className='flex flex-col gap-[8px]'>
            <div className='flex items-center gap-[8px] '>
              <button
                type='button'
                className='flex cursor-grab'
                ref={setActivatorNodeRef}
                {...listeners}
              >
                <IconDragHandle color='#A6B0C9' />
              </button>

              <AudioPlayer key={currentAudioUrl} url={currentAudioUrl} />

              <button
                type='button'
                className='flex h-[48px] w-[56px] items-center justify-center rounded-[12px] bg-[#EEF0FF]'
                onClick={() => remove(index)}
              >
                <IconClose color='#71798F' />
              </button>
            </div>

            <div className='flex items-start justify-end gap-[24px] pl-[112px] pr-[16px]'>
              {type === AudioType.TEXT && (
                <span className='text-[16px] leading-[19px] text-[#71798F]'>{textForAudio}</span>
              )}

              <button type='button' className='flex select-none gap-[8px]' onClick={handleNewAudio}>
                <IconPen color='#71798F' className='h-[16px] w-[16px]' />
                <span className='text-[14px] leading-[16px] text-[#71798F]'>Редактировать</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

export default AudioPart;
