import { instance } from './api';

export interface IResponse {
  accessToken: string;
  refreshToken: string;
}

export const apiAuth = {
  login: async (email: string, password: string) => {
    const response = await instance.post<IResponse>('auth/log-in/admin', {
      email,
      password
    });

    return response.data;
  }
};
