import React from 'react';

const IconCheckOff: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='32' height='32' rx='16' fill='currentColor' />
    </svg>
  );
};

export default IconCheckOff;
