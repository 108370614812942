import { FC } from 'react';

import { ICourse } from 'api/api-courses';
import avatarPlaceholder from 'assets/images/svg/avatar.svg';
import { COURSES_COLORS } from 'consts';
import styles from './MoveCourseCard.module.css';

interface CourseCardProps {
  course: ICourse;
  isSelected: boolean;
  select?: () => void;
}

const MoveCourseCard: FC<CourseCardProps> = ({
  course: { imageUrl, title, color, level },
  isSelected,
  select
}) => {
  return (
    <button
      type='button'
      className={`${styles.container} ${isSelected ? styles.container_selected : ''}`}
      onClick={select}
      disabled={!select}
    >
      <div className={styles.image} style={{ backgroundColor: color || COURSES_COLORS[0].hex }}>
        <img src={imageUrl || avatarPlaceholder} alt={imageUrl} />
      </div>

      <div className={styles.text}>
        <span className={styles.level}>Уровень {level}</span>
        <h3 className={styles.title}>{title}</h3>
      </div>
    </button>
  );
};

export default MoveCourseCard;
