import { FC } from 'react';

const IconDialog: FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Icons'>
        <path
          id='Vector (Stroke)'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.29354 10.6264C7.48108 10.4389 7.73543 10.3335 8.00065 10.3335H12.0007C12.0891 10.3335 12.1738 10.2984 12.2364 10.2359C12.2989 10.1734 12.334 10.0886 12.334 10.0002V6.66683C12.334 6.57842 12.2989 6.49364 12.2364 6.43113C12.1738 6.36861 12.0891 6.3335 12.0007 6.3335H6.66732C6.48627 6.3335 6.33398 6.48578 6.33398 6.66683V11.5859L7.29354 10.6264ZM5.33398 14.0002V6.66683C5.33398 5.9335 5.93398 5.3335 6.66732 5.3335H12.0007C12.3543 5.3335 12.6934 5.47397 12.9435 5.72402C13.1935 5.97407 13.334 6.31321 13.334 6.66683V10.0002C13.334 10.3538 13.1935 10.6929 12.9435 10.943C12.6934 11.193 12.3543 11.3335 12.0007 11.3335H8.00065L5.33398 14.0002Z'
          fill='currentColor'
        />
        <path
          id='Vector (Stroke)_2'
          opacity='0.5'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.9993 16L18.666 18.6667V11.3333C18.666 10.9797 18.5255 10.6406 18.2755 10.3905C18.0254 10.1405 17.6863 10 17.3327 10H15.9993L15.1993 10.6C15.3817 10.8429 15.6722 11 15.9993 11H17.3327C17.4211 11 17.5059 11.0351 17.5684 11.0976C17.6309 11.1601 17.666 11.2449 17.666 11.3333V16.2525L16.7065 15.2929C16.5189 15.1054 16.2646 15 15.9993 15H11.9993C11.9109 15 11.8262 14.9649 11.7636 14.9024C11.7011 14.8399 11.666 14.7551 11.666 14.6667V14C11.666 13.7749 11.5916 13.5671 11.4661 13.4L10.666 14V14.6667C10.666 15.0203 10.8065 15.3594 11.0565 15.6095C11.3066 15.8595 11.6457 16 11.9993 16H15.9993Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default IconDialog;
