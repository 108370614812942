import { FC, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { apiWords } from 'api/api-words';
import { FORM_ERROR_MESSAGES } from 'consts';
import { handleError } from 'helpers/handleError';
import styles from './NaverUrl.module.css';

import Button from 'components/atoms/Button';
import ButtonAdd from 'components/atoms/ButtonAdd';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import Input from 'components/atoms/Input/Input';
import Modal from 'components/atoms/Modal';
import { IconLinkSmall, IconPen, IconTrash } from 'components/atoms/icons';
import { notifySuc } from 'helpers/notification';
import AddNaverForm from './components/AddNaverForm';

interface NaverUrlProps {
  wordId: string;
  naverUrl: string | undefined;
  refetchWord: () => void;
}

const NaverUrl: FC<NaverUrlProps> = ({ wordId, naverUrl, refetchWord }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm<FieldValues>({
    defaultValues: {
      naverUrl
    },
    mode: 'onSubmit'
  });

  const { mutate: updateNaverUrlMutate, isLoading: updateNaverUrlLoading } = useMutation(
    (naverUrl: string | null) => {
      return apiWords.updateWord({ wordId, naverUrl });
    },
    {
      onSuccess(_, variables) {
        if (variables) {
          notifySuc('Ссылка обновлена');
        } else {
          notifySuc('Ссылка удалена');
        }

        setEditMode(false);
        refetchWord();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const onSubmit = (data: FieldValues) => {
    const { naverUrl } = data;

    updateNaverUrlMutate(naverUrl);
  };

  const onReset = () => {
    reset();
    setEditMode(false);
  };

  const confirmDelete = () => {
    updateNaverUrlMutate(null);
  };

  const [addNaverModalActive, setAddNaverModalActive] = useState<boolean>(false);
  const [deleteNaverModalActive, setDeleteNaverUrlModalActive] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <div className={styles.container}>
      {naverUrl ? (
        <>
          {editMode ? (
            <div className={styles.formContainer}>
              <label className={styles.label}>редактировать ссылку на naver dictionary</label>

              <form className={styles.editForm} onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
                <Input
                  type='text'
                  variant='bordered'
                  width='big'
                  name='naverUrl'
                  placeholder='Ссылка на Naver Dictionary'
                  control={control}
                  rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
                />

                <div className={styles.buttons}>
                  <Button
                    type='submit'
                    variant='primary'
                    title='Сохранить'
                    className='w-[141px]'
                    isDisabled={!isDirty}
                    isLoading={updateNaverUrlLoading}
                  />

                  <Button
                    type='reset'
                    variant='secondary'
                    title='Отменить'
                    className='w-[136px]'
                    isDisabled={updateNaverUrlLoading}
                  />
                </div>
              </form>
            </div>
          ) : (
            <>
              <a href={naverUrl} target='_blank' rel='noreferrer' className={styles.link}>
                <span className={styles.link__text}>Смотреть в Naver Dictionary</span>
                <IconLinkSmall color='#5770F3' />
              </a>

              <div className={styles.controls}>
                <button
                  type='button'
                  className={styles.buttonEdit}
                  onClick={() => setEditMode(true)}
                >
                  <IconPen color='#71798F' />
                </button>

                <button
                  type='button'
                  className={styles.buttonDelete}
                  onClick={() => setDeleteNaverUrlModalActive(true)}
                >
                  <IconTrash color='#A1AABC' />
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <span className={styles.title}>Ссылка на Naver Dictionary</span>

          <ButtonAdd
            variant='light'
            type='button'
            title='Добавить'
            onClick={() => setAddNaverModalActive(true)}
          />
        </>
      )}

      {addNaverModalActive && (
        <Modal onClose={() => setAddNaverModalActive(false)}>
          <AddNaverForm
            wordId={wordId}
            refetchWord={refetchWord}
            onCancel={() => setAddNaverModalActive(false)}
          />
        </Modal>
      )}

      {deleteNaverModalActive && (
        <ConfirmationModal
          title={<>Удалить ссылку на Naver?</>}
          text={<>Ссылка будет удалена, но при необходимости вы сможете добавить новую ссылку.</>}
          isDelete={true}
          confirmButtonText='Удалить'
          onConfirm={confirmDelete}
          onClose={() => setDeleteNaverUrlModalActive(false)}
          isLoading={updateNaverUrlLoading}
        />
      )}
    </div>
  );
};

export default NaverUrl;
