import { useQuery } from 'react-query';

import { apiSubscriptions } from 'api';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';
import { GetSubscriptionsDto } from 'models/subscription';

export const useGetSubscriptionsQuery = (dto: GetSubscriptionsDto) => {
  return useQuery(
    [`purchases/subscriptions/${dto.userId}`],
    async () => await apiSubscriptions.getSubscriptions(dto),
    {
      staleTime: DEFAULT_STALE_TIME,
      onError(error) {
        handleError(error);
      }
    }
  );
};
