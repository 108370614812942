import React from 'react';

const IconPlay: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19.1914 11.3086L9.06641 5.12109C8.78516 4.98047 8.46875 4.875 8.15234 4.875C7.20312 4.91016 6.5 5.68359 6.5 6.5625V18.9375C6.5 19.8516 7.20312 20.625 8.1875 20.625C8.46875 20.625 8.78516 20.5547 9.06641 20.3789L19.1914 14.1914C19.6836 13.9102 20 13.3477 20 12.75C20 12.1875 19.6836 11.625 19.1914 11.3086ZM8.1875 18.9375L8.15234 6.59766L18.3125 12.75L8.1875 18.9375Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconPlay;
