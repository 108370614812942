import axios from 'axios';

import { notifyErr } from './notification';
import { NO_INTERNET_ERROR_MESSAGE } from '../consts';

export function handleError(error: unknown) {
  if (axios.isAxiosError(error) && error.response) {
    const errorMessage = error.response.data.message;
    notifyErr(errorMessage);
  } else {
    notifyErr(NO_INTERNET_ERROR_MESSAGE);
  }
}
