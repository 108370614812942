import React, { useState } from 'react';

import styles from './console.module.css';

import SideBar from 'components/organisms/SideBar';

interface ConsoleProps {
  children: React.ReactNode;
}

const Console: React.FC<ConsoleProps> = ({ children }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <div className={styles.container}>
      <SideBar sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />

      <div className={`${styles.content} ${sidebarCollapsed ? styles.content_collapsed : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default Console;
