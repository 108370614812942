/* eslint-disable react-hooks/exhaustive-deps */

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { OutputData } from '@editorjs/editorjs';
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  apiCourses,
  ICreateMatchingPairsStepData,
  IMatchingPairsStep,
  IPair,
  ISection,
  IUpdateMatchingPairsStepData,
  STEP_TYPES
} from 'api/api-courses';
import { DEFAULT_EDITABLE_CONDITION_MATCHING_PAIRS, DEFAULT_PAIRS_MATCHING_PAIRS } from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useUploadStepImageMutation } from 'hooks/mutations';
import { AudioPart, StepFormat } from 'models';
import { normalizeData } from 'utils';
import { useDeleteStep } from '../hooks';
import styles from './MatchingPairsStep.module.css';

import Button from 'components/atoms/Button';
import ButtonAdd from 'components/atoms/ButtonAdd';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import { IconLang, IconMatchingPairs, IconTrash } from 'components/atoms/icons';
import { Condition, Format, Hint } from '../components';
import { Pair } from './components/Pair';

export interface FieldValues {
  condition?: string;
  editableCondition: OutputData;
  pairs: IPair[];
  hint?: string;
  format: StepFormat;
  audioParts?: AudioPart[];
  url?: string;
  picture?: FileList;
  videoUrl?: string;
}

interface MatchingPairsStepProps {
  step: IMatchingPairsStep;
  section: ISection;
  stepIndex: number | 'potential';
  isPotential?: boolean;
  deletePotentialStep?: () => void;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  setOnSubmitCallback: Dispatch<SetStateAction<(() => void) | null>>;
  refetchSection: () => void;
}

const MatchingPairsStep: FC<MatchingPairsStepProps> = ({
  step,
  section,
  stepIndex,
  isPotential,
  deletePotentialStep,
  setIsDirty,
  setIsValid,
  setOnSubmitCallback,
  refetchSection
}) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    formState: { isDirty, isValid, errors }
  } = useForm<FieldValues>({
    mode: 'onSubmit',
    defaultValues: {
      condition: step.condition,
      editableCondition: step.editableCondition || DEFAULT_EDITABLE_CONDITION_MATCHING_PAIRS,
      pairs: step.pairs || DEFAULT_PAIRS_MATCHING_PAIRS,
      hint: step.hint || '',
      format: step?.format || 'text',
      audioParts: !!step.audioList?.length ? step.audioList : [],
      url: step.url,
      videoUrl: step.format === 'video' ? step.url : undefined
    }
  });

  const {
    fields: pairsFields,
    append: appendPair,
    move: movePair,
    remove: removePair
  } = useFieldArray({ control, name: 'pairs' });

  const format = watch('format');

  const { mutate: setStepImageMutate, isLoading: setStepImageLoading } =
    useUploadStepImageMutation();

  const { mutateAsync: addMatchingPairsStepMutate, isLoading: isLoadingCreate } = useMutation(
    ({ data }: { data: ICreateMatchingPairsStepData; image?: File }) => {
      return apiCourses.addMatchingPairsStep(section.id, data);
    },
    {
      async onSuccess(data, variables) {
        const onSuccess = () => {
          deletePotentialStep && deletePotentialStep();

          notifySuc('Шаг добавлен');
          refetchSection();
        };

        if (variables.image) {
          setStepImageMutate(
            {
              stepId: data.id,
              image: variables.image,
              type: STEP_TYPES.MATCHING_PAIRS
            },
            { onSuccess }
          );
        } else {
          onSuccess();
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const { mutateAsync: updateMatchingPairsStepMutate, isLoading: isLoadingUpdate } = useMutation(
    ({ data }: { data: IUpdateMatchingPairsStepData; image?: File }) => {
      return apiCourses.updateMatchingPairsStep(step.id, data);
    },
    {
      async onSuccess(data, variables) {
        const onSuccess = () => {
          notifySuc('Шаг обновлен');
          refetchSection();
        };

        if (variables.image) {
          setStepImageMutate(
            {
              stepId: data.id,
              image: variables.image,
              type: STEP_TYPES.MATCHING_PAIRS
            },
            {
              onSuccess
            }
          );
        } else {
          onSuccess();
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const { deleteStepMutate: deleteMatchingPairsStepMutate, isLoading: isLoadingDelete } =
    useDeleteStep({
      section,
      stepId: step.id,
      stepType: step.type,
      setIsDirty,
      setIsValid,
      setOnSubmitCallback
    });
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

  const deleteMatchingPairsStep = () => {
    if (isPotential) {
      if (deletePotentialStep) {
        deletePotentialStep();
      }
      return;
    }
    deleteMatchingPairsStepMutate();
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = pairsFields.findIndex(({ id }) => id === active.id);
      const newIndex = pairsFields.findIndex(({ id }) => id === over.id);

      movePair(oldIndex, newIndex);
    }
  };

  const handleReset = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    reset();
  };

  const onSubmit = async (data: FieldValues) => {
    const {
      condition,
      editableCondition,
      pairs,
      hint,
      format,
      picture,
      audioParts,
      url,
      videoUrl
    } = data;

    const stepData: ICreateMatchingPairsStepData & IUpdateMatchingPairsStepData = {
      order: step.order || section.steps?.length + 1 || 1,
      condition,
      editableCondition: normalizeData(editableCondition),
      pairs,
      hint,
      format,
      audioList: format === 'audio' ? audioParts : undefined,
      url: format === 'video' ? videoUrl : url
    };

    if (isPotential) {
      if (format === 'image' && picture && picture.length) {
        await addMatchingPairsStepMutate({ data: stepData, image: picture[0] });
      } else {
        await addMatchingPairsStepMutate({ data: stepData });
      }
    } else {
      if (format === 'image' && picture && picture.length) {
        await updateMatchingPairsStepMutate({ data: stepData, image: picture[0] });
      } else {
        await updateMatchingPairsStepMutate({ data: stepData });
      }
    }
  };

  useEffect(() => {
    setIsDirty(isDirty);
    setIsValid(isValid);

    setOnSubmitCallback(() => handleSubmit(async data => await onSubmit(data)));
  }, [isDirty, isValid]);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h3 className={styles.title}>
          {`Шаг ${isPotential ? section.steps?.length + 1 : stepIndex} | Тест — `}
          <IconMatchingPairs color='#5770F3' className='mx-[8px]' /> Пары слов
        </h3>

        <button
          className='flex h-[24px] w-[24px] items-center justify-center'
          onClick={() => setDeleteModalIsOpen(true)}
        >
          <IconTrash color='#71798F' />
        </button>
      </header>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
        <Condition stepId={step.id || 'potential'} control={control} />

        <div className={styles.pairs}>
          <label className={styles.label}>Пары слов</label>

          <div className='mb-[8px] flex h-[36px] items-end text-[14px] leading-[16px] text-[#71798F]'>
            <IconLang color='#A6B0C9' />
            <span className='mb-[4px] ml-[24px] flex-1'>Оригинал</span>
            <span
              className={`mb-[4px] mr-[64px] flex-1 ${
                pairsFields.length > 3 ? 'ml-[32px]' : 'ml-[96px]'
              }`}
            >
              Перевод
            </span>
          </div>

          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext items={pairsFields} strategy={verticalListSortingStrategy}>
              <ul className={styles.pairs__list}>
                {pairsFields.length > 0 && (
                  <>
                    {pairsFields.map((pair, index) => (
                      <Pair
                        key={pair.id}
                        pair={pair}
                        index={index}
                        control={control}
                        getValues={getValues}
                        remove={removePair}
                        fieldsAmount={pairsFields.length}
                      />
                    ))}
                  </>
                )}
              </ul>
            </SortableContext>
          </DndContext>

          <ButtonAdd
            variant='light'
            type='button'
            title='Добавить пару'
            onClick={() => appendPair({ original: '', translation: '' })}
          />
        </div>

        <Hint control={control} />

        <Format
          savedFormat={step.format}
          format={format}
          control={control}
          register={register}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          imageError={!!errors.picture}
        />

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Сбросить'
            className='w-[198px]'
            isDisabled={!isDirty || isLoadingCreate || isLoadingUpdate || setStepImageLoading}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={isLoadingCreate || isLoadingUpdate || setStepImageLoading}
          />
        </div>
      </form>

      {deleteModalIsOpen && (
        <ConfirmationModal
          title={
            <>
              Удалить{' '}
              {isPotential ? 'Шаг | Тест - Пары слов' : `Шаг ${stepIndex} | Тест - Пары слов`}?
            </>
          }
          text={
            <>
              Шаг будет удален <strong>навсегда</strong>.
            </>
          }
          isDelete={true}
          confirmButtonText='Удалить шаг'
          onConfirm={deleteMatchingPairsStep}
          onClose={() => setDeleteModalIsOpen(false)}
          isLoading={isLoadingDelete}
        />
      )}
    </div>
  );
};

export default MatchingPairsStep;
