import React from 'react';

const IconQuestion: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.4375 17.875C10.8047 17.875 10.3125 18.4023 10.3125 19C10.3125 19.6328 10.8047 20.1602 11.4375 20.1602C12.0352 20.1602 12.5625 19.6328 12.5625 19C12.5625 18.4023 12.0352 17.875 11.4375 17.875ZM13.793 4.375H10.0312C7.99219 4.375 6.375 6.02734 6.375 8.03125V8.59375C6.375 9.08594 6.72656 9.4375 7.21875 9.4375C7.67578 9.4375 8.0625 9.08594 8.0625 8.59375V8.03125C8.0625 6.97656 8.94141 6.0625 10.0312 6.0625H13.793C14.9531 6.0625 15.9375 7.04688 15.9375 8.20703C15.9375 9.01562 15.4805 9.75391 14.7773 10.1055L12.2461 11.4414C11.1914 11.9688 10.5938 13.0234 10.5938 14.1836V14.7812C10.5938 15.2734 10.9453 15.6602 11.4375 15.6602C11.8945 15.6602 12.2812 15.2734 12.2812 14.7812V14.1836C12.2812 13.6562 12.5625 13.1992 13.0195 12.9531L15.5508 11.6172C16.8164 10.9492 17.625 9.64844 17.625 8.20703C17.625 6.09766 15.9023 4.375 13.793 4.375Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconQuestion;
