export type UserAvatar = {
  label: string;
  url: string;
};

export const userAvatars: UserAvatar[] = [
  {
    label: 'Avatar 1',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697622827252.png'
  },
  {
    label: 'Avatar 2',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697628387590.png'
  },
  {
    label: 'Avatar 3',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697623913436.png'
  },
  {
    label: 'Avatar 4',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697628444406.png'
  },
  {
    label: 'Avatar 5',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697628613431.png'
  },
  {
    label: 'Avatar 6',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697628668104.png'
  },
  {
    label: 'Avatar 7',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697628693037.png'
  },
  {
    label: 'Avatar 8',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697628712108.png'
  },
  {
    label: 'Avatar 9',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697628733596.png'
  },
  {
    label: 'Avatar 10',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697628795624.png'
  },
  {
    label: 'Avatar 11',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697628834782.png'
  },
  {
    label: 'Avatar 12',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697628857150.png'
  },
  {
    label: 'Avatar 13',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697629411789.png'
  },
  {
    label: 'Avatar 14',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697629435702.png'
  },
  {
    label: 'Avatar 15',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697629456364.png'
  },
  {
    label: 'Avatar 16',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697629207474.png'
  },
  {
    label: 'Avatar 17',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697629242628.png'
  },
  {
    label: 'Avatar 18',
    url: 'https://koreansimple.s3.ap-northeast-2.amazonaws.com/avatars/1697629292730.png'
  }
];
