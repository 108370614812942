import * as React from 'react';

const IconNotification: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M17.7.042a4.821 4.821 0 0 0-3.162 1.709l-.207.252-3.516.009c-3.919.011-3.677-.006-4.295.295-.233.114-.381.228-.661.51-.407.408-.596.724-.747 1.245l-.092.318v15.24l.092.318c.151.521.34.837.747 1.245.28.282.428.396.661.51.627.306.27.287 5.48.287 5.213 0 4.86.019 5.48-.289.228-.113.386-.235.661-.51.399-.399.588-.709.745-1.222l.092-.299.012-5.086.013-5.086.149-.026c1.528-.275 2.95-1.481 3.528-2.994.449-1.175.373-2.689-.191-3.808C22.056 1.8 21.2.944 20.34.511 19.609.142 18.525-.05 17.7.042m1.18 2.02c.143.034.413.135.599.225.278.135.405.23.705.529.299.3.394.427.529.705.235.486.297.796.278 1.379-.012.39-.035.535-.12.772-.301.842-.919 1.478-1.698 1.749-.699.243-1.356.221-2.073-.068-.495-.2-.679-.209-1.133-.057-.182.062-.342.103-.354.091-.012-.012.029-.172.091-.354.15-.449.143-.644-.042-1.087-.161-.387-.262-.836-.261-1.16 0-.331.141-.919.294-1.235.347-.715.991-1.244 1.793-1.473.324-.093 1.03-.101 1.392-.016M13.501 4.07c-.016.039-.038.287-.05.553-.026.57.053 1.134.229 1.629l.112.317-.381 1.105c-.302.879-.38 1.152-.38 1.327a.983.983 0 0 0 .6.902c.305.129.506.092 1.695-.316l1.106-.379.284.101.284.102v4.996c0 5.42.011 5.148-.212 5.386-.203.218.049.207-4.788.207-4.837 0-4.585.011-4.788-.207C6.987 19.552 7 20.048 7 12c0-6.571.007-7.419.062-7.55a.718.718 0 0 1 .318-.376c.114-.07.27-.074 3.134-.074 2.855 0 3.013.004 2.987.07m-1.698 12.975a1.033 1.033 0 0 0-.724.615 1.254 1.254 0 0 0-.046.349.947.947 0 0 0 .967.959c.559 0 .977-.414.977-.968 0-.625-.549-1.072-1.174-.955'
    />
  </svg>
);
export default IconNotification;
