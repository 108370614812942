import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { courseAvatars } from 'consts';
import { FormValues } from '../../model';

import { IconCamera } from 'components/atoms/icons';

interface SelectAvatarProps {
  control: Control<FormValues>;
  cover?: string;
  openModal: () => void;
}

const SelectAvatar: FC<SelectAvatarProps> = ({ control, cover, openModal }) => {
  return (
    <Controller
      control={control}
      name='avatarId'
      render={({ field: { value: avatarId }, fieldState: { error } }) => (
        <div className='flex flex-col'>
          <button
            type='button'
            className='relative flex h-[224px] w-[224px] overflow-hidden rounded-[16px]'
            onClick={openModal}
          >
            <img
              src={avatarId ? courseAvatars.find(({ id }) => id === avatarId)?.url || cover : cover}
              alt='course-avatar'
              className='h-full w-full object-cover'
            />

            <div className='absolute inset-0 flex items-center justify-center bg-[rgba(22,32,48,0.42)]'>
              <IconCamera color='#FFFFFF' className='h-[52px] w-[52px]' />
            </div>
          </button>

          {!!error && (
            <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
              {error.message}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default SelectAvatar;
