import {
  ActiveElement,
  BarElement,
  BubbleDataPoint,
  CategoryScale,
  Chart,
  ChartData,
  ChartEvent,
  Chart as ChartJS,
  ChartTypeRegistry,
  Filler,
  Legend,
  LinearScale,
  Point,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  TooltipModel
} from 'chart.js';
import { FC, useCallback, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import { ReviewsLesson as IReviewsLesson, ReviewsModule } from 'api/api-analytics';
import { useGetCourseReviewsQuery } from 'hooks/course';
import { findMaximum } from 'utils';
import { chartOptions } from './const';
import { TooltipData } from './model';

import Modal from 'components/atoms/Modal';
import { IconStroke } from 'components/atoms/icons';
import { ReviewsLesson } from '..';
import ReviewsSkeleton from './ReviewsSkeleton';
import ReviewsTooltip from './ReviewsTooltip';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

interface ReviewsProps {
  courseId: string;
}

const Reviews: FC<ReviewsProps> = ({ courseId }) => {
  const { data: courseReviews, isLoading: courseReviewsLoading } =
    useGetCourseReviewsQuery(courseId);
  const [moduleIndex, setModuleIndex] = useState<number>(0);
  const [labelSums, setLabelSums] = useState<number[]>([]);
  const [currentModule, setCurrentModule] = useState<ReviewsModule>();
  const [currentLessons, setCurrentLessons] = useState<IReviewsLesson[]>([]);
  const [currentLesson, setCurrentLesson] = useState<IReviewsLesson>();
  const [lessonModalActive, setLessonModalActive] = useState<boolean>(false);
  const [tooltipData, setTooltipData] = useState<TooltipData>({
    showTooltip: false,
    x: undefined,
    y: undefined,
    height: undefined,
    width: undefined,
    xAlign: undefined,
    yAlign: undefined,
    reviewsLesson: undefined
  });

  const chartData = useMemo<ChartData<'bar', number[], string> | undefined>(() => {
    if (!courseReviews || !courseReviews[moduleIndex]) return;
    const module = courseReviews[moduleIndex];
    setCurrentModule(module.module);

    const lessons = module.lessons;
    setCurrentLessons(lessons);

    const dataBadReviews = lessons.map(lesson => lesson.badReviews);
    const dataNeutralReviews = lessons.map(lesson => lesson.neutralReviews);
    const dataGoodReviews = lessons.map(lesson => lesson.goodReviews);

    const labels = lessons.map(lesson => `Урок ${lesson.lesson.order}`);
    const datasets = [
      {
        label: 'Отрицательных оценок',
        data: dataBadReviews,
        backgroundColor: 'rgba(255, 68, 68, 0.40)',
        hoverBackgroundColor: 'rgba(255, 68, 68, 0.60)'
      },
      {
        label: 'Нейтральных оценок',
        data: dataNeutralReviews,
        backgroundColor: 'rgba(251, 189, 69, 0.40)',
        hoverBackgroundColor: 'rgba(251, 189, 69, 0.60)'
      },
      {
        label: 'Положительных оценок',
        data: dataGoodReviews,
        backgroundColor: 'rgba(102, 200, 77, 0.40)',
        hoverBackgroundColor: 'rgba(102, 200, 77, 0.60)'
      }
    ];

    const labelSums = Array(labels.length).fill(0);
    datasets.forEach(dataset => {
      dataset.data.forEach((value, index) => {
        labelSums[index] += value;
      });
    });
    setLabelSums(labelSums);

    return {
      labels,
      datasets
    };
  }, [courseReviews, moduleIndex]);

  const prevModule = () => setModuleIndex(prev => prev - 1);
  const nextModule = () => setModuleIndex(prev => prev + 1);

  const handleClick = (
    event: ChartEvent,
    elements: ActiveElement[],
    chart: ChartJS<
      keyof ChartTypeRegistry,
      (number | Point | [number, number] | BubbleDataPoint | null)[],
      unknown
    >
  ) => {
    if (!elements.length || !currentLessons.length) return;
    const index = elements[0].index;
    const lesson = currentLessons[index];
    if (!lesson) return;
    setCurrentLesson(lesson);
    setLessonModalActive(true);
  };

  const externalTooltipHandler = useCallback<
    (
      this: TooltipModel<'bar'>,
      args: {
        chart: Chart<
          keyof ChartTypeRegistry,
          (number | Point | [number, number] | BubbleDataPoint | null)[],
          unknown
        >;
        tooltip: TooltipModel<'bar'>;
      }
    ) => void
  >(
    ({ chart, tooltip }) => {
      // hide if no tooltip
      if (tooltip.opacity === 0 && tooltipData.showTooltip) {
        setTooltipData(tooltipData => ({ ...tooltipData, showTooltip: false }));
        return;
      }

      if (!currentLessons.length) return;
      const index = tooltip.dataPoints[0].dataIndex;
      const lesson = currentLessons[index];
      setCurrentLesson(lesson);
      if (!lesson) return;

      if (
        tooltipData.y !== tooltip.y ||
        tooltipData.x !== tooltip.x ||
        tooltipData.reviewsLesson !== currentLesson ||
        (tooltip.opacity === 1 && !tooltipData.showTooltip)
      ) {
        setTooltipData({
          reviewsLesson: lesson,
          showTooltip: tooltip.opacity === 1,
          height: tooltip.height,
          width: tooltip.width,
          x: tooltip.x,
          y: tooltip.y,
          xAlign: tooltip.xAlign,
          yAlign: tooltip.yAlign
        });
      }
    },
    [
      currentLesson,
      currentLessons,
      tooltipData.reviewsLesson,
      tooltipData.showTooltip,
      tooltipData.x,
      tooltipData.y
    ]
  );

  if (courseReviewsLoading) return <ReviewsSkeleton />;

  return (
    <div className='flex flex-1 flex-col px-[15px] pb-[30px]'>
      <div className='relative mb-[30px] flex flex-1 cursor-pointer'>
        {courseReviewsLoading ? (
          <span>Загрузка...</span>
        ) : (
          <>
            {chartData ? (
              <Bar
                data={chartData}
                options={{
                  ...chartOptions,
                  plugins: {
                    ...chartOptions.plugins,
                    tooltip: {
                      enabled: false,
                      external: externalTooltipHandler
                    }
                  },
                  scales: {
                    ...chartOptions.scales,
                    y: {
                      stacked: true,
                      suggestedMax: findMaximum(labelSums) * 1.1,
                      ticks: {
                        stepSize: 1,
                        color: '#A6B0C9',
                        font: {
                          size: 14
                        }
                      }
                    }
                  },
                  onClick: handleClick
                }}
              />
            ) : (
              <span>Для данного курса статистика не записывается</span>
            )}
          </>
        )}
        <ReviewsTooltip currentModule={currentModule} tooltipData={tooltipData} />
      </div>

      <div className='flex select-none items-center gap-[12px] self-center'>
        <button
          type='button'
          className='flex h-[24px] w-[24px] items-center justify-center disabled:opacity-30'
          onClick={prevModule}
          disabled={moduleIndex === 0}
        >
          <IconStroke color='#5770F3' className='h-full w-full' />
        </button>
        <h5 className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>
          Модуль {moduleIndex + 1}
        </h5>
        <button
          type='button'
          className='flex h-[24px] w-[24px] items-center justify-center disabled:opacity-30'
          onClick={nextModule}
          disabled={moduleIndex + 1 === courseReviews?.length}
        >
          <IconStroke color='#5770F3' className='h-full w-full rotate-180' />
        </button>
      </div>

      {lessonModalActive && (
        <Modal onClose={() => setLessonModalActive(false)}>
          <ReviewsLesson
            currentModule={currentModule}
            currentLesson={currentLesson}
            onCancel={() => setLessonModalActive(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Reviews;
