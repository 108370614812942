import { FC } from 'react';

import { DEFAULT_COLORS_AMOUNT } from './const';

import { Skeleton } from 'components/atoms';

const SidebarSkeleton: FC = () => {
  return (
    <div className='flex w-[210px] grow flex-col'>
      <Skeleton height={28} width={69} className='mb-[35px]' />
      <Skeleton height={200} width={200} border={16} className='mb-[20px]' />
      <Skeleton height={19} width={176} className='mb-[20px]' />

      <ul className='flex flex-wrap gap-[20px]'>
        {Array.from(Array(DEFAULT_COLORS_AMOUNT)).map((_, index) => (
          <Skeleton key={index} height={32} width={32} border='50%' />
        ))}
      </ul>
    </div>
  );
};

export default SidebarSkeleton;
