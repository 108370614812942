import { NOTIFICATION_FOR } from 'models';

export const getNotificationFor = (notificationFor: NOTIFICATION_FOR) => {
  switch (notificationFor) {
    case NOTIFICATION_FOR.ALL:
      return <span className='text-[16px] leading-[19px] text-[#20233A]'>Всем</span>;
    case NOTIFICATION_FOR.SELECTED:
      return <span className='text-[16px] leading-[19px] text-[#20233A]'>Некоторым</span>;
  }
};
