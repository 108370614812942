import { ChangeEvent, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notifySuc } from 'helpers/notification';
import { useGetWordsFromFileMutation } from 'hooks/dictionary';
import { ValidatedWordsData } from 'models';
import { handleError } from 'helpers/handleError';
import { Content, Sidebar, UploadWords } from './components';
import { apiDictionary } from 'api';
import NotionTextParse from './components/NotionTextParse/NotionTextParse';

type Params = { dictionaryId: string };

const ThematicDictionaryPage: FC = () => {
  const { dictionaryId } = useParams<keyof Params>() as Params;
  const { mutate: getWordsFromFileMutate, isLoading: getWordsFromFileLoading } =
    useGetWordsFromFileMutation();
  const [validatedWordsData, setValidatedWordsData] = useState<ValidatedWordsData | null>(null);
  const [isNotionTextParseOpen, setIsNotionTextParseOpen] = useState<boolean>(false);
  const [notionTextUploadLoading, setNotionTextUploadLoading] = useState<boolean>(false);
  const handleUploadFile = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = event.target?.files && event.target.files[0];
    if (!file) return;

    getWordsFromFileMutate(
      { dictionaryId, body: { file } },
      {
        onSuccess: data => {
          setValidatedWordsData(data);
        }
      }
    );
  };
  const toggleIsNotionTextParseOpen = () => {
    setIsNotionTextParseOpen(prev => !prev);
  };
  const validateNotionText = async (dictionaryId: string, text: string): Promise<void> => {
    setIsNotionTextParseOpen(false);
    setNotionTextUploadLoading(true);

    try {
      const validatedWordsData = await apiDictionary.getWordsFromNotionTextThematic(
        dictionaryId,
        text
      );

      setValidatedWordsData(validatedWordsData);
      notifySuc('Текст обработан');
    } catch (error) {
      handleError(error);
    }

    setNotionTextUploadLoading(false);
  };
  if (isNotionTextParseOpen) {
    return (
      <div className='flex h-full gap-[54px]'>
        <Sidebar />
        <NotionTextParse
          onClose={toggleIsNotionTextParseOpen}
          validateNotionText={validateNotionText}
        />
      </div>
    );
  }
  return (
    <div className='flex gap-[54px]'>
      <Sidebar />

      {validatedWordsData ? (
        <UploadWords
          validatedWordsData={validatedWordsData}
          setValidatedWordsData={setValidatedWordsData}
        />
      ) : (
        <Content
          handleUploadFile={handleUploadFile}
          fileUploadLoading={getWordsFromFileLoading}
          notionTextUploadLoading={notionTextUploadLoading}
          toggleIsNotionTextParseOpen={toggleIsNotionTextParseOpen}
        />
      )}
    </div>
  );
};

export default ThematicDictionaryPage;
