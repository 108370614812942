import { FC } from 'react';

import { Skeleton } from 'components/atoms';

export const DEFAULT_STEPS_AMOUNT = 8;

const CourseChecklistSkeleton: FC = () => {
  return (
    <div className='flex grow flex-col'>
      <header className='mb-[16px] flex h-[49px] items-center justify-between'>
        <Skeleton height={35} width={120} />
        <Skeleton height={48} width={218} border={14} />
      </header>

      <div className='mb-[24px] flex h-[49px] items-center justify-between'>
        <Skeleton height={24} width={260} />
        <Skeleton height={24} width={170} />
      </div>

      <div className='mb-[40px] flex flex-col gap-[16px]'>
        <Skeleton height={15} width={50} />

        <ul className='flex gap-[12px]'>
          {Array.from({ length: DEFAULT_STEPS_AMOUNT }).map((_, index) => (
            <li key={index} className='flex shrink-0 items-center gap-[12px]'>
              <Skeleton height={40} width={40} border='50%' className='w-[40px]' />
              {index < DEFAULT_STEPS_AMOUNT - 1 && (
                <span className='h-[2px] w-[8px] bg-[#EFF1F4]' />
              )}
            </li>
          ))}
        </ul>
      </div>

      <Skeleton height={21} width={100} className='mb-[8px]' />
      <Skeleton height={24} width={350} className='mb-[24px]' />
      <Skeleton height={54} width={275} className='mb-auto' border={27} />

      <div className='flex items-center justify-between'>
        <Skeleton height={64} width={120} border={13} />
        <Skeleton height={64} width={120} border={13} />
        <Skeleton height={64} width={120} border={13} />
      </div>
    </div>
  );
};

export default CourseChecklistSkeleton;
