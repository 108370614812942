import React from 'react';

const IconCamera: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='52'
      height='52'
      viewBox='0 0 52 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M40.3998 14.5332H34.9998L34.3248 12.8832C33.6498 11.0082 31.8498 9.73315 29.8248 9.73315H22.0998C20.0748 9.73315 18.2748 11.0082 17.5998 12.8832L16.9998 14.5332H11.5998C8.8998 14.5332 6.7998 16.7082 6.7998 19.3332V38.5332C6.7998 41.2332 8.8998 43.3332 11.5998 43.3332H40.3998C43.0248 43.3332 45.1998 41.2332 45.1998 38.5332V19.3332C45.1998 16.7082 43.0248 14.5332 40.3998 14.5332ZM41.5998 38.5332C41.5998 39.2082 40.9998 39.7332 40.3998 39.7332H11.5998C10.9248 39.7332 10.3998 39.2082 10.3998 38.5332V19.3332C10.3998 18.7332 10.9248 18.1332 11.5998 18.1332H19.4748L20.9748 14.1582C21.1248 13.7082 21.5748 13.3332 22.0998 13.3332H29.8248C30.3498 13.3332 30.7998 13.7082 30.9498 14.1582L32.4498 18.1332H40.3998C40.9998 18.1332 41.5998 18.7332 41.5998 19.3332V38.5332ZM25.9998 20.5332C21.3498 20.5332 17.5998 24.3582 17.5998 28.9332C17.5998 33.5832 21.3498 37.3332 25.9998 37.3332C30.5748 37.3332 34.3998 33.5832 34.3998 28.9332C34.3998 24.3582 30.5748 20.5332 25.9998 20.5332ZM25.9998 33.7332C23.2998 33.7332 21.1998 31.6332 21.1998 28.9332C21.1998 26.3082 23.2998 24.1332 25.9998 24.1332C28.6248 24.1332 30.7998 26.3082 30.7998 28.9332C30.7998 31.6332 28.6248 33.7332 25.9998 33.7332Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconCamera;
