import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { apiCourses, ILesson } from 'api/api-courses';
import { FORM_ERROR_MESSAGES } from 'consts';
import { handleError } from 'helpers/handleError';

import Button from 'components/atoms/Button';
import { IconNext } from 'components/atoms/icons';
import Input from 'components/atoms/Input/Input';
import { notifySuc } from 'helpers/notification';

interface NewSectionProps {
  sectionOrder: number;
  lesson: ILesson;
  pageStatusChange: (id: string) => void;
  moduleTitle?: string;
}

const NewSection: FC<NewSectionProps> = ({
  sectionOrder,
  lesson,
  pageStatusChange,
  moduleTitle
}) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm();

  const { mutate: createSectionMutate, isLoading: createSectionLoading } = useMutation(
    (title: string) => {
      return apiCourses.addSections(lesson.id, [{ order: sectionOrder, title }]);
    },
    {
      onSuccess(data) {
        notifySuc('Раздел создан');

        pageStatusChange((data as Array<{ id: string }>)[0].id);
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const onSubmit = async (data: FieldValues) => {
    const { title } = data;

    createSectionMutate(title.trim());
  };

  return (
    <div className='flex h-full w-full flex-col pt-[100px]'>
      <header className='mb-[24px] flex h-[74px] items-center'>
        <h1 className='text-[36px] font-[700] leading-[42px] text-[#20233A]'>
          Добавление раздела урока
        </h1>
      </header>

      {moduleTitle && (
        <h2 className='mb-[24px] text-[21px] font-[700] leading-[24px] text-[#20233A]'>
          {moduleTitle}
        </h2>
      )}

      <div className='mb-[24px] flex items-center'>
        <div className='mr-[20px]'>
          <img className='h-[54px] w-[54px]' src={lesson.imageUrl} alt='lessonImage' />
        </div>

        <div className='flex flex-col'>
          <div className='mb-[4px] text-[14px] leading-[16px] text-[#71798F]'>{`Урок ${lesson.order}`}</div>
          <div className='text-[18px] font-[500] leading-[21px] text-[#20233A]'>{lesson.title}</div>
        </div>
      </div>

      <form className='flex h-full flex-col' onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
          {`Название раздела №${lesson.order}-${sectionOrder}`}
        </div>

        <Input
          type='text'
          variant='bordered'
          width='big'
          name='title'
          placeholder='Введите название'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            validate: {
              doesntConsistOfSpaces: (value: any) => {
                return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
              }
            }
          }}
          containerClassName='mb-auto max-w-[615px]'
        />

        <div className='flex items-center self-end justify-self-end'>
          <span className='mr-[16px] text-[18px] leading-[21px] text-[#394357]'>
            {!isDirty ? 'Добавьте название раздела' : 'Добавьте шаги в текущий раздел'}
          </span>

          <Button
            type='submit'
            variant='primary'
            title={'Добавить раздел'}
            icon={<IconNext />}
            iconPosition='right'
            className='px-[48px] py-[20px]'
            isDisabled={!isDirty}
            isLoading={createSectionLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default NewSection;
