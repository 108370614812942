import { FC } from 'react';

import styles from './AnalyticsPage.module.css';
import { Numbers } from './ui/Numbers';
import { StatisticsTable } from './ui/StatisticsTable';

const AnalyticsPage: FC = () => {
  return (
    <div className={styles.container}>
      <header className='mb-[12px] flex h-[74px] w-full items-center justify-between'>
        <h2 className='text-[30px] font-[700] leading-[36px] text-[#20233A]'>Аналитика</h2>
      </header>
      <Numbers className='mb-[24px]' />
      <StatisticsTable />
    </div>
  );
};

export default AnalyticsPage;
