import { FC, useMemo } from 'react';

import { ChecklistItem } from 'models';

import { IconCheck, IconClose } from 'components/atoms/icons';

interface CourseReadyProps {
  steps: ChecklistItem[];
  variant?: 'small' | 'big';
}

const CourseReady: FC<CourseReadyProps> = ({ steps, variant = 'small' }) => {
  const stepsInfo = useMemo(() => {
    const stepsDone = steps.reduce((acc, step) => (step.condition ? ++acc : acc), 0);
    const isReady = stepsDone === steps.length;

    return { stepsDone, isReady };
  }, [steps]);

  return (
    <div className='flex items-center gap-[12px]'>
      {stepsInfo.isReady ? <IconCheck color='#66C84D' /> : <IconClose color='#FF4444' />}
      <span
        className={
          'text-[#20233A] ' +
          `${
            variant === 'small'
              ? 'text-[16px] font-[500] leading-[19px]'
              : 'text-[21px] font-[700] leading-[24px]'
          }`
        }
      >
        Курс готов на {stepsInfo.stepsDone}/{steps.length}
      </span>
    </div>
  );
};

export default CourseReady;
