import React from 'react';

const IconNew: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path
        d='M5.79999 13.6667L2.46665 9H0.799988V17H2.46665V12.3333L5.86665 17H7.46665V9H5.79999V13.6667ZM8.79999 17H14.1333V15.3333H10.8V13.8533H14.1333V12.1733H10.8V10.68H14.1333V9H8.79999V17ZM21.8 9V15H20.3067V10.32H18.64V15.0133H17.1333V9H15.4667V15.6667C15.4667 16.4 16.0667 17 16.8 17H22.1333C22.8667 17 23.4667 16.4 23.4667 15.6667V9H21.8Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconNew;
