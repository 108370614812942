import React from 'react';

const IconSpace: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Icons'>
        <path
          id='Vector'
          d='M22 17V18C22 18.5 21.5 19 21 19H3C2.5 19 2 18.5 2 18V17'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export default IconSpace;
