import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IWord } from 'api/api-words';
import { useSetWordImageMutation } from 'hooks/word';
import styles from './WordCard.module.css';

import Modal from 'components/atoms/Modal';
import { IconPen, IconTrash, IconUpload, GenerateIcon } from 'components/atoms/icons';
import ImageUploadForm from 'components/organisms/ImageUploadForm';
import { DeleteWordForm } from '..';
import { GeneratedWordImageModal } from 'components/organisms';

type WordCardProps = {
  word: IWord;
  index: number;
  dictionaryId: string;
};

const WordCard: FC<WordCardProps> = ({ word, index, dictionaryId }) => {
  const navigate = useNavigate();
  const [isImageUploadFormModalActive, setImageUploadFormModalActive] = useState<boolean>(false);
  const [confirmationModalActive, setConfirmationModalActive] = useState(false);
  const { mutate: setWordImageMutate } = useSetWordImageMutation();
  const [isShowGenerateImageModal, setIsShowGenerateImageModal] = useState<boolean>(false);

  const setWordImage = (image: File) => {
    setWordImageMutate({ wordId: word.id, dictionaryId, body: { image } });
  };

  const toggleShowGenerateImageModal = () => {
    setIsShowGenerateImageModal(prev => !prev);
  };

  return (
    <div
      className={`${styles.table__row} ${index % 2 === 1 ? styles.table__row_even : ''}`}
      key={word.id}
    >
      <div className={`${styles.table__cell} ${styles.table__cell_image}`}>
        <button
          className={`${styles.image} ${word.imageUrl ? '' : styles.image_placeholder}`}
          onClick={() => setImageUploadFormModalActive(true)}
        >
          {word.imageUrl ? (
            <>
              <div className={styles.image__backdrop}></div>
              <IconUpload className={styles.image__icon} color='white' />
              <img src={word.imageUrl} alt={word.imageUrl} />
            </>
          ) : (
            <IconUpload className={styles.iconUpload} />
          )}
        </button>
        <button
          onClick={toggleShowGenerateImageModal}
          className={`flex h-[60px] w-[60px] items-center justify-center rounded-[12px] ${
            word.imageUrl ? 'bg-gray-400 hover:bg-[#162030]' : 'bg-[#5770F3] hover:bg-[#162030]'
          }`}
        >
          <GenerateIcon />
        </button>
      </div>

      <div className={`${styles.table__cell} ${styles.table__cell_original}`}>
        <span>{word.original}</span>
      </div>

      <div className={`${styles.table__cell} ${styles.table__cell_translation}`}>
        <span>{word.translation}</span>
      </div>

      <div className={`${styles.table__cell} ${styles.table__cell_actions}`}>
        <button
          onClick={() => navigate(`/dictionaries/catalog/${word.id}`)}
          className={styles.buttonAction}
        >
          <IconPen color='#71798F' />
        </button>

        <button onClick={() => setConfirmationModalActive(true)} className={styles.buttonAction}>
          <IconTrash color='#71798F' />
        </button>
      </div>

      {isImageUploadFormModalActive && (
        <Modal onClose={() => setImageUploadFormModalActive(false)}>
          <ImageUploadForm
            imageUrl={word.imageUrl}
            setImage={(image: File) => setWordImage(image)}
            onCancel={() => setImageUploadFormModalActive(false)}
          />
        </Modal>
      )}

      {confirmationModalActive && (
        <Modal onClose={() => setConfirmationModalActive(false)}>
          <DeleteWordForm
            dictionaryId={dictionaryId}
            word={word}
            onCancel={() => setConfirmationModalActive(false)}
          />
        </Modal>
      )}

      {isShowGenerateImageModal && (
        <GeneratedWordImageModal
          wordId={word.id}
          handleClose={toggleShowGenerateImageModal}
          dictionaryId={dictionaryId}
          autoGenerate={true}
        />
      )}
    </div>
  );
};

export default WordCard;
