import { useMutation, useQueryClient } from 'react-query';

import { apiNotifications } from 'api/api-notifications';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useDeleteNotificationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) => {
      return apiNotifications.deleteNotification(id);
    },
    {
      onSuccess() {
        notifySuc('Уведомление удалено');
        queryClient.invalidateQueries('/notifications');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
