import { apiCourses } from 'api/api-courses';

const audioIcon =
  '<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:#231f20;}</style></defs><path class="cls-1" d="M100,175.69a5,5,0,0,1-3.37-1.31L48.06,130H29.31a5,5,0,0,1-5-5V75a5,5,0,0,1,5-5H48.06L96.63,25.62A5,5,0,0,1,105,29.31V170.69a5,5,0,0,1-5,5ZM34.31,120H50a5,5,0,0,1,3.37,1.31l41.63,38V40.65l-41.63,38A5,5,0,0,1,50,80H34.31Z"/><path class="cls-1" d="M119.4,122.53a5,5,0,0,1-3.54-8.53,19.82,19.82,0,0,0,0-28,5,5,0,0,1,7.07-7.07,29.83,29.83,0,0,1,0,42.14A5,5,0,0,1,119.4,122.53Z"/><path class="cls-1" d="M137.08,140.21a5,5,0,0,1-3.54-8.54,44.78,44.78,0,0,0,0-63.34,5,5,0,1,1,7.07-7.07,54.78,54.78,0,0,1,0,77.48A5,5,0,0,1,137.08,140.21Z"/><path class="cls-1" d="M152,155.13a5,5,0,0,1-3.54-8.53,65.91,65.91,0,0,0,0-93.2,5,5,0,1,1,7.07-7.07,75.9,75.9,0,0,1,0,107.34A5,5,0,0,1,152,155.13Z"/></svg>';

class SimpleAudio {
  static get isReadOnlySupported() {
    return true;
  }

  static get toolbox() {
    return {
      icon: audioIcon,
      title: 'Audio'
    };
  }

  constructor({ data, api, readOnly }) {
    this.data = data;
    this.api = api;
    this.readOnly = readOnly;
    this.wrapper = undefined;
  }

  render() {
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('simple-audio');

    if (this.data && this.data.url) {
      this._createAudio(this.data.url);
      return this.wrapper;
    }

    // blocks for audio uploading
    const id = Date.now().toString();
    const input = document.createElement('input');
    input.id = id;
    input.type = 'file';
    input.accept = 'audio/*';
    input.classList.add('hidden');

    input.addEventListener('change', async event => {
      const file = event.target.files[0];
      const { url } = await apiCourses.getUrlFromAudio(file);
      this._createAudio(url);
    });

    const icon = document.createElement('div');
    icon.innerHTML = audioIcon;

    const span = document.createElement('span');
    span.innerHTML = this.api.i18n.t('Upload an Audio');

    const label = document.createElement('label');
    label.appendChild(icon);
    label.appendChild(span);
    label.htmlFor = id;
    label.classList.add('cdx-button');

    // blocks for text generation
    const inputTextId = Date.now().toString();
    const inputText = document.createElement('input');
    inputText.id = inputTextId;
    inputText.placeholder = this.api.i18n.t('Enter a word or phrase');
    inputText.type = 'text';
    inputText.classList.add('cdx-input');

    inputText.addEventListener('keydown', async event => {
      if (event.key !== 'Enter') return;
      event.preventDefault();
      const textForAudio = event.target.value;
      if (!textForAudio) return;
      const { url } = await apiCourses.getAudioUrl(textForAudio);
      this._createAudio(url);
    });

    const generateLabel = document.createElement('label');
    generateLabel.innerText = this.api.i18n.t('Generate audio');
    generateLabel.htmlFor = inputTextId;
    generateLabel.classList.add('cdx-button');
    generateLabel.addEventListener('click', async event => {
      event.preventDefault();
      const textForAudio = inputText.value;
      if (!textForAudio) return;
      const { url } = await apiCourses.getAudioUrl(textForAudio);
      this._createAudio(url);
    });

    const generateCointainer = document.createElement('div');
    generateCointainer.classList.add('generate-container');
    generateCointainer.appendChild(inputText);
    generateCointainer.appendChild(generateLabel);

    this.wrapper.appendChild(input);
    this.wrapper.appendChild(label);
    this.wrapper.appendChild(generateCointainer);

    return this.wrapper;
  }

  renderReadOnly() {
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('simple-audio');

    if (this.data && this.data.url) {
      this._createAudio(this.data.url);
    }

    return this.wrapper;
  }

  _createAudio(url) {
    const audioWrapper = document.createElement('div');
    audioWrapper.classList.add('audio-wrapper');

    const audio = document.createElement('audio');
    audio.src = url.trim();
    audio.controls = true;

    this.wrapper.innerHTML = '';
    audioWrapper.appendChild(audio);
    this.wrapper.appendChild(audioWrapper);
  }

  save(blockContent) {
    const audio = blockContent.querySelector('audio');

    return {
      url: audio?.src
    };
  }

  validate(savedData) {
    if (!savedData.url?.trim()) {
      return false;
    }

    return true;
  }
}

export default SimpleAudio;
