import { useMutation } from 'react-query';

import { apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useGenerateAudioFromTextMutation = () => {
  return useMutation(
    (textForAudio: string) => {
      return apiCourses.getAudioUrl(textForAudio);
    },
    {
      onSuccess() {
        notifySuc('Аудиофайл сгенерирован');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
