import { FC } from 'react';

import { Skeleton } from 'components/atoms';

const SkeletonSidebar: FC = () => {
  return (
    <div className='flex h-full w-[300px] flex-col pb-[48px]'>
      <Skeleton height={28} width={69} className='mb-[30px]' />
      <Skeleton height={100} width={100} className='mb-[24px]' />
      <Skeleton height={80} className='mb-[24px]' />
      <Skeleton height={19} className='mb-[24px]' />
      <Skeleton height={44} width={202} border={34} className='mb-[8px]' />
      <Skeleton height={44} width={202} border={34} className='mb-[8px]' />
      <Skeleton height={44} width={202} border={34} className='mb-auto' />
      <Skeleton height={28} width={253} className='mb-[24px]' />
      <Skeleton height={46} width={214} className='mb-[24px]' />
      <Skeleton height={28} width={204} />
    </div>
  );
};

export default SkeletonSidebar;
