import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { COURSES_COLORS } from 'consts';
import {
  useGetDictionaryQuery,
  useUpdateDictionaryMutation,
  useUploadImageMutation
} from 'hooks/dictionary';
import { IColor } from 'models';
import styles from './Sidebar.module.css';

import { InputImageControlled } from 'components/atoms';
import { IconBack } from 'components/atoms/icons';
import { ColorSelect } from 'components/molecules';
import SidebarSkeleton from './SidebarSkeleton';

type Params = { dictionaryId: string };

const Sidebar: FC = () => {
  const navigate = useNavigate();
  const { dictionaryId } = useParams<keyof Params>() as Params;
  const { data: dictionary, isLoading: dictionaryLoading } = useGetDictionaryQuery(dictionaryId);
  const { mutate: updateDictionaryMutate, isLoading: updateDictionaryLoading } =
    useUpdateDictionaryMutation();
  const { mutate: uploadImageMutate, isLoading: uploadImageLoading } = useUploadImageMutation();

  const updateImage = (file: File) => {
    if (!dictionary) return;
    uploadImageMutate({ dictionaryId: dictionary.id, body: { file } });
  };

  const updateColor = (color: string) => {
    if (!dictionary) return;
    updateDictionaryMutate({ dictionaryId: dictionary.id, body: { color } });
  };

  const goBack = () => {
    navigate('/dictionaries?filter=thematic');
  };

  if (dictionaryLoading) return <SidebarSkeleton />;
  if (!dictionary) return <h2>Не удалось загрузить словарь</h2>;

  return (
    <div className={styles.container}>
      <button className={styles.back} onClick={goBack}>
        <IconBack className='text-[#71798F]' />
        <span className='text-[14px] font-[500] leading-[16px] text-[#71798f]'>Назад</span>
      </button>

      <InputImageControlled
        setValue={updateImage}
        cover={dictionary.imageUrl}
        name='image'
        accept='image/svg+xml'
        title='Загрузить иллюстрацию словаря'
        subtitle='Файл должен быть формата SVG, в пропорции 1:1'
        containerClassName='mb-[20px]'
        loading={updateDictionaryLoading || uploadImageLoading}
      />

      <div className='flex flex-col gap-[16px]'>
        <label className='text-[16px] font-[500] leading-[19px] text-[#71798F]'>
          Выбрать цвет словаря:
        </label>

        <ColorSelect
          colors={COURSES_COLORS}
          currentColor={COURSES_COLORS.find(({ hex }) => hex === dictionary.color) || null}
          setCurrentColor={newValue => {
            newValue && updateColor((newValue as IColor).hex);
          }}
          loading={updateDictionaryLoading || uploadImageLoading}
        />
      </div>
    </div>
  );
};

export default Sidebar;
