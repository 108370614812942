import React from 'react';

const IconFillingWords: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g id='Icons'>
      <rect id='Rectangle 673' x='5' y='17' width='14' height='1' rx='0.5' fill='currentColor' />
      <rect
        id='Rectangle 664'
        opacity='0.5'
        x='5'
        y='6'
        width='6'
        height='3'
        rx='1'
        fill='currentColor'
      />
      <rect
        id='Rectangle 674'
        opacity='0.5'
        x='13'
        y='11'
        width='6'
        height='3'
        rx='1'
        fill='currentColor'
      />
      <rect id='Rectangle 654' x='5' y='12' width='7' height='1' rx='0.5' fill='currentColor' />
      <rect id='Rectangle 665' x='12' y='7' width='7' height='1' rx='0.5' fill='currentColor' />
    </g>
  </svg>
);

export default IconFillingWords;
