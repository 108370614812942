import { FC, useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import avatarPlaceholder from 'assets/images/svg/avatar.svg';
import { EMAIL_REGEXP, FORM_ERROR_MESSAGES } from 'consts';
import { useGetCourseQuery } from 'hooks/course';
import { useSendInvitesMutation } from 'hooks/subscription';
import { SubscriptionTerm, subscriptionTermOptions } from 'models/subscription';
import { addMonths, timestampToInput } from 'utils';

import { InputDate, Select } from 'components/atoms';
import Button from 'components/atoms/Button';
import ButtonAdd from 'components/atoms/ButtonAdd';
import Input from 'components/atoms/Input';
import { IconClose } from 'components/atoms/icons';
import CourseStudentsSkeleton from '../CourseStudents/CourseStudentsSkeleton';

type Params = {
  courseId: string;
};

type FormValues1 = {
  email: string;
  name: string;
};

type FormValues = {
  users: {
    email: string;
    name: string;
  }[];
  subscriptionTerm: SubscriptionTerm;
  from?: string;
  to?: string;
};

const CourseSendInvites: FC = () => {
  const navigate = useNavigate();
  const { courseId } = useParams<keyof Params>() as Params;
  const { data: course, isLoading: courseLoading } = useGetCourseQuery({ courseId });
  const { mutate: sendInvitesMutate, isLoading: sendInvitesLoading } = useSendInvitesMutation();

  const {
    control: control1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { isDirty: isDirty1 }
  } = useForm<FormValues1>({
    defaultValues: {
      email: '',
      name: ''
    },
    mode: 'onSubmit'
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty }
  } = useForm<FormValues>({
    defaultValues: {
      users: [],
      from: timestampToInput(Date.now())
    },
    mode: 'onSubmit'
  });

  const {
    field: { value: users, onChange: onChangeUsers },
    fieldState: { error }
  } = useController({
    name: 'users',
    control,
    rules: {
      required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
    }
  });
  const subscriptionTerm = watch('subscriptionTerm');

  const SubscriptionTermComponent = useMemo(() => {
    switch (subscriptionTerm) {
      case SubscriptionTerm.ARBITRARY:
        return (
          <div className='flex items-center gap-[8px]'>
            <InputDate
              name='from'
              label='с'
              width='big'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
              }}
              containerClassName='min-w-[206px]'
            />

            <span className='h-[2px] w-[10px] shrink-0 bg-[#20233A]' />

            <InputDate
              name='to'
              label='по'
              width='big'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
              }}
              containerClassName='min-w-[206px]'
            />
          </div>
        );
      case SubscriptionTerm.THREE_MONTHS:
      case SubscriptionTerm.SIX_MONTHS:
      case SubscriptionTerm.NINE_MONTHS:
      case SubscriptionTerm.TWELVE_MONTHS:
      case SubscriptionTerm.TWENTY_FOUR_MONTHS:
        return (
          <div className='flex items-center gap-[8px]'>
            <InputDate
              name='from'
              label='с'
              width='big'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
              }}
              containerClassName='min-w-[206px]'
            />
          </div>
        );
      case SubscriptionTerm.PERMANENT:
        return null;
    }
  }, [control, subscriptionTerm]);

  const onCancel = () => {
    navigate(-1);
  };

  const onSubmit1 = async (data: FormValues1) => {
    onChangeUsers([...users, data]);
    reset1();
  };

  const onSubmit = async (data: FormValues) => {
    const { users, subscriptionTerm, from, to } = data;

    const purchaseDate = from ? new Date(from).getTime() : undefined;
    let expirationDate = to ? new Date(to).getTime() : undefined;
    const isPermanent = subscriptionTerm === SubscriptionTerm.PERMANENT;

    switch (subscriptionTerm) {
      case SubscriptionTerm.THREE_MONTHS:
        expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 3).getTime();
        break;
      case SubscriptionTerm.SIX_MONTHS:
        expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 6).getTime();
        break;
      case SubscriptionTerm.NINE_MONTHS:
        expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 9).getTime();
        break;
      case SubscriptionTerm.TWELVE_MONTHS:
        expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 12).getTime();
        break;
      case SubscriptionTerm.TWENTY_FOUR_MONTHS:
        expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 24).getTime();
        break;
    }

    sendInvitesMutate(
      {
        courseId,
        body: {
          users,
          isPermanent,
          purchaseDate: isPermanent ? undefined : purchaseDate,
          expirationDate: isPermanent ? undefined : expirationDate
        }
      },
      { onSuccess: onCancel }
    );
  };

  if (courseLoading) return <CourseStudentsSkeleton />;
  if (!course) return <h2>Не удалось получить курс</h2>;

  return (
    <div className='flex grow flex-col'>
      <button className='flex select-none items-center gap-[16px] self-start' onClick={onCancel}>
        <IconClose className='h-[24px] w-[24px] text-[#5770F3]' />
        <span className='text-[16px] leading-[16px] text-[#20233A]'>Отменить</span>
      </button>

      <form className='mb-[32px] flex flex-col' onSubmit={handleSubmit1(onSubmit1)}>
        <header className='mb-[24px] flex items-center justify-between'>
          <h2 className='h-[88px] pt-[30px] text-[30px] font-[700] leading-[36px] text-[#20233A]'>
            Выслать приглашения на курс
          </h2>
        </header>

        <div className='mb-[24px] flex gap-[28px]'>
          <div
            className='h-[88px] w-[88px] overflow-hidden rounded-[20px]'
            style={{ background: course.color }}
          >
            <img
              className='h-full w-full'
              src={course.imageUrl || avatarPlaceholder}
              alt={course.imageUrl}
            />
          </div>

          <div className='flex flex-col gap-[4.5px] pt-[16px]'>
            <p
              title={`Уровень ${course.level}`}
              className={
                'text-[13px] font-[500] leading-[15px] text-[#5A667B] ' +
                'overflow-hidden whitespace-nowrap text-ellipsis'
              }
            >
              {`Уровень ${course.level}`}
            </p>
            <p
              title={course.title}
              className={
                'text-[21px] font-[700] leading-[24px] text-[#20233A] ' +
                'overflow-hidden whitespace-nowrap text-ellipsis'
              }
            >
              {course.title}
            </p>
          </div>
        </div>

        <div className='flex gap-[12px]'>
          <Input
            type='text'
            variant='bordered'
            name='email'
            placeholder='Введите email ученика'
            control={control1}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 50, message: 'Максимальная длина - 50 символов' },
              validate: {
                doesntContainSpaces: (value: any) => {
                  return !value.includes(' ') ? true : FORM_ERROR_MESSAGES.DOESNT_CONTAIN_SPACES;
                },
                validEmail: (value: any) => {
                  return EMAIL_REGEXP.test(value) ? true : FORM_ERROR_MESSAGES.INCORRECT_EMAIL;
                }
              }
            }}
            className='h-[54px]'
          />

          <Input
            type='text'
            variant='bordered'
            name='name'
            placeholder='Введите имя ученика'
            control={control1}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              minLength: { value: 1, message: 'Имя должно быть более 1 символа' },
              maxLength: { value: 32, message: 'Имя должно быть не более 32 символов' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                }
              }
            }}
            className='h-[54px]'
          />

          <ButtonAdd
            variant='light'
            type='submit'
            title='Добавить'
            className='h-[54px] rounded-[62px]'
            isDisabled={!isDirty1}
          />
        </div>
      </form>

      <form className='flex grow flex-col' onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-[32px] flex items-center gap-[8px]'>
          <Select
            name='subscriptionTerm'
            placeholder='Выберите срок'
            control={control}
            options={subscriptionTermOptions}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
            }}
          />
          {SubscriptionTermComponent}
        </div>

        <div className='mb-auto'>
          <ul className='flex flex-wrap gap-x-[16px] gap-y-[8px]'>
            {users?.map((user, index) => (
              <li
                key={index}
                className='flex h-[54px] items-center gap-[12px] rounded-[27px] bg-[#eef0ff] px-[20px]'
              >
                <div className='flex flex-col gap-[2px]'>
                  <p className='text-[16px] leading-[19px] text-[#20233A]'>{user.name}</p>
                  <p className='text-[16px] leading-[19px] text-[#20233A]'>{user.email}</p>
                </div>

                <button
                  type='button'
                  className='flex h-[24px] w-[24px] items-center justify-center'
                  onClick={() => {
                    const oldUsers = users;
                    const updatedUsers = oldUsers.filter(({ email }) => email !== user.email);
                    onChangeUsers(updatedUsers);
                  }}
                >
                  <IconClose color='#71798F' />
                </button>
              </li>
            ))}
          </ul>

          {!!error && (
            <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
              {error.message}
            </span>
          )}
        </div>

        <div className='flex items-center gap-[8px] self-end'>
          <span className='mr-[16px] text-[16px] leading-[19px] text-[#71798F]'>
            Всего {users?.length} пользователей
          </span>

          <Button
            type='submit'
            variant='primary'
            title='Отправить приглашения'
            isDisabled={!isDirty}
            isLoading={sendInvitesLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default CourseSendInvites;
