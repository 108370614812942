import React from 'react';

import avatarPlaceholder from 'assets/images/svg/avatar.svg';
import { User } from 'models';
import styles from './SpeakerCard.module.css';

import { IconClose } from 'components/atoms/icons';

interface SpeakerCardProps {
  speaker: User;
  deleteSpeaker: () => void;
}

const SpeakerCard: React.FC<SpeakerCardProps> = ({
  speaker: { name, avatarUrl },
  deleteSpeaker
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <img src={avatarUrl || avatarPlaceholder} alt={`${name}-avatar`} />
      </div>
      <h3 className={styles.title}>{name}</h3>
      <button type='button' className={styles.buttonDelete} onClick={deleteSpeaker}>
        <IconClose color='#71798F' />
      </button>
    </div>
  );
};

export default SpeakerCard;
