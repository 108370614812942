import { FC } from 'react';

import { DEFAULT_EXAMPLES_AMOUNT } from './const';

import { Skeleton } from 'components/atoms';

const ContentSkeleton: FC = () => {
  return (
    <div className='flex w-[calc(100%-264px)] grow flex-col'>
      <header className='mb-[4.5px] flex h-[49px] grow items-center justify-between gap-[8px]'>
        <Skeleton height={40} width={120} className='mr-[4px]' />
        <Skeleton height={30} width={30} className='mr-auto' />
        <Skeleton height={40} width={120} border={12} />
      </header>

      <Skeleton height={15} width={200} className='mb-[29px]' />
      <Skeleton height={16} width={80} className='mb-[8px]' />
      <Skeleton height={24} width={120} className='mb-[16.5px]' />

      <div className='mb-[24px] flex grow items-center justify-between gap-[24px]'>
        <Skeleton height={21} width={200} />
        <Skeleton height={48} width={200} border={62} />
      </div>

      <ul className='flex grow flex-col gap-[24px]'>
        {Array.from(Array(DEFAULT_EXAMPLES_AMOUNT)).map((_, i) => (
          <li key={i} className='flex grow justify-between gap-[20px] py-[8px]'>
            <div className='flex grow flex-col justify-start gap-[8.5px]'>
              <Skeleton height={24} width={560} />
              <Skeleton height={15} width={480} />
            </div>
            <Skeleton height={40} width={120} border={12} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContentSkeleton;
