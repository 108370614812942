import { FC } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form';

import { StepFormat } from 'models';
import styles from './Format.module.css';

import { ChooseFormat, FormatAudio, FormatImage, FormatVideo } from './components';

// TODO type react-hook-form.
interface FormatProps {
  savedFormat?: StepFormat;
  format: StepFormat;
  register: UseFormRegister<any>;
  control: Control<any, any>;
  watch: UseFormWatch<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  imageError: boolean;
}

const Format: FC<FormatProps> = ({
  savedFormat,
  format,
  control,
  register,
  getValues,
  watch,
  setValue,
  imageError
}) => {
  return (
    <div className={styles.container}>
      <ChooseFormat name='format' control={control} />

      {format === 'audio' && <FormatAudio control={control} />}

      {format === 'image' && (
        <FormatImage
          register={register}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          savedFormat={savedFormat}
          imageError={imageError}
        />
      )}

      {format === 'video' && (
        <FormatVideo
          control={control}
          register={register}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          savedFormat={savedFormat}
        />
      )}
    </div>
  );
};

export default Format;
