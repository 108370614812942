import { FC } from 'react';

import { Skeleton } from 'components/atoms';

export const DEFAULT_STUDENTS_AMOUNT = 20;

const CourseStudentsSkeleton: FC = () => {
  return (
    <div className='flex grow flex-col'>
      <div className='mb-[24px] flex items-center justify-between gap-[8px]'>
        <Skeleton height={48} width='68.5%' />
        <div className='flex grow justify-end gap-[8px]'>
          <Skeleton height={48} width={243} border={62} />
          <Skeleton height={48} width={224} border={62} />
        </div>
      </div>
      <ul className='grid grid-cols-[30%_29%_24%_17%] gap-[8px]'>
        {Array.from({ length: DEFAULT_STUDENTS_AMOUNT }).map((_, index) => (
          <Skeleton key={index} height={54} className='w-full' border={27} />
        ))}
      </ul>
    </div>
  );
};

export default CourseStudentsSkeleton;
