import { NOTIFICATION_STATUS, RECIPIENTS_ROLE } from 'models';

export const filterRoleOptions = [
  'Все роли',
  RECIPIENTS_ROLE.ADMIN,
  RECIPIENTS_ROLE.ASSISTANT,
  RECIPIENTS_ROLE.STUDENT,
  RECIPIENTS_ROLE.TEACHER
].map((option, index) => ({
  value: index === 0 ? '' : option,
  label: option,
  color: index === 0 ? '#8C9AAF' : '#20233A'
}));

export const filterStatusOptions = [
  {
    value: '',
    label: 'Все статусы',
    color: '#8C9AAF'
  },
  {
    value: NOTIFICATION_STATUS.ACTIVE,
    label: 'Активный',
    color: '#66C84D'
  },
  {
    value: NOTIFICATION_STATUS.DRAFT,
    label: 'Черновик',
    color: '#5770F3'
  },
  {
    value: NOTIFICATION_STATUS.COMPLETED,
    label: 'Завершен',
    color: '#71798F'
  }
];
