import { Pagination } from './common';

export interface GetDealNotificationsDto {
  page: number;
  itemsPerPage: number;
  search?: string;
  sortBy?: string;
  filter?: DealNotificationsFilterByEnum;
}

export enum DealStatus {
  SUCCESS = 'success',
  ERROR = 'error'
}

export enum DealType {
  AMO = 'amo',
  APPLE = 'apple',
  GOOGLE_PLAY = 'google_play',
  CLOUDPAYMENTS = 'cloudpayments'
}

export interface IDealNotification {
  dealId: string;
  dealStatus: DealStatus;
  dealType: DealType;
  id: string;
  createdAt: string;
  metadata: AmoCrmMetadata | AppleMetaData | GooglePlayMetaData;
}

export interface GetDealNotificationsResponse {
  data: IDealNotification[];
  pagination: Pagination;
}

export type GetDealNotificationResponse = IDealNotification;

export enum DealNotificationsFilterByEnum {
  ALL = 'all',
  WITH_ERROR = 'with-error',
  WITHOUT_ERROR = 'without-error'
}

export type AmoCrmMetadata = {
  period: string;
  courses: string[];
  userEmail?: string;
  errorFields?: string[];
  username?: string;
};

export type AppleMetaData = {
  courseId: string;

  courseTitle?: string;

  userId: string;

  userEmail: string;

  errorReason?: string;

  errorDescription: string;
};

export type GooglePlayMetaData = {
  courseId: string;

  courseTitle?: string;

  userId: string;

  userEmail: string;

  errorReason?: string;

  purchaseToken?: string;

  errorDescription: string;
};
