import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import avatarPlaceholder from 'assets/images/svg/avatar.svg';
import { useGetUserInfoQuery } from 'hooks/queries';
import { useAddSubscriptionsMutation, useGetSubscriptionsQuery } from 'hooks/subscription';
import {
  AddSubscriptionsDto,
  AddSubscriptionsFormValues,
  SubscriptionTerm
} from 'models/subscription';
import { addMonths, sortByMainRole, timestampToInput } from 'utils';

import Button from 'components/atoms/Button';
import { IconCheckSquareOff, IconCheckSquareOn, IconClose } from 'components/atoms/icons';
import { SkeletonUserCourses } from '..';
import { CourseCards } from '../UserSubscriptions/components';

type IParams = {
  id: string;
};

const AddSubscriptions: FC = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams<keyof IParams>() as IParams;
  const { data: user, isLoading: userLoading } = useGetUserInfoQuery(userId);
  const { data: subscriptions, isLoading: subscriptionsLoading } = useGetSubscriptionsQuery({
    userId
  });
  const { mutate: addSubscriptionsMutate, isLoading } = useAddSubscriptionsMutation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm<AddSubscriptionsFormValues>({
    defaultValues: {
      courses: [{ from: timestampToInput(Date.now()) }],
      isSendEmail: true
    },
    mode: 'onSubmit'
  });

  const onCancel = () => {
    navigate(-1);
  };

  const handleReset = () => {
    reset();
  };

  const onSubmit = (data: AddSubscriptionsFormValues) => {
    const { courses, isSendEmail } = data;

    const body: AddSubscriptionsDto['body'] = {
      courses: courses.map(({ course: { id: courseId }, subscriptionTerm, from, to }) => {
        const purchaseDate = from ? new Date(from).getTime() : undefined;
        let expirationDate = to ? new Date(to).getTime() : undefined;
        const isPermanent = subscriptionTerm === SubscriptionTerm.PERMANENT;

        switch (subscriptionTerm) {
          case SubscriptionTerm.THREE_MONTHS:
            expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 3).getTime();
            break;
          case SubscriptionTerm.SIX_MONTHS:
            expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 6).getTime();
            break;
          case SubscriptionTerm.NINE_MONTHS:
            expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 9).getTime();
            break;
          case SubscriptionTerm.TWELVE_MONTHS:
            expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 12).getTime();
            break;
          case SubscriptionTerm.TWENTY_FOUR_MONTHS:
            expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 24).getTime();
            break;
        }

        return {
          courseId,
          isPermanent,
          purchaseDate: isPermanent ? undefined : purchaseDate,
          expirationDate: isPermanent ? undefined : expirationDate
        };
      }),
      isSendEmail
    };

    addSubscriptionsMutate({ userId, body }, { onSuccess: onCancel });
  };

  if (userLoading || subscriptionsLoading) return <SkeletonUserCourses />;
  if (!subscriptions || !user) return <h2>Не удалось загрузить подписки пользователя</h2>;

  return (
    <div className='flex h-full grow flex-col'>
      <button className='flex select-none items-center gap-[16px] self-start' onClick={onCancel}>
        <IconClose className='h-[24px] w-[24px] text-[#5770F3]' />
        <span className='text-[16px] leading-[16px] text-[#20233A]'>Отменить</span>
      </button>

      <form className='flex grow flex-col' onSubmit={handleSubmit(onSubmit)}>
        <header className='mb-[24px] flex items-center justify-between'>
          <h2 className='h-[88px] pt-[30px] text-[30px] font-[700] leading-[36px] text-[#20233A]'>
            Добавить подписку на курс
          </h2>
        </header>

        <div className='mb-[32px] flex items-center gap-[16px]'>
          <div className='h-[100px] w-[100px] overflow-hidden rounded-[12px]'>
            <img
              className='h-full w-full'
              src={user.avatarUrl || avatarPlaceholder}
              alt={user.avatarUrl}
            />
          </div>

          <div className='flex flex-col gap-[8px]'>
            <p
              title={user.name}
              className={
                'text-[22px] font-[700] leading-[26px] ' +
                'overflow-hidden whitespace-nowrap text-ellipsis'
              }
            >
              {user.name}
            </p>
            <p
              title={sortByMainRole(user.roles).join(', ')}
              className={
                'text-[16px] font-[500] leading-[19px] text-[#20233A] ' +
                'overflow-hidden whitespace-nowrap text-ellipsis'
              }
            >
              {sortByMainRole(user.roles).join(', ')}
            </p>
            <p
              title={user.email}
              className={
                'text-[16px] leading-[19px] text-[#5770F3] ' +
                'overflow-hidden whitespace-nowrap text-ellipsis'
              }
            >
              {user.email}
            </p>
          </div>
        </div>

        <CourseCards control={control} className='mb-auto' />

        <div className='mt-[24px] flex items-center gap-[24px] self-end'>
          {/* TODO separate SendEmail component */}
          <Controller
            control={control}
            name='isSendEmail'
            render={({ field: { value, onChange } }) => (
              <label
                htmlFor='sendEmailInput'
                className='flex cursor-pointer items-center gap-[8px]'
              >
                {value ? <IconCheckSquareOn color='#5770F3' /> : <IconCheckSquareOff />}
                <span className='text-[16px] leading-[24px] text-[#20233a]'>
                  Выслать приглашение на email
                </span>
                <input
                  type='checkbox'
                  id='sendEmailInput'
                  checked={value || false}
                  onChange={onChange}
                  className='hidden'
                />
              </label>
            )}
          />

          <div className='flex gap-[8px]'>
            <Button
              type='button'
              variant='secondary'
              title='Сбросить'
              className='w-[198px]'
              onClick={handleReset}
              isDisabled={!isDirty || isLoading}
            />

            <Button
              type='submit'
              variant='primary'
              title='Добавить'
              className='w-[198px]'
              isDisabled={!isDirty}
              isLoading={isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSubscriptions;
