import { FC, useMemo, useState } from 'react';

import { ReviewsLesson as IReviewsLesson, LessonReview, ReviewsModule } from 'api/api-analytics';
import { useGetLessonReviewUsersQuery } from 'hooks/queries';

import { IconBad, IconClose, IconGood, IconNeutral } from 'components/atoms/icons';
import UserCard from './UserCard';

interface ReviewsLessonProps {
  currentModule: ReviewsModule | undefined;
  currentLesson: IReviewsLesson | undefined;
  onCancel: () => void;
}

const ReviewsLesson: FC<ReviewsLessonProps> = ({ currentModule, currentLesson, onCancel }) => {
  const { data: lessonReviewUsers, isLoading: lessonReviewUsersLoading } =
    useGetLessonReviewUsersQuery(currentLesson?.lesson.id || '');
  const [filter, setFilter] = useState<LessonReview | null>(null);

  const filteredUsers = useMemo(() => {
    if (!lessonReviewUsers) return [];
    if (!filter) return lessonReviewUsers;

    return lessonReviewUsers.filter(user => user.review === filter);
  }, [filter, lessonReviewUsers]);

  if (!currentModule || !currentLesson) return null;
  return (
    <div className='flex w-[584px] flex-col'>
      <header className='mb-[16px] flex h-[32px] items-center justify-between gap-[8px]'>
        <span className=' text-[22px] font-[700] leading-[26px] text-[#20233a]'>Оценки урока</span>
        <button className='h-[24px] w-[24px]' onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <div className='mb-[24px] flex h-[58px] gap-[16px]'>
        <div className='h-[54px] w-[54px]'>
          <img src={currentLesson.lesson.imageUrl} alt={currentLesson.lesson.title} />
        </div>
        <div className='flex max-w-[514px] flex-col gap-[4px]'>
          <p className='text-[14px] leading-[16px] text-[#71798F]'>
            Урок {currentLesson.lesson.order}.
          </p>
          <h5
            className={
              'text-[16px] font-[500] leading-[19px] text-[#20233A] ' +
              'overflow-hidden text-ellipsis whitespace-nowrap'
            }
          >
            {currentLesson.lesson.title}
          </h5>
          <h6
            className={
              'text-[13px] font-[500] uppercase leading-[15px] text-[#71798F] ' +
              'overflow-hidden text-ellipsis whitespace-nowrap'
            }
          >
            {/* Модуль №{currentModule.order}. {currentModule.title} */}
            {currentModule.title}
          </h6>
        </div>
      </div>

      <div className='mb-[24px] flex h-[64px] gap-[16px]'>
        <button
          className={
            'rounded-[12px] border-[1px] border-solid border-[#E9ECF0] p-[15px] select-none ' +
            'shadow-[0_6px_12px_0_rgba(0,0,0,0.06)] transition-all duration-200 ' +
            `${filter === null ? 'bg-[#EEF0FF]' : 'bg-[#FFFFFF]'}`
          }
          onClick={() => setFilter(null)}
        >
          <h3 className='text-[21px] font-[700] leading-[24px] text-[#20233A]'>Все</h3>
        </button>

        <button
          className={
            'rounded-[12px] border-[1px] border-solid border-[#E9ECF0] p-[15px] select-none ' +
            'shadow-[0_6px_12px_0_rgba(0,0,0,0.06)] transition-all duration-200 ' +
            `${filter === LessonReview.BAD ? 'bg-[#EEF0FF]' : 'bg-[#FFFFFF]'}`
          }
          onClick={() => setFilter(LessonReview.BAD)}
        >
          <IconBad color='#FF4444' className='h-[32px] w-[32px]' />
        </button>

        <button
          className={
            'rounded-[12px] border-[1px] border-solid border-[#E9ECF0] p-[15px] select-none ' +
            'shadow-[0_6px_12px_0_rgba(0,0,0,0.06)] transition-all duration-200 ' +
            `${filter === LessonReview.NEUTRAL ? 'bg-[#EEF0FF]' : 'bg-[#FFFFFF]'}`
          }
          onClick={() => setFilter(LessonReview.NEUTRAL)}
        >
          <IconNeutral color='#71798F' className='h-[32px] w-[32px]' />
        </button>

        <button
          className={
            'rounded-[12px] border-[1px] border-solid border-[#E9ECF0] p-[15px] select-none ' +
            'shadow-[0_6px_12px_0_rgba(0,0,0,0.06)] transition-all duration-200 ' +
            `${filter === LessonReview.GOOD ? 'bg-[#EEF0FF]' : 'bg-[#FFFFFF]'}`
          }
          onClick={() => setFilter(LessonReview.GOOD)}
        >
          <IconGood color='#66C84D' className='h-[32px] w-[32px]' />
        </button>
      </div>

      {lessonReviewUsersLoading ? (
        <h2>Список учеников, оценивших урок, загружается...</h2>
      ) : (
        <>
          {!!filteredUsers ? (
            <>
              {!!filteredUsers.length ? (
                <ul
                  className={
                    'flex max-h-[364px] flex-col gap-[8px] overflow-y-auto ' +
                    `${filteredUsers.length > 6 ? 'pr-[12px]' : ''}`
                  }
                >
                  {filteredUsers.map(user => (
                    <UserCard key={user.id} user={user} />
                  ))}
                </ul>
              ) : (
                <h2>Список учеников, оценивших урок, пуст.</h2>
              )}
            </>
          ) : (
            <h2>Не удалось загрузить список учеников, оценивших урок.</h2>
          )}
        </>
      )}
    </div>
  );
};

export default ReviewsLesson;
