import React from 'react';

const IconDictionary: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M21.3594 5.25391C19.707 4.65625 18.1602 4.375 16.7891 4.41016C14.75 4.41016 13.2031 5.00781 12.5 5.35938C11.7617 5.00781 10.2148 4.41016 8.17578 4.41016C6.76953 4.375 5.25781 4.65625 3.60547 5.25391C2.86719 5.53516 2.375 6.23828 2.375 7.04688V17.3125C2.375 17.875 2.62109 18.4375 3.07812 18.7891C3.57031 19.1406 4.20312 19.2812 4.80078 19.1055C8.94922 17.9805 11.9023 19.9141 12.0078 19.9844C12.1484 20.0898 12.3242 20.125 12.4648 20.125C12.5703 20.125 12.7812 20.0898 12.9219 19.9844C13.0625 19.9141 16.0156 17.9805 20.1641 19.1055C20.7266 19.2812 21.3594 19.1406 21.8516 18.7891C22.3438 18.4375 22.625 17.875 22.625 17.3125V7.04688C22.625 6.23828 22.0977 5.53516 21.3594 5.25391ZM11.6562 17.9102C10.6719 17.5234 9.19531 17.0664 7.36719 17.0664C6.45312 17.0664 5.43359 17.1719 4.34375 17.4883C4.23828 17.5234 4.13281 17.4531 4.13281 17.4531C4.0625 17.3828 4.0625 17.3125 4.0625 17.3125V7.04688C4.0625 6.94141 4.09766 6.87109 4.16797 6.83594C5.60938 6.34375 6.98047 6.0625 8.14062 6.09766C9.79297 6.09766 11.0234 6.55469 11.6562 6.83594V17.9102ZM20.9375 17.3125C20.9375 17.3125 20.9375 17.3828 20.8672 17.4531C20.832 17.4531 20.7266 17.5234 20.6211 17.4883C17.4219 16.6094 14.8203 17.3125 13.3086 17.9102V6.83594C13.9414 6.55469 15.1719 6.09766 16.8242 6.09766C17.9844 6.0625 19.3555 6.34375 20.7969 6.83594C20.8672 6.87109 20.9375 6.94141 20.9375 7.04688V17.3125Z'
        fill='currentColor'
      />
    </svg>
  );
};
export default IconDictionary;
