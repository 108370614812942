import { FC } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import courseBg from 'assets/images/png/courseBg.png';
import { COURSE_PAGE_OPTIONS } from 'consts';
import { useGetCourseQuery } from 'hooks/course';
import styles from './Sidebar.module.css';

import { IconBack } from 'components/atoms/icons';
import SkeletonSidebar from './SkeletonSidebar';

type Params = {
  courseId: string;
};

const Sidebar: FC = () => {
  const navigate = useNavigate();
  const { courseId } = useParams<keyof Params>() as Params;
  const { data: course, isLoading: courseLoading } = useGetCourseQuery({ courseId });

  if (courseLoading) return <SkeletonSidebar />;
  if (!course) return <h2>Не удалось загрузить курс</h2>;

  return (
    <div className={styles.sidebar}>
      <button className={styles.sidebar__back} onClick={() => navigate('/courses')}>
        <IconBack className='text-[#71798F]' />
        <p>Назад</p>
      </button>

      <div className={styles.sidebar__image}>
        <img src={course.imageUrl || courseBg} alt='' className='h-full w-full' />
      </div>

      <span
        title={`Уровень ${course.level}`}
        className='mb-[8px] text-[16px] font-[500] leading-[19px] text-[#71798F]'
      >
        {`Уровень ${course.level}`}
      </span>

      <p title={course.title} className={styles.sidebar__title}>
        {course.title}
      </p>

      <div className={styles.sidebar__options}>
        <NavLink
          to={`/courses/${course.id}/info`}
          className={({ isActive }) =>
            isActive
              ? `${styles.sidebar__option} ${styles.sidebar__option_active}`
              : styles.sidebar__option
          }
        >
          {COURSE_PAGE_OPTIONS.INFO}
        </NavLink>

        <NavLink
          to={`/courses/${course.id}/content`}
          className={({ isActive }) =>
            isActive
              ? `${styles.sidebar__option} ${styles.sidebar__option_active}`
              : styles.sidebar__option
          }
        >
          {COURSE_PAGE_OPTIONS.CONTENT}
        </NavLink>

        <NavLink
          to={`/courses/${course.id}/checklist`}
          className={({ isActive }) =>
            isActive
              ? `${styles.sidebar__option} ${styles.sidebar__option_active}`
              : styles.sidebar__option
          }
        >
          {COURSE_PAGE_OPTIONS.CHECKLIST}
        </NavLink>

        <NavLink
          to={`/courses/${course.id}/analytics`}
          className={({ isActive }) =>
            isActive
              ? `${styles.sidebar__option} ${styles.sidebar__option_active}`
              : styles.sidebar__option
          }
        >
          {COURSE_PAGE_OPTIONS.ANALYTICS}
        </NavLink>

        <NavLink
          to={`/courses/${course.id}/students`}
          className={({ isActive }) =>
            isActive
              ? `${styles.sidebar__option} ${styles.sidebar__option_active}`
              : styles.sidebar__option
          }
        >
          {COURSE_PAGE_OPTIONS.STUDENTS}
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
