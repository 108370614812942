import { FieldValues, UseControllerProps, useController } from 'react-hook-form';

type ChooseFormatProps<T extends FieldValues> = UseControllerProps<T>;

const ChooseFormat = <T extends FieldValues>(props: ChooseFormatProps<T>) => {
  const { name, control, rules } = props;
  const { field } = useController<T>({
    name,
    control,
    rules
  });

  const handleChange = (value: string) => {
    field.onChange(value);
  };

  return (
    <div className={`flex flex-col ${field.value !== 'text' ? 'mb-[40px]' : ''}`}>
      <div className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
        Формат
      </div>
      <div className='flex gap-[8px]'>
        <button
          type='button'
          className={`${
            field.value === 'text' ? 'bg-[#162030] text-[#FFFFFF]' : 'bg-[#EEF0FF] text-[#20233A]'
          } rounded-[12px] px-[24px] py-[16px] text-[16px] font-[500] leading-[19px]`}
          onClick={() => handleChange('text')}
        >
          По умолчанию
        </button>
        <button
          type='button'
          className={`${
            field.value === 'audio' ? 'bg-[#162030] text-[#FFFFFF]' : 'bg-[#EEF0FF] text-[#20233A]'
          } rounded-[12px] px-[24px] py-[16px] text-[16px] font-[500] leading-[19px]`}
          onClick={() => handleChange('audio')}
        >
          Аудио
        </button>
        <button
          type='button'
          className={`${
            field.value === 'video' ? 'bg-[#162030] text-[#FFFFFF]' : 'bg-[#EEF0FF] text-[#20233A]'
          } rounded-[12px] px-[24px] py-[16px] text-[16px] font-[500] leading-[19px]`}
          onClick={() => handleChange('video')}
        >
          Видео
        </button>
        <button
          type='button'
          className={`${
            field.value === 'image' ? 'bg-[#162030] text-[#FFFFFF]' : 'bg-[#EEF0FF] text-[#20233A]'
          } rounded-[12px] px-[24px] py-[16px] text-[16px] font-[500] leading-[19px]`}
          onClick={() => handleChange('image')}
        >
          Картинка
        </button>
      </div>
    </div>
  );
};

export default ChooseFormat;
