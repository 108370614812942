import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { DealStatus, IDealNotification } from 'models';
import styles from './DealNotificationCard.module.css';
import { formatDate } from '../../utils/formatDate';

interface DealNotificationCardProps {
  dealNotification: IDealNotification;
  index: number;
}

export const DealNotificationCard: FC<DealNotificationCardProps> = ({
  dealNotification,
  index
}) => {
  const navigate = useNavigate();

  const handleOpenDealNotification = () => {
    navigate(`/deal-notification/${dealNotification.id}`);
  };

  return (
    <div
      className={`${styles.table__row} ${index % 2 === 1 ? styles.table__row_even : ''}`}
      onClick={handleOpenDealNotification}
    >
      <div className={`${styles.table__cell} ${styles.table__cell_id}`}>
        <div className='flex flex-col'>
          <h6 className='mb-[5px] text-[16px] font-[500] leading-[19px] text-[#5770F3] transition-[all] duration-300'>
            {dealNotification.dealId}
          </h6>
        </div>
      </div>

      <div className={`${styles.table__cell} ${styles.table__cell_type}`}>
        <div className='flex flex-col'>
          <h6 className='mb-[5px] text-[16px] font-[500] leading-[19px] text-[#5770F3] transition-[all] duration-300 first-letter:uppercase'>
            {dealNotification.dealType}
          </h6>
        </div>
      </div>

      <div className={`${styles.table__cell} ${styles.table__cell_createdAt}`}>
        <span className='text-[16px] leading-[19px] text-[#20233A]'>
          {formatDate(dealNotification.createdAt)}
        </span>
      </div>

      <div className={`${styles.table__cell} ${styles.table__cell_result}`}>
        <span className='text-[16px] leading-[19px] text-[#20233A]'>
          {dealNotification.dealStatus === DealStatus.SUCCESS ? (
            <span className={styles.table__cell_result__succeed}>Успех</span>
          ) : (
            <span className={styles.table__cell_result__error}>Ошибка!</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default DealNotificationCard;
