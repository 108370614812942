import React from 'react';

const IconCheckSquareOff: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='1' y='1' width='22' height='22' rx='5' stroke='#E9ECF0' strokeWidth='2' />
    </svg>
  );
};

export default IconCheckSquareOff;
