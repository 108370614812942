import { useMutation, useQueryClient } from 'react-query';

import { apiDictionary } from 'api/dictionary';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { ThematicDictionary } from 'models';

export const useDeleteDictionaryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (dictionaryId: string) => {
      return apiDictionary.deleteDictionary(dictionaryId);
    },
    {
      onSuccess(data) {
        notifySuc('Тематический словарь удален');

        const dictionariesQuery = 'dictionary/thematic-dictionary/all';
        queryClient.invalidateQueries([dictionariesQuery], {
          refetchActive: false,
          refetchInactive: false
        });

        const oldData = queryClient.getQueryData<ThematicDictionary[]>(dictionariesQuery);
        if (!oldData) return;

        const updatedData = oldData.filter(dictionary => dictionary.id !== data.id);

        queryClient.setQueryData<ThematicDictionary[]>(dictionariesQuery, updatedData);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
