import { useQuery } from 'react-query';

import { apiUsers } from 'api/api-users';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';
import { GetUsersDto } from 'models';

export const useGetUsersQuery = (data: GetUsersDto, enabled?: boolean) => {
  const { page, itemsPerPage, roles, letters, sortBy } = data;
  // let query = `users/all?page=${page}&itemsPerPage=${itemsPerPage}`;

  // if (!!roles?.length) {
  //   query += `&roles[]=${roles.join('&roles=')}`;
  // }
  // if (!!letters?.length) {
  //   query += `&letters=${letters}`;
  // }
  // if (!!sortBy?.length) {
  //   const [sort, sortOrder] = sortBy.split('-');
  //   query += `&sort=${sort}&sortOrder=${sortOrder}`;
  // }

  return useQuery(
    ['users/all', page, itemsPerPage, roles, letters, sortBy],
    async () => await apiUsers.getUsers(data),
    {
      staleTime: DEFAULT_STALE_TIME,
      onError(error) {
        handleError(error);
      },
      enabled
    }
  );
};
