/* eslint-disable react-hooks/exhaustive-deps */

import { RefObject, useEffect, useState } from 'react';

const useChildHeightExceeded = (
  parentRef: RefObject<HTMLElement>,
  childRef: RefObject<HTMLElement>
) => {
  const [isChildHeightExceeded, setIsChildHeightExceeded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (childRef.current && parentRef.current) {
        const listHeight = childRef.current.clientHeight;
        const wrapperHeight = parentRef.current.clientHeight;
        setIsChildHeightExceeded(listHeight > wrapperHeight);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [isChildHeightExceeded];
};

export default useChildHeightExceeded;
