import React from 'react';

const IconSoundSmall: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='14'
      height='11'
      viewBox='0 0 14 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.00421 9.81621C7.00421 10.0601 6.86171 10.2818 6.63871 10.3852C6.55248 10.4255 6.46009 10.445 6.3683 10.445C6.22294 10.445 6.07894 10.3956 5.96262 10.3002L2.48692 7.45162H0.635762C0.284701 7.45192 0 7.17018 0 6.82296V4.31773C0 3.97036 0.284701 3.68877 0.635762 3.68877H2.48707L5.96277 0.840187C6.15267 0.684512 6.416 0.651323 6.63886 0.755503C6.86171 0.858939 7.00436 1.08069 7.00436 1.32448L7.00421 9.81621ZM9.44749 8.83364C9.432 8.83469 9.4171 8.83528 9.40175 8.83528C9.23382 8.83528 9.07191 8.76965 8.95228 8.65118L8.86726 8.56679C8.64425 8.34668 8.61807 7.99797 8.80586 7.74764C9.28197 7.11273 9.53327 6.36011 9.53327 5.57057C9.53327 4.72135 9.24796 3.92482 8.70805 3.26699C8.5025 3.01696 8.52146 2.65382 8.75184 2.42611L8.83671 2.34202C8.96371 2.21641 9.1339 2.14795 9.31808 2.15881C9.49745 2.16774 9.66493 2.25123 9.77869 2.38875C10.5276 3.29512 10.9232 4.39556 10.9232 5.57072C10.9232 6.66521 10.5734 7.70701 9.91126 8.58302C9.80066 8.72902 9.63153 8.8207 9.44749 8.83364ZM12.076 10.7769C11.961 10.9113 11.7948 10.992 11.6168 10.9994C11.608 10.9997 11.5992 11 11.5901 11C11.4217 11 11.2601 10.9342 11.1405 10.8159L11.057 10.7333C10.8236 10.5026 10.8078 10.1337 11.02 9.88393C12.0453 8.67782 12.6102 7.14607 12.6102 5.57057C12.6102 3.93181 12.0054 2.35422 10.9077 1.12847C10.6852 0.879626 10.6964 0.50294 10.9333 0.267791L11.0167 0.18519C11.1404 0.0622576 11.3011 -0.00545952 11.4854 0.000344812C11.6604 0.00525617 11.8259 0.0817543 11.9422 0.211235C13.2691 1.68881 14 3.59233 14 5.57057C14.0003 7.47394 13.317 9.32299 12.076 10.7769Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconSoundSmall;
