import { useQuery } from 'react-query';

import { apiDictionary } from 'api/dictionary';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetDictionaryQuery = (dictionaryId: string) => {
  return useQuery(
    [`dictionary/thematic-dictionary/one/${dictionaryId}`],
    async () => await apiDictionary.getDictionary(dictionaryId),
    {
      staleTime: DEFAULT_STALE_TIME,
      onError(error) {
        handleError(error);
      }
    }
  );
};
