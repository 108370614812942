import { useQuery } from 'react-query';

import { GetAllWordsDto, apiWords } from 'api/api-words';
import { handleError } from 'helpers/handleError';

type Params = GetAllWordsDto & {
  enabled?: boolean;
  keepPreviousData?: boolean;
};

export const useGetWordsQuery = ({
  page,
  itemsPerPage,
  letters = '',
  withFavorites = true,
  withReferences = true,
  withExamples = false,
  enabled = true,
  keepPreviousData = false
}: Params) => {
  return useQuery(
    ['words/all', page, itemsPerPage, letters, withFavorites, withReferences, withExamples],
    async () =>
      await apiWords.getAllWords({
        page,
        itemsPerPage,
        letters,
        withFavorites,
        withReferences,
        withExamples
      }),
    {
      enabled,
      keepPreviousData,
      onError(error) {
        handleError(error);
      }
    }
  );
};
