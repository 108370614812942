import { FC, MouseEvent, ReactNode, useCallback } from 'react';

import spinner from 'assets/images/svg/spinner.svg';
import styles from './Button.module.css';

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'warning' | 'dark' | 'light';
  size?: 'small' | 'big';
  title: string | ReactNode;
  className?: string;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const Button: FC<ButtonProps> = ({
  type = 'button',
  variant = 'primary',
  size = 'small',
  title,
  className,
  icon,
  iconPosition = 'left',
  onClick,
  isDisabled = false,
  isLoading = false
}) => {
  const getVariantClass = useCallback(() => {
    switch (variant) {
      case 'primary':
        return styles.button_primary;
      case 'secondary':
        return styles.button_secondary;
      case 'warning':
        return styles.button_warning;
      case 'dark':
        return styles.button_dark;
      case 'light':
        return styles.button_light;
      default:
        return '';
    }
  }, [variant]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      disabled={isDisabled || isLoading}
      type={type}
      onClick={handleClick}
      className={
        `${styles.button} ` +
        `${getVariantClass()} ` +
        `${size === 'small' ? '' : styles.button_big} ` +
        `${className}`
      }
    >
      {isLoading ? (
        <img src={spinner} alt='spinner' className='block h-[24px] w-[24px] animate-spin' />
      ) : (
        <>
          {icon && iconPosition === 'left' && <div className='mr-[8px]'>{icon}</div>}
          {title}
          {icon && iconPosition === 'right' && <div className='ml-[8px]'>{icon}</div>}
        </>
      )}
    </button>
  );
};

export default Button;
