import { FC, useMemo } from 'react';

import { MistakesModule } from 'api/api-analytics';
import { TOOLTIP_DEFAULT_HEIGHT, TOOLTIP_DEFAULT_WIDTH } from './const';
import { TooltipData } from './model';

interface MistakesTooltipProps {
  currentModule: MistakesModule | undefined;
  tooltipData: TooltipData;
}

const MistakesTooltip: FC<MistakesTooltipProps> = ({
  currentModule,
  tooltipData: { mistakesLesson, showTooltip, y, x, height, width, xAlign, yAlign }
}) => {
  const top = useMemo<number | undefined>(() => {
    if (!y || !height || !yAlign) return undefined;

    switch (yAlign) {
      case 'center':
        return y + height / 2 - TOOLTIP_DEFAULT_HEIGHT / 2;
      case 'top':
        return y;
      case 'bottom':
        return y;
    }
  }, [y, height, yAlign]);

  const left = useMemo<number | undefined>(() => {
    if (!x || !width || !xAlign) return undefined;

    switch (xAlign) {
      case 'center':
        return x + width / 2 - TOOLTIP_DEFAULT_WIDTH / 2;
      case 'left':
        return x;
      case 'right':
        return x - (TOOLTIP_DEFAULT_WIDTH - width);
    }
  }, [x, width, xAlign]);

  return (
    <div
      style={{
        opacity: showTooltip ? 1 : 0,
        top,
        left
      }}
      className={
        'absolute flex flex-col p-[16px] rounded-[12px] ' +
        `w-[${TOOLTIP_DEFAULT_WIDTH}px] h-[${TOOLTIP_DEFAULT_HEIGHT}px] ` +
        'transition-all duration-500 select-none pointer-events-none ' +
        'bg-[#FFFFFF] shadow-[0_4px_22px_0_rgba(0,0,0,0.08)]'
      }
    >
      <header className='mb-[24px] flex h-[58px] gap-[16px]'>
        <div className='h-[54px] w-[54px]'>
          <img
            src={mistakesLesson?.lesson.imageUrl}
            alt={mistakesLesson?.lesson.title}
            className='h-auto w-full'
          />
        </div>
        <div className='flex w-[155px] flex-col gap-[4px]'>
          {mistakesLesson && (
            <>
              <p className='text-[14px] leading-[16px] text-[#71798F]'>
                {mistakesLesson.lesson.subtitle || `Урок ${mistakesLesson.lesson.order}`}.
              </p>
              <h5
                className={
                  'text-[16px] font-[500] leading-[19px] text-[#20233A] ' +
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                }
              >
                {mistakesLesson.lesson.title}
              </h5>
            </>
          )}
          {currentModule && (
            <h6
              className={
                'text-[13px] font-[500] uppercase leading-[15px] text-[#71798F] ' +
                'overflow-hidden text-ellipsis whitespace-nowrap'
              }
            >
              {/* Модуль №{currentModule.order}. {currentModule.title} */}
              {currentModule.title}
            </h6>
          )}
        </div>
      </header>

      <div
        className={
          'mb-[4px] flex h-[42px] max-w-[225px] items-center gap-[8px] ' +
          'overflow-hidden text-ellipsis whitespace-nowrap'
        }
      >
        <span className={'text-[36px] font-[700] leading-[42px] text-[#20233A]'}>
          {mistakesLesson?.mistakesCount}
        </span>
        <span
          className={
            'text-[21px] font-[700] leading-[24px] text-[#20233A] ' +
            'overflow-hidden text-ellipsis whitespace-nowrap'
          }
        >
          ошибок
        </span>
      </div>

      <p className='mb-[24px] text-[14px] leading-[16px] text-[#71798F]'>
        сделали студенты в этом уроке
      </p>

      <div
        className={
          'flex h-[48px] w-[225px] items-center justify-center rounded-[62px] bg-[#5770F3] ' +
          'text-[16px] leading-[19px] font-[700] text-[#FFFFFF] text-center'
        }
      >
        Просмотреть список
      </div>
    </div>
  );
};

export default MistakesTooltip;
