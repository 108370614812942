/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { apiCourses, STEP_TYPES } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { useLesson } from 'hooks';
import styles from './LessonSectionPage.module.css';

import { IconBack } from 'components/atoms/icons';
import { NewSection, SectionSideInfo } from './components';
import EditSection from './components/EditSection/EditSection';

type Params = { courseId: string; lessonId: string };

const LessonSectionPage: FC = () => {
  const { courseId, lessonId } = useParams<keyof Params>() as Params;

  const [currentStepType, setCurrentStepType] = useState<STEP_TYPES>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const moduleOrder = searchParams.get('moduleOrder') || undefined;
  const moduleTitle = searchParams.get('moduleTitle') || undefined;

  const isEditing = Boolean(searchParams.get('edit'));
  const sectionStep =
    searchParams.get('step') === 'potential' ? 'potential' : Number(searchParams.get('step'));
  const sectionId = searchParams.get('sectionId');

  const [lesson] = useLesson({ lessonId });

  const { data: sectionData, refetch: refetchSectionData } = useQuery(
    [`sections/one/${sectionId}`],
    async () => await apiCourses.getSection(sectionId || ''),
    {
      enabled: false,
      onError(error) {
        handleError(error);
      }
    }
  );

  const sectionOrder = lesson?.sections?.length ? lesson?.sections?.length + 1 : 1;

  const pageStatusChangeHandle = (id: string) => {
    if (moduleOrder && moduleTitle) {
      setSearchParams({
        moduleOrder,
        moduleTitle,
        edit: 'true',
        step: '1',
        sectionId: id
      });
    } else {
      setSearchParams({
        edit: 'true',
        step: '1',
        sectionId: id
      });
    }
  };

  const stepSelectHandle = (step: number | 'potential') => {
    if (moduleOrder && moduleTitle) {
      setSearchParams({
        moduleOrder: moduleOrder.toString(),
        moduleTitle: moduleTitle,
        edit: 'true',
        step: step.toString(),
        sectionId: searchParams.get('sectionId') || ''
      });
    } else {
      setSearchParams({
        edit: 'true',
        step: step.toString(),
        sectionId: searchParams.get('sectionId') || ''
      });
    }
  };

  const changeCurrentStepTypeHandler = (type: STEP_TYPES) => {
    setCurrentStepType(type);
  };

  useEffect(() => {
    if (sectionId) {
      refetchSectionData();
    }
  }, [sectionId]);

  return (
    <div className='flex h-full max-w-full gap-[43px]'>
      <div className='flex w-[234px] flex-col'>
        <button
          className='mb-[35px] flex h-[28px] items-center text-[14px] font-[500] leading-[16px] text-[#71798F]'
          onClick={() => navigate(`/courses/${courseId}/content`)}
        >
          <IconBack className='mr-[4px]' />В содержание
        </button>

        {isEditing && lesson && (
          <SectionSideInfo
            sectionOrder={sectionOrder}
            moduleTitle={moduleTitle}
            lesson={lesson}
            stepType={currentStepType}
          />
        )}
      </div>

      {lesson && (
        <div className={styles.content}>
          {isEditing ? (
            <>
              {sectionData && (
                <EditSection
                  section={sectionData}
                  lesson={lesson}
                  currentStepIndex={sectionStep}
                  stepSelect={stepSelectHandle}
                  changeCurrentStepType={changeCurrentStepTypeHandler}
                  refetchSection={() => refetchSectionData()}
                />
              )}
            </>
          ) : (
            <NewSection
              lesson={lesson}
              sectionOrder={sectionOrder}
              pageStatusChange={pageStatusChangeHandle}
              moduleTitle={moduleTitle}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LessonSectionPage;
