/* eslint-disable react-hooks/exhaustive-deps */

import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { apiCourses, ICourse, ILesson, IModule, MoveLessonDto } from 'api/api-courses';
import schoolPNG from 'assets/images/png/school.png';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useGetCourseQuery, useGetCoursesQuery } from 'hooks/course';
import useDebounce from 'hooks/useDebounce';
import styles from './MoveLessonForm.module.css';

import Button from 'components/atoms/Button';
import { IconClose, IconPaste } from 'components/atoms/icons';
import SearchField from 'components/atoms/SearchField/SearchField';
import MoveCourseCard from 'components/molecules/MoveCourseCard';
import MoveModuleCard from 'components/molecules/MoveModuleCard';

interface UpdateModuleFormProps {
  courseId: string;
  lesson: ILesson;
  refetchCourse: () => void;
  onCancel: () => void;
}

const MoveLessonForm: FC<UpdateModuleFormProps> = ({
  courseId,
  lesson: { id: lessonId, imageUrl: lessonImageUrl, title: lessonTitle },
  refetchCourse,
  onCancel
}) => {
  const [step, setStep] = useState<'chooseCourse' | 'chooseModule'>('chooseCourse');
  const [selectedCourse, setSelectedCourse] = useState<ICourse | null>(null);
  const [selectedModule, setSelectedModule] = useState<IModule | null>(null);

  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentCourses, setCurrentCourses] = useState<ICourse[]>([]);
  const [currentModules, setCurrentModules] = useState<IModule[]>([]);

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    setSearchValue(value);
  }, [debouncedSearchValue]);

  const { data: courses, isLoading: coursesLoading } = useGetCoursesQuery();
  const {
    data: course,
    isLoading: courseLoading,
    refetch: fetchCourse
  } = useGetCourseQuery({ courseId: selectedCourse?.id || courseId, enabled: false });

  useEffect(() => {
    if (!courses) return;

    const filteredCourses = courses.filter(({ title }) =>
      title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setCurrentCourses(filteredCourses);

    const currentCourse = filteredCourses.find(({ id }) => id === courseId);
    if (currentCourse) {
      setSelectedCourse(currentCourse);
    }
  }, [courses, searchValue]);

  useEffect(() => {
    if (course) {
      setCurrentModules(course.modules);
    }
  }, [course]);

  const { mutate: moveLessonMutate, isLoading: moveLessonLoading } = useMutation(
    (dto: MoveLessonDto) => {
      return apiCourses.moveLesson(dto);
    },
    {
      onSuccess() {
        notifySuc('Урок перемещен');

        refetchCourse();
        onCancel();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (step === 'chooseCourse' && selectedCourse) {
      setStep('chooseModule');
      fetchCourse();
    }

    if (step === 'chooseModule' && selectedModule) {
      moveLessonMutate({ lessonId, targetModuleId: selectedModule.id });
    }

    if (step === 'chooseModule' && !selectedModule && selectedCourse) {
      moveLessonMutate({ lessonId, targetCourseId: selectedCourse.id });
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <span className={styles.header__title}>Переместить урок</span>
        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <div className={styles.preview}>
        <div className={styles.preview__image}>
          <img src={lessonImageUrl || schoolPNG} alt={lessonImageUrl} />
        </div>
        <h4 className={styles.preview__text}>{lessonTitle}</h4>
      </div>

      {step === 'chooseCourse' && (
        <form className={styles.form} onSubmit={handleSubmit}>
          <SearchField
            placeholder={'Поиск курса'}
            className='mb-[16px]'
            value={value}
            setState={setValue}
            type={'text'}
            id={'title'}
          />

          <h5 className='mb-[16px] text-[16px] font-[500] leading-[19px] text-[#71798F]'>
            Выбрать курс:
          </h5>

          <div className={`${styles.courses} ${currentCourses.length > 3 ? 'pr-[20px]' : ''}`}>
            {coursesLoading ? (
              <span>Загрузка...</span>
            ) : (
              <>
                {currentCourses.length > 0 ? (
                  currentCourses.map(course => (
                    <MoveCourseCard
                      key={course.id}
                      course={course}
                      isSelected={!!selectedCourse && selectedCourse.id === course.id}
                      select={() => {
                        if (!!selectedCourse && selectedCourse.id === course.id) {
                          setSelectedCourse(null);
                        } else {
                          setSelectedCourse(course);
                        }
                      }}
                    />
                  ))
                ) : (
                  <span>Результатов не найдено</span>
                )}
              </>
            )}
          </div>

          <div className={styles.buttons}>
            <Button
              type='reset'
              variant='secondary'
              size='big'
              title='Отменить'
              onClick={onCancel}
            />

            <Button
              type='submit'
              variant='primary'
              size='big'
              title='Далее'
              isDisabled={!selectedCourse}
            />
          </div>
        </form>
      )}

      {step === 'chooseModule' && selectedCourse && (
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className='mb-[16px] flex items-center gap-[8px]'>
            <IconPaste color='#71798F' className='h-[24px] w-[24px]' />
            <h5 className='text-[16px] font-[500] leading-[19px] text-[#71798F]'>В курс:</h5>
          </div>

          <div className='mb-[24px]'>
            <MoveCourseCard course={selectedCourse} isSelected={true} />
          </div>

          {selectedCourse.withModules ? (
            <>
              <h5 className='mb-[16px] text-[16px] font-[500] leading-[19px] text-[#71798F]'>
                Выбрать модуль:
              </h5>

              <div className={`${styles.modules} ${currentModules.length > 4 ? 'pr-[20px]' : ''}`}>
                {courseLoading ? (
                  <span>Загрузка...</span>
                ) : (
                  <>
                    {currentModules.length > 0 ? (
                      currentModules.map((module, index) => (
                        <MoveModuleCard
                          key={module.id}
                          module={module}
                          isSelected={!!selectedModule && selectedModule.id === module.id}
                          select={() => {
                            if (!!selectedModule && selectedModule.id === module.id) {
                              setSelectedModule(null);
                            } else {
                              setSelectedModule(module);
                            }
                          }}
                        />
                      ))
                    ) : (
                      <span>Результатов не найдено</span>
                    )}
                  </>
                )}
              </div>

              <div className={styles.buttons}>
                <Button
                  type='reset'
                  variant='secondary'
                  size='big'
                  title='Назад'
                  onClick={() => setStep('chooseCourse')}
                  isDisabled={moveLessonLoading}
                />

                <Button
                  type='submit'
                  variant='primary'
                  size='big'
                  title='Переместить'
                  isDisabled={!selectedModule || !currentModules.length}
                  isLoading={moveLessonLoading}
                />
              </div>
            </>
          ) : (
            <div className={styles.buttons}>
              <Button
                type='reset'
                variant='secondary'
                size='big'
                title='Назад'
                onClick={() => setStep('chooseCourse')}
                isDisabled={moveLessonLoading}
              />

              <Button
                type='submit'
                variant='primary'
                size='big'
                title='Переместить'
                isLoading={moveLessonLoading}
              />
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default MoveLessonForm;
