import { FC } from 'react';
import { Control, useController } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { NOTIFICATION_FOR } from 'models';
import { FormValues } from '../../model';

import Button from 'components/atoms/Button';
import { IconBack, IconNext } from 'components/atoms/icons';
import { Recipients, RoleStatus, SearchUsers } from '..';

interface Step2Props {
  control: Control<FormValues>;
  isDirty: boolean;
  onPrev: () => void;
}

const Step2: FC<Step2Props> = ({ control, isDirty, onPrev }) => {
  const { field, fieldState } = useController({
    name: 'for',
    control,
    rules: {
      required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
    }
  });

  return (
    <div className='flex flex-1 flex-col'>
      <h4 className='mb-[30px] text-[18px] font-[500] leading-[21px] text-[#20233A]'>Получатели</h4>

      <div className='mb-auto flex flex-col'>
        <div className='mb-[30px] flex flex-col'>
          <h6 className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            кому выслать
          </h6>

          <div className='flex gap-[8px]'>
            <button
              type='button'
              className={
                'rounded-[12px] py-[16px] px-[24px] text-[16px] font-[500] leading-[19px] ' +
                `${
                  field.value === NOTIFICATION_FOR.ALL
                    ? 'bg-[#162030] text-[#FFFFFF]'
                    : 'bg-[#EEF0FF] text-[#20233A]'
                }`
              }
              onClick={() => field.onChange(NOTIFICATION_FOR.ALL)}
            >
              Всем
            </button>
            <button
              type='button'
              className={
                'rounded-[12px] py-[16px] px-[24px] text-[16px] font-[500] leading-[19px] ' +
                `${
                  field.value === NOTIFICATION_FOR.SELECTED
                    ? 'bg-[#162030] text-[#FFFFFF]'
                    : 'bg-[#EEF0FF] text-[#20233A]'
                }`
              }
              onClick={() => field.onChange(NOTIFICATION_FOR.SELECTED)}
            >
              Выбрать из списка
            </button>
          </div>

          {!!fieldState.error && (
            <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
              {fieldState.error.message}
            </span>
          )}
        </div>

        {field.value === NOTIFICATION_FOR.ALL && <RoleStatus control={control} />}
        {field.value === NOTIFICATION_FOR.SELECTED && (
          <div className='flex flex-col gap-[16px]'>
            <SearchUsers control={control} />
            <Recipients control={control} />
          </div>
        )}
      </div>

      <div className='mt-[30px] flex justify-between'>
        <Button
          type='button'
          variant='secondary'
          title='Назад'
          iconPosition='left'
          icon={<IconBack className='text-[#71798F]' />}
          onClick={onPrev}
        />
        <Button
          type='submit'
          variant='primary'
          title='Далее'
          iconPosition='right'
          icon={<IconNext />}
          className='min-w-[175px]'
          isDisabled={!isDirty}
        />
      </div>
    </div>
  );
};

export default Step2;
