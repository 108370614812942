import {
  AddSubscriptionsDto,
  DeleteSubscriptionsDto,
  GetPurchasesDto,
  GetPurchasesResponseDto,
  GetSubscriptionsDto,
  Subscription,
  UpdateSubscriptionDto
} from 'models/subscription';
import { instance } from './api';

export const apiSubscriptions = {
  getSubscriptions: async ({ userId }: GetSubscriptionsDto) => {
    const response = await instance.get<Subscription[]>(`purchases/subscriptions/${userId}`);
    return response.data;
  },
  getPurchases: async ({
    page,
    itemsPerPage,
    paidFrom,
    search,
    sortOrder
  }: GetPurchasesDto): Promise<GetPurchasesResponseDto> => {
    let query = `purchases?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (paidFrom) query += `&paidFrom=${paidFrom}`;
    if (sortOrder) query += `&sortOrder=${sortOrder}`;
    if (search) query += `&search=${search}`;

    const response = await instance.get<GetPurchasesResponseDto>(query);

    return response.data;
  },
  addSubscriptions: async ({ userId, body }: AddSubscriptionsDto) => {
    const response = await instance.post(`users/${userId}/courses`, body);
    return response.data;
  },
  deleteSubscription: async ({ userId, body }: DeleteSubscriptionsDto) => {
    const response = await instance.delete(`users/${userId}/courses`, { data: body });
    return response.data;
  },
  updateSubscription: async ({ purchaseId, body }: UpdateSubscriptionDto) => {
    const response = await instance.patch(`purchases/${purchaseId}/expiration-date`, body);
    return response.data;
  }
};
