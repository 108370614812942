import { FC } from 'react';
import { Control } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { RECIPIENTS_ROLE } from 'models';
import { FormValues } from '../../model';

import { Select } from 'components/atoms';

interface RoleStatusProps {
  control: Control<FormValues>;
}

const RoleStatus: FC<RoleStatusProps> = ({ control }) => {
  return (
    <div className='flex flex-col'>
      <h6 className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
        уточнить роль получателя
      </h6>

      <div className='flex gap-[12px]'>
        <Select
          name='recipientsRole'
          placeholder='Роль'
          options={[
            RECIPIENTS_ROLE.ADMIN,
            RECIPIENTS_ROLE.ASSISTANT,
            RECIPIENTS_ROLE.STUDENT,
            RECIPIENTS_ROLE.TEACHER
          ].map(option => ({ value: option, label: option }))}
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
          }}
        />
      </div>
    </div>
  );
};

export default RoleStatus;
