import { useQuery } from 'react-query';

import { apiAnalytics } from 'api/api-analytics';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetCourseReviewsQuery = (courseId: string) => {
  return useQuery(
    ['analytics/lesson-reviews', courseId],
    async () => await apiAnalytics.getCourseReviews(courseId),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};
