import React from 'react';

const IconCheck: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19.875 7.78516L10.3125 17.3477C10.1719 17.5234 9.96094 17.5938 9.75 17.5938C9.50391 17.5938 9.29297 17.5234 9.15234 17.3477L4.08984 12.2852C3.73828 11.9688 3.73828 11.4414 4.08984 11.125C4.40625 10.7734 4.93359 10.7734 5.25 11.125L9.75 15.5898L18.7148 6.625C19.0312 6.27344 19.5586 6.27344 19.875 6.625C20.2266 6.94141 20.2266 7.46875 19.875 7.78516Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconCheck;
