import { FC } from 'react';

import { classNames } from 'utils';

type Props = {
  totalStudents?: number;
  subscribed?: number;
  className?: string;
};

export const Periods: FC<Props> = props => {
  const { totalStudents, subscribed, className } = props;

  if (!totalStudents || !subscribed) {
    return null;
  }

  return (
    <header
      className={classNames(
        'flex flex-1 w-full justify-between p-[15px] ' +
          'bg-[#F5F5F5] border-b-[1px] border-solid border-[#E9ECF0]',
        {},
        [className]
      )}
    >
      <ul className='flex items-center overflow-hidden'>
        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] truncate'>За всё время</p>
          <h3 className='h3 text-[#20233A] truncate'>{totalStudents}</h3>
        </li>
        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] truncate'>Сейчас подписаны</p>
          <h3 className='h3 text-[#20233A] truncate'>{subscribed}</h3>
        </li>
      </ul>
    </header>
  );
};
