import { ValidatedWord, ValidatedWordStatus } from 'models';

export const filterWordsForAddingToLesson = (words: ValidatedWord[]): ValidatedWord[] => {
  const filteredWords = words.filter(
    word =>
      word.status === ValidatedWordStatus.NEW ||
      word.status === ValidatedWordStatus.IN_VOCABULARY ||
      word.status === ValidatedWordStatus.NEW_EXAMPLES
  );

  return filteredWords;
};
