import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { apiWords } from 'api/api-words';
import { FORM_ERROR_MESSAGES } from 'consts';
import { handleError } from 'helpers/handleError';
import styles from './AddNaverForm.module.css';

import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';
import Input from 'components/atoms/Input/Input';
import { notifySuc } from 'helpers/notification';

interface AddNaverFormProps {
  wordId: string;
  refetchWord: () => void;
  onCancel: () => void;
}

const AddNaverForm: FC<AddNaverFormProps> = ({ wordId, refetchWord, onCancel }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FieldValues>({ mode: 'onSubmit' });

  const { mutate: addNaverUrlMutate, isLoading: addNaverUrlLoading } = useMutation(
    (naverUrl: string) => {
      return apiWords.updateWord({ wordId, naverUrl });
    },
    {
      onSuccess() {
        notifySuc('Ссылка добавлена');

        refetchWord();
        onCancel();
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const onSubmit = async (data: FieldValues) => {
    const { naverUrl } = data;

    addNaverUrlMutate(naverUrl);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Добавить ссылку на Naver</div>

        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose />
        </button>
      </div>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fields}>
          <Input
            type='text'
            name='naverUrl'
            placeholder='Ссылка на Naver Dictionary'
            control={control}
            rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            isLoading={addNaverUrlLoading}
            onClick={onCancel}
          />

          <Button
            type='submit'
            variant='primary'
            title='Добавить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={addNaverUrlLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default AddNaverForm;
