import { useMutation, useQueryClient } from 'react-query';

import { ICourse, apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { ChecklistItem } from 'models';

interface UpdateCourseChecklistData {
  courseId: string;
  body: {
    checklist: ChecklistItem[];
  };
}

export const useUpdateCourseChecklistMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ courseId, body }: UpdateCourseChecklistData) => {
      return apiCourses.updateCourseInfo({ courseId, body });
    },
    {
      onSuccess(_, variables) {
        notifySuc('Чеклист обновлен');
        queryClient.invalidateQueries([`courses/one/${variables.courseId}`], {
          refetchActive: false
        });

        const oldCourseInfoData = queryClient.getQueryData<ICourse>(
          `courses/one/${variables.courseId}`
        );
        if (!oldCourseInfoData) return;

        queryClient.setQueryData(`courses/one/${variables.courseId}`, {
          ...oldCourseInfoData,
          checklist: variables.body.checklist
        });
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
