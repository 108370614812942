import { FC } from 'react';

import { Skeleton } from 'components/atoms';

export const NumbersSkeleton: FC = () => {
  return (
    <div className='mb-[16px] w-full flex justify-between'>
      <div className='flex grow gap-[8px]'>
        <Skeleton height={95} width={120} />
        <Skeleton height={95} width={120} />
        <Skeleton height={95} width={120} />
        <Skeleton height={95} width={120} />
        <Skeleton height={95} width={120} />
      </div>

      <Skeleton height={95} width={160} className='shrink-0' />
    </div>
  );
};
