import { useMutation, useQueryClient } from 'react-query';

import { UpdateDictionaryDto, apiDictionary } from 'api/dictionary';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { ThematicDictionary } from 'models';

export const useUpdateDictionaryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (dto: UpdateDictionaryDto) => {
      return apiDictionary.updateDictionary(dto);
    },
    {
      onSuccess(data) {
        notifySuc('Словарь обновлен');

        // update cache for all dictionaries
        const dictionariesQuery = 'dictionary/thematic-dictionary/all';
        queryClient.invalidateQueries([dictionariesQuery], {
          refetchActive: false,
          refetchInactive: false
        });

        const oldDictionariesData =
          queryClient.getQueryData<ThematicDictionary[]>(dictionariesQuery);
        if (oldDictionariesData) {
          const updatedData = oldDictionariesData.map(dictionary =>
            dictionary.id === data.id ? data : dictionary
          );

          queryClient.setQueryData<ThematicDictionary[]>(dictionariesQuery, updatedData);
        }

        // update cache for single dictionary
        const dictionaryQuery = `dictionary/thematic-dictionary/one/${data.id}`;
        queryClient.invalidateQueries([dictionariesQuery], {
          refetchActive: false,
          refetchInactive: false
        });

        const oldDictionaryData = queryClient.getQueryData<ThematicDictionary>(dictionaryQuery);
        if (oldDictionaryData) {
          queryClient.setQueryData<ThematicDictionary>(dictionaryQuery, data);
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
