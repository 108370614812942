import React from 'react';

const IconPlusBig: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.1743 12.7605V23.5583H13.1743V12.7605H24.3837V10.7605H13.1743V0H11.1743V10.7605H0V12.7605H11.1743Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconPlusBig;
