import { FC, useEffect, useMemo, useState } from 'react';
import { useController, useForm } from 'react-hook-form';

import avatarPlaceholder from 'assets/images/svg/avatar.svg';

import { ICourse } from 'api/api-courses';
import { FORM_ERROR_MESSAGES } from 'consts';
import { useDebounce } from 'hooks';
import { useAddCourseUsersMutation } from 'hooks/mutations';
import { useGetUsersQuery } from 'hooks/queries';
import { SubscriptionTerm, subscriptionTermOptions } from 'models/subscription';
import { addMonths, timestampToInput } from 'utils';

import { InputDate, Select } from 'components/atoms';
import Button from 'components/atoms/Button';
import SearchField from 'components/atoms/SearchField/SearchField';
import { IconCheckOff, IconCheckOn, IconClose } from 'components/atoms/icons';

type FormValues = {
  emails: string[];
  subscriptionTerm: SubscriptionTerm;
  from?: string;
  to?: string;
};

interface AddUserFormProps {
  course: ICourse;
  onCancel: () => void;
}

const AddUserForm: FC<AddUserFormProps> = ({ course: { id: courseId }, onCancel }) => {
  const { mutate: addCourseUsersMutate, isLoading: addCourseUsersLoading } =
    useAddCourseUsersMutation();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty }
  } = useForm<FormValues>({
    defaultValues: {
      emails: [],
      from: timestampToInput(Date.now())
    },
    mode: 'onSubmit'
  });

  const {
    field: { value: emails, onChange: onChangeEmails },
    fieldState: { error: emailsError }
  } = useController({
    name: 'emails',
    control,
    rules: {
      required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
    }
  });
  const subscriptionTerm = watch('subscriptionTerm');

  const SubscriptionTermComponent = useMemo(() => {
    switch (subscriptionTerm) {
      case SubscriptionTerm.ARBITRARY:
        return (
          <div className='flex flex-[2] items-center gap-[8px]'>
            <InputDate
              name='from'
              label='с'
              width='big'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
              }}
            />
            <InputDate
              name='to'
              label='по'
              width='big'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
              }}
            />
          </div>
        );
      case SubscriptionTerm.THREE_MONTHS:
      case SubscriptionTerm.SIX_MONTHS:
      case SubscriptionTerm.NINE_MONTHS:
      case SubscriptionTerm.TWELVE_MONTHS:
      case SubscriptionTerm.TWENTY_FOUR_MONTHS:
        return (
          <div className='flex flex-[1] items-center gap-[8px]'>
            <InputDate
              name='from'
              label='с'
              width='big'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
              }}
            />
          </div>
        );
      case SubscriptionTerm.PERMANENT:
        return null;
    }
  }, [control, subscriptionTerm]);

  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    setSearchValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  const { data, isLoading } = useGetUsersQuery({
    page: 1,
    itemsPerPage: 10,
    letters: searchValue
  });

  const onSubmit = async (data: FormValues) => {
    const { emails, subscriptionTerm, from, to } = data;

    const purchaseDate = from ? new Date(from).getTime() : undefined;
    let expirationDate = to ? new Date(to).getTime() : undefined;
    const isPermanent = subscriptionTerm === SubscriptionTerm.PERMANENT;

    switch (subscriptionTerm) {
      case SubscriptionTerm.THREE_MONTHS:
        expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 3).getTime();
        break;
      case SubscriptionTerm.SIX_MONTHS:
        expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 6).getTime();
        break;
      case SubscriptionTerm.NINE_MONTHS:
        expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 9).getTime();
        break;
      case SubscriptionTerm.TWELVE_MONTHS:
        expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 12).getTime();
        break;
      case SubscriptionTerm.TWENTY_FOUR_MONTHS:
        expirationDate = purchaseDate && addMonths(new Date(purchaseDate), 24).getTime();
        break;
    }

    addCourseUsersMutate(
      {
        courseId,
        body: {
          emails,
          isPermanent,
          purchaseDate: isPermanent ? undefined : purchaseDate,
          expirationDate: isPermanent ? undefined : expirationDate
        }
      },
      { onSuccess: onCancel }
    );
  };

  return (
    <div className='flex w-[584px] flex-col'>
      <header className='mb-[24px] flex h-[32px] items-center justify-between'>
        <h2 className='text-[22px] font-[700] leading-[26px] text-[#20233a]'>
          Добавить учеников из списка
        </h2>
        <button className='h-[24px] w-[24px]' onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <form className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-[24px] flex items-center gap-[8px]'>
          <Select
            name='subscriptionTerm'
            placeholder='Выберите срок'
            control={control}
            options={subscriptionTermOptions}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
            }}
            containerClassName='flex-[1]'
          />
          {SubscriptionTermComponent}
        </div>

        {/* TODO separate SelectUsers component */}
        <SearchField
          type='text'
          id='title'
          placeholder='Поиск ученика по имени/email'
          value={value}
          setState={setValue}
          className='mb-[24px]'
        />

        <div className='mb-[24px]'>
          {isLoading ? (
            <span>Загрузка...</span>
          ) : (
            <>
              {!!data?.data.length ? (
                <ul
                  className={
                    'flex max-h-[302px] flex-col gap-[8px] overflow-y-auto ' +
                    `${data.data.length > 5 ? 'pr-[12px]' : ''}`
                  }
                >
                  {data.data.map(user => {
                    const isActive = emails.includes(user.email);

                    return (
                      <button
                        key={user.id}
                        type='button'
                        onClick={() => {
                          if (isActive) {
                            const oldEmails = emails;
                            const updatedUsers = oldEmails.filter(email => email !== user.email);
                            onChangeEmails(updatedUsers);
                          } else {
                            onChangeEmails([...emails, user.email]);
                          }
                        }}
                        className={
                          'flex h-[54px] w-full gap-[16px] rounded-[27px] pr-[8px] select-none items-center ' +
                          `${isActive ? 'bg-[#F5F5F5]' : ''}`
                        }
                      >
                        <div className='h-[54px] w-[54px] overflow-hidden rounded-full'>
                          <img src={user.avatarUrl || avatarPlaceholder} alt={`${user.name}`} />
                        </div>
                        <div className='flex flex-1 gap-[16px]'>
                          <p
                            title={user.name}
                            className={
                              'text-[16px] font-[500] leading-[19px] text-[#20233a] ' +
                              'max-w-[165px] overflow-hidden whitespace-nowrap text-ellipsis'
                            }
                          >
                            {user.name}
                          </p>
                          <p
                            title={user.email}
                            className={
                              'text-[16px] leading-[19px] text-[#71798F] ' +
                              'max-w-[165px] overflow-hidden whitespace-nowrap text-ellipsis'
                            }
                          >
                            {user.email}
                          </p>
                        </div>
                        {isActive ? (
                          <IconCheckOn className='h-[32px] w-[32px] self-center' color='#5770F3' />
                        ) : (
                          <IconCheckOff className='h-[32px] w-[32px] self-center' color='#EFF1F4' />
                        )}
                      </button>
                    );
                  })}
                </ul>
              ) : (
                <span>Результатов не найдено</span>
              )}
            </>
          )}

          {!!emailsError && (
            <span className='mt-[6px] ml-[16px] block text-[12px] leading-[14px] text-[#ff4444]'>
              {emailsError.message}
            </span>
          )}
        </div>

        <div className='flex gap-[8px]'>
          <Button
            type='reset'
            variant='secondary'
            size='big'
            title='Отменить'
            isDisabled={addCourseUsersLoading}
            onClick={onCancel}
          />
          <Button
            type='submit'
            variant='primary'
            size='big'
            title='Добавить'
            isDisabled={!isDirty}
            isLoading={addCourseUsersLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default AddUserForm;
