import { FC, useState } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import { useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import 'react-tooltip/dist/react-tooltip.css';

import { MONTH_LABELS, WEEKDAY_LABELS } from 'consts';
import { useGetUserStatsQuery } from 'hooks/queries';
import { EARLIEST_YEAR, LATEST_YEAR } from './const';
import { convertToLocalDate } from './utils';

import { IconStroke } from 'components/atoms/icons';
import ActivitySkeleton from './ActivitySkeleton';

type IParams = {
  id: string;
};

const Activity: FC = () => {
  const { id: userId } = useParams<keyof IParams>() as IParams;
  const [currentYear, setCurrentYear] = useState<number>(new Date(Date.now()).getFullYear());
  const { data: userStats, isLoading: userStatsLoading } = useGetUserStatsQuery({
    userId,
    visitDates: true,
    visitStreak: true,
    learnedLessonDates: false,
    learnedWordDates: false
  });

  const handlePrevYear = () => {
    if (currentYear > EARLIEST_YEAR) {
      setCurrentYear(prev => prev - 1);
    }
  };

  const handleNextYear = () => {
    if (currentYear < LATEST_YEAR) {
      setCurrentYear(prev => prev + 1);
    }
  };

  if (userStatsLoading) return <ActivitySkeleton />;
  if (!userStats) return <h2>Активность пользователя не записывается</h2>;

  return (
    <div className='mb-[40px] flex flex-col gap-[20px]'>
      <h2 className='text-[18px] font-[500] leading-[21px] text-[#20233A]'>
        Активность за последний год
      </h2>

      <div
        className={
          'flex flex-col max-w-[1300px] p-[10px] ' +
          'border-[2px] border-solid border-[#e9ecf0] rounded-[12px]'
        }
      >
        <CalendarHeatmap
          startDate={new Date(`${currentYear}-01-01`)}
          endDate={new Date(`${currentYear}-12-31`)}
          values={(userStats.visitDates || []).map(visitDate => {
            const [day, month, year] = visitDate.split('/');
            return {
              date: `${year}-${month}-${day}`,
              content: convertToLocalDate(visitDate)
            };
          })}
          showMonthLabels={true}
          monthLabels={MONTH_LABELS}
          showWeekdayLabels={true}
          weekdayLabels={WEEKDAY_LABELS}
          tooltipDataAttrs={(value: { date: Date; content: string }) => ({
            'data-tooltip-id': 'my-tooltip',
            'data-tooltip-content': value.date ? value.content : ''
          })}
        />

        <ReactTooltip id='my-tooltip' />

        <div className='flex items-center gap-[12px] self-center'>
          <button
            type='button'
            className='flex h-[20px] w-[20px] items-center justify-center disabled:opacity-30'
            onClick={handlePrevYear}
            disabled={currentYear === EARLIEST_YEAR}
          >
            <IconStroke color='#71798F' className='h-full w-full' />
          </button>
          <h6 className='text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            {currentYear}
          </h6>
          <button
            type='button'
            className='flex h-[20px] w-[20px] items-center justify-center disabled:opacity-30'
            onClick={handleNextYear}
            disabled={currentYear === LATEST_YEAR}
          >
            <IconStroke color='#71798F' className='h-full w-full rotate-180' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Activity;
