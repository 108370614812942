import { Dispatch, SetStateAction } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { apiCourses, ISection, STEP_TYPES, UpdateStepsOrderData } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export default function useDeleteStep({
  section,
  stepId,
  stepType,
  setIsDirty,
  setIsValid,
  setOnSubmitCallback
}: {
  section: ISection;
  stepId: string;
  stepType: STEP_TYPES;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  setOnSubmitCallback: Dispatch<SetStateAction<(() => void) | null>>;
}) {
  const queryClient = useQueryClient();

  const { mutate: updateStepsOrderMutate } = useMutation((data: UpdateStepsOrderData) => {
    return apiCourses.updateStepsOrder(data);
  });

  const { mutate: deleteStepMutate, isLoading } = useMutation(
    () => {
      switch (stepType) {
        case STEP_TYPES.TEXT:
          return apiCourses.deleteTextStep(section.id, stepId);
        case STEP_TYPES.VIDEO:
          return apiCourses.deleteVideoStep(section.id, stepId);
        case STEP_TYPES.MATCHING_PAIRS:
          return apiCourses.deleteMatchingPairsStepBySectionAndStepIds(section.id, stepId);
        case STEP_TYPES.CHOOSE_ANSWER:
          return apiCourses.deleteChooseAnswerStep(section.id, stepId);
        case STEP_TYPES.CHOOSE_SENTENCE:
          return apiCourses.deleteChooseSentenceStep({ sectionId: section.id, stepId });
        case STEP_TYPES.MAKING_SENTENCE:
          return apiCourses.deleteMakingSentenceStep(section.id, stepId);
        case STEP_TYPES.FILLING_MISSING_WORDS:
          return apiCourses.deleteFillingWordsStep(section.id, stepId);
        case STEP_TYPES.INSERTING_MISSING_WORDS:
          return apiCourses.deleteInsertingWordsStep(section.id, stepId);
        case STEP_TYPES.DIALOG:
          return apiCourses.deleteDialogStep({ sectionId: section.id, stepId });
      }
    },
    {
      onSuccess() {
        const newData = section.steps
          ?.filter(filtredStep => filtredStep.id !== stepId)
          .map((mapedStep, index) => {
            return {
              id: mapedStep.id!,
              type: mapedStep.type!,
              newOrder: index + 1
            };
          });
        updateStepsOrderMutate({ data: newData });

        const oldSectionData = queryClient.getQueryData<ISection>(`sections/one/${section.id}`);

        if (oldSectionData) {
          queryClient.setQueryData<ISection>(`sections/one/${section.id}`, {
            ...oldSectionData,
            steps: [
              ...oldSectionData.steps
                .filter(filteredStep => filteredStep.id !== stepId)
                .map((mapedStep, index) => {
                  const updatedStep = mapedStep;
                  updatedStep.order = index + 1;
                  return updatedStep;
                })
            ]
          });
        }
        notifySuc('Шаг удален');

        setIsDirty(false);
        setIsValid(false);
        setOnSubmitCallback(null);
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  return { deleteStepMutate, isLoading };
}
