import { FC, MouseEvent, ReactElement, useEffect } from 'react';

import Button from '../Button';
import { IconClose } from '../icons';
import styles from './ConfirmationModal.module.css';

interface IConfirmationModalProps {
  title: ReactElement;
  text: ReactElement;
  isDelete?: boolean;
  confirmButtonText: string;
  cancelButtonText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  onClose: () => void;
  isLoading?: boolean;
}

const ConfirmationModal: FC<IConfirmationModalProps> = ({
  title,
  text,
  isDelete = false,
  confirmButtonText,
  cancelButtonText = 'Отменить',
  onConfirm,
  onCancel,
  onClose,
  isLoading = false
}) => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const handleClickContainer = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div className={styles.container} onClick={handleClickContainer}>
      <div className={styles.backdrop} onClick={onClose} />
      <div className={styles.body}>
        <header className={styles.header}>
          <h2 className={styles.title}>{title}</h2>

          <button className={styles.buttonClose} onClick={onCancel ? onCancel : onClose}>
            <IconClose color='#71798F' />
          </button>
        </header>

        <div className={styles.text}>{text}</div>

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title={cancelButtonText}
            className='w-[198px]'
            onClick={onCancel ? onCancel : onClose}
            isDisabled={isLoading}
          />

          <Button
            type='submit'
            variant={isDelete ? 'warning' : 'primary'}
            title={confirmButtonText}
            className='w-[198px]'
            onClick={onConfirm}
            isDisabled={isLoading}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
