import {
  IconBan,
  IconBook,
  IconBookX,
  IconNew,
  IconStudy,
  IconUsers
} from 'components/atoms/icons';
import { Tabs } from './enums';

export const options: { tab: Tabs; title: string; Icon: typeof IconUsers }[] = [
  { tab: Tabs.ALL, title: 'Всего учащихся', Icon: IconUsers },
  { tab: Tabs.NEW, title: 'Новых учащихся', Icon: IconNew },
  { tab: Tabs.NOT_STARTED, title: 'Не приступили', Icon: IconBookX },
  { tab: Tabs.DROPOUT, title: 'Прекратили обучение', Icon: IconBan },
  { tab: Tabs.COMPLETERS, title: 'Завершили курс', Icon: IconStudy },
  { tab: Tabs.LESSON_MISTAKES, title: 'Ошибки в уроках', Icon: IconBook }
];
