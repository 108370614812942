import { FC } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import styles from './CoursePage.module.css';

import {
  CourseAnalytics,
  CourseChecklist,
  CourseContent,
  CourseInfo,
  CourseSendInvites,
  CourseStudents,
  Sidebar
} from './components';

const CoursePage: FC = () => {
  const hideSidebar = useMatch('/courses/:courseId/invite');

  return (
    <div className={styles.container}>
      {!hideSidebar && <Sidebar />}

      <Routes>
        <Route path='/info' element={<CourseInfo />} />
        <Route path='/content' element={<CourseContent />} />
        <Route path='/checklist' element={<CourseChecklist />} />
        <Route path='/analytics' element={<CourseAnalytics />} />
        <Route path='/students' element={<CourseStudents />} />
        <Route path='/invite' element={<CourseSendInvites />} />
        <Route path='*' element={<Navigate to='info' replace />} />
      </Routes>
    </div>
  );
};

export default CoursePage;
