import { FC } from 'react';

import { ITextStep } from 'api/api-courses';

import Editor from 'components/organisms/Editor';

interface TextStepChangeProps {
  step: ITextStep;
  time: string;
}

const TextStepChange: FC<TextStepChangeProps> = ({ step, time }) => {
  return (
    <div
      className={
        'flex flex-1 flex-col rounded-[16px] bg-[#eef0ff] px-[24px]  ' +
        'max-h-[calc(80vh-210px)] flex-1 overflow-auto pt-[12px] pb-[24px]'
      }
    >
      <header className='mb-[24px] flex h-[56px] min-h-[56px] items-center'>
        <h4 className='text-[18px] font-[500] leading-[21px] text-[#20233A]'>
          {`Шаг ${step.order} | Текст`}
        </h4>
      </header>

      <Editor data={step.content} holder='editorjs-wrapper' readOnly key={time} />
    </div>
  );
};

export default TextStepChange;
