import { FC, MouseEvent } from 'react';
import { useParams } from 'react-router-dom';

import { useGetCourseQuery } from 'hooks/course';
import styles from './Content.module.css';

import { IconCopyLink } from 'components/atoms/icons';
import { creatLinkToCourseVocabulary } from 'consts';
import { notifySuc } from 'helpers/notification';
import LessonCard from '../LessonCard';
import ModuleCard from '../ModuleCard';
import ContentSkeleton from './ContentSkeleton';

type Params = { courseId: string };

const Content: FC = () => {
  const { courseId } = useParams<keyof Params>() as Params;
  const { data: course, isLoading: courseLoading } = useGetCourseQuery({ courseId });

  const handleCopyLinkToClipboard = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(creatLinkToCourseVocabulary({ courseId }))
      .then(() => notifySuc('Ссылка скопирована'));
  };

  if (courseLoading) return <ContentSkeleton />;
  if (!course) return <h2>Не удалось загрузить курс</h2>;

  return (
    <div className={styles.content}>
      <header className={styles.header}>
        <h2 className={styles.header__title}>
          <div>Словарь курса</div>
          <button title='Скопировать ссылку на словарь' onClick={handleCopyLinkToClipboard}>
            <IconCopyLink />
          </button>
        </h2>
        <h4 className={styles.header__level}>{course.level} уровень</h4>
        <h3 className={styles.header__name}>{course.title}</h3>
      </header>

      {course.withModules ? (
        <div className={styles.modules}>
          <ul className={styles.modules__list}>
            {course.modules.length > 0 ? (
              <>
                {course.modules
                  .sort((a, b) => a.order - b.order)
                  .map((module, i) => (
                    <ModuleCard key={module.id} courseId={course.id} module={module} index={i} />
                  ))}
              </>
            ) : (
              <span>Пока у данного курса нет модулей</span>
            )}
          </ul>
        </div>
      ) : (
        <ul className={styles.lessons}>
          {(course.lessons || []).length > 0 ? (
            <>
              {(course.lessons || [])
                .sort((a, b) => a.order - b.order)
                .map((lesson, i) => (
                  <LessonCard key={lesson.id} courseId={courseId} lesson={lesson} index={i} />
                ))}
            </>
          ) : (
            <span>Пока в данном модуле нет уроков</span>
          )}
        </ul>
      )}
    </div>
  );
};

export default Content;
