import { FC } from 'react';

import { useGetCompletedStudentsByPeriodQuery } from 'hooks/general-analytics';
import { classNames } from 'utils';
import { PeriodsSkeleton } from '../PeriodsSkeleton';

type Props = {
  className?: string;
};

export const Periods: FC<Props> = props => {
  const { className } = props;
  const { data: completedStudentsByPeriod, isLoading: completedStudentsByPeriodLoading } =
    useGetCompletedStudentsByPeriodQuery();

  if (completedStudentsByPeriodLoading) {
    return <PeriodsSkeleton count={4} />;
  }

  if (!completedStudentsByPeriod) {
    return null;
  }

  return (
    <header
      className={classNames(
        'flex flex-1 w-full justify-between p-[15px] ' +
          'bg-[#F5F5F5] border-b-[1px] border-solid border-[#E9ECF0]',
        {},
        [className]
      )}
    >
      <ul className='flex items-end overflow-hidden'>
        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] line-clamp-2'>Всего завершили</p>
          <div className='flex items-end'>
            <h3 className='mr-[4px] h3 text-[#20233A] truncate'>
              {completedStudentsByPeriod.allTime.totalCompleted}
            </h3>
            {/* <h5 className='h5 text-[#20233A] truncate'>
              /{completedStudentsByPeriod.totalStudentsCount}
            </h5> */}
          </div>
        </li>
        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] line-clamp-2'>В прошлом году</p>
          <h3 className='h3 text-[#20233A] truncate'>
            {completedStudentsByPeriod.lastYear.totalCompleted}
          </h3>
        </li>
        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] line-clamp-2'>В прошлом месяце</p>
          <h3 className='h3 text-[#20233A] truncate'>
            {completedStudentsByPeriod.lastMonth.totalCompleted}
          </h3>
        </li>
        <li className='p-[0_32px_0_16px] flex flex-col overflow-hidden'>
          <p className='mb-[8px] caption-small text-[#71798F] line-clamp-2'>На прошлой неделе</p>
          <h3 className='h3 text-[#20233A] truncate'>
            {completedStudentsByPeriod.lastWeek.totalCompleted}
          </h3>
        </li>
      </ul>
    </header>
  );
};
