import { FC, MouseEvent, ReactNode, useCallback } from 'react';

import { IconPlus } from '../icons';
import styles from './ButtonAdd.module.css';

interface ButtonProps {
  variant?: 'primary' | 'light' | 'dark';
  size?: 'small' | 'big';
  title: string | ReactNode;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick?: () => void;
  isDisabled?: boolean;
}

const ButtonAdd: FC<ButtonProps> = ({
  variant = 'primary',
  size = 'small',
  title,
  type,
  className,
  onClick,
  isDisabled = false
}) => {
  const getVariantClass = useCallback(() => {
    switch (variant) {
      case 'primary':
        return styles.button_primary;
      case 'light':
        return styles.button_light;
      case 'dark':
        return styles.button_dark;
      default:
        return '';
    }
  }, [variant]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  const Icon = useCallback(() => {
    switch (variant) {
      case 'primary':
        return <IconPlus color='#FFFFFF' className='shrink-0' />;
      case 'light':
        return <IconPlus color='#5770F3' className='shrink-0' />;
      case 'dark':
        return <IconPlus color='#5770F3' className='shrink-0' />;
    }
  }, [variant]);

  return (
    <button
      disabled={isDisabled}
      type={type}
      onClick={handleClick}
      className={
        `${styles.button} ` +
        `${getVariantClass()} ` +
        `${size === 'small' ? styles.button_small : styles.button_big} ` +
        `${className}`
      }
    >
      <Icon />
      {title}
    </button>
  );
};

export default ButtonAdd;
