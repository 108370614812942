import { useMutation, useQueryClient } from 'react-query';

import { UpdateUserData, apiUsers } from 'api/api-users';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useGetCurrentUserQuery } from 'hooks/queries';

export const useUpdateUserInfoMutation = () => {
  const queryClient = useQueryClient();
  const { data: viewer } = useGetCurrentUserQuery();

  return useMutation(
    (data: UpdateUserData) => {
      return apiUsers.updateUserInfo(data);
    },
    {
      onSuccess(_, { id }) {
        notifySuc('Пользователь обновлен');

        queryClient.invalidateQueries(`users/one/${id}`);

        if (viewer?.id === id) {
          queryClient.invalidateQueries('users/current');
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
