import React from 'react';

const IconPause: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.9375 5.5H14.8125C13.8633 5.5 13.125 6.23828 13.125 7.15234V17.2773C13.125 18.1914 13.8633 18.9648 14.8125 18.9648L15.9375 19C16.8516 19 17.625 18.2617 17.625 17.3125V7.1875C17.625 6.27344 16.8516 5.5 15.9375 5.5ZM9.1875 5.5H8.0625C7.11328 5.5 6.375 6.23828 6.375 7.1875V17.3125C6.375 18.2617 7.11328 19 8.0625 19H9.1875C10.1016 19 10.875 18.2617 10.875 17.3125V7.1875C10.875 6.27344 10.1016 5.5 9.1875 5.5Z'
      fill='currentColor'
    />
  </svg>
);

export default IconPause;
