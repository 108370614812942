import { NOTIFICATION_STATUS } from 'models';

export const getNotificationStatus = (status: NOTIFICATION_STATUS) => {
  switch (status) {
    case NOTIFICATION_STATUS.ACTIVE:
      return <span className='text-[16px] font-[500] leading-[19px] text-[#66C84D]'>Активный</span>;
    case NOTIFICATION_STATUS.DRAFT:
      return <span className='text-[16px] font-[500] leading-[19px] text-[#5770F3]'>Черновик</span>;
    case NOTIFICATION_STATUS.COMPLETED:
      return <span className='text-[16px] font-[500] leading-[19px] text-[#71798F]'>Завершён</span>;
  }
};
