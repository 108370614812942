import { CHANGE_HISTORY_LIMIT } from 'consts';
import { HistoryRecord, SaveChangeDto } from 'models';

export const saveToChangeHistory = ({ stepId, change }: SaveChangeDto) => {
  const changeHistory = localStorage.getItem('changeHistory');
  if (!changeHistory) {
    // initialize record
    const newRecord: HistoryRecord = { stepId, changes: [change] };

    // initialize change history
    const records: HistoryRecord[] = [newRecord];
    const changeHistory = JSON.stringify(records);
    localStorage.setItem('changeHistory', changeHistory);

    return;
  }

  const records = JSON.parse(changeHistory) as HistoryRecord[];
  const record = records.find(record => record.stepId === change.step.id);

  if (record) {
    let updatedRecord: HistoryRecord;

    // update the record (up to change limit)
    if (record.changes.length === CHANGE_HISTORY_LIMIT) {
      // add new change, delete the last change
      updatedRecord = {
        ...record,
        changes: [change, ...record.changes.slice(0, CHANGE_HISTORY_LIMIT - 1)]
      };
    } else {
      // add new change
      updatedRecord = { ...record, changes: [change, ...record.changes] };
    }

    const updatedRecords: HistoryRecord[] = records.map(record =>
      record.stepId === updatedRecord.stepId ? updatedRecord : record
    );
    const updatedChangeHistory = JSON.stringify(updatedRecords);
    localStorage.setItem('changeHistory', updatedChangeHistory);
  } else {
    // initialize record
    const newRecord: HistoryRecord = { stepId, changes: [change] };

    const updatedRecords: HistoryRecord[] = [...records, newRecord];
    const updatedChangeHistory = JSON.stringify(updatedRecords);
    localStorage.setItem('changeHistory', updatedChangeHistory);
  }
};
