import { StylesConfig } from 'react-select';

import { USER_ROLES } from 'consts';
import { IOption } from 'models';

export const filterSelectStyles: StylesConfig = {
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    height: '54px',
    width: '200px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'rgba(140, 154, 175, 0.25)',
    '&:hover': {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'rgba(140, 154, 175, 0.25)'
    },
    boxShadow: isFocused ? 'none' : 'inherit',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  option: (styles, { data, isFocused, isDisabled }) => ({
    ...styles,
    color: (data as IOption).color,
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: '500',
    backgroundColor: isFocused ? '#EFF1F4' : 'none',
    '&:active': {
      backgroundColor: '#EFF1F4'
    },
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  singleValue: (styles, { data, isDisabled }) => ({
    ...styles,
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: '500',
    color: (data as IOption).color,
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  })
};

export const singleSelectStyles: StylesConfig = {
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    height: '40px',
    width: '160px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'rgba(140, 154, 175, 0.25)',
    '&:hover': {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'rgba(140, 154, 175, 0.25)'
    },
    boxShadow: isFocused ? 'none' : 'inherit',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  option: (styles, { data, isFocused, isDisabled }) => ({
    ...styles,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    color: (data as IOption).color,
    backgroundColor: isFocused ? '#EFF1F4' : 'none',
    '&:active': {
      backgroundColor: '#EFF1F4'
    },
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  singleValue: (styles, { data, isDisabled }) => ({
    ...styles,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '500',
    color: (data as IOption).color,
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  })
};

export const filterRoleOptions: IOption[] = ['Все роли', ...USER_ROLES].map(role => ({
  value: role === 'Все роли' ? '' : role,
  label: role,
  color: role === 'Все роли' ? '#8C9AAF' : '#20233A'
}));

export const usersPerPageOptions: IOption[] = [
  { value: 10, label: 'По 10' },
  { value: 20, label: 'По 20' },
  { value: 50, label: 'По 50' },
  { value: 100, label: 'По 100' },
  { value: 200, label: 'По 200' }
];

export const DEFAULT_PAGE = 1;

export enum SEARCH_PARAMS {
  SEARCH = 'search',
  ROLE = 'role',
  STATUS = 'status',
  SORT_BY = 'sort',
  USERS_PER_PAGE = 'users',
  PAGE = 'page'
}
