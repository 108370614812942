import { useMutation, useQueryClient } from 'react-query';

import { CopySectionDto, apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useMoveSectionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CopySectionDto) => {
      return apiCourses.moveSection(data);
    },
    {
      onSuccess(_, { lessonId, currentLessonId }) {
        notifySuc('Раздел перемещен');
        queryClient.invalidateQueries([`lessons/one/${currentLessonId}`]);
        queryClient.invalidateQueries([`lessons/one/${lessonId}`]);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
