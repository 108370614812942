import { useQuery } from 'react-query';

import { apiAnalytics } from 'api/api-analytics';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetLessonReviewUsersQuery = (lessonId: string) => {
  return useQuery(
    ['analytics/lesson-reviews/rated-users', lessonId],
    async () => await apiAnalytics.getLessonReviewUsers(lessonId),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};
