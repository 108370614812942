/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { CHECKLIST_OPTIONS, DEFAULT_CHECKLIST } from 'consts';
import { useUpdateCourseChecklistMutation } from 'hooks/mutations';
import { useGetCourseQuery } from 'hooks/course';
import styles from './CourseChecklist.module.css';

import { Review, Steps, UpdateStatus } from './components';
import CourseChecklistSkeleton from './CourseChecklistSkeleton';

type Params = {
  courseId: string;
};

const CourseChecklist: FC = () => {
  const { courseId } = useParams<keyof Params>() as Params;
  const { data: course, isLoading: courseLoading } = useGetCourseQuery({ courseId });

  const [searchParams] = useSearchParams();
  const activeOption = useMemo(
    () => searchParams.get('checklistOption') || CHECKLIST_OPTIONS.STEPS,
    [searchParams]
  );

  // fallback for older courses that do not have a checklist
  const { mutate: updateCourseChecklistMutate, isLoading: courseChecklistLoading } =
    useUpdateCourseChecklistMutation();
  useEffect(() => {
    if (!course) return;
    if (!course.checklist) {
      updateCourseChecklistMutate({ courseId: course.id, body: { checklist: DEFAULT_CHECKLIST } });
    }
  }, [course]);

  if (courseLoading || courseChecklistLoading) return <CourseChecklistSkeleton />;
  if (!course) return <h2>Не удалось загрузить курс</h2>;
  if (!course.checklist) return <h2>Чек-лист отсутствует</h2>;

  return (
    <div className={styles.container}>
      {activeOption === CHECKLIST_OPTIONS.STEPS && <Steps course={course} />}
      {activeOption === CHECKLIST_OPTIONS.REVIEW && <Review steps={course.checklist} />}
      {activeOption === CHECKLIST_OPTIONS.UPDATE_STATUS && <UpdateStatus course={course} />}
    </div>
  );
};

export default CourseChecklist;
