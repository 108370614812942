import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { useUpdateSubscriptionMutation } from 'hooks/subscription';
import {
  Subscription,
  SubscriptionTerm,
  UpdateSubscriptionFormValues,
  subscriptionTermOptionsMonths
} from 'models/subscription';
import { addMonths, timestampToInput } from 'utils';

import { InputDate, Select } from 'components/atoms';
import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';

type ExtendSubscriptionFormProps = {
  userId: string;
  subscription: Subscription;
  onClose: () => void;
};

const ExtendSubscriptionForm: FC<ExtendSubscriptionFormProps> = props => {
  const {
    userId,
    subscription: {
      course: { title, level, imageUrl },
      purchase: { id: purchaseId, paymentDate, expirationDate, isExpired }
    },
    onClose
  } = props;
  const { mutate: updateSubscriptionMutate, isLoading: updateSubscriptionLoading } =
    useUpdateSubscriptionMutation();

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<UpdateSubscriptionFormValues>({
    defaultValues: {
      subscriptionTerm: SubscriptionTerm.ARBITRARY,
      from: isExpired
        ? timestampToInput(new Date().getTime())
        : expirationDate
        ? timestampToInput(expirationDate)
        : undefined
    },
    mode: 'onSubmit'
  });

  const onSubmit = (data: UpdateSubscriptionFormValues) => {
    const { subscriptionTerm, from } = data;

    const fromDate = from ? new Date(from).getTime() : undefined;
    let newExpirationDate = undefined;

    switch (subscriptionTerm) {
      case SubscriptionTerm.THREE_MONTHS:
        newExpirationDate = fromDate && addMonths(new Date(fromDate), 3).getTime();
        break;
      case SubscriptionTerm.SIX_MONTHS:
        newExpirationDate = fromDate && addMonths(new Date(fromDate), 6).getTime();
        break;
      case SubscriptionTerm.NINE_MONTHS:
        newExpirationDate = fromDate && addMonths(new Date(fromDate), 9).getTime();
        break;
      case SubscriptionTerm.TWELVE_MONTHS:
        newExpirationDate = fromDate && addMonths(new Date(fromDate), 12).getTime();
        break;
      case SubscriptionTerm.TWENTY_FOUR_MONTHS:
        newExpirationDate = fromDate && addMonths(new Date(fromDate), 24).getTime();
        break;
    }

    updateSubscriptionMutate(
      {
        userId,
        purchaseId,
        body: { isPermanent: false, newPurchaseDate: paymentDate, newExpirationDate }
      },
      { onSuccess: onClose }
    );
  };

  return (
    <div className='flex w-[404px] cursor-auto flex-col'>
      <header className='mb-[8px] flex h-[32px] items-center justify-between'>
        <h2 className='text-[22px] font-[700] leading-[26px] text-[#20233a]'>Продлить подписку</h2>
        <button className='h-[24px] w-[24px]' onClick={onClose}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <div className='mb-[24px] flex h-[64px] items-center gap-[16px]'>
        <div
          className='h-[64px] w-[64px] rounded-[20px]'
          // style={{ background: color }}
        >
          <img src={imageUrl} alt={title} className='h-full w-full' />
        </div>

        <div className='flex grow flex-col items-start gap-[8px]'>
          <span className='text-[13px] font-[500] leading-[15px] text-[#5A667B]'>{level}</span>
          <h5 className='text-[16px] font-[500] leading-[19px] text-[#20233A]'>{title}</h5>
        </div>
      </div>

      <form className='flex flex-col gap-[8px]' onSubmit={handleSubmit(onSubmit)}>
        <Select
          name='subscriptionTerm'
          placeholder='Выберите срок подписки'
          control={control}
          options={subscriptionTermOptionsMonths}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
          }}
        />

        <InputDate
          name='from'
          label='с'
          width='big'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD }
          }}
        />

        <div className='flex gap-[8px]'>
          <Button
            type='button'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            onClick={onClose}
            isDisabled={updateSubscriptionLoading || !isDirty}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={updateSubscriptionLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default ExtendSubscriptionForm;
