import { useQuery } from 'react-query';

import { GetDropoutStudentsDto, apiAnalytics } from 'api/api-analytics';
import { DEFAULT_STALE_TIME } from 'consts';
import { handleError } from 'helpers/handleError';

export const useGetDropoutStudentsQuery = (data: GetDropoutStudentsDto) => {
  return useQuery(
    [
      `/analytics/inactive-students-by-periods/${data.courseId}?startData=${data.startDate}&endDate=${data.endDate}`
    ],
    async () => await apiAnalytics.getDropoutStudents(data),
    {
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
};
