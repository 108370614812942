import React from 'react';

const IconLinkSmall: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11 10.3333C10.5781 10.3333 10.25 10.6848 10.25 11.0833V13.3333L3.5 13.3333L3.5 6.58325H5.75C6.14844 6.58325 6.5 6.25513 6.5 5.83325C6.5 5.43481 6.14844 5.08325 5.75 5.08325H3.5C2.65625 5.08325 2 5.76294 2 6.58325L2 13.3333C2 14.177 2.65625 14.8333 3.5 14.8333L10.25 14.8333C11.0703 14.8333 11.75 14.177 11.75 13.3333V11.0833C11.75 10.6848 11.3984 10.3333 11 10.3333ZM13.7656 3.06763C13.625 2.927 13.4375 2.83325 13.25 2.83325L9.5 2.83325C9.07812 2.83325 8.75 3.18481 8.75 3.58325C8.75 3.98169 9.10156 4.30981 9.5 4.30981L11.4453 4.30981L6.17188 9.65356C5.89063 9.95825 5.89063 10.427 6.17188 10.7083C6.47656 11.0129 6.94531 11.0129 7.25 10.7083L12.5 5.41138V7.33325C12.5 7.75513 12.8281 8.08325 13.2266 8.08325C13.6016 8.08325 14 7.75513 14 7.33325V3.58325C14 3.39575 13.9062 3.20825 13.7656 3.06763Z'
      fill='currentColor'
    />
  </svg>
);

export default IconLinkSmall;
