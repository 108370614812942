import { ChangeEvent, FC, useState } from 'react';

import { courseAvatars } from 'consts';

import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';

interface SelectAvatarFormProps {
  currentAvatarId: string | null;
  confirmSelectAvatar: (avatarId: string) => void;
  onCancel: () => void;
}

const SelectAvatarForm: FC<SelectAvatarFormProps> = ({
  currentAvatarId,
  confirmSelectAvatar,
  onCancel
}) => {
  const [selectedAvatarId, setSelectedAvatarId] = useState<string | null>(currentAvatarId);

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!selectedAvatarId) return;

    confirmSelectAvatar(selectedAvatarId);
  };

  return (
    <div className='flex max-w-[980px] flex-col'>
      <header className='mb-[24px] flex h-[32px] items-center justify-between'>
        <h2 className='text-[22px] font-[700] leading-[26px] text-[#20233a]'>
          Выбрать картинку для курса
        </h2>

        <button className='h-[24px] w-[24px]' onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <form className='flex flex-col' onSubmit={handleSubmit}>
        <ul className='mb-[24px] flex max-h-[489px] flex-wrap gap-[12px] overflow-auto'>
          {courseAvatars.map(avatar => (
            <li key={avatar.id}>
              <button
                type='button'
                className={
                  'h-[150px] w-[150px] overflow-hidden rounded-[20px] border-[2px] border-solid ' +
                  `${
                    selectedAvatarId === avatar.id
                      ? 'bg-[#EEF0FF] border-[#5770F3]'
                      : 'bg-[#EFF1F4] border-[transparent]'
                  }`
                }
                onClick={() => setSelectedAvatarId(avatar.id)}
              >
                <img src={avatar.url} alt={`course-avatar-${avatar.id}`} />
              </button>
            </li>
          ))}
        </ul>

        <div className='flex justify-end gap-[8px]'>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            onClick={onCancel}
            className='w-[180px]'
          />

          <Button
            type='submit'
            variant='primary'
            title='Выбрать'
            isDisabled={!selectedAvatarId}
            className='w-[180px]'
          />
        </div>
      </form>
    </div>
  );
};

export default SelectAvatarForm;
