import { FC } from 'react';

import { Skeleton } from 'components/atoms';
import { classNames } from 'utils';

type Props = {
  count?: number;
  className?: string;
};

export const PeriodsSkeleton: FC<Props> = props => {
  const { count = 4, className } = props;

  return (
    <header
      className={classNames(
        'w-full p-[15px] flex flex-1 gap-[16px] ' +
          'bg-[#F5F5F5] border-b-[1px] border-solid border-[#E9ECF0]',
        {},
        [className]
      )}
    >
      {Array.from({ length: count }).map((_, index) => (
        <Skeleton key={index} height={48} width={170} />
      ))}
    </header>
  );
};
