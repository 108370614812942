/* eslint-disable react-hooks/exhaustive-deps */

import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { apiUsers } from 'api/api-users';
import { TEACHERS_PER_PAGE, USER_ROLES } from 'consts';
import useDebounce from 'hooks/useDebounce';
import { User } from 'models';
import styles from './AddSpeakerForm.module.css';

import Button from 'components/atoms/Button';
import { IconClose } from 'components/atoms/icons';
import SearchField from 'components/atoms/SearchField/SearchField';
import SelectSpeakerCard from 'components/molecules/SelectSpeakerCard';
import { handleError } from 'helpers/handleError';

interface AddSpeakerFormProps {
  currentSpeakers: User[];
  setCurrentSpeakers: Dispatch<SetStateAction<User[]>>;
  onCancel: () => void;
}

const AddSpeakerForm: FC<AddSpeakerFormProps> = ({
  currentSpeakers,
  setCurrentSpeakers,
  onCancel
}) => {
  const [value, setValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentTeachers, setCurrentTeachers] = useState<User[]>([]);

  const debouncedSearchValue = useDebounce(value, 500);
  useEffect(() => {
    setSearchValue(value);
  }, [debouncedSearchValue]);

  const { data, isLoading } = useQuery(
    [`users/all?page=1&itemsPerPage=1000&roles=Учитель&letters=${searchValue}`, searchValue],
    async () =>
      await apiUsers.getUsers({
        page: 1,
        itemsPerPage: TEACHERS_PER_PAGE,
        roles: [USER_ROLES[2]],
        letters: searchValue
      }),
    {
      keepPreviousData: true,
      onError(error) {
        handleError(error);
      }
    }
  );
  const teachersData = useMemo(() => data, [data]);

  useEffect(() => {
    if (teachersData) {
      setCurrentTeachers(teachersData.data);
    }
  }, [teachersData]);

  const [selectedSpeakers, setSelectedSpeakers] = useState<User[]>(currentSpeakers);

  const handleSelectSpeaker = (teacher: User) => {
    const selectedSpeakersIds = selectedSpeakers.map(speaker => speaker.id);

    if (selectedSpeakersIds.includes(teacher.id)) {
      setSelectedSpeakers(prev => prev.filter(el => el.id !== teacher.id));
    } else {
      setSelectedSpeakers(prev => [...prev, teacher]);
    }
  };

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    setCurrentSpeakers(selectedSpeakers);
    onCancel();
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <span className={styles.title}>Добавить спикера курса</span>
        <button className={styles.buttonClose} onClick={onCancel}>
          <IconClose color='#71798F' />
        </button>
      </header>

      <form className={styles.form} onSubmit={handleSubmit}>
        <SearchField
          placeholder={'Поиск учителя'}
          value={value}
          setState={setValue}
          type={'text'}
          id={'title'}
        />

        <div className={`${styles.speakers} ${currentTeachers.length > 3 ? 'pr-[11px]' : ''}`}>
          {isLoading ? (
            <span>Загрузка...</span>
          ) : (
            <>
              {currentTeachers.length > 0 ? (
                currentTeachers.map(teacher => (
                  <SelectSpeakerCard
                    key={teacher.id}
                    speaker={teacher}
                    isSelected={selectedSpeakers.map(speaker => speaker.id).includes(teacher.id)}
                    selectSpeaker={() => handleSelectSpeaker(teacher)}
                  />
                ))
              ) : (
                <span>Результатов не найдено</span>
              )}
            </>
          )}
        </div>

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='w-[198px]'
            onClick={onCancel}
          />

          <Button type='submit' variant='primary' title='Добавить' className='w-[198px]' />
        </div>
      </form>
    </div>
  );
};

export default AddSpeakerForm;
