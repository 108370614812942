import { Dispatch, FC, SetStateAction, useState } from 'react';

import { USER_ROLES } from 'consts';
import { IOption } from 'models';
import { getMainRole } from 'utils';
import styles from './RolesDropdown.module.css';

import { IconCheckSquareOff, IconCheckSquareOn, IconStroke } from 'components/atoms/icons';

interface RolesDropdownProps {
  values: string[];
  setValues: Dispatch<SetStateAction<string[]>>;
  options: IOption[];
  wide?: boolean;
  className?: string;
}

const RolesDropdown: FC<RolesDropdownProps> = ({
  values,
  setValues,
  options,
  wide = false,
  className
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleOptionClick = (value: string) => {
    if (values.includes(value)) {
      if (value === USER_ROLES[0]) {
        setValues([]);
      } else {
        const newValues = values.filter(v => v !== value);
        setValues(newValues);
      }
    } else {
      if (value === USER_ROLES[0]) {
        setValues(USER_ROLES);
      } else {
        setValues([...values, value]);
      }
    }
  };

  return (
    <div
      className={`${styles.container} ${className} ${wide ? '' : 'w-[198px]'}`}
      tabIndex={1}
      onBlur={e => {
        const currentTarget = e.currentTarget;

        requestAnimationFrame(() => {
          if (!currentTarget.contains(document.activeElement)) {
            setMenuOpen(false);
          }
        });
      }}
    >
      <button
        type='button'
        className={`${styles.header} ${values.length > 0 ? '' : styles.header_placeholder}`}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        {values.length > 0 ? getMainRole(values) : 'Выбрать роль'}

        <IconStroke
          className={
            '-rotate-90 h-[24px] w-[24px] duration-[150ms] ' +
            `${menuOpen ? 'text-[hsl(0,0%,40%)]' : 'text-[hsl(0,0%,80%)]'}`
          }
        />
      </button>

      <div className={`${styles.options} ${menuOpen ? styles.options_active : ''}`}>
        {options.length > 0 ? (
          options.map(({ value, label }) => (
            <button
              key={value}
              type='button'
              className={styles.option}
              onClick={() => handleOptionClick(value)}
              disabled={value !== USER_ROLES[0] && values.includes(USER_ROLES[0])}
            >
              {values.includes(value) || values.includes(USER_ROLES[0]) ? (
                <IconCheckSquareOn color='#5770F3' />
              ) : (
                <IconCheckSquareOff />
              )}
              <span>{label}</span>
            </button>
          ))
        ) : (
          <span>Список опций пуст</span>
        )}
      </div>
    </div>
  );
};

export default RolesDropdown;
