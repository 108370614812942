import { OutputData } from '@editorjs/editorjs';
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  CreateChooseSentenceStep,
  CreateChooseSentenceStepDto,
  ChooseSentenceStep as IChooseSentenceStep,
  ISection,
  STEP_TYPES,
  UpdateChooseSentenceStep,
  UpdateChooseSentenceStepDto,
  apiCourses
} from 'api/api-courses';
import {
  DEFAULT_EDITABLE_CONDITION_CHOOSE_SENTENCE,
  DEFAULT_EDITABLE_PURPOSE_DATA,
  FORM_ERROR_MESSAGES,
  TEST_STEPS_ERROR_MESSAGES
} from 'consts';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';
import { useUploadStepImageMutation } from 'hooks/mutations';
import { AudioPart, StepFormat } from 'models';
import { normalizeData } from 'utils';
import { useDeleteStep } from '../hooks';
import styles from './ChooseSentenceStep.module.css';

import Button from 'components/atoms/Button';
import ConfirmationModal from 'components/atoms/ConfirmationModal';
import Input from 'components/atoms/Input';
import { IconChooseSentence, IconTrash } from 'components/atoms/icons';
import { Condition, Format, Hint, IncorrectWords, Purpose } from '../components';

type FieldValues = {
  editableCondition: OutputData;
  editablePurpose: OutputData;
  correctAnswers: string;
  incorrectAnswers: Array<{ value: string }>;
  hint?: string;
  format: StepFormat;
  textForAudio?: string;
  audioParts?: AudioPart[];
  url?: string;
  picture?: FileList;
  audio?: FileList;
  videoUrl?: string;
};

interface ChooseSentenceStepProps {
  stepIndex: number | 'potential';
  step: IChooseSentenceStep;
  section: ISection;
  isPotential?: boolean;
  deletePotentialStep?: () => void;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  setOnSubmitCallback: Dispatch<SetStateAction<(() => void) | null>>;
  refetchSection: () => void;
}

const ChooseSentenceStep: FC<ChooseSentenceStepProps> = ({
  stepIndex,
  step,
  deletePotentialStep,
  isPotential,
  section,
  setIsDirty,
  setIsValid,
  setOnSubmitCallback,
  refetchSection
}) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    setValue,
    formState: { isDirty, isValid, errors }
  } = useForm<FieldValues>({
    mode: 'onSubmit',
    defaultValues: {
      editableCondition: step.editableCondition || DEFAULT_EDITABLE_CONDITION_CHOOSE_SENTENCE,
      editablePurpose: step.editablePurpose || DEFAULT_EDITABLE_PURPOSE_DATA,
      correctAnswers: step?.answers.filter(answer => answer.correct)[0].sentence || '',
      incorrectAnswers: step?.answers
        .filter(answer => !answer.correct)
        .map(answer => {
          return { value: answer.sentence };
        }) || [{ value: '' }],
      hint: step.hint || '',
      format: step?.format || 'text',
      textForAudio: step.textForAudio || '',
      audioParts: !!step.audioList?.length ? step.audioList : [],
      url: step.url,
      videoUrl: step.format === 'video' ? step.url : undefined
    }
  });

  const {
    fields: incorrectWordFields,
    append: appendIncorrectWord,
    remove: removeIncorrectWord
  } = useFieldArray({
    control,
    name: 'incorrectAnswers'
  });

  const format = watch('format');

  const { mutate: setStepImageMutate, isLoading: setStepImageLoading } =
    useUploadStepImageMutation();

  // TODO separate mutation hook
  const { mutateAsync: addChooseSentenceStepMutate, isLoading: isLoadingCreate } = useMutation(
    (dto: CreateChooseSentenceStepDto) => {
      return apiCourses.addChooseSentenceStep(dto);
    },
    {
      async onSuccess(data, variables) {
        const onSuccess = () => {
          deletePotentialStep && deletePotentialStep();

          notifySuc('Шаг добавлен');
          refetchSection();
        };

        if (variables.image) {
          setStepImageMutate(
            {
              stepId: data.id,
              image: variables.image,
              type: STEP_TYPES.CHOOSE_SENTENCE
            },
            {
              onSuccess
            }
          );
        } else {
          onSuccess();
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  // TODO separate mutation hook
  const { mutateAsync: updateChooseSentenceStepMutate, isLoading: isLoadingUpdate } = useMutation(
    (dto: UpdateChooseSentenceStepDto) => {
      return apiCourses.updateChooseSentenceStep(dto);
    },
    {
      async onSuccess(data, variables) {
        const onSuccess = () => {
          notifySuc('Шаг обновлен');
          refetchSection();
        };

        if (variables.image) {
          setStepImageMutate(
            {
              stepId: data.id,
              image: variables.image,
              type: STEP_TYPES.CHOOSE_SENTENCE
            },
            {
              onSuccess
            }
          );
        } else {
          onSuccess();
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );

  const { deleteStepMutate: deleteChooseAnswerStepMutate, isLoading: isLoadingDelete } =
    useDeleteStep({
      section,
      stepId: step.id,
      stepType: step.type,
      setIsDirty,
      setIsValid,
      setOnSubmitCallback
    });
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

  const deleteChooseAnswerStep = () => {
    if (isPotential) {
      if (deletePotentialStep) {
        deletePotentialStep();
      }
      return;
    }
    deleteChooseAnswerStepMutate();
  };

  const handleReset = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    reset();
  };

  const onSubmit = async (data: FieldValues) => {
    const {
      editableCondition,
      editablePurpose,
      correctAnswers,
      format,
      incorrectAnswers,
      hint,
      picture,
      audio,
      textForAudio,
      audioParts,
      url,
      videoUrl
    } = data;
    const stepData: CreateChooseSentenceStep & UpdateChooseSentenceStep = {
      order: step?.order || section.steps?.length + 1 || 1,
      editableCondition: normalizeData(editableCondition),
      editablePurpose: normalizeData(editablePurpose),
      answers: [
        {
          sentence: correctAnswers,
          correct: true
        },
        ...incorrectAnswers.map(answer => {
          return {
            sentence: answer.value,
            correct: false
          };
        })
      ],
      hint,
      format,
      textForAudio: format === 'audio' && !audio?.length ? textForAudio : '',
      audioList: format === 'audio' ? audioParts : undefined,
      url: format === 'video' ? videoUrl : url
    };

    if (isPotential) {
      if (format === 'image' && picture && picture.length) {
        await addChooseSentenceStepMutate({
          sectionId: section.id,
          body: stepData,
          image: picture[0]
        });
      } else {
        await addChooseSentenceStepMutate({ sectionId: section.id, body: stepData });
      }
    } else {
      if (format === 'image' && picture && picture.length) {
        await updateChooseSentenceStepMutate({
          stepId: step.id,
          body: stepData,
          image: picture[0]
        });
      } else {
        await updateChooseSentenceStepMutate({ stepId: step.id, body: stepData });
      }
    }
  };

  useEffect(() => {
    setIsDirty(isDirty);
    setIsValid(isValid);

    setOnSubmitCallback(() => handleSubmit(async data => await onSubmit(data)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isValid]);

  return (
    <>
      <div className='mb-[16px] flex items-center justify-between'>
        <div className='flex h-[56px] items-center justify-between text-[18px] font-[500] leading-[21px] text-[#20233A]'>
          {`Шаг ${isPotential ? section.steps?.length + 1 : stepIndex} | Тест — `}
          <IconChooseSentence className='mx-[8px]' /> Выбрать правильное предложение
        </div>

        <button
          className='flex h-[24px] w-[24px] items-center justify-center'
          onClick={() => setDeleteModalIsOpen(true)}
        >
          <IconTrash color='#71798F' />
        </button>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
        <Condition stepId={step.id || 'potential'} control={control} />

        <Purpose
          stepId={step.id || 'potential'}
          control={control}
          text='Цель (слово или фраза для перевода) (Выберите размер шрифта Заголовок 3)'
        />

        <div className='mb-[16px] flex flex-col'>
          <label className='mb-[8px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
            Правильный ответ
          </label>

          <Input
            type='text'
            variant='bordered'
            width='big'
            name='correctAnswers'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' },
              validate: {
                doesntConsistOfSpaces: (value: any) => {
                  return !!value.trim() ? true : FORM_ERROR_MESSAGES.DOESNT_CONSIST_OF_SPACES;
                },
                isNotDuplicate: (value: any) => {
                  const variants = [
                    ...getValues('incorrectAnswers').map(answer => answer.value),
                    getValues('correctAnswers')
                  ];
                  const copies = variants.reduce(
                    (acc, variant) => (variant.trim() === value.trim() ? acc + 1 : acc),
                    0
                  );
                  return copies < 2 ? true : TEST_STEPS_ERROR_MESSAGES.CHOOSE_ANSWER;
                }
              }
            }}
          />
        </div>

        <IncorrectWords
          control={control}
          name='incorrectAnswers'
          fields={incorrectWordFields}
          append={appendIncorrectWord}
          remove={removeIncorrectWord}
          getValues={getValues}
          isEmptyAllowed={false}
        />

        <Hint control={control} />

        <Format
          savedFormat={step.format}
          format={format}
          control={control}
          register={register}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          imageError={!!errors.picture}
        />

        <div className={styles.buttons}>
          <Button
            type='reset'
            variant='secondary'
            title='Сбросить'
            className='w-[198px]'
            isDisabled={!isDirty || isLoadingCreate || isLoadingUpdate || setStepImageLoading}
          />

          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='w-[198px]'
            isDisabled={!isDirty}
            isLoading={isLoadingCreate || isLoadingUpdate || setStepImageLoading}
          />
        </div>
      </form>

      {deleteModalIsOpen && (
        <ConfirmationModal
          title={
            <>
              Удалить{' '}
              {isPotential
                ? 'Шаг | Тест — Выбрать ответ'
                : `Шаг ${stepIndex} | Тест — Выбрать ответ`}
              ?
            </>
          }
          text={
            <>
              Шаг будет удален <strong>навсегда</strong>.
            </>
          }
          isDelete={true}
          confirmButtonText='Удалить шаг'
          onConfirm={deleteChooseAnswerStep}
          onClose={() => setDeleteModalIsOpen(false)}
          isLoading={isLoadingDelete}
        />
      )}
    </>
  );
};

export default ChooseSentenceStep;
