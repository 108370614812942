import React, { FC, useEffect } from 'react';
import styles from './Modal.module.css';

interface IModalProps {
  children: React.ReactNode;
  className?: string;
  onClose: () => void;
}

const Modal: FC<IModalProps> = ({ children, className, onClose }) => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  return (
    <div className={className ? `${styles.container} ${className}` : styles.container}>
      <div className={styles.backdrop} onClick={onClose} />
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default Modal;
