import { MONTHS, REPEAT_DAYS, REPEAT_TYPE } from 'models';

export const repeatTypeOptions = [
  {
    value: REPEAT_TYPE.DAY,
    label: 'Каждый день'
  },
  {
    value: REPEAT_TYPE.WEEK,
    label: 'Каждую неделю'
  },
  {
    value: REPEAT_TYPE.MONTH,
    label: 'Каждый месяц'
  },
  {
    value: REPEAT_TYPE.YEAR,
    label: 'Каждый год'
  }
];

export const repeatDaysOptions = [
  { value: REPEAT_DAYS.MONDAY, label: 'пн' },
  { value: REPEAT_DAYS.TUESDAY, label: 'вт' },
  { value: REPEAT_DAYS.WEDNESDAY, label: 'ср' },
  { value: REPEAT_DAYS.THURSDAY, label: 'чт' },
  { value: REPEAT_DAYS.FRIDAY, label: 'пт' },
  { value: REPEAT_DAYS.SATURDAY, label: 'сб' },
  { value: REPEAT_DAYS.SUNDAY, label: 'вс' }
];

export const repeatMonthsOptions = [
  { value: MONTHS.JANUARY, label: 'Янв' },
  { value: MONTHS.FEBRUARY, label: 'Фев' },
  { value: MONTHS.MARCH, label: 'Мар' },
  { value: MONTHS.APRIL, label: 'Апр' },
  { value: MONTHS.MAY, label: 'Май' },
  { value: MONTHS.JUNE, label: 'Июн' },
  { value: MONTHS.JULY, label: 'Июл' },
  { value: MONTHS.AUGUST, label: 'Авг' },
  { value: MONTHS.SEPTEMBER, label: 'Сен' },
  { value: MONTHS.OCTOBER, label: 'Окт' },
  { value: MONTHS.NOVEMBER, label: 'Ноя' },
  { value: MONTHS.DECEMBER, label: 'Дек' }
];
