import { NO_INTERNET_WARNING_MESSAGE } from 'consts';
import { notifyWarn } from 'helpers/notification';
import { CreateUserWithCoursesDto, GetUsersDto, Pagination, User } from 'models';
import { instance } from './api';
import { ICourse, ILesson, IModule, ISection, IStep } from './api-courses';

export interface CreateUserData {
  email: string;
  password: string;
  name: string;
  roles: string[];
}

export interface UpdateUserData {
  id: string;
  body: {
    email?: string;
    password?: string;
    name?: string;
    roles?: string[];
    avatarUrl?: string;
    position?: string;
    achievements?: string;
    isLinearPassageActive?: boolean;
  };
}

export interface IUserData {
  data: User[];
  pagination: Pagination;
}

export interface UserCourse {
  receivingMethod: 'bought';
  id: string;
  title: string;
  level: string;
  imageUrl: string;
  miniImageUrl: string;
  color?: string;
}

export interface AddUserCoursesDto {
  userId: string;
  courseIds: string[];
}

export interface DeleteUserCoursesDto {
  userId: string;
  courseIds: string[];
}

export type UserStatsReponse = {
  visitStreak?: number;
  learnedWordDates?: string[];
  learnedLessonDates?: string[];
  visitDates?: string[];
};

export type GetUserStatsDto = {
  userId: string;
  visitDates?: boolean;
  visitStreak?: boolean;
  learnedWordDates?: boolean;
  learnedLessonDates?: boolean;
};

export type UserCourseResponse = {
  courseInformation: {
    course: Omit<ICourse, 'modules'>;
    module: Omit<IModule, 'lessons'>;
    lesson: Omit<ILesson, 'sections'>;
    section: Omit<ISection, 'steps' | 'amountSteps'>;
    passedSteps: {
      stepId: string;
      answeredCorrect: true;
      userAnswer: string;
    }[];
    lastPassedStep: IStep;
  };
  maxConsecutiveDaysStudied: number;
  learnedLessonsCount: number;
  learnedWordsCount: number;
  adoptedWordCount: number;
};

export const apiUsers = {
  getUsers: async ({ page, itemsPerPage, roles, letters, sortBy }: GetUsersDto) => {
    let query = `users/all?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (!!roles?.length) {
      query += `&roles[]=${roles.join('&roles=')}`;
    }
    if (!!letters?.length) {
      query += `&letters=${letters}`;
    }
    if (!!sortBy?.length) {
      const [sort, sortOrder] = sortBy.split('-');
      query += `&sort=${sort}&sortOrder=${sortOrder}`;
    }

    const abortController = new AbortController();
    const timeout = setTimeout(() => {
      abortController.abort();
      notifyWarn(NO_INTERNET_WARNING_MESSAGE);
    }, 2000);

    const response = await instance.get<IUserData>(query, { signal: abortController.signal });
    clearTimeout(timeout);

    return response.data;
  },
  getCurrentUser: async () => {
    const response = await instance.get<User>('users');
    return response.data;
  },
  createUser: async (data: CreateUserData) => {
    await instance.post('/users', {
      ...data
    });
  },
  createUserWithCourses: async (dto: CreateUserWithCoursesDto) => {
    await instance.post('/users/with-courses', dto);
  },
  getUserInfo: async (userId: string) => {
    const response = await instance.get<User>(`/users/one/${userId}`);
    return response.data;
  },
  updateUserInfo: async ({ id, body }: UpdateUserData) => {
    await instance.patch(`/users/${id}`, body);
  },
  uploadAvatar: async (userId: string, avatar: File): Promise<void> => {
    const formData = new FormData();
    formData.append('image', avatar, avatar.name);

    await instance.patch(`/users/${userId}/avatar`, formData);
  },
  deleteUser: async (userId: string) => {
    await instance.delete(`users/${userId}`);
  },
  getUserCourses: async (userId: string) => {
    const response = await instance.get<UserCourse[]>(`/users/${userId}/courses`);
    return response.data;
  },
  addUserCourses: async ({ userId, courseIds }: AddUserCoursesDto) => {
    await instance.post(`/users/${userId}/courses`, { courseIds });
  },
  deleteUserCourses: async ({ userId, courseIds }: DeleteUserCoursesDto) => {
    await instance.delete(`/users/${userId}/courses`, { data: { courseIds } });
  },
  getUserStats: async ({
    userId,
    visitDates,
    visitStreak,
    learnedWordDates,
    learnedLessonDates
  }: GetUserStatsDto) => {
    const include: string[] = [];
    if (visitDates) include.push('visitDates');
    if (visitStreak) include.push('visitStreak');
    if (learnedWordDates) include.push('learnedWordDates');
    if (learnedLessonDates) include.push('learnedLessonDates');

    const response = await instance.get<UserStatsReponse>(
      `stats/users/${userId}?${include ? include.map(value => `include=${value}`).join('&') : ''}`
    );
    return response.data;
  },
  getUserCourse: async (userId: string) => {
    const response = await instance.get<UserCourseResponse>(
      `/courses/${userId}/current-course-of-user`
    );
    return response.data;
  }
};
