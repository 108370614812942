import React from 'react';

const CoursesIcon: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M23.1523 8.06641L13.2031 4.51562C12.9922 4.44531 12.7461 4.375 12.5 4.375C12.2188 4.375 11.9727 4.44531 11.7266 4.51562L1.8125 8.06641C1.46094 8.20703 1.25 8.52344 1.25 8.875C1.25 9.26172 1.46094 9.57812 1.8125 9.71875L3.92188 10.457C3.5 11.0195 3.18359 11.6523 3.04297 12.3555C2.65625 12.5312 2.375 12.918 2.375 13.375C2.375 13.7266 2.51562 14.043 2.76172 14.2539L1.91797 19.4922C1.84766 19.8438 2.12891 20.125 2.44531 20.125H4.51953C4.83594 20.125 5.11719 19.8438 5.04688 19.4922L4.20312 14.2539C4.44922 14.043 4.625 13.7266 4.625 13.375C4.625 13.0234 4.41406 12.707 4.16797 12.5312C4.30859 11.8984 4.625 11.3359 5.04688 10.8789L11.7266 13.2695C11.9727 13.3398 12.2188 13.375 12.5 13.375C12.7461 13.375 12.9922 13.3398 13.2383 13.2695L23.1523 9.71875C23.5039 9.57812 23.75 9.26172 23.75 8.875C23.75 8.52344 23.5039 8.20703 23.1523 8.06641ZM12.6758 11.6875C12.5 11.7227 12.3594 11.6875 12.3242 11.6875L7.40234 9.92969L12.6406 8.3125C12.957 8.20703 13.0977 7.89062 13.0273 7.60938C12.9219 7.29297 12.6055 7.11719 12.3242 7.22266L5.64453 9.29688L4.51953 8.875L12.2891 6.09766C12.4297 6.0625 12.5703 6.09766 12.6406 6.09766L20.4453 8.875L12.6758 11.6875ZM17.1406 13.0586L17.5625 16.9961C17.5625 17.3828 15.8047 18.4375 12.5 18.4375C9.16016 18.4375 7.4375 17.3828 7.4375 16.9961L7.82422 13.0586L6.20703 12.4961L5.75 16.9258C5.75 19.0703 9.125 20.125 12.5 20.125C15.8398 20.125 19.25 19.0703 19.2148 16.9258L18.7578 12.4961L17.1406 13.0586Z'
        fill='currentColor'
      />
    </svg>
  );
};
export default CoursesIcon;
