import { FC } from 'react';

import { DEFAULT_COURSE_DICTIONARIES_AMOUNT } from './const';

import { Skeleton } from 'components/atoms';

const CourseDictionariesSkeleton: FC = () => {
  return (
    <>
      {Array.from({ length: DEFAULT_COURSE_DICTIONARIES_AMOUNT }).map((_, index) => (
        <Skeleton key={index} height={160} width={335} border={30} />
      ))}
    </>
  );
};

export default CourseDictionariesSkeleton;
