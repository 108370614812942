import { FC, useMemo } from 'react';

import { ReviewsModule } from 'api/api-analytics';
import { TOOLTIP_DEFAULT_HEIGHT, TOOLTIP_DEFAULT_WIDTH } from './const';
import { TooltipData } from './model';

import { IconBad, IconGood, IconNeutral } from 'components/atoms/icons';

interface ReviewsTooltipProps {
  currentModule: ReviewsModule | undefined;
  tooltipData: TooltipData;
}

const ReviewsTooltip: FC<ReviewsTooltipProps> = ({
  currentModule,
  tooltipData: { reviewsLesson, showTooltip, y, x, height, width, xAlign, yAlign }
}) => {
  const top = useMemo<number | undefined>(() => {
    if (!y || !height || !yAlign) return undefined;

    switch (yAlign) {
      case 'center':
        return y - height / 2 - TOOLTIP_DEFAULT_HEIGHT / 2;
      case 'top':
        return y;
      case 'bottom':
        return y;
    }
  }, [y, height, yAlign]);

  const left = useMemo<number | undefined>(() => {
    if (!x || !width || !xAlign) return undefined;

    switch (xAlign) {
      case 'center':
        return x + width / 2 - TOOLTIP_DEFAULT_WIDTH / 2;
      case 'left':
        return x;
      case 'right':
        return x - (TOOLTIP_DEFAULT_WIDTH - width);
    }
  }, [x, width, xAlign]);

  return (
    <div
      style={{
        opacity: showTooltip ? 1 : 0,
        top,
        left
      }}
      className={
        'absolute flex flex-col p-[16px] rounded-[12px] ' +
        `w-[${TOOLTIP_DEFAULT_WIDTH}px] h-[${TOOLTIP_DEFAULT_HEIGHT}px] ` +
        'transition-all duration-500 select-none pointer-events-none ' +
        'bg-[#FFFFFF] shadow-[0_4px_22px_0_rgba(0,0,0,0.08)]'
      }
    >
      <header className='mb-[24px] flex h-[58px] gap-[16px]'>
        <div className='h-[54px] w-[54px]'>
          <img src={reviewsLesson?.lesson.imageUrl} alt={reviewsLesson?.lesson.title} />
        </div>
        <div className='flex w-[155px] flex-col gap-[4px]'>
          {reviewsLesson && (
            <>
              <p className='text-[14px] leading-[16px] text-[#71798F]'>
                Урок {reviewsLesson.lesson.order}.
              </p>
              <h5
                className={
                  'text-[16px] font-[500] leading-[19px] text-[#20233A] ' +
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                }
              >
                {reviewsLesson.lesson.title}
              </h5>
            </>
          )}
          {currentModule && (
            <h6
              className={
                'text-[13px] font-[500] uppercase leading-[15px] text-[#71798F] ' +
                'overflow-hidden text-ellipsis whitespace-nowrap'
              }
            >
              {/* Модуль №{currentModule.order}. {currentModule.title} */}
              {currentModule.title}
            </h6>
          )}
        </div>
      </header>

      <div className='mb-[24px] flex w-[225px] items-center justify-between gap-[24px]'>
        <div className='flex w-[59px] flex-col items-center gap-[10px]'>
          <IconBad color='#FF4444' />
          <h3
            className={
              'text-[21px] font-[700] leading-[24px] text-[#20233A] ' +
              'overflow-hidden text-ellipsis whitespace-nowrap'
            }
          >
            {reviewsLesson?.badReviews}
          </h3>
        </div>
        <div className='flex w-[59px] flex-col items-center gap-[10px]'>
          <IconNeutral color='#71798F' />
          <h3
            className={
              'text-[21px] font-[700] leading-[24px] text-[#20233A] ' +
              'overflow-hidden text-ellipsis whitespace-nowrap'
            }
          >
            {reviewsLesson?.neutralReviews}
          </h3>
        </div>
        <div className='flex w-[59px] flex-col items-center gap-[10px]'>
          <IconGood color='#66C84D' />
          <h3
            className={
              'text-[21px] font-[700] leading-[24px] text-[#20233A] ' +
              'overflow-hidden text-ellipsis whitespace-nowrap'
            }
          >
            {reviewsLesson?.goodReviews}
          </h3>
        </div>
      </div>

      <div
        className={
          'flex h-[48px] w-[225px] items-center justify-center rounded-[62px] bg-[#5770F3] ' +
          'text-[16px] leading-[19px] font-[700] text-[#FFFFFF] text-center'
        }
      >
        Просмотреть список
      </div>
    </div>
  );
};

export default ReviewsTooltip;
