import { FC, useState } from 'react';

import Button from 'components/atoms/Button';
import { IconImport } from 'components/atoms/icons';
import { Periods } from './Periods';
import { Mistakes, Reviews } from './components';

type LessonStatisticsProps = {
  courseId: string;
};

const LessonStatistics: FC<LessonStatisticsProps> = ({ courseId }) => {
  const [option, setOption] = useState<number>(0);

  return (
    <div className='flex w-full flex-1 flex-col'>
      <Periods />

      <header className='flex h-[96px] items-center justify-between py-[16px] px-[15px]'>
        {/* <ControlledSelect
          placeholder='Выберите график'
          options={options}
          className='w-[210px]'
          value={options.find(({ value }) => Number(value) === option) || options[0]}
          onChange={option => {
            setOption(Number(option.value));
          }}
        /> */}

        <h3 className='text-[21px] font-[700] leading-[24px] text-[#20233A]'>Ошибки в уроках</h3>

        <Button
          type='button'
          variant='light'
          title='Скачать .xlsx (90Кб)'
          icon={<IconImport color='#5770F3' />}
          iconPosition='left'
          className='h-[48px] rounded-[62px]'
          isDisabled
        />
      </header>

      {option === 0 && <Mistakes courseId={courseId} />}
      {option === 1 && <Reviews courseId={courseId} />}
    </div>
  );
};

export default LessonStatistics;
