import { FC } from 'react';
import { Control } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'consts';
import { FormValues } from '../../../../model';

import Input from 'components/atoms/Input';

interface RepeatMonthProps {
  control: Control<FormValues>;
}

const RepeatMonth: FC<RepeatMonthProps> = ({ control }) => {
  return (
    <div className='flex flex-col gap-[16px]'>
      <Input
        type='text'
        variant='dark'
        width='big'
        placeholder='Число'
        name='repeatEveryMonthDay'
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          validate: {
            validTime: (value: any) => {
              const dayNumber = Number(value);
              if (Number.isInteger(dayNumber) && dayNumber >= 1 && dayNumber <= 31) {
                return true;
              }
              return 'Введите корректное число месяца (1-31)';
            }
          }
        }}
        containerClassName=''
        className='h-[56px] w-[100px] text-[16px] font-[500] leading-[22px] text-[#71798F]'
      />

      <Input
        type='text'
        variant='dark'
        placeholder='Время'
        name='repeatEveryMonthTime'
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          validate: {
            validTime: (value: any) => {
              const errorMessage = 'Введите корректное время в формате hh:mm';
              const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
              if (!timeRegex.test(value)) {
                return errorMessage;
              }
              const [hours, minutes] = value.split(':').map(Number);
              if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
                return errorMessage;
              }
              return true;
            }
          }
        }}
        containerClassName=''
        className='h-[56px] w-[100px] text-[16px] font-[500] leading-[22px] text-[#71798F]'
      />
    </div>
  );
};

export default RepeatMonth;
