import { Menu } from '@headlessui/react';
import { FC, SetStateAction } from 'react';
import { classNames } from '../../../utils';
import IconBack from '../icons/IconBack';
import cls from './MenuButton.module.css';

export type MenuItems = Array<{
  title?: string;
  items: Array<{
    title: string;
    onClick: (...props: any) => void;
    icon?: React.ReactNode;
    wrapper?: (children: React.ReactNode) => React.ReactNode;
  }>;
}>;

interface MenuButtonProps {
  items: MenuItems;
  isMustBeOpened?: boolean;
  setIsMustBeOpened?: React.Dispatch<SetStateAction<boolean>>;
  className?: string;
  title: string;
  dropdownMenuContainerClassName?: string;
}

const MenuButton: FC<MenuButtonProps> = ({
  items,
  isMustBeOpened,
  setIsMustBeOpened,
  className,
  title,
  dropdownMenuContainerClassName
}) => {
  return (
    <Menu>
      {({ open, close }) => (
        <div className={`relative ${className}`}>
          <Menu.Button
            className={cls.menuButton}
            onClick={() => {
              if (isMustBeOpened) setTimeout(close, 0);
              if (setIsMustBeOpened) setIsMustBeOpened(false);
            }}
          >
            <span>{title}</span>
            <IconBack className={'rotate-[270deg] text-[#fff]'} />
          </Menu.Button>
          {(open || isMustBeOpened) && (
            <Menu.Items
              static
              className={classNames(cls.container, {}, [dropdownMenuContainerClassName])}
            >
              {items.map((item, index, array) => {
                return (
                  <div key={index}>
                    {item.title && <div className={cls.itemsTitle}>{item.title}</div>}
                    {item.items.map((nestedItem, index) => (
                      <Menu.Item key={index}>
                        {() => {
                          if (nestedItem.wrapper) {
                            return nestedItem.wrapper(
                              <button
                                className={cls.itemButton}
                                onClick={event => {
                                  event.stopPropagation();
                                  nestedItem.onClick();
                                }}
                              >
                                {nestedItem.icon}
                                {nestedItem.title}
                              </button>
                            );
                          }
                          return (
                            <button
                              className={cls.itemButton}
                              onClick={event => {
                                event.stopPropagation();
                                nestedItem.onClick();
                                // close();
                              }}
                            >
                              {nestedItem.icon}
                              {nestedItem.title}
                            </button>
                          );
                        }}
                      </Menu.Item>
                    ))}
                    {array.length !== index && <div className={cls.divider} />}
                  </div>
                );
              })}
            </Menu.Items>
          )}
        </div>
      )}
    </Menu>
  );
};

export default MenuButton;
