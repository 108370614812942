import { useMutation, useQueryClient } from 'react-query';

import { DeleteWordsFromLessonDto, apiCourses } from 'api/api-courses';
import { handleError } from 'helpers/handleError';
import { notifySuc } from 'helpers/notification';

export const useDeleteWordsFromLessonMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ lessonId, wordIds }: DeleteWordsFromLessonDto) => {
      return apiCourses.deleteWords({ lessonId, wordIds });
    },
    {
      onSuccess(_, { lessonId }) {
        notifySuc('Слово удалено из урока');
        queryClient.invalidateQueries([`lessons/one/${lessonId}`]);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
