import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import avatarPlaceholder from 'assets/images/png/emptyAvatar.png';
import LogoSVG from 'assets/images/svg/LogoWithoutBg.svg';

import { LINK_TO_CQ_CHAT } from 'consts';
import { useGetCurrentUserQuery } from 'hooks/queries';
import { setLogin } from 'store/actionCreators/authActions';
import { getInitials } from 'utils';

import cls from './SideBar.module.css';

import {
  CheckMarkIcon,
  CoursesIcon,
  IconChart,
  IconChat,
  IconDealNotification,
  IconDictionary,
  IconExit,
  IconGear,
  IconNotification,
  TransactionIcon,
  UsersIcon
} from 'components/atoms/icons';

interface SideBarProps {
  sidebarCollapsed: boolean;
  setSidebarCollapsed: (bool: boolean) => void;
}

const SideBar: FC<SideBarProps> = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: viewer } = useGetCurrentUserQuery();

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);

  useEffect(() => {
    if (sidebarCollapsed) {
      setSidebarOpen(false);
    } else {
      setTimeout(() => {
        setSidebarOpen(true);
      }, 200);
    }
  }, [sidebarCollapsed]);

  const logOut = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('refreshToken');
    dispatch(setLogin(false));
  };

  return (
    <div
      className={
        `fixed duration-200 min-h-[100%] h-screen flex flex-col justify-between pt-[45px] px-[24px] pb-[40px] bg-[#162030] rounded-tr-3xl z-10 overflow-y-scroll ${cls.hideScroll} ` +
        (sidebarCollapsed ? 'w-[90px] !px-[13px]' : 'w-[271px]')
      }
    >
      <NavLink className='z-20 mb-16' to='/'>
        <div className='flex items-center'>
          <div className='h-[57px] w-[57px]'>
            <img src={LogoSVG} alt='LogoWithoutBg.svg' className='h-full w-full' />
          </div>

          {sidebarOpen && (
            <div className='ml-[9px] flex flex-col items-start opacity-100 duration-300'>
              <span
                className={
                  'mb-[2px] text-[13px] font-[500] uppercase leading-[15px] text-[#FFFFFF] opacity-50'
                }
              >
                admin panel
              </span>

              <h1 className={'text-[20px] font-[500] leading-[23px] text-[white]'}>
                Korean simple
              </h1>
            </div>
          )}
        </div>
      </NavLink>

      <nav className='mb-auto flex flex-col items-center'>
        <NavLink
          to='/courses'
          className={({ isActive }) =>
            'relative mb-[4px] h-[56px] w-full pl-[17px] pr-[16px] text-[16px] flex items-center font-medium rounded-[12px] duration-300 gap-[12px] ' +
            `${sidebarOpen ? '' : 'justify-center'} ` +
            `${
              isActive
                ? 'text-[#FFFFFF] bg-[rgba(87,112,243,0.05)] before:fixed before:left-0 before:h-[24px] before:w-[3px] before:bg-primary before:text-[white] bluePath'
                : 'text-[#71798F] hover:bg-[rgba(87,112,243,0.05)] hover:text-[white]'
            }`
          }
        >
          <CoursesIcon />
          {sidebarOpen && <span className='text-[16px]'>Курсы</span>}
        </NavLink>
        <NavLink
          to='/dictionaries'
          className={({ isActive }) =>
            'relative mb-[4px] h-[56px] w-full pl-[17px] pr-[16px] text-[16px] flex items-center font-medium rounded-[12px] duration-300 gap-[12px] ' +
            `${sidebarOpen ? '' : 'justify-center'} ` +
            `${
              isActive
                ? 'text-[#FFFFFF] bg-[rgba(87,112,243,0.05)] before:fixed before:left-0 before:h-[24px] before:w-[3px] before:bg-primary before:text-[white] bluePath'
                : 'text-[#71798F] hover:bg-[rgba(87,112,243,0.05)] hover:text-[white]'
            }`
          }
        >
          <IconDictionary />
          {sidebarOpen && <span className='text-[16px]'>Словари</span>}
        </NavLink>
        <NavLink
          to='/users'
          className={({ isActive }) =>
            'relative mb-[4px] h-[56px] w-full pl-[17px] pr-[16px] text-[16px] flex items-center font-medium rounded-[12px] duration-300 gap-[12px] ' +
            `${sidebarOpen ? '' : 'justify-center'} ` +
            `${
              isActive
                ? 'text-[#FFFFFF] bg-[rgba(87,112,243,0.05)] before:fixed before:left-0 before:h-[24px] before:w-[3px] before:bg-primary before:text-[white] bluePath'
                : 'text-[#71798F] hover:bg-[rgba(87,112,243,0.05)] hover:text-[white]'
            }`
          }
        >
          <UsersIcon />
          {sidebarOpen && <span className='text-[16px]'>Пользователи</span>}
        </NavLink>
        <a
          href={LINK_TO_CQ_CHAT}
          rel='noreferrer'
          target='_blank'
          className={
            'relative mb-[4px] h-[56px] w-full pl-[17px] pr-[16px] text-[16px] flex items-center font-medium rounded-[12px] duration-300 gap-[12px] ' +
            `${sidebarOpen ? '' : 'justify-center'} ` +
            'text-[#71798F] hover:bg-[rgba(87,112,243,0.05)] hover:text-[white]'
          }
        >
          <IconChat />
          {sidebarOpen && <span className='text-[16px]'>Чат (Carrot Quest)</span>}
        </a>
        <NavLink
          to='/notifications'
          className={({ isActive }) =>
            'relative mb-[4px] h-[56px] w-full pl-[17px] pr-[16px] text-[16px] flex items-center font-medium rounded-[12px] duration-300 gap-[12px] ' +
            `${sidebarOpen ? '' : 'justify-center'} ` +
            `${
              isActive
                ? 'text-[#FFFFFF] bg-[rgba(87,112,243,0.05)] before:fixed before:left-0 before:h-[24px] before:w-[3px] before:bg-primary before:text-[white] bluePath'
                : 'text-[#71798F] hover:bg-[rgba(87,112,243,0.05)] hover:text-[white]'
            }`
          }
        >
          <IconNotification />
          {sidebarOpen && <span className='text-[16px]'>Создать PUSH</span>}
        </NavLink>
        <NavLink
          to='/deal-notifications'
          className={({ isActive }) =>
            'relative mb-[4px] h-[56px] w-full pl-[17px] pr-[16px] text-[16px] flex items-center font-medium rounded-[12px] duration-300 gap-[12px] ' +
            `${sidebarOpen ? '' : 'justify-center'} ` +
            `${
              isActive
                ? 'text-[#FFFFFF] bg-[rgba(87,112,243,0.05)] before:fixed before:left-0 before:h-[24px] before:w-[3px] before:bg-primary before:text-[white] bluePath'
                : 'text-[#71798F] hover:bg-[rgba(87,112,243,0.05)] hover:text-[white]'
            }`
          }
        >
          <IconDealNotification />
          {sidebarOpen && <span className='text-[16px]'>Уведомления</span>}
        </NavLink>

        <NavLink
          to='/purchases'
          className={({ isActive }) =>
            'relative mb-[4px] h-[56px] w-full pl-[17px] pr-[16px] text-[16px] flex items-center font-medium rounded-[12px] duration-300 gap-[12px] ' +
            `${sidebarOpen ? '' : 'justify-center'} ` +
            `${
              isActive
                ? 'text-[#FFFFFF] bg-[rgba(87,112,243,0.05)] before:fixed before:left-0 before:h-[24px] before:w-[3px] before:bg-primary before:text-[white] blueStroke'
                : 'text-[#71798F] hover:bg-[rgba(87,112,243,0.05)] hover:text-[white]'
            }`
          }
        >
          <TransactionIcon />
          {sidebarOpen && <span className='text-[16px]'>Транзакции</span>}
        </NavLink>

        <NavLink
          to='/analytics'
          className={({ isActive }) =>
            'relative mb-[4px] h-[56px] w-full pl-[17px] pr-[16px] text-[16px] flex items-center font-medium rounded-[12px] duration-300 gap-[12px] ' +
            `${sidebarOpen ? '' : 'justify-center'} ` +
            `${
              isActive
                ? 'text-[#FFFFFF] bg-[rgba(87,112,243,0.05)] before:fixed before:left-0 before:h-[24px] before:w-[3px] before:bg-primary before:text-[white] blueStroke'
                : 'text-[#71798F] hover:bg-[rgba(87,112,243,0.05)] hover:text-[white]'
            }`
          }
        >
          <IconChart />
          {sidebarOpen && <span className='text-[16px]'>Аналитика</span>}
        </NavLink>
      </nav>

      {viewer && (
        <div className={'flex flex-col items-center'}>
          <img
            className={'mb-[16px] h-[64px] w-[64px] rounded-[50%] object-cover'}
            src={viewer.avatarUrl || avatarPlaceholder}
            alt='user-avatar'
          />

          {sidebarOpen ? (
            <>
              <h4
                className={
                  'mb-[8px] w-full overflow-hidden text-ellipsis whitespace-nowrap text-center text-[18px] font-[500] leading-[21px] text-[white]'
                }
                title={viewer.name}
              >
                {viewer.name}
              </h4>

              <p
                className={
                  'mb-[20px] w-full overflow-hidden text-ellipsis whitespace-nowrap text-center text-[16px] leading-[24px] text-[#A6B0C9]'
                }
                title={viewer.email}
              >
                {viewer.email}
              </p>
            </>
          ) : (
            <h4
              className={
                'mb-[20px] w-full overflow-hidden text-ellipsis whitespace-nowrap text-center text-[18px] font-[500] leading-[21px] text-[white]'
              }
              title={viewer.name}
            >
              {getInitials(viewer.name)}
            </h4>
          )}

          <div
            className={
              'flex items-center gap-[16px] duration-500 ' +
              (sidebarOpen ? '' : 'justify-between flex-col')
            }
          >
            <button
              className={
                'flex items-center gap-[4px] text-[#71798F] duration-300 hover:text-primary'
              }
              title='Профиль'
              onClick={() => navigate(`/users/${viewer.id}`)}
            >
              {sidebarOpen && <span className={'text-[16px] leading-[24px]'}>Профиль</span>}
              <IconGear />
            </button>

            <button
              className={
                'flex items-center gap-[4px] text-[#71798F] duration-300 hover:text-primary'
              }
              title='Выйти'
              onClick={() => {
                logOut();
                navigate('/login');
              }}
            >
              {sidebarOpen && <span className={'text-[16px] leading-[24px]'}>Выйти</span>}
              <IconExit />
            </button>
          </div>
        </div>
      )}

      <div
        onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
        className={
          'flex items-center justify-center w-[44px] h-[44px] rounded-[100px] fixed left-[227px] translate-x-[45%] top-[58px] bg-[#162030] text-[white] cursor-pointer duration-200 ' +
          (sidebarCollapsed ? 'translate-x-[45%] rotate-180 left-[46px]' : '')
        }
      >
        <CheckMarkIcon className='text-[white]' />
      </div>
    </div>
  );
};
export default SideBar;
