/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'assets/css/main.css';
import { useTypedSelector } from './hooks';

import {
  AnalyticsPage,
  CreateNotificationPage,
  CreateThematicDictionaryPage,
  CreateUserPage,
  DealNotificationPage,
  DealNotificationsPage,
  NotificationPage,
  NotificationsPage,
  PurchasesPage,
  ThematicDictionaryPage
} from 'pages';
import CatalogPage from 'pages/CatalogPage';
import { DictionaryPage } from 'pages/DictionaryPage';
import LessonWordsPage from 'pages/LessonWordsPage';
import WordPage from 'pages/WordPage';
import Console from './layouts/console/console';
import CoursePage from './pages/CoursePage/CoursePage';
import CoursesPage from './pages/CoursesPage/CoursesPage';
import DictionariesPage from './pages/DictionariesPage';
import LessonSectionPage from './pages/LessonSectionPage/LessonSectionPage';
import LoginPage from './pages/LoginPage/LoginPage';
import UserPage from './pages/UserPage/UserPage';
import UsersPage from './pages/UsersPage/UsersPage';

const WithConsoleLayout: React.FC = () => (
  <Console>
    <Outlet />
  </Console>
);

const App: React.FC = () => {
  const isLoggedIN = useTypedSelector(state => state.auth.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIN && location.pathname === '/') {
      navigate('/courses');
    }
  }, [isLoggedIN]);

  return (
    <>
      {isLoggedIN ? (
        <Routes>
          <Route element={<WithConsoleLayout />}>
            <Route path={'/users'} element={<UsersPage />} />
            <Route path={'/users/create'} element={<CreateUserPage />} />
            <Route path={'/users/:id/*'} element={<UserPage />} />

            <Route path={'/courses'} element={<CoursesPage />} />
            <Route path={'/courses/:courseId/*'} element={<CoursePage />} />
            <Route
              path={'/courses/:courseId/:moduleId/:lessonId'}
              element={<LessonSectionPage />}
            />
            <Route
              path={'/courses/:courseId/no-module/:lessonId'}
              element={<LessonSectionPage />}
            />

            <Route path={'/dictionaries'} element={<DictionariesPage />} />
            <Route
              path={'/dictionaries/create-thematic-dictionary'}
              element={<CreateThematicDictionaryPage />}
            />
            <Route path={'/dictionaries/catalog'} element={<CatalogPage />} />
            <Route path={'/dictionaries/catalog/:wordId'} element={<WordPage />} />
            <Route path={'/dictionaries/:courseId'} element={<DictionaryPage />} />
            <Route
              path={'/dictionaries/thematic/:dictionaryId'}
              element={<ThematicDictionaryPage />}
            />
            <Route
              path={'/dictionaries/:courseId/:moduleId/:lessonId'}
              element={<LessonWordsPage />}
            />
            <Route
              path={'/dictionaries/:courseId/no-module/:lessonId'}
              element={<LessonWordsPage />}
            />

            <Route path={'/notifications'} element={<NotificationsPage />} />
            <Route path={'/notifications/create'} element={<CreateNotificationPage />} />
            <Route path={'/notifications/:notificationId'} element={<NotificationPage />} />

            <Route path={'/deal-notifications'} element={<DealNotificationsPage />} />
            <Route
              path={'/deal-notification/:dealNotificationId'}
              element={<DealNotificationPage />}
            />

            <Route path={'purchases'} element={<PurchasesPage />} />

            <Route path='/analytics' element={<AnalyticsPage />} />

            <Route path='*' element={<Navigate to='/courses' replace />} />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route path={'/login'} element={<LoginPage />} />
          <Route path={'*'} element={<LoginPage />} />
        </Routes>
      )}
      <ToastContainer />
    </>
  );
};

export default App;
