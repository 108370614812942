import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import miniAvatarSVG from 'assets/images/svg/mini-avatar.svg';
import { COURSES_COLORS } from 'consts';
import { ThematicDictionary } from 'models';
import styles from './ThematicDictionaryCard.module.css';

import { IconPen } from 'components/atoms/icons';

type ThematicDictionaryCardProps = {
  dictionary: ThematicDictionary;
};

const ThematicDictionaryCard: FC<ThematicDictionaryCardProps> = ({
  dictionary: { id, title, imageUrl, color }
}) => {
  const navigate = useNavigate();
  const goToDictionaryPage = () => {
    navigate(`/dictionaries/thematic/${id}`);
  };

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: color || COURSES_COLORS[0].hex }}
      onClick={goToDictionaryPage}
    >
      <div className='flex flex-1 flex-col'>
        <header className={styles.header}>
          <span className={styles.theme}>Тема:</span>
        </header>

        <p title={title} className={styles.title}>
          {title}
        </p>

        <button className={styles.buttonEdit}>
          <IconPen className='h-[20px] w-[20px] text-[#5770F3]' />
          Редактировать
        </button>
      </div>

      {imageUrl && (
        <div className='h-[120px] w-[120px] shrink-0 p-[12px]'>
          <img src={imageUrl} alt={title} className='h-full w-full' />
        </div>
      )}
    </div>
  );
};

export default ThematicDictionaryCard;
