import { FC } from 'react';

import avatarPlaceholder from 'assets/images/svg/avatar.svg';
import { User } from 'models';
import styles from './SelectSpeakerCard.module.css';

import { IconCheckOff, IconCheckOn } from 'components/atoms/icons';

interface SelectSpeakerCardProps {
  speaker: User;
  isSelected: boolean;
  selectSpeaker: () => void;
}

const SelectSpeakerCard: FC<SelectSpeakerCardProps> = ({
  speaker: { name, avatarUrl },
  isSelected,
  selectSpeaker
}) => {
  return (
    <button
      type='button'
      className={`${styles.container} ${isSelected ? styles.container_selected : ''}`}
      onClick={selectSpeaker}
    >
      <div className={styles.avatar}>
        <img src={avatarUrl || avatarPlaceholder} alt={`${name}-avatar`} />
      </div>
      <h3 className={styles.title}>{name}</h3>
      {isSelected ? <IconCheckOn color='#5770F3' /> : <IconCheckOff color='#EFF1F4' />}
    </button>
  );
};

export default SelectSpeakerCard;
