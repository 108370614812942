import { FC } from 'react';

import { steps } from './const';

interface StepperProps {
  activeStep: number;
  setStep: (stepIndex: number) => void;
}

const Stepper: FC<StepperProps> = ({ activeStep, setStep }) => {
  return (
    <div className='flex flex-col'>
      <h6 className='mb-[16px] text-[13px] font-[500] uppercase leading-[15px] text-[#71798F]'>
        Шаги
      </h6>

      <div className='flex gap-[12px]'>
        {steps.map((step, index) => {
          const isPassed = activeStep > step.value;
          const isActive = activeStep === step.value;

          return (
            <div key={step.value} className='flex gap-[12px]'>
              <button
                type='button'
                className={
                  'flex gap-[12px] items-center ' +
                  `${isPassed ? 'text-[#66C84D]' : ''} ` +
                  `${isActive ? 'text-[blue]' : ''}`
                }
                onClick={() => setStep(step.value)}
              >
                <span
                  className={
                    'flex h-[40px] w-[40px] items-center justify-center rounded-[50%] bg-[#F5F5F5] text-[#71798F] ' +
                    `${isPassed ? '!bg-[rgba(102,200,77,0.1)] !text-[#66C84D]' : ''} ` +
                    `${isActive ? '!bg-[#EEF0FF] !text-[#5770F3]' : ''}`
                  }
                >
                  {step.value}
                </span>
                <span
                  className={
                    'text-[16px] font-[500] leading-[19px] text-[#71798F] ' +
                    `${isPassed ? '!text-[#66C84D]' : ''} ` +
                    `${isActive ? '!text-[#5770F3]' : ''}`
                  }
                >
                  {step.label}
                </span>

                {index < steps.length - 1 && <span className='h-[2px] w-[8px] bg-[#EFF1F4]' />}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
