import { instance } from './api';

export type GetAllWordsDto = {
  page: number;
  itemsPerPage: number;
  letters: string;
  withFavorites: boolean;
  withReferences?: boolean;
  withExamples?: boolean;
};

export interface Example {
  id: string;
  original: string;
  translation: string;
}

export interface IWord {
  id: string;
  index: number;
  original: string;
  translation: string;
  transliteration: string;
  naverUrl?: string;
  examples: Example[];
  imageUrl?: string;
  audioUrl?: string;
  references?: IWordReference[];
}

export interface UpdateWordDto {
  wordId: string;
  original?: string;
  translation?: string;
  transliteration?: string;
  naverUrl?: string | null;
}

export type DeleteWordDto = {
  wordId: string;
  lessonId?: string;
  dictionaryId?: string;
};

export type DeleteWordsDto = {
  lessonId?: string;
  dictionaryId?: string;
  body: {
    wordIds: string[];
  };
};

export interface IPagination {
  currentPage: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  lastPage: number;
  nextPage: number;
  prevPage: number;
  totalItems: number;
}

export interface IWordData {
  data: IWord[];
  pagination: IPagination;
}

export type GetWordDto = { wordId: string; isFavorite?: boolean; withReferences?: boolean };

export type GenerateTransliterationDto = { wordId: string; original: string };

export type GenerateImageDto = { wordId: string };

export type DeleteImageDto = { wordId: string };

export type GenerateTransliterationResponse = {
  audioUrl: string;
  transliteration: string;
};

export type SetWordImageDto = {
  wordId: string;
  lessonId?: string;
  dictionaryId?: string;
  body: {
    image: File;
  };
};

export type IWordReference = {
  course?: { id: string; title: string; level: string };
  lesson?: { id: string; title: string };
  courseId?: string;
  moduleId?: string;
  lessonId?: string;
  thematicDictionaryId?: string;
  thematicDictionary?: { id: string; title: string };
};

export interface UploadBase64ImageDto {
  wordId: string;
  base64: string;
}

export interface UploadImageFromUrlDto {
  wordId: string;
  url: string;
}

export const apiWords = {
  createWord: async (original: string, translation: string) => {
    const response = await instance.post<IWord>('/words', {
      original,
      translation
    });
    return response.data;
  },
  getAllWords: async ({
    page,
    itemsPerPage,
    letters = '',
    withFavorites = true,
    withReferences = true,
    withExamples = false
  }: GetAllWordsDto) => {
    // TODO add query constant for a clean request, as in getUsers or getNotifications.
    const response = await instance.get<IWordData>(
      `/words/all?page=${page}&itemsPerPage=${itemsPerPage}&letters=${letters}&withFavorites=${withFavorites}&withReferences=${withReferences}&withExamples=${withExamples}`
    );
    return response.data;
  },
  getWord: async ({ wordId, isFavorite = false, withReferences = false }: GetWordDto) => {
    const response = await instance.get<IWord>(
      `/words/one/${wordId}?isFavorite=${isFavorite}&withReferences=${withReferences}`
    );
    return response.data;
  },
  updateWord: async ({ wordId, ...body }: UpdateWordDto) => {
    const response = await instance.patch<IWord>(`/words/${wordId}`, body);
    return response.data;
  },
  deleteWord: async ({ wordId }: DeleteWordDto) => {
    const response = await instance.delete(`/words/${wordId}`);
    return response.data;
  },
  deleteWords: async ({ body }: DeleteWordsDto) => {
    const response = await instance.delete('/words/delete-many', {
      data: body
    });
    return response.data;
  },
  addExample: async (wordId: string, original: string, translation: string) => {
    const response = await instance.patch<Example>(`/words/${wordId}/example`, {
      original,
      translation
    });
    return response.data;
  },
  deleteExample: async (wordId: string, exampleId: string) => {
    await instance.delete(`/words/${wordId}/example/${exampleId}`);
  },
  setWordImage: async ({ wordId, body: { image } }: SetWordImageDto) => {
    const formData = new FormData();
    formData.append('image', image);

    await instance.patch(`/words/${wordId}/image`, formData);
  },
  updateExample: async (exampleId: string, original: string, translation: string) => {
    const response = await instance.patch<Example>(`/examples/${exampleId}`, {
      original,
      translation
    });
    return response.data;
  },
  generateTransliteration: async ({ wordId, original }: GenerateTransliterationDto) => {
    const response = await instance.patch<GenerateTransliterationResponse>(
      `/words/new-transliteration/${wordId}`,
      { original }
    );
    return response.data;
  },
  generateImage: async ({ wordId }: GenerateImageDto) => {
    const response = await instance.get<string[]>(`/words/image/generate/${wordId}`);
    return response.data;
  },
  uploadBase64Image: async ({ wordId, base64 }: UploadBase64ImageDto) => {
    const response = await instance.patch<IWord>(`/words/${wordId}/base64-image`, {
      wordId,
      base64
    });
    return response.data;
  },
  uploadImageFromUrl: async ({ wordId, url }: UploadImageFromUrlDto) => {
    const response = await instance.patch<IWord>(`/words/${wordId}/url-image`, {
      wordId,
      url
    });
    return response.data;
  },
  deleteImage: async ({ wordId }: DeleteImageDto) => {
    const response = await instance.delete(`/words/${wordId}/image`);
    return response.data;
  }
};
