import React from 'react';

const SortDisabledIcon: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.575 11.9083L9.99999 15.4917L6.42499 11.9083C6.26807 11.7514 6.05524 11.6633 5.83333 11.6633C5.61141 11.6633 5.39858 11.7514 5.24166 11.9083C5.08474 12.0653 4.99658 12.2781 4.99658 12.5C4.99658 12.7219 5.08474 12.9348 5.24166 13.0917L9.40833 17.2583C9.48579 17.3365 9.57796 17.3984 9.67951 17.4408C9.78106 17.4831 9.88998 17.5048 9.99999 17.5048C10.11 17.5048 10.2189 17.4831 10.3205 17.4408C10.422 17.3984 10.5142 17.3365 10.5917 17.2583L14.7583 13.0917C14.836 13.014 14.8977 12.9217 14.9397 12.8202C14.9818 12.7187 15.0034 12.6099 15.0034 12.5C15.0034 12.3901 14.9818 12.2813 14.9397 12.1798C14.8977 12.0783 14.836 11.986 14.7583 11.9083C14.6806 11.8306 14.5884 11.769 14.4869 11.727C14.3853 11.6849 14.2765 11.6633 14.1667 11.6633C14.0568 11.6633 13.948 11.6849 13.8465 11.727C13.7449 11.769 13.6527 11.8306 13.575 11.9083Z'
        fill='#8C9AAF'
      />
      <path
        d='M9.99999 4.50834L6.42499 8.09168C6.26807 8.2486 6.05524 8.33675 5.83333 8.33675C5.61141 8.33675 5.39858 8.2486 5.24166 8.09168C5.08474 7.93476 4.99658 7.72193 4.99658 7.50001C4.99658 7.39013 5.01823 7.28132 5.06028 7.1798C5.10233 7.07828 5.16396 6.98604 5.24166 6.90834L9.40833 2.74168C9.48579 2.66357 9.57796 2.60157 9.67951 2.55927C9.78106 2.51696 9.88998 2.49518 9.99999 2.49518C10.11 2.49518 10.2189 2.51696 10.3205 2.55927C10.422 2.60157 10.5142 2.66357 10.5917 2.74168L14.7583 6.90834C14.8364 6.98581 14.8984 7.07798 14.9407 7.17953C14.983 7.28108 15.0048 7.39 15.0048 7.50001C15.0048 7.61002 14.983 7.71894 14.9407 7.82049C14.8984 7.92204 14.8364 8.01421 14.7583 8.09168C14.6809 8.16978 14.5887 8.23178 14.4871 8.27409C14.3856 8.31639 14.2767 8.33818 14.1667 8.33818C14.0566 8.33818 13.9477 8.31639 13.8462 8.27409C13.7446 8.23178 13.6525 8.16978 13.575 8.09168L9.99999 4.50834Z'
        fill='#8C9AAF'
      />
    </svg>
  );
};
export default SortDisabledIcon;
